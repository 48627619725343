import type { AssetCategory } from '@bit-ui-libs/common'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AssetsService } from '@/core'

import type { TypeaheadOption } from '..'
import { Typeahead } from '..'

export interface CategoryAutocompleteProps {
  label?: string
  selected?: AssetCategory
  parentId?: string
  onChange?: (selected: AssetCategory) => void | Promise<void>
  className?: string
}

const mapCategoryOption = (category?: AssetCategory) =>
  category
    ? ({ id: category.id, text: category.name } as TypeaheadOption)
    : undefined

const assetService = new AssetsService()

export const CategoryAutocomplete: React.FC<CategoryAutocompleteProps> = (
  props,
) => {
  const defaultLabel = 'Category' // todo: add translation

  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState<AssetCategory[]>([])

  const options = useMemo(
    () => categories.map(mapCategoryOption) as TypeaheadOption[],
    [categories],
  )

  const selectedOption = useMemo(
    () => mapCategoryOption(props.selected),
    [props.selected],
  )

  const handleChange = useCallback(
    (value: TypeaheadOption) => {
      if (props.onChange) {
        props.onChange(categories.find((i) => i.id === value.id)!)
      }
    },
    [props.onChange, categories],
  )

  const handleChangeFilter = useCallback(
    async (search: string) => {
      setIsLoading(true)
      const _categories = await assetService.getCategories({
        parentId: props.parentId,
        search,
      })
      setIsLoading(false)
      setCategories(_categories.items)
    },
    [props.parentId, setIsLoading, setCategories],
  )

  useEffect(() => {
    handleChangeFilter('')
  }, [props.parentId])

  return (
    <Typeahead
      label={props.label ?? defaultLabel}
      loading={isLoading}
      onChange={handleChange}
      onFilterChange={handleChangeFilter}
      options={options}
      selected={selectedOption}
      className={props.className}
    />
  )
}
