// TODO add a description of the expected usage of each color as a docstring
export const colors = {
  background: '#F5F5F5',
  surface: '#FFFFFF',
  surfaceVariant: '#DDE8ED',
  cardContrast: '#013D6B',
  secondary: {
    50: '#f0faff',
    100: '#e0f4fe',
    200: '#b9eafe',
    300: '#7cdbfd',
    400: '#36cafa',
    500: '#0cb3eb',
    DEFAULT: '#008ac0',
    600: '#008ac0',
    700: '#0173a3',
    800: '#066186',
    900: '#0b506f',
    950: '#07334a',
  },
  primary: {
    50: '#f3faeb',
    100: '#e4f4d3',
    200: '#cce9ad',
    300: '#abda7c',
    400: '#7fc241',
    DEFAULT: '#7fc241',
    500: '#6dad35',
    600: '#538927',
    700: '#406922',
    800: '#365420',
    900: '#2f481f',
    950: '#16270c',
  },
  details: '#0C0D18',
  linkGreen: '#44A85F',
  onboardingGray: '#898C8C',
  onboardingBackGround: '#f9f9f9',
  surfaceDark: '#1C1B1F',
  secondaryVariant: '#008AC0',
  error: '#ef4444',
  disabled: '#AAAAAA',
  yellow: '#D8BD5D',
  iconGray: '#464646',
  redGoogle: '#db4a39',
  blue: '#3b5998',
  yellowLight: '#D7BB1B',
  gray: {
    50: '#f6f6f6',
    100: '#e7e7e7',
    200: '#d1d1d1',
    300: '#b0b0b0',
    400: '#888888',
    500: '#767676',
    600: '#5d5d5d',
    700: '#4f4f4f',
    800: '#454545',
    900: '#3d3d3d',
    950: '#262626',
  },
  info: {
    '50': '#eff6ff',
    '100': '#dbebfe',
    '200': '#bfdbfe',
    '300': '#93c2fd',
    '400': '#60a5fa',
    DEFAULT: '#60a5fa',
    '500': '#3b8ff6',
    '600': '#257eeb',
    '700': '#1d71d8',
    '800': '#1e5faf',
    '900': '#1e4e8a',
    '950': '#173254',
  },
  red: {
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#d72323',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d',
    '950': '#450a0a',
  },
}
