import { useContext } from 'react'

import { ProductListingContext, ProductListingDispatch } from '.'

export const useProductListing = () => {
  return useContext(ProductListingContext)
}

export const useProductListingDispatch = () => {
  return useContext(ProductListingDispatch)
}
