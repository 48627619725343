import React, { useEffect, useState } from 'react'

import { Button } from '@/common/components'
import { validateEmail } from '@/common/components/Paysafe/utils'
import { useNotify } from '@/common/hooks'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { UserService } from '@/core'
import { PaymentsService } from '@/core/marketplace/payments.service'

import SuccessIcon from '../../../resources/success-icon.png'

export const VerificationSteps = Object.freeze({
  email: 'email',
  code: 'code',
  venmoVerify: 'venmoVerify',
  complete: 'complete',
})

export const redirectLinks = Object.freeze({
  successRedirectLink: window.location.origin + '/profile/chainItPay',
  failedRedirectLink: window.location.origin + '/profile/chainItPay',
  defaultRedirectLink: window.location.origin + '/profile/chainItPay',
})

type VerificationStep =
  (typeof VerificationSteps)[keyof typeof VerificationSteps]

export const VenmoEmail: React.FC = () => {
  const { error } = useNotify()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [verificationCode, setVerificationCode] = useState('')
  const [isSubmitEmailDisabled, setIsSubmitEmailDisabled] = useState(true)
  const [isSubmitCodeDisabled, setIsSubmitCodeDisabled] = useState(true)
  const [currentStep, setCurrentStep] = useState<VerificationStep | null>(
    VerificationSteps.email,
  )

  useEffect(() => {
    fetchPaymentEmail()
  }, [])

  useEffect(() => {
    setIsSubmitEmailDisabled(!validateEmail(email))
  }, [email])

  useEffect(() => {
    setIsSubmitCodeDisabled(verificationCode.trim().length !== 6)
  }, [verificationCode])

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handleVerificationCodeChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setVerificationCode(event.target.value)
  }

  const handleSendCode = async () => {
    setIsLoading(true)
    try {
      const userService = new UserService()
      await userService.sendEmailCodeVerification(email)
      setCurrentStep(VerificationSteps.code)
      setIsSubmitCodeDisabled(true)
    } catch (e) {
      Logger.error('Failed to send email code', undefined, e as Error)
      error('Failed to send email code')
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerifyCodeEmail = async () => {
    setIsLoading(true)
    try {
      const userService = new UserService()
      const res = await userService.verifyEmailCode(verificationCode)
      if (res.verified) {
        await fetchPaymentEmail()
      }
    } catch (e) {
      Logger.error('Failed to verify email', undefined, e as Error)
      error('Failed to verify email')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateVenmoPaymentHandle = async () => {
    setIsLoading(true)
    try {
      const res = await PaymentsService.createVenmoPaymentHandle({
        email,
        ...redirectLinks,
      })
      if (res.status === 'PAYABLE') {
        setCurrentStep(VerificationSteps.complete)
      }
      if (res.redirectUrl) {
        window.location.href = res.redirectUrl
      }
    } catch (e) {
      Logger.error('Failed create VENMO payment handle', undefined, e as Error)
      error('Failed to create VENMO payment handle')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPaymentEmail = async () => {
    setIsLoading(true)
    try {
      const userService = new UserService()
      const res = await userService.getUserPaymentEmail()
      setEmail(res.email || '')
      if (res.email && !res.codeVerified && !res.venmoVerified) {
        setCurrentStep(VerificationSteps.code)
      } else if (res.email && res.codeVerified && !res.venmoVerified) {
        setCurrentStep(VerificationSteps.venmoVerify)
      } else {
        setCurrentStep(VerificationSteps.complete)
      }
    } catch (err) {
      Logger.error('Error while loading payment email', undefined, err as Error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading || currentStep === null) {
    return (
      <div className="w-full justify-center flex">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="bg-white px-6 py-5 flex flex-col">
      <div className="flex items-center">
        <span>Email</span>
        {(currentStep === VerificationSteps.venmoVerify ||
          currentStep === VerificationSteps.complete) && (
          <div className="flex items-center">
            <span className="ml-0">:</span>
            <span className="font-semibold ml-1">{email}</span>
            {currentStep === VerificationSteps.complete && (
              <img
                src={SuccessIcon}
                alt="Verified"
                title="Email verified"
                className="w-5 h-5 ml-3 cursor-pointer"
              />
            )}
          </div>
        )}
      </div>
      {currentStep === VerificationSteps.email && (
        <div className="w-full">
          <h2 className="w-full m-2">Enter your VENMO email</h2>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Venmo email"
            className="w-full ml-2 mt-2 mb-2 p-2 border border-gray-300 rounded-md"
            required
          />
          <Button
            onClick={handleSendCode}
            className="m-2"
            disabled={isSubmitEmailDisabled}
          >
            Get code
          </Button>
        </div>
      )}

      {currentStep === VerificationSteps.code && (
        <div className="w-full">
          <h2 className="w-full m-2">Enter your code verification</h2>
          <input
            type="text"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            placeholder="Verification code"
            className="w-full ml-2 mt-2 mb-2 p-2 border border-gray-300 rounded-md"
            required
          />
          <Button
            onClick={handleVerifyCodeEmail}
            className="m-2"
            disabled={isSubmitCodeDisabled}
          >
            Send code
          </Button>
          <Button
            onClick={handleSendCode}
            className="m-2"
            disabled={isSubmitEmailDisabled}
          >
            Get code again
          </Button>
        </div>
      )}

      {currentStep === VerificationSteps.venmoVerify && (
        <div className="w-full">
          <Button onClick={handleCreateVenmoPaymentHandle} className="m-2">
            Verify by VENMO application
          </Button>
        </div>
      )}
    </div>
  )
}
