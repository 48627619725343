import type { DeviceDetectorResult } from 'device-detector-js'
import DeviceDetector from 'device-detector-js'

const deviceDetectorResult: DeviceDetectorResult = new DeviceDetector().parse(
  navigator.userAgent,
)

export const getUniqueDeviceName = (): string => {
  const deviceType = deviceDetectorResult.device?.type || ''
  const osName = deviceDetectorResult.os?.name || ''
  const osPlatform = deviceDetectorResult.os?.platform || ''
  const browserName = deviceDetectorResult.client?.name || ''
  const browserType = deviceDetectorResult.client?.type || ''
  return `${deviceType} ${osName} ${osPlatform} ${browserType} ${browserName}`.trim()
}

export const getDeviceModel = (): string => {
  return deviceDetectorResult.device?.model || 'Unknown'
}

export const getDeviceOSName = (): string => {
  return deviceDetectorResult.os?.name || 'Unknown'
}
