import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { t } from 'i18next'
import React from 'react'

// TODO replace hardcoded values with real data
const CartCostSummary: React.FC = () => {
  return (
    <div className="shadow p-5 border-2">
      <h3>{t`cartSummary.summary`}</h3>
      <div className="flex justify-between mt-5">
        <span>{t`cartSummary.subtotal`}</span>
        <span>$350.00</span>
      </div>
      <div className="flex justify-between">
        <span>
          {t`cartSummary.shipping`}{' '}
          <QuestionMarkCircleIcon className="h-5 w-5 inline-block" />
        </span>
        <span>$35.00</span>
      </div>
      <div className="flex justify-between mt-5">
        <span>{t`cartSummary.total`}</span>
        <span>$385.00</span>
      </div>
      <div className="mt-1">
        <button
          type="button"
          className="bg-green-400 text-white px-3 py-2 w-full cursor-pointer"
        >
          {t`cartSummary.checkout`}
        </button>
      </div>
    </div>
  )
}

CartCostSummary.displayName = 'CartCostDetail'
export default CartCostSummary
