import type { BitAuthClaims } from '@bit-ui-libs/common'
import { t } from 'i18next'
import jwtDecode from 'jwt-decode'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'

import type { UserProfileType } from '@/common/context/auth_context'
import { authContext } from '@/common/context/auth_context'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { finalizeDocumentsAuth } from '@/core'
import { AuthService } from '@/core/auth/auth.service'
import ApplicationStorage from '@/core/storage'

import { Icon } from '../icon'

import type { IncodeDocumentVerificationProps } from './IncodeBase'
import {
  IncodeCameraType,
  IncodeRenderType,
  INCODE_NUMBER_OF_TRIES,
  startIncodeClient,
} from './IncodeBase'
import styles from './IncodeStyles.module.css'

// TODO add translations to this component
const IncodeDocumentVerification: React.FC<IncodeDocumentVerificationProps> = (
  props: IncodeDocumentVerificationProps,
) => {
  const { profile, mainAddress, loadingMainAddress, setUserProfile, bitToken } =
    useContext(authContext)
  const [isDocumentVerificationFinished, setIsDocumentVerificationFinished] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const incodeContainer = document.getElementById('camera-container')
  let incodeClient: any

  useEffect(() => {
    if (!loadingMainAddress && props.incodeFlowId) {
      startDocumentVerificationFlow(props.incodeFlowId)
        .catch((error) => {
          setErrorMessage(
            error && error.message
              ? error.message
              : 'Error starting document verification flow.',
          )
        })
        .finally(() => setLoading(false))
    }
  }, [loadingMainAddress, props.incodeFlowId])

  const startDocumentVerificationFlow = async (incodeFlowId: string) => {
    try {
      incodeClient = await startIncodeClient(incodeFlowId, mainAddress?.country)

      if (props.renderType === IncodeRenderType.DESKTOP) {
        renderCustomFlow()
      } else if (props.renderType === IncodeRenderType.MOBILE) {
        renderRedirectComponent()
      }
    } catch (error: any) {
      setErrorMessage(
        error && error.message
          ? error.message
          : 'Error starting document verification flow.',
      )
    }
  }

  const renderRedirectComponent = () => {
    const qrContainer = document.getElementById('incode-document-qr-container')
    incodeClient.renderRedirectToMobile(qrContainer, {
      session: incodeClient.session,
      flowId: props.incodeFlowId,
      skipDesktopRedirect: false,
      allowSkipRedirect: false,
      allowReEnrollment: true,
      onSuccess: () => {
        finishDocumentVerification(true)
      },
      onError: (error: any) => {
        setErrorMessage(
          error.message ? error.message : 'renderRedirectComponent error.',
        )
      },
    })
  }

  const renderCustomFlow = () => {
    const onProcessGovernmentValidationSuccess = async () =>
      finishDocumentVerification(false)
    const onProcessFaceSuccess = async () =>
      processGovernmentValidation(onProcessGovernmentValidationSuccess)
    const onSelfieCameraSuccess = async () => processFace(onProcessFaceSuccess)
    const onProcessIdSuccess = () => renderSelfieCamera(onSelfieCameraSuccess)
    const onBackIDCameraSuccess = async () => processId(onProcessIdSuccess)
    const onFrontIDCameraSuccess = async (result: any) =>
      !result.skipBackIdCapture
        ? renderBackIDCamera(onBackIDCameraSuccess)
        : processId(onProcessIdSuccess)

    renderFrontIDCamera(onFrontIDCameraSuccess)
  }

  const renderIncodeCamera = (type: IncodeCameraType, onSuccess: Function) => {
    incodeClient.renderCamera(type, incodeContainer, {
      onSuccess,
      onError: (error: any) => {
        setErrorMessage(
          error.message ? error.message : 'renderIncodeCamera error.',
        )
      },
      token: incodeClient.session,
      numberOfTries: INCODE_NUMBER_OF_TRIES,
      skipDesktopRedirect: false,
      allowSkipRedirect: false,
    })
  }

  const renderFrontIDCamera = (onSuccess: Function) => {
    renderIncodeCamera(IncodeCameraType.FRONT, onSuccess)
  }

  const renderBackIDCamera = (onSuccess: Function) => {
    renderIncodeCamera(IncodeCameraType.BACK, onSuccess)
  }

  const processId = async (onSuccess: Function) => {
    const validationResult = await incodeClient.processId({
      token: incodeClient.session.token,
    })
    Logger.info('processId result: ', validationResult)
    onSuccess()
  }

  const renderSelfieCamera = (onSuccess: Function) => {
    incodeClient.renderCamera(IncodeCameraType.SELFIE, incodeContainer, {
      onSuccess,
      onError: (error: any) => {
        setErrorMessage(error.message ? error.message : 'renderCamera error.')
      },
      token: incodeClient.session,
      numberOfTries: INCODE_NUMBER_OF_TRIES,
    })
  }

  const processFace = async (onSuccess: Function) => {
    const validationResult = await incodeClient.processFace({
      token: incodeClient.session.token,
    })
    Logger.debug('processFace result: ', validationResult)
    onSuccess()
  }

  const processGovernmentValidation = async (onSuccess: Function) => {
    const validationResult = await incodeClient.processGovernmentValidation({
      token: incodeClient.session.token,
    })
    Logger.debug('processGovernmentValidation result: ', validationResult)
    onSuccess()
  }

  async function finishDocumentVerification(completedInMobile = false) {
    if (!completedInMobile) {
      // TODO handle error correctly and don't call backend
      const finishStatusResult = await incodeClient.getFinishStatus(
        props.incodeFlowId,
        {
          token: incodeClient.session.token,
        },
      )
      if (finishStatusResult && finishStatusResult.action !== 'approved') {
        setErrorMessage(finishStatusResult.reason)
        Logger.warn(
          'Document verification result not approved: ',
          finishStatusResult,
        )
        return
      }
    }

    if (profile?.userId) {
      await finalizeDocumentsAuth(
        profile.userId,
        ApplicationStorage.incodeSessionId!,
      )
      setIsDocumentVerificationFinished(true)
      const authService = new AuthService()
      const bitTokenDecoded = jwtDecode(bitToken!) as BitAuthClaims

      let userProfile = { ...profile }
      userProfile = await authService.getProfile(bitTokenDecoded.userId)

      setUserProfile({
        ...userProfile,
      } as UserProfileType)
    }
    if (props.onBack !== undefined) {
      props.onBack()
    }
  }

  return (
    <div>
      <div
        id="incode-document-qr-container"
        className={styles.incodeQrContainer}
      />
      <div className="min-h-[400px] pt-5 pl-2 pr-2 flex">
        <ConditionalRendering renderIf={!isEmpty(errorMessage)}>
          <div
            className="w-full text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-auto"
            role="alert"
          >
            <p>{errorMessage}</p>
          </div>
        </ConditionalRendering>
        <ConditionalRendering renderIf={loading}>
          <div className="w-full justify-center flex mt-5 m-auto">
            <Spinner text={t(i18nKeys.ui.loading)} />
          </div>
        </ConditionalRendering>
        <ConditionalRendering renderIf={isDocumentVerificationFinished}>
          <div
            className="w-full text-center bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md m-auto"
            role="alert"
          >
            <p>Document verification finished successfully</p>
          </div>
        </ConditionalRendering>
      </div>
      <div className="flex justify-start">
        <button
          type="button"
          className="text-sm leading-6 text-gray-900 m-2 ml-5 flex"
          onClick={props.onClose}
        >
          <Icon
            icon="arrowRight"
            className="fill-gray-400 rotate-180 size-4 mt-1 mr-1"
          />
          <span>{t(i18nKeys.ui.back)}</span>
        </button>
      </div>
    </div>
  )
}

export default IncodeDocumentVerification
