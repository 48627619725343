import { t } from 'i18next'
import React from 'react'

import { Button } from '@/common/components'
import { PaymentMethods } from '@/common/components/Paysafe/PaymentMethods'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { EPaymentMethod } from '@/common/constants'
import i18nKeys from '@/common/i18nKeys'
import type { BuyerShippingAddress } from '@/common/types'
import type { PaymentCard } from '@/core'
import { BuyPage } from '@/features/buyProcess/BuyPage'

import type { PaymentHandleToken } from './PurchaseSubscriptionPage'
import { purchaseSubscriptionStep } from './subscription-steps'

export interface ChooseSubscriptionPaymentProps {
  shippingAddress?: BuyerShippingAddress
  selectedCard?: PaymentCard
  paymentEmail: string
  handleToken?: PaymentHandleToken
  onSelectCard: (card: PaymentCard) => void
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: () => void | Promise<void>
  setStep: (e?: number) => void
  paymentMethod?: EPaymentMethod
  onPaymentSelect: (paymentMethod: EPaymentMethod) => void
  isLoading?: boolean
}

// TODO missing translations
export const ChooseSubscriptionPayment: React.FC<
  ChooseSubscriptionPaymentProps
> = ({
  shippingAddress,
  selectedCard,
  handleToken,
  onSelectCard,
  onPaymentComplete,
  onPaymentFailed,
  setStep,
  paymentMethod,
  onPaymentSelect,
  paymentEmail,
  isLoading,
}) => {
  return (
    <BuyPage
      heading={t(i18nKeys.buyerProcess.confirmationSection.payment)}
      description="Choose a payment method for your purchase. Ensure the card details are accurate. For your security, all transactions are encrypted and we do not store your card information." // TODO add translation
    >
      <PaymentMethods
        shippingAddress={shippingAddress}
        selectedCard={selectedCard}
        handleToken={handleToken}
        onSelectCard={onSelectCard}
        onPaymentComplete={onPaymentComplete}
        onPaymentFailed={onPaymentFailed}
        setStep={setStep}
        paymentMethod={paymentMethod}
        paymentEmail={paymentEmail!}
        onPaymentSelect={onPaymentSelect}
        isLoading={isLoading}
      />

      <div className="flex justify-end mt-16">
        <Button
          onClick={setStep.bind(null, purchaseSubscriptionStep.confirm)}
          disabled={
            (paymentMethod === EPaymentMethod.Card && !selectedCard) ||
            paymentMethod === undefined ||
            (paymentMethod === EPaymentMethod.Venmo && !paymentEmail)
          }
        >
          {t(i18nKeys.ui.continue)}
        </Button>
      </div>
    </BuyPage>
  )
}
