import type { ProductListingActions, ProductListingState } from '.'
import { ActionType } from '.'

export function productListingReducer(
  state: ProductListingState,
  action: ProductListingActions,
): ProductListingState {
  const { type, payload } = action

  switch (type) {
    case ActionType.SetProducts:
      return { ...state, products: payload }

    case ActionType.SetUseCurrentAsset:
      return {
        ...state,
        useExistingAsset: payload,
        selectedAsset: payload ? state.selectedAsset : undefined,
      }

    case ActionType.SetSelectedAsset:
      return { ...state, selectedAsset: { ...payload } }

    case ActionType.SetImages:
      return { ...state, images: [...payload] }

    case ActionType.AddImage: {
      const found = state.images.find((i) => i.src === payload.src)

      if (found) {
        return state
      }

      return { ...state, images: [...state.images, payload] }
    }

    case ActionType.RemoveImage:
      return {
        ...state,
        images: [...state.images].filter((i) => i.src !== payload.src),
      }

    case ActionType.SetProductDetails: {
      return { ...state, productDetails: payload }
    }

    case ActionType.SetCompletenessType:
      return { ...state, completeness: payload }

    case ActionType.SetPrivacy:
      return { ...state, privacy: payload }

    case ActionType.SetBankAccounts:
      return { ...state, bankAccounts: payload }

    case ActionType.SetCryptoAccounts:
      return { ...state, cryptoAccounts: payload }

    case ActionType.SetSelectedAccount:
      return { ...state, selectedAccount: payload }

    case ActionType.SetPayoutCurrency:
      return { ...state, payoutCurrency: payload, selectedAccount: undefined }

    case ActionType.SetSellMethod:
      return { ...state, sellMethod: payload }

    case ActionType.SetAddresses:
      return { ...state, addresses: payload }

    case ActionType.SetDelivery:
      return { ...state, delivery: payload }

    case ActionType.SetBuyerLevel:
      return { ...state, buyerLevel: payload }

    case ActionType.SetCategories:
      return { ...state, categories: payload }

    case ActionType.SetAssetType:
      return { ...state, assetType: payload }

    case ActionType.SetSteps:
      return { ...state, steps: payload }

    case ActionType.SetEvent:
      return { ...state, event: payload }
  }
}
