import { translateKey } from '@/core'

import type { CardOption } from '..'
import { ServiceSellMethod } from '../types'

export const servicePricing: CardOption[] = [
  {
    id: ServiceSellMethod.FixedPrice,
    title: translateKey((i) => i.sell.nav.servicePricing.options.fixed.title),
    description: translateKey(
      (i) => i.sell.nav.servicePricing.options.fixed.description,
    ),
  },
  {
    id: ServiceSellMethod.HourlyRate,
    title: translateKey((i) => i.sell.nav.servicePricing.options.hourly.title),
    description: translateKey(
      (i) => i.sell.nav.servicePricing.options.hourly.description,
    ),
  },
]
