import { t } from 'i18next'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { biometricsAuth, buildAuthBiometricsUrl } from '@/core'
import type { AuthBiometricsResponseData } from '@/core/auth/interfaces'
import ApplicationStorage from '@/core/storage'

import type { IncodeCreateOnboardingProps } from './IncodeBase'
import {
  IncodeCameraType,
  IncodeRenderType,
  INCODE_NUMBER_OF_TRIES,
  startIncodeClient,
} from './IncodeBase'
import styles from './IncodeStyles.module.css'

const IncodeCreateOnboarding: React.FC<IncodeCreateOnboardingProps> = (
  props: IncodeCreateOnboardingProps,
) => {
  const [isOnboardingFinish, setIsOnboardingFinish] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const sessionToken =
    searchParams.get('sessionToken') || ApplicationStorage.sessionToken
  const state = searchParams.get('state') || ApplicationStorage.state

  if (!sessionToken || !state) {
    setErrorMessage('Missing sessionToken or state')
    throw new Error('Missing sessionToken or state')
  }

  const incodeContainer = document.getElementById('camera-container')
  const incodeMobileContainer = document.getElementById(
    'mobile-camera-container',
  )
  let incodeClient: any

  useEffect(() => {
    if (!loading && props.incodeFlowId) {
      startOnboardingFlow(props.incodeFlowId)
        .catch((error) => {
          setErrorMessage(
            error && error.message
              ? error.message
              : 'Error starting document verification flow.',
          )
        })
        .finally(() => setLoading(false))
    }
  }, [loading, props.incodeFlowId])

  async function startOnboardingFlow(incodeFlowId: string) {
    try {
      incodeClient = await startIncodeClient(incodeFlowId)

      if (props.renderType === IncodeRenderType.DESKTOP) {
        renderSelfieCamera()
      } else if (props.renderType === IncodeRenderType.MOBILE) {
        renderRedirectComponent()
      }
    } catch (error: any) {
      setErrorMessage(error.message)
    }
  }

  function renderRedirectComponent() {
    incodeClient.renderRedirectToMobile(incodeMobileContainer, {
      session: incodeClient.session,
      flowId: props.incodeFlowId,
      skipDesktopRedirect: false,
      allowSkipRedirect: false,
      onSuccess: finishOnboarding,
      onError: (error: any) => {
        setErrorMessage(
          error.message ? error.message : 'renderRedirectComponent error.',
        )
      },
    })
  }

  function renderSelfieCamera() {
    incodeClient.renderCamera(IncodeCameraType.SELFIE, incodeContainer, {
      onSuccess: finishOnboarding,
      onError: (error: any) => {
        setErrorMessage(error.message ? error.message : 'renderCamera error.')
      },
      token: incodeClient.session,
      numberOfTries: INCODE_NUMBER_OF_TRIES,
      skipDesktopRedirect: false,
      allowSkipRedirect: false,
    })
  }

  async function finishOnboarding() {
    if (props.renderType === IncodeRenderType.DESKTOP) {
      const finishStatusResult = await incodeClient.getFinishStatus(
        props.incodeFlowId,
        { token: incodeClient.session.token },
      )

      if (finishStatusResult.action === 'rejected') {
        setErrorMessage(finishStatusResult.reason)
        return
      }
    }

    const approveResult = await incodeClient.approve({
      token: incodeClient.session.token,
    })
    const res: AuthBiometricsResponseData = await biometricsAuth(
      sessionToken as string,
      state as string,
      {
        userId: approveResult.customerId,
        sessionId: ApplicationStorage.incodeSessionId as string,
      },
    )

    if (res) {
      const redirectUrl = buildAuthBiometricsUrl(res)
      setIsOnboardingFinish(true)
      window.location.href = redirectUrl
    }
  }

  // TODO missing translation
  return (
    <div>
      <div
        id="incode-onboarding-qr-container"
        className={styles.incodeQrContainer}
      />
      <div className="pt-5 pb-5">
        <ConditionalRendering renderIf={!isEmpty(errorMessage)}>
          <div
            className="w-full text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        </ConditionalRendering>
        <ConditionalRendering renderIf={loading}>
          <div className="w-full justify-center flex mt-5">
            <Spinner text={t(i18nKeys.ui.loading)} />
          </div>
        </ConditionalRendering>
        <ConditionalRendering renderIf={isOnboardingFinish}>
          <div
            className="w-full text-center bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
            role="alert"
          >
            <span className="block sm:inline">
              Onboarding finished successfully
            </span>
          </div>
        </ConditionalRendering>
      </div>
      <div className="flex justify-start">
        <button
          type="button"
          className="text-sm leading-6 text-gray-900 m-2"
          onClick={props.onClose}
        >
          {t(i18nKeys.ui.back)}
        </button>
      </div>
    </div>
  )
}

export default IncodeCreateOnboarding
