import React, { useMemo } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'

import { BeingIdLevelCard, Icon } from '@/common/components'
import { useBeingIDCheck, useSellerProfile } from '@/common/hooks'
import Spinner from '@/components/Spinner'
import { classNames } from '@/core'
import { sellItemsRoutes } from '@/routes'
import { sellServiceRoutes } from '@/routes/sell-service-routes'

import { StepsNavigationBar } from '..'
import { ProductListingProvider, useProductListing } from '../context'
import { ListingType } from '../types/listing-type'

export function ListingLayout() {
  return (
    <ProductListingProvider>
      <ListingAssetRouter />
    </ProductListingProvider>
  )
}

// TODO missing translations
function ListingAssetRouter() {
  const location = useLocation()

  const { type } = useParams<{ type: string }>()

  const { steps } = useProductListing()
  const {
    allowed: allowedBeingId,
    loading: loadingBeingId,
    currentLevel,
    required: requiredLevel,
  } = useBeingIDCheck()

  const { isLoading: loadingValidations, isValid } = useSellerProfile({
    redirectOnFailedValidation: true,
    showToasts: true,
  })

  const currentRoute = useMemo(() => {
    const _routes =
      type === ListingType.Asset ? sellItemsRoutes : sellServiceRoutes
    return _routes.find(
      (r) => `/sell/${type}/${r.route.path}` === location.pathname,
    )
  }, [location])

  const routes = useMemo(() => {
    const _routes =
      type === ListingType.Asset ? sellItemsRoutes : sellServiceRoutes
    return _routes.filter((r) => !r.hideOnMenu)
  }, [steps])

  const currentIndex = useMemo(
    () =>
      routes.findIndex(
        (r) => `/sell/${type}/${r.route.path}` === location.pathname,
      ),
    [routes, location],
  )

  if (loadingBeingId || !allowedBeingId || loadingValidations || !isValid) {
    return (
      <div className="min-h-[80vh]">
        <section
          className={classNames(
            'mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white transition-all duration-300 ease-in-out',
            loadingBeingId || loadingValidations
              ? 'flex items-center justify-center py-12 sm:py-48 mb-12'
              : 'py-6 sm:py-8',
          )}
        >
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0 flex flex-col">
            {loadingBeingId || loadingValidations ? (
              <Spinner />
            ) : (
              <>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Insufficient Being ID level
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  Listing an item requires a better Being ID level. Please
                  upgrade your being ID level to sell this product.
                </p>

                <div className="flex items-center mt-12 gap-10">
                  <BeingIdLevelCard
                    label="Required"
                    level={requiredLevel!}
                    href="/profile/digitalIdentity#being-id"
                  />
                  <BeingIdLevelCard
                    label="Current"
                    level={Number(currentLevel)!}
                    mode="warn"
                    href="/profile/digitalIdentity#being-id"
                  />
                </div>
                <Link
                  to="/profile/digitalIdentity#being-id"
                  className="font-semibold flex items-center gap-3 text-primary-600 mt-8 "
                >
                  <span>Improve your Being ID level</span>
                  <Icon icon="arrowRight" className="fill-primary-600 size-3" />
                </Link>
              </>
            )}
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className="flex">
      {!currentRoute?.hideOnMenu && (
        <StepsNavigationBar
          routes={routes}
          currentStep={currentIndex}
          className="ms-6 flex-1 hidden sm:block"
        />
      )}

      <main className="flex flex-col flex-[6] min-w-80 px-6 pb-8">
        <div className="max-w-[900px] self-center w-full">
          <Outlet />
        </div>
      </main>
    </div>
  )
}
