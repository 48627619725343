import React from 'react'

import {
  SellDetailsPage,
  SellImagesPage,
  SellMintingAssetPage,
  SellPaymentPage,
  SellPrivacyPage,
} from '@/features/listing'
import SellCategory from '@/features/listing/pages/SellCategory'
import ServiceAvailabilityPage from '@/features/listing/pages/ServiceAvailabilityPage'
import ServiceConfirmPage from '@/features/listing/pages/ServiceConfirmPage'
import ServiceLicensurePage from '@/features/listing/pages/ServiceLicensurePage'
import ServiceLocationPage from '@/features/listing/pages/ServiceLocationPage'
import ServicePaymentTermsPage from '@/features/listing/pages/ServicePaymentTermsPage'
import ServicePricingPage from '@/features/listing/pages/ServicePricingPage'
import ServiceTermsPage from '@/features/listing/pages/ServiceTermsPage'
import type { RouteWithMetadata } from '@/types'

export const sellServiceRoutes: RouteWithMetadata[] = [
  {
    label: 'Categories',
    description: 'Select a category for your service',
    route: {
      path: 'categories',
      element: <SellCategory />,
    },
    hideOnMenu: true,
  },
  {
    label: 'Details',
    description: 'Provide details about your service',
    route: {
      path: 'details',
      element: <SellDetailsPage />,
    },
  },
  {
    label: 'Images',
    description:
      'Upload images related to the service (optional but recommended).',
    route: {
      path: 'image',
      element: <SellImagesPage />,
    },
  },
  {
    label: 'Licensure',
    description:
      'Licensure associated with your ability to perform this service.',
    route: {
      path: 'licensure',
      element: <ServiceLicensurePage />,
    },
  },
  {
    label: 'Privacy',
    description: "What information you'll share with buyers",
    route: {
      path: 'privacy',
      element: <SellPrivacyPage />,
    },
  },
  {
    hideOnMenu: true,
    route: {
      path: 'minting',
      element: <SellMintingAssetPage />,
    },
  },
  {
    label: 'Pricing',
    description:
      "Indicate the price of the service or specify if it's negotiable.",
    route: {
      path: 'pricing',
      element: <ServicePricingPage />,
    },
  },
  {
    label: 'Payment',
    description: 'How would you like to be paid for your service?',
    route: {
      path: 'payment',
      element: <SellPaymentPage />,
    },
  },
  {
    label: 'Location',
    description: 'Where will you be providing this service?',
    route: {
      path: 'location',
      element: <ServiceLocationPage />,
    },
  },
  {
    label: 'Payment Terms',
    description: 'Specify the terms for payment',
    route: {
      path: 'payment-terms',
      element: <ServicePaymentTermsPage />,
    },
  },
  {
    label: 'Terms',
    description: 'Specify the terms of the service',
    route: {
      path: 'listing-terms',
      element: <ServiceTermsPage />,
    },
  },
  {
    label: 'Availability',
    description: 'When will you be available to provide this service?',
    route: {
      path: 'availability',
      element: <ServiceAvailabilityPage />,
    },
  },
  {
    label: 'Confirm',
    description: 'Review your service listing before publishing',
    route: {
      path: 'confirm',
      element: <ServiceConfirmPage />,
    },
  },
]
