import { t } from 'i18next'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Icon,
  UserChangePinModal,
  AuthGoogleDrive,
  AuthOneDrive,
  UserPinModal,
} from '@/common/components'
import { authContext } from '@/common/context/auth_context'
import { useWalletManagement } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { classNames } from '@/core'
import { colors } from '@/theme'
// TODO: uncomment the imports as we implement these features
// import ApplicationStorage from '@/core/storage'
// import { wallet } from '@/mock'
// import depositIcon from '@assets/icons/deposit.svg'
// import usdtCardIcon from '@assets/icons/tether-usdt-logo.svg'
// import visaCardIcon from '@assets/icons/visa-brandmark-blue.svg'
// import withdrawIcon from '@assets/icons/withdraw.svg'

export type WalletBalanceProps = {
  className?: string
}

export const WalletBalance: React.FC<WalletBalanceProps> = (props) => {
  const {
    googleAuthRef,
    microsoftAuthRef,
    isShowUserPinModal,
    isShowRetrievePinModal,
    isChangePin,
    isRetrievePinFlow,
    // setIsRetrievePinFlow,
    setIsShowUserPinModal,
    setIsShowRetrievePinModal,
    setUserOldPin,
    handleDecrypt,
    handleGotNewPin,
    handleForgotPin,
    handleOpenAuthPopup,
    handleAccessToken,
    handleCloseModal,
  } = useWalletManagement()

  const { profile } = useContext(authContext)
  const navigate = useNavigate()
  const handleGotExistingPin = (userPin: string) => {
    setIsShowUserPinModal(false)
    handleDecrypt(userPin)
    setUserOldPin(userPin)
  }

  const handleContinue = (userPin: string) => {
    setIsShowRetrievePinModal(false)
    handleDecrypt(userPin)
  }
  // TODO: uncomment this when we implement withdraw functionality
  // const handleClickOnWithdraw = () => {
  //   if (!encryptedPrivateKey) {
  //     setIsRetrievePinFlow(true)
  //   }
  // }

  // const encryptedPrivateKey = ApplicationStorage.encryptedPrivateKey
  const handleMyCollectionButtonClick = () => {
    navigate('/profile/myLibrary')
  }
  return (
    <div className={classNames('bg-white px-6 py-5', props.className)}>
      <UserChangePinModal
        key={`changePinModal-${isShowUserPinModal}`}
        handleClose={handleCloseModal}
        isOpen={isShowUserPinModal || isChangePin}
        isChangePin={isChangePin}
        handleGotExistingPin={(userPin: string) => {
          handleGotExistingPin(userPin)
        }}
        handleGotNewPin={(userNewPin: string) => {
          handleGotNewPin(userNewPin)
        }}
      />
      <UserPinModal
        key={`userPinModal-${isRetrievePinFlow}`}
        handleClose={handleCloseModal}
        isOpen={isShowRetrievePinModal}
        handleForgotPin={handleForgotPin}
        handleContinue={(userPin: string) => {
          handleContinue(userPin)
        }}
      />
      <div className="grid lg:grid-cols-2 gap-5">
        <div>
          <h2 className="text-base font-medium">
            {t(i18nKeys.payout.chainItWallet)}
          </h2>
          <h3>{profile?.walletAddress}</h3>
          {/* TODO: uncomment this when we have implement the wallet balances*/}
          {/* <div className="text-center md:text-left my-5">
            <h3 className="text-[40px] font-normal md:flex">
              <sub className="text-sm bottom-[-30px] right-[5px] font-normal">
                {t(i18nKeys.payout.currency)}
              </sub> */}
          {/* TODO: Replace with the appropriate value when available */}
          {/* <p> 1,248 </p> */}
          {/* TODO: Replace with the appropriate value when available */}
          {/* <p className="text-sm mt-3 ml-1">50</p> */}
          {/* </h3>
          </div> */}
          <div className="grid md:grid-cols-5 grid-cols-3 my-7 text-center">
            {/* TODO: uncomment this when we have implement the deposit and withdraw features */}
            {/* <div className="md:mb-0 mb-3">
              <div className="mx-auto rounded-full flex items-center justify-center bg-gray-100 h-[45px] w-[45px]">
                <img src={depositIcon} alt="" />
              </div>
              <p className="font-medium text-xs">
                {t(i18nKeys.payout.deposit)}{' '}
              </p>
            </div> */}
            {/* <button onClick={handleClickOnWithdraw}>
              <div className="mx-auto rounded-full flex items-center justify-center bg-gray-100 h-[45px] w-[45px]">
                <img src={withdrawIcon} alt="" />
              </div>
              <p className="font-medium text-xs">
                {t(i18nKeys.payout.withdraw)}
              </p>
            </button> */}
            <button onClick={() => handleOpenAuthPopup()}>
              <div className="mx-auto rounded-full flex items-center justify-center bg-gray-100 h-[45px] w-[45px]">
                <Icon icon="lock" color={colors.gray[400]} size={20} />
              </div>
              <p className="font-medium text-xs">
                {t(i18nKeys.payout.changePIN)}
              </p>
            </button>
          </div>
          <button
            onClick={handleMyCollectionButtonClick}
            className="bg-white p-3 rounded-sm mt-10 border-black border w-full text-left hidden md:block"
          >
            <div className="grid grid-cols-1">
              <div className="flex items-center">
                <Icon icon="shop" size={36} />
                <div className="ml-6">
                  <h4 className="text-base font-medium">
                    {t(i18nKeys.payout.myLibrary)}
                  </h4>
                  <p>{t(i18nKeys.payout.myLibraryWallet)}</p>
                </div>

                <Icon icon="arrowRight" className="ml-auto" size={22} />
              </div>
            </div>
          </button>
          <button className="font-semibold text-lg flex items-center text-primary my-3 ml-auto md:hidden cursor-pointer">
            {t(i18nKeys.payout.myLibrary)}
            <Icon
              icon="arrowRight"
              color={colors.primary.DEFAULT}
              className="ml-3"
              size={22}
            />
          </button>
        </div>
        {/* TODO: uncomment this when we have add feature to show multiple crypto balance such as USDC, BTC etc */}
        {/* <div>
          <h5 className="text-base mb-2 font-semibold">
            {t(i18nKeys.payout.assets)}
          </h5>
          <div className="grid grid-cols-5 gap-3 text-center mb-3">
            {wallet.assetsList.map((item, index) => (
              <div key={`assests-${index}`}>
                <img
                  src={item.icon}
                  alt=""
                  width={35}
                  className="mx-auto pb-2"
                />
                <p className="text-xs font-semibold">{item.label}</p>
              </div>
            ))}
          </div>
          <h5 className="text-xs font-semibold mb-2">
            {t(i18nKeys.payout.paymentMethod)}
          </h5>
          <button className="bg-white p-4 rounded-sm mb-3 border-black border w-full text-left">
            <div className="grid grid-cols-1">
              <div className="flex items-center">
                <div className="w-1/5">
                  <img src={visaCardIcon} alt="" width={60} />
                </div>
                <div className="ml-4">
                  TODO: Replace with the appropriate value when available
                  <div className="text-sm font-semibold"> John Doe </div>
                  TODO: Replace with the appropriate value when available
                  <div className="text-xs text-gray-500">**** 6767</div>
                </div>
                <Icon icon="arrowRight" className="ml-auto" size={22} />
              </div>
            </div>
          </button>
          <h5 className="text-xs font-semibold mb-2">
            {t(i18nKeys.payout.paymentMethod)}
          </h5>
          <button className="bg-white p-3 rounded-sm mb-3 border-black border w-full text-left">
            <div className="grid grid-cols-1">
              <div className="flex items-center">
                <div className="w-1/5">
                  <img
                    src={usdtCardIcon}
                    alt=""
                    width={50}
                    className="mx-auto"
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-semibold">
                    {t(i18nKeys.payout.coinbase)}
                  </div>
                  <div className="text-xs text-gray-500">{`${t(i18nKeys.payout.usdt)} 500`}</div>
                </div>
                <Icon icon="arrowRight" className="ml-auto" size={22} />
              </div>
            </div>
          </button>
        </div> */}
      </div>
      <AuthGoogleDrive ref={googleAuthRef} onAccessToken={handleAccessToken} />
      <AuthOneDrive ref={microsoftAuthRef} onAccessToken={handleAccessToken} />
    </div>
  )
}
