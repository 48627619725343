import React, { useEffect, useState } from 'react'

import type { CustomerAssetWithPrice } from '@/core'
import { mockedCustomerAssetWithPrice } from '@/mock'

import { ProductSection } from './ProductSection'

export type StorefrontContentProps = {
  tags: string[]
  search?: string | null
}

export const StorefrontContent: React.FC<StorefrontContentProps> = ({
  tags,
  search,
}) => {
  const [products, setProducts] = useState<CustomerAssetWithPrice[]>([])

  const handleFetchProducts = async () => {
    // TODO fetch products based on tags
    setProducts(Array(20).fill(mockedCustomerAssetWithPrice))
  }

  useEffect(() => {
    if (!tags.length) return

    handleFetchProducts()
  }, [tags])

  useEffect(() => {
    if (!search?.length) return

    handleFetchProducts()
  }, [search])

  return (
    <div className="flex flex-col gap-12">
      <ProductSection items={products} />
    </div>
  )
}
