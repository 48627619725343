import React, { useCallback, useContext, useState } from 'react'

import { authContext } from '@/common/context/auth_context'
import type { Address, BuyerShippingAddress } from '@/common/types'
import type { CreateUserAddressRequest, EditUserAddressRequest } from '@/core'
import { UserService } from '@/core'
import type { AddressInformationFormResult } from '@/pages/Onboarding/PersonalInformation/AddressInformation'

import { AddEditAddress } from './AddEditAddress'
import { BuyPage } from './BuyPage'
import { SelectAddress } from './SelectAddress'
import { stepperProcess } from './Stepper'

export interface BuyerAddressesProps {
  setStep: (e: number) => void
  selectedBuyerAddresses?: BuyerShippingAddress
  setSelectedBuyerAddresses: (e: BuyerShippingAddress) => void
}

export const BuyerAddresses: React.FC<BuyerAddressesProps> = (props) => {
  const { addresses, profile, replaceAddress, setUserAddresses } =
    useContext(authContext)

  const [showAddressForm, setShowAddressForm] = useState<boolean>(false)
  const [locationId, setLocationId] = useState<string>('')
  const [isAddAddress, setIsAddAddress] = useState<boolean>(true)

  const onShowAddressForm = (
    addressId: string,
    showForm: boolean,
    isAdd: boolean,
  ) => {
    setIsAddAddress(isAdd)
    setLocationId(addressId)
    setShowAddressForm(showForm)
  }

  const submitAddressForm = useCallback(
    async (address: AddressInformationFormResult) => {
      if (!profile?.userId) {
        throw new Error('error') //todo: improve errors
      }

      const userService = new UserService()
      const request: CreateUserAddressRequest = {
        address: address.address,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        country: address.country,
        isMain: false, // new addresses are added as not main by default
        name: address.name,
        state: address.state,
        type: address.type,
        userId: profile?.userId,
        zip: address.zip,
      }

      let result: Address
      if (isAddAddress) {
        result = await userService.addUserAddress(request)
        const newAddresses = [...addresses]
        newAddresses.push(result)
        setUserAddresses(newAddresses)
      } else {
        const existingAddress = addresses.filter((x) => x.id === locationId)[0]
        if (!existingAddress) {
          throw new Error('error') //todo: improve errors
        }
        const editRequest: EditUserAddressRequest = {
          ...request,
          id: existingAddress.id,
          isMain: existingAddress.isMain,
        }

        result = await userService.updateUserAddress(
          existingAddress.id,
          editRequest,
        )
      }

      replaceAddress(locationId, result)
      setShowAddressForm(false)
    },
    [profile, isAddAddress, locationId],
  )

  return (
    <BuyPage>
      {showAddressForm ? (
        <AddEditAddress
          addresses={addresses}
          isAddAddress={isAddAddress}
          locationId={locationId}
          setShowAddressForm={setShowAddressForm}
          submitAddressForm={submitAddressForm}
        />
      ) : (
        <SelectAddress
          addresses={addresses}
          setSelectedBuyerAddresses={props.setSelectedBuyerAddresses}
          selectedBuyerAddresses={props.selectedBuyerAddresses}
          onContinue={() => props.setStep(stepperProcess.selectCarrier)}
          onAddAddress={() => onShowAddressForm('', true, true)}
          onEditAddress={() =>
            onShowAddressForm(props.selectedBuyerAddresses!.id, true, false)
          }
        />
      )}
    </BuyPage>
  )
}
