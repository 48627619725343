import React, { createContext, useContext, useEffect, useState } from 'react'

import { Logger } from '@/config'
import { CategoriesService } from '@/core'

import type { MegaMenuCategory } from '../components'
import { Identifiers } from '../constants'
import { useNotify } from '../hooks'

export type CategoriesContextProviderProps = {
  children?: React.ReactNode
}

export type CategoriesState = {
  product?: MegaMenuCategory[]
  service?: MegaMenuCategory[]
  event?: MegaMenuCategory[]
}

const CategoriesContext = createContext<CategoriesState>({} as any)

// TODO this context should be exporting the interfaces and not importing them from the MegaMenu component
export default function CategoriesContextProvider(
  props: CategoriesContextProviderProps,
) {
  const { error } = useNotify()

  const [productCategories, setProductCategories] =
    useState<MegaMenuCategory[]>()

  const handleFetchCategories = async () => {
    try {
      const categoriesService = new CategoriesService()
      const response = await categoriesService.getAllByParentId(
        Identifiers.PRODUCTS_CATEGORY_ID,
      )

      const _categories: MegaMenuCategory[] = []

      await Promise.all(
        response.map(async (category) => {
          const subcategories =
            await CategoriesService.getAssetTypesByCategoryId({
              categoryId: category.id,
              $perPage: 5,
            })

          _categories.push({
            name: category.name,
            href: `/category/${category.id}`,
            subcategories: subcategories.items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((subcategory) => ({
                name: subcategory.name,
                href: `/subcategory/${subcategory.id}`,
              })),
          })
        }),
      )
      setProductCategories(_categories)
    } catch (e) {
      Logger.error('Failed to load categories', undefined, e as Error)
      error('Failed to load categories')
    }
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  return (
    <CategoriesContext.Provider value={{ product: productCategories }}>
      {props.children}
    </CategoriesContext.Provider>
  )
}

export const useCategories = () => {
  return useContext(CategoriesContext)
}
