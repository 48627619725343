import axios from 'axios'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { Button } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import {
  getStateCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import type { Address } from '@/common/types'
import { Environment } from '@/common/types'
import { Logger } from '@/config'
import { PaymentsService } from '@/core/marketplace/payments.service'
import { colors } from '@/theme'

type VenmoProps = {
  paymentEmail: string
  totalPrice: number
  billingAddress: Address
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed: (error?: string) => void | Promise<void>
  onLoading: (isLoading: boolean) => void
}

export const Venmo: React.FC<VenmoProps> = ({
  totalPrice,
  billingAddress,
  onPaymentComplete,
  onPaymentFailed,
  paymentEmail,
  onLoading,
}: VenmoProps) => {
  async function tokenize() {
    onLoading(true)
    try {
      const paymentCredentials = await PaymentsService.getPaymentsCredentials()
      const merchantRefNum = uuid()

      const requestBody = {
        merchantRefNum: merchantRefNum,
        transactionType: 'PAYMENT',
        paymentType: 'VENMO',
        amount: totalPrice,
        currencyCode: 'USD',
        accountId: paymentCredentials.venmoAccountId,
        venmo: {
          consumerId: paymentEmail.toLowerCase(),
        },
        billingDetails: {
          nickName: 'Home',
          state: getStateCode(billingAddress.state),
          city: billingAddress.city,
          zip: billingAddress.zip,
          street: billingAddress.addressLine1 || billingAddress.address,
          country: selectCountryFromSplitString(billingAddress.country),
        },
      }

      const paysafeApiUrl = `https://api${envVariables.ENVIRONMENT !== Environment.PRODUCTION ? '.test' : ''}.paysafe.com`
      const response = await axios.post(
        `${paysafeApiUrl}/paymenthub/v1/singleusepaymenthandles`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + paymentCredentials.publicKey,
          },
        },
      )

      if (response?.data.status !== 'PAYABLE') {
        throw new Error('Payment handle not payable')
      }
      onPaymentComplete({
        paymentHandleToken: response?.data?.paymentHandleToken,
        merchantRefNum: merchantRefNum,
        amount: totalPrice,
        currencyCode: 'USD',
        paymentType: 'VENMO',
      } as TokenizationResponse)
    } catch (error) {
      Logger.error('VENMO - tokenize error', undefined, error as Error)
      onPaymentFailed()
    } finally {
      onLoading(false)
    }
  }

  return (
    <Button onClick={tokenize} color={colors.secondary['500']}>
      venmo
    </Button>
  )
}
