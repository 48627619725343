import React, { useMemo, useState } from 'react'

import { Button, Input } from '@/common/components'
import { BadgeSelectable } from '@/common/components/form/BadgeSelectable'
import { useForm, useNotify } from '@/common/hooks'
import { translate } from '@/core'
import { colors } from '@/theme'

import { SellPage, PageActionButtons, OptionCards, Badge } from '..'
import { serviceAvailabilityOptionTypes } from '../constants/availability'
import { useServiceListing } from '../context/service-listing-context'
import { ServiceAvailabilityOptionType } from '../types/availability'
import type { AvailabilitySchedule } from '../types/availability'

export default function ServiceAvailabilityPage() {
  const availabilityType = useServiceListing((state) => state.availabilityType)
  const scheduleUrl = useServiceListing((state) => state.scheduleUrl)
  const availability = useServiceListing((state) => state.availability)

  const update = useServiceListing((state) => state.update)

  const options = useMemo(
    () =>
      serviceAvailabilityOptionTypes.map((i) => ({
        ...i,
        title: translate(i.title),
        description: translate(i.description),
      })),
    [],
  )

  return (
    <SellPage title="Availability">
      <p className="text-center">
        Specify your availability to provide the service (e.g., days of the
        week, hours).
      </p>

      <OptionCards
        options={options}
        selected={availabilityType}
        onChange={(v) => update('availabilityType', v)}
        className="flex gap-4 mt-6 flex-col sm:flex-row"
      />

      <div className="flex flex-col w-full items-center mt-6">
        {availabilityType.id === ServiceAvailabilityOptionType.ExternalLink ? (
          <ExternalLinkAvailability
            value={scheduleUrl}
            onChange={(value) => update('scheduleUrl', value)}
          />
        ) : (
          <CalendarAvailability
            value={availability}
            onChange={(value) => update('availability', value)}
          />
        )}
      </div>

      <PageActionButtons />
    </SellPage>
  )
}

type AvailabilityTypeProps<T> = {
  value: T
  onChange: (value: T) => void
}

function CalendarAvailability(
  props: AvailabilityTypeProps<AvailabilitySchedule[]>,
) {
  const { error } = useNotify()

  const { data, handleChangeField } = useForm({
    days: [] as string[],
    from: '',
    to: '',
  })

  const [isEdit, setIsEdit] = useState(false)

  const dayOptions = useMemo(
    () => [
      translate((i) => i.common.dates.monday),
      translate((i) => i.common.dates.tuesday),
      translate((i) => i.common.dates.wednesday),
      translate((i) => i.common.dates.thursday),
      translate((i) => i.common.dates.friday),
      translate((i) => i.common.dates.saturday),
      translate((i) => i.common.dates.sunday),
    ],
    [],
  )

  const handleToggleDay = (day: string) => {
    handleChangeField(
      'days',
      data.days.includes(day)
        ? data.days.filter((d) => d !== day)
        : [...data.days, day],
    )
  }

  const handleAddRange = () => {
    if (!data.from || !data.to) {
      error(translate((i) => i.sell.nav.availability.error.timeRangeRequired))
      return
    }

    if (data.days.length === 0) {
      error(translate((i) => i.sell.nav.availability.error.dayRequired))
      return
    }

    if (data.from >= data.to) {
      error(translate((i) => i.sell.nav.availability.error.startBeforeEnd))
      return
    }

    props.onChange([
      ...props.value,
      {
        days: data.days,
        start: data.from,
        end: data.to,
      },
    ])

    handleClear()
  }

  const handleEditRange = (index: number) => {
    const schedule = props.value[index]
    handleChangeField('days', schedule.days)
    handleChangeField('from', schedule.start)
    handleChangeField('to', schedule.end)

    props.onChange([
      ...props.value.slice(0, index),
      ...props.value.slice(index + 1),
    ])

    setIsEdit(true)
  }

  const handleClear = () => {
    handleChangeField('days', [])
    handleChangeField('from', '')
    handleChangeField('to', '')
    setIsEdit(false)
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col ring-1 ring-primary-200 mt-4 rounded-lg p-2 w-full">
        <h3 className="font-semibold">{isEdit ? 'Edit' : 'Add new'} range</h3>
        <div className="flex flex-wrap gap-2 items-center mt-2">
          {dayOptions.map((day, dayKey) => (
            <BadgeSelectable
              key={dayKey}
              text={day}
              selected={data.days.includes(day)}
              onClick={() => handleToggleDay(day)}
            />
          ))}
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-6 mt-4">
          <Input
            id="availability-from"
            name="availability-from"
            label={translate((i) => i.sell.nav.availability.fromTime)}
            type="time"
            placeholder={translate(
              (i) => i.sell.nav.availability.fromTimePlaceholder,
            )}
            onChange={(v) => handleChangeField('from', v.target.value)}
            value={data.from}
            className="w-full max-w-lg mx-auto"
          />

          <Input
            id="availability-to"
            name="availability-to"
            type="time"
            label={translate((i) => i.sell.nav.availability.endTime)}
            placeholder={translate(
              (i) => i.sell.nav.availability.fromTimePlaceholder,
            )}
            onChange={(v) => handleChangeField('to', v.target.value)}
            value={data.to}
            className="w-full max-w-lg mx-auto"
          />
        </div>

        <div className="flex items-center justify-end w-full gap-4 mt-4">
          {isEdit ? (
            <Button
              onClick={handleClear}
              color={colors.red[400]}
              textColor={colors.red[700]}
              className="text-red-700"
              mode="outline"
            >
              {translate((i) => i.ui.remove)}
            </Button>
          ) : null}
          <Button onClick={handleAddRange}>
            {isEdit ? translate((i) => i.ui.edit) : translate((i) => i.ui.add)}
          </Button>
        </div>
      </div>

      {props.value.map((schedule, scheduleKey) => (
        <button
          key={scheduleKey}
          onClick={() => handleEditRange(scheduleKey)}
          className="flex flex-col ring-1 ring-primary-200 mt-4 rounded-lg p-2 hover:shadow-md transition-all duration-300 hover:ring-2"
        >
          <div className="flex items-center gap-4">
            <div>
              <h3 className="font-semibold">
                {translate((i) => i.common.dates.days)}
              </h3>
              <div className="flex gap-2">
                {schedule.days.map((day, dayKey) => (
                  <Badge key={dayKey} text={day} />
                ))}
              </div>
            </div>

            <div>
              <h3 className="font-semibold">
                {translate((i) => i.common.dates.time)}
              </h3>
              <div>
                {schedule.start} - {schedule.end}
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  )
}

function ExternalLinkAvailability(props: AvailabilityTypeProps<string>) {
  return (
    <div className="w-full">
      <Input
        id="availability-url"
        name="availability-url"
        label={translate((i) => i.sell.nav.availability.calendlyLink)}
        placeholder={translate(
          (i) => i.sell.nav.availability.calendlyLinkPlaceholder,
        )}
        onChange={(v) => props.onChange(v.target.value)}
        value={props.value}
        className="w-full max-w-lg mx-auto"
        maxLength={255}
      />
    </div>
  )
}
