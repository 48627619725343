import { t } from 'i18next'
import { useState, useRef, useContext, useEffect } from 'react'

import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { EncryptionService } from '@/core/encryption'
import { GoogleDriveService } from '@/core/googleDrive'
import { OneDriveService } from '@/core/oneDrive'
import ApplicationStorage from '@/core/storage'
import { UserService } from '@/core/users/user.service'

export interface OneDriveFileUploaderHandles {
  handleGetMicrosoftAccessToken: () => void
}

export interface GoogleOAuthHandles {
  handleGetGoogleAccessToken: () => void
}

enum StorageOption {
  GoogleDrive = 'googleDrive',
  OneDrive = 'oneDrive',
}

export const useWalletManagement = () => {
  const [backendToken, setBackendToken] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')
  const [isRetrievePinFlow, setIsRetrievePinFlow] = useState<boolean>(false)
  const [isShowUserPinModal, setIsShowUserPinModal] = useState<boolean>(false)
  const [isShowRetrievePinModal, setIsShowRetrievePinModal] =
    useState<boolean>(false)
  const [isChangePin, setIsChangePin] = useState<boolean>(false)
  const [userOldPin, setUserOldPin] = useState<string>('')
  const [decodedPrivateKey, setDecodedPrivateKey] = useState<string>('')

  const { profile } = useContext(authContext)
  const { error, success } = useNotify()

  const googleAuthRef = useRef<GoogleOAuthHandles>(null)
  const microsoftAuthRef = useRef<OneDriveFileUploaderHandles>(null)

  const userService = new UserService()

  const userEncryptedPrivateKey = ApplicationStorage.encryptedPrivateKey

  useEffect(() => {
    if (isRetrievePinFlow) {
      handleOpenAuthPopup()
    }
  }, [isRetrievePinFlow])

  useEffect(() => {
    handleDecryptPrivateKey()
  }, [userEncryptedPrivateKey])

  const handleDecryptPrivateKey = async () => {
    if (userEncryptedPrivateKey) {
      const privateKey = await EncryptionService.decryptPrivateKey(
        userEncryptedPrivateKey,
        profile?.id || '',
      )
      setDecodedPrivateKey(privateKey)
    }
  }

  const handleOpenAuthPopup = () => {
    setUserOldPin('')
    const token = ApplicationStorage.socialToken
    if (token) {
      handleAccessToken(token)
      return
    }
    if (
      profile?.storageType === StorageOption.GoogleDrive &&
      googleAuthRef.current
    ) {
      googleAuthRef.current.handleGetGoogleAccessToken()
    } else if (
      profile?.storageType === StorageOption.OneDrive &&
      microsoftAuthRef.current
    ) {
      microsoftAuthRef.current.handleGetMicrosoftAccessToken()
    }
  }

  const handleAccessToken = async (token: string) => {
    if (token === 'failed') return
    setAccessToken(token)
    ApplicationStorage.socialToken = token
    const fileName = `chainIT-${profile?.walletAddress}`
    try {
      const content =
        profile?.storageType === StorageOption.GoogleDrive
          ? await GoogleDriveService.handleDownloadGoogleDriveAction(
              token,
              fileName,
            )
          : await OneDriveService.handleDownloadOneDriveAction(token, fileName)
      if (content) handleGetFile(content)
    } catch {
      handleShowError(t(i18nKeys.onboarding.errors.downloadFileError))
    }
  }

  const handleGetFile = (content: string) => {
    setIsShowUserPinModal(false)
    handleCipherDecryptToBE(content)
  }

  const handleCipherDecryptToBE = async (content: string) => {
    try {
      const res = await userService.cipherDecrypt(content)
      setBackendToken(res.cipherString)
      setIsShowRetrievePinModal(isRetrievePinFlow)
      setIsShowUserPinModal(!isRetrievePinFlow)
    } catch (err) {
      Logger.error('Post cipher decrypt error', undefined, err as Error)
      handleShowError()
      throw err
    }
  }

  const handleDecrypt = async (userPin: string) => {
    try {
      const data = backendToken.split('.')
      const privateKey = await EncryptionService.decryptPrivateKey(
        data[1],
        userPin,
      )
      setDecodedPrivateKey(privateKey)
      if (!privateKey) {
        handleShowError(t(i18nKeys.onboarding.errors.pinNotMatched))
        setIsRetrievePinFlow(false)
        return
      }
      if (isRetrievePinFlow) {
        const encryptedPrivateKey = await EncryptionService.encryptPrivateKey(
          privateKey,
          profile?.id || '',
        )
        ApplicationStorage.encryptedPrivateKey = encryptedPrivateKey
        handleShowSuccess()
        setIsRetrievePinFlow(false)
        setIsShowUserPinModal(false)
        return privateKey
      } else {
        setIsChangePin(true)
      }
    } catch (err) {
      handleShowError()
    }
  }

  const handleGotNewPin = async (userNewPin: string) => {
    setIsShowUserPinModal(false)
    const data = backendToken.split('.')
    const privateKey = await EncryptionService.decryptPrivateKey(
      data[1],
      userOldPin,
    )
    //Encode private key with new pin
    const encrypted1 = await EncryptionService.encryptPrivateKey(
      privateKey,
      userNewPin,
    )
    const updatedSalt = await handleUpdateNonce()
    const encrypted2 = await EncryptionService.encryptPrivateKey(
      privateKey,
      updatedSalt,
    )
    const finalEncrypted = await handleCipherEncryptToBE(
      profile?.walletAddress + '.' + encrypted1 + '.' + encrypted2,
    )
    handleUpload(finalEncrypted)
  }

  const handleCipherEncryptToBE = async (encryptedkey: string) => {
    try {
      const res = await userService.cipherEncrypt(encryptedkey)
      return res.cipherString
    } catch (err) {
      Logger.error('Post cipher encrypt error', undefined, err as Error)
      handleShowError()
    }
  }

  const handleForgotPin = async (userPin: string) => {
    const salt = await handleGetUserSalt()
    const data = backendToken.split('.')
    const privateKey = await EncryptionService.decryptPrivateKey(data[2], salt)
    //Encode private key with new pin
    const encrypted1 = await EncryptionService.encryptPrivateKey(
      privateKey,
      userPin,
    )
    const updatedSalt = await handleUpdateNonce()
    const encrypted2 = await EncryptionService.encryptPrivateKey(
      privateKey,
      updatedSalt,
    )
    const finalEncrypted = await handleCipherEncryptToBE(
      profile?.walletAddress + '.' + encrypted1 + '.' + encrypted2,
    )
    handleUpload(finalEncrypted, true)
  }

  const handleUpload = async (encryptKey: string, isForgotPinFlow = false) => {
    const fileName = `chainIT-${profile?.walletAddress}`
    if (profile?.storageType === StorageOption.GoogleDrive) {
      try {
        await GoogleDriveService.uploadFileOnGoogleDrive(
          encryptKey,
          accessToken,
          fileName,
        )
        handleFileUploaded(isForgotPinFlow)
      } catch {
        handleShowError(t(i18nKeys.onboarding.errors.uploadFileError))
      }
    } else if (profile?.storageType === StorageOption.OneDrive) {
      try {
        await OneDriveService.uploadFileOnOneDrive(
          encryptKey,
          accessToken,
          fileName,
        )
        handleFileUploaded(isForgotPinFlow)
      } catch {
        handleShowError(t(i18nKeys.onboarding.errors.uploadFileError))
      }
    }
  }

  const handleFileUploaded = (isForgotPinFlow: boolean) => {
    setIsRetrievePinFlow(false)
    setIsShowRetrievePinModal(false)
    setIsShowUserPinModal(false)
    setIsChangePin(false)
    handleShowSuccess(t(i18nKeys.onboarding.myWallet.pinChangeSuccessfully))
    if (isForgotPinFlow) {
      setTimeout(() => {
        setIsRetrievePinFlow(true)
      }, 100)
    }
  }

  const handleGetUserSalt = async () => {
    try {
      const res = await userService.getUserSalt()
      return res.nonce
    } catch (err) {
      Logger.error('Get cipher nonce error', undefined, err as Error)
      handleShowError()
    }
  }

  const handleUpdateNonce = async () => {
    try {
      const res = await userService.updateNonce()
      return res.nonce
    } catch (err) {
      Logger.error('Update nonce error', undefined, err as Error)
      handleShowError()
    }
  }

  const handleShowError = (msg?: string) => {
    error(msg || t(i18nKeys.common.somethingWentWrong))
  }

  const handleShowSuccess = (msg?: string) => {
    success(msg || t(i18nKeys.onboarding.myWallet.success))
  }

  const handleCloseModal = () => {
    if (isShowRetrievePinModal) {
      setIsShowRetrievePinModal(false)
      setIsRetrievePinFlow(false)
    } else {
      setIsShowUserPinModal(false)
      setIsChangePin(false)
    }
  }

  return {
    googleAuthRef,
    microsoftAuthRef,
    isShowUserPinModal,
    isShowRetrievePinModal,
    isChangePin,
    isRetrievePinFlow,
    decodedPrivateKey,
    setIsRetrievePinFlow,
    setIsShowUserPinModal,
    setIsShowRetrievePinModal,
    setUserOldPin,
    handleDecrypt,
    handleGotNewPin,
    handleForgotPin,
    handleOpenAuthPopup,
    handleAccessToken,
    handleCloseModal,
  }
}
