import React from 'react'

import type { PaymentCard } from '@/core'
import { classNames, formatExpirationDate } from '@/core'
import CardChip from '@assets/icons/card-chip.svg'

import { PaymentCardIcon } from '.'

export type CreditDebitCardProps = {
  card: PaymentCard
  className?: string
}

export const CreditDebitCard: React.FC<CreditDebitCardProps> = (props) => {
  return (
    <div
      className={classNames(
        'flex flex-col text-white w-[354px] h-[225px] rounded-[22px] px-[26px] pt-8 pb-[35px] overflow-hidden shadow-lg bg-gradient-to-tr from-primary-400 to-primary-700 justify-between',
        props.className,
      )}
    >
      <div className="flex items-center justify-between">
        <img src={CardChip} alt="card chip" />
        <PaymentCardIcon type={props.card.type} className="w-10" />
      </div>
      <div className="flex items-center gap-3">
        {[...Array(3).fill('****'), props.card.lastDigits].map((i, key) => (
          <span key={key} className="font-bold text-xl">
            {i}
          </span>
        ))}
      </div>
      <div className="grid grid-cols-3 justify-between">
        {/* TODO add translation */}
        <span className="text-[10px] col-span-2">Card Holder name</span>
        {/* TODO add translation */}
        <span className="text-[10px]">Expiry Date</span>

        <span className="font-medium col-span-2 overflow-hidden line-clamp-1">
          {props.card.holderName}
        </span>
        <span className="font-medium">
          {formatExpirationDate(
            props.card?.cardExpiry?.month,
            props.card?.cardExpiry?.year,
          )}
        </span>
      </div>
    </div>
  )
}
