import React from 'react'

import { Input } from '@/common/components'
import { translate } from '@/core'

import { SellPage, PageActionButtons } from '..'
import { useServiceListing } from '../context/service-listing-context'

export default function ServiceLocationPage() {
  const location = useServiceListing((state) => state.location)
  const update = useServiceListing((state) => state.update)

  return (
    <SellPage title={translate((i) => i.sell.nav.location.title)}>
      <Input
        id="location"
        name="location"
        label={translate((i) => i.sell.nav.location.location)}
        placeholder={translate((i) => i.sell.nav.location.locationPlaceholder)}
        onChange={(e) => update('location', e.target.value)}
        value={location}
        className="w-full max-w-lg mt-6"
        maxLength={255}
      />
      <PageActionButtons />
    </SellPage>
  )
}
