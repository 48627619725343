/*
 TODO these flags could be on a database or env file, so we can change them
  without the need of a new deploy, and to have different values on each environment.
*/
export const featureFlags = Object.freeze({
  /**
   * Shows service links on the mega menu
   */
  megaMenuServices: false,

  /**
   * Shows event links on the mega menu
   */
  megaMenuEvents: false,

  /**
   * Enables the cart feature on the header
   */
  cart: false,

  /**
   * Enables organization navigation on the header and profile
   */
  organizationNavigation: false,

  /**
   * Shows wallet balance on profile / Digital Identity
   */
  walletBalance: false,

  /**
   * Enables the profile section on profile / Digital Identity
   */
  profileSection: false,

  /**
   * Shows gas fee information on the buy flow and order detail
   */
  gasFee: false,

  /**
   * Enables the settings page on the menu
   */
  settingsPage: false,

  /**
   * Show a best rating filter on the search page
   */
  bestRatingFilter: false,

  /**
   * Shows an option to upload a government ID on the profile
   */
  beingIDGovernmentID: false,

  /**
   * Allows the payment of $10 per year to have a better BeingID
   */
  yearSubscription: true,

  /**
   * Shows a BeingID tab on the profile
   */
  beingIdProfile: false,
})
