import { Dialog, Popover, Transition } from '@headlessui/react'
import { t } from 'i18next'
import React, { Fragment, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Icon, MegaMenu } from '@/common/components'
import { HeaderButton } from '@/common/components/display'
import { useCategories } from '@/common/context/categories-context'
import { featureFlags } from '@/config'
import companyLogoURL from '@assets/icons/chainIT_marketplace_logo.svg'
import companyIconURL from '@assets/images/chainIT_marketplace_icon.png'

import { authContext } from '../../common/context/auth_context'
import tKeys from '../../common/i18nKeys'
import { UserProfileImage } from '../../components/UserProfileImage'
import useApplicationNavigate from '../../hooks/useApplicationNavigate'
import { login } from '../../pages/Onboarding/Login'
import { colors } from '../../theme'

/** The size in pixels to use for most icons on the header */
const ICON_SIZE = 24
const ProfileRoute = '/profile'

const headerOptionsWhenLogged = [
  {
    name: tKeys.profile.digitalIdentity.title,
    href: `${ProfileRoute}/digitalIdentity`,
    type: 'link',
    id: 'main-menu-digital-identity-id',
  },
  {
    name: tKeys.profile.personalInformation.title,
    href: `${ProfileRoute}/personalInformation`,
    type: 'link',
    id: 'main-menu-personal-information',
  },
  {
    name: tKeys.profile.beingId.title,
    href: `${ProfileRoute}/beingId`,
    type: 'link',
    id: 'main-menu-being-id',
    hidden: !featureFlags.beingIdProfile,
  },
  {
    name: tKeys.profile.chainItPay,
    href: `${ProfileRoute}/chainItPay`,
    type: 'link',
    id: 'main-menu-chainit-pay',
  },
  {
    name: tKeys.profile.organization,
    href: `${ProfileRoute}/organization`,
    type: 'link',
    id: 'main-menu-organization',
    hidden: !featureFlags.organizationNavigation,
  },
  { name: 'Sep1', href: `#`, type: 'separator' },
  {
    name: tKeys.profile.seller,
    href: `${ProfileRoute}/seller`,
    type: 'link',
    id: 'main-menu-seller',
  },
  {
    name: tKeys.profile.sell,
    href: `/sell`,
    type: 'link',
    id: 'main-menu-sell',
  },
  {
    name: tKeys.profile.myLibrary.title,
    href: `${ProfileRoute}/myLibrary`,
    type: 'link',
    id: 'main-menu-my-library',
  },
  {
    name: tKeys.profile.order.nav,
    href: `${ProfileRoute}/orderTracking`,
    type: 'link',
    id: 'order-tracking',
  },
  { name: 'Sep2', href: `#`, type: 'separator' },
  {
    name: tKeys.settings,
    href: `${ProfileRoute}/settings`,
    type: 'link',
    id: 'main-menu-settings',
    hidden: !featureFlags.settingsPage,
  },
  {
    name: tKeys.signOut,
    href: '/signout',
    type: 'link',
    id: 'main-menu-signout',
  },
]
const AvatarPicture: React.FC = () => {
  return (
    <>
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              <UserProfileImage section="header" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  {headerOptionsWhenLogged
                    .filter((i) => !i.hidden)
                    .map((item) => {
                      if (item.type === 'link') {
                        return (
                          <Link
                            id={item.id}
                            key={item.name}
                            to={item.href}
                            onClick={close}
                            className="block p-2 hover:text-primary-600"
                          >
                            {t(item.name)}
                          </Link>
                        )
                      }

                      if (item.type === 'separator') {
                        return (
                          <hr
                            key={item.name}
                            className="my-2 border-gray-900"
                          />
                        )
                      }
                    })}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default function Header() {
  const { authenticated, profile } = useContext(authContext)
  const { goToSearchResult } = useApplicationNavigate()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [searchError, setSearchError] = useState('')
  const categories = useCategories()
  const companyName = t`company`

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //TODO: need to be improved to search in my collection page
    if (e.key !== 'Enter' || search === '') {
      return
    }

    if (e.key === 'Enter' && search.length < 3) {
      setSearchError(t(tKeys.header.searchError))
      return
    } else {
      setSearchError('')
    }

    goToSearchResult({ search })
  }

  const signInAvatar = useMemo(() => {
    if (!authenticated) {
      return {
        screen: (
          <button
            id="header-sign-in"
            onClick={async () => login()}
            className="ms-5"
          >
            {t`header.signIn`}
          </button>
        ),
        mobile: (
          <Link
            to="#"
            onClick={async () => login()}
            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
          >
            {t`header.signIn`}
          </Link>
        ),
      }
    }

    return {
      screen: <AvatarPicture />,
      mobile: (
        <div className="flex flex-col items-center mt-4">
          <UserProfileImage section="header" size={120} />
          <p className="font-semibold text-lg mt-4">
            {profile?.firstName} {profile?.lastName}
          </p>

          <div className="w-full shrink rounded-xl p-2 text-sm font-semibold leading-6 text-gray-900 mt-4">
            {headerOptionsWhenLogged
              .filter((i) => !i.hidden)
              .map((item) => {
                if (item.type === 'link') {
                  return (
                    <Link
                      id={item.id}
                      key={item.name}
                      to={item.href}
                      onClick={close}
                      className="block p-2 hover:text-primary-600"
                    >
                      {t(item.name)}
                    </Link>
                  )
                }

                if (item.type === 'separator') {
                  return <hr key={item.name} className="my-2 border-gray-900" />
                }
              })}
          </div>
        </div>
      ),
    }
  }, [authenticated])

  return (
    <header className="border-b-2 border-[#E6EEFF] bg-white relative">
      <nav
        className="mx-auto flex flex-col lg:flex-row lg:gap-7 max-w-[1440px] p-6 lg:px-8 "
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">{companyName}</span>
              <img
                className="hidden lg:block h-8 w-auto"
                src={companyLogoURL}
                alt={companyName}
              />
              <img
                className="block lg:hidden h-9 w-auto"
                src={companyIconURL}
                alt={companyName}
              />
            </Link>
          </div>
          <div className="flex gap-9 lg:hidden">
            <Link to="/auctionTracking">
              <Icon
                icon="gavel"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>
            <Link to="/cart#wishlist">
              <Icon
                icon="heart"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>
            <Link to={featureFlags.cart ? '/cart' : '/coming-soon'}>
              <Icon
                icon="cart"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>

            <button onClick={() => setMobileMenuOpen(true)}>
              <Icon icon="bars" size={ICON_SIZE} />
            </button>
          </div>
        </div>
        <div className="flex sm:ps-4 flex-col-reverse lg:flex-row lg:items-center justify-between flex-1 lg:gap-7">
          <Popover.Group className="flex justify-between mt-6 lg:mt-0 lg:flex-1">
            <MegaMenu
              name={t(tKeys.header.products)}
              icon="baseball"
              viewAllHref="/products"
              categories={categories?.product ?? []}
            />
            {featureFlags.megaMenuServices ? (
              <MegaMenu
                name={t(tKeys.header.services)}
                icon="car"
                viewAllHref="#"
                categories={categories?.service ?? []}
              />
            ) : (
              <HeaderButton
                name={t(tKeys.header.services)}
                href="/coming-soon"
                icon="car"
              />
            )}
            {featureFlags.megaMenuEvents ? (
              <MegaMenu
                name={t(tKeys.header.events)}
                icon="champagneGlasses"
                viewAllHref="#"
                categories={categories?.event ?? []}
              />
            ) : (
              <HeaderButton
                name={t(tKeys.header.events)}
                href="/coming-soon"
                icon="champagneGlasses"
              />
            )}
          </Popover.Group>
          <div className="lg:flex-1">
            <div className="flex mt-4 lg:mt-0 lg:flex-1">
              <div className="flex flex-1 lg:hidden">
                <input
                  type="search"
                  placeholder={t`header.search`}
                  className="flex-1 rounded-s-2xl border py-3 px-4 pl-7 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary-600 lg:text-sm lg:leading-6"
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={onSearchKeyDown}
                />
                <button
                  className="py-2 px-3 rounded-e-2xl bg-secondary-800"
                  onClick={() => goToSearchResult({ search })}
                >
                  <Icon icon="search" color={colors.surface} size={ICON_SIZE} />
                </button>
              </div>
              <div className="hidden lg:flex flex-1 rounded-2xl border border-gray-500 items-center px-2">
                <Icon icon="search" color={colors.primary.DEFAULT} size={18} />

                <input
                  type="search"
                  placeholder={t`header.search`}
                  className="flex-1 border-0 rounded-2xl text-gray-900 placeholder:text-black placeholder:text-base placeholder:font-medium lg:text-sm lg:leading-6 focus:ring-0"
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={onSearchKeyDown}
                />
              </div>
            </div>
            {searchError ? (
              <p className="pl-3 text-xs absolute text-red-500">
                {searchError}
              </p>
            ) : null}
          </div>
          <div className="gap-9 lg:gap-12 hidden lg:flex justify-end items-center">
            <Link to="/auctionTracking">
              <Icon
                icon="gavel"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>
            <Link to="/cart#wishlist">
              <Icon
                icon="heart"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>
            <Link to={featureFlags.cart ? '/cart' : '/coming-soon'}>
              <Icon
                icon="cart"
                color={colors.primary.DEFAULT}
                size={ICON_SIZE}
              />
            </Link>
            {signInAvatar?.screen}
          </div>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 lg:max-w-sm lg:ring-1 lg:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">{companyName}</span>
              <img className="h-8 w-auto" src={companyLogoURL} alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">{t`header.closeMenu`}</span>
              <Icon icon="close" size={ICON_SIZE} />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">{signInAvatar?.mobile}</div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
