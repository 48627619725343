import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'

import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { Order, OrderTracking } from '@/common/types'
import { Logger } from '@/config'
import { OrderService } from '@/core'

export type OrderDetailHookParams = {
  orderId: string
  pollingCount?: number
}

export const useOrderDetail = ({
  orderId,
  pollingCount,
}: OrderDetailHookParams) => {
  const { error } = useNotify()

  const [order, setOrder] = useState<Order>()
  const [tracking, setTracking] = useState<OrderTracking>()

  const handleFetchHistory = useCallback(async () => {
    try {
      const _order = await OrderService.getOrderById(orderId)
      setOrder(_order)
      const _tracking = await OrderService.getTrackingById(
        _order.shipment.trackingId,
      )
      setTracking(_tracking)
    } catch (err) {
      Logger.error('Failed to fetch order history', undefined, err as Error)
      error(t(i18nKeys.common.somethingWentWrong))
    }
  }, [orderId, error, setOrder, setTracking, t])

  useEffect(() => {
    handleFetchHistory()
  }, [pollingCount])

  return { order, tracking, isLoading: !order || !tracking }
}
