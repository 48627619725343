import React from 'react'

import { classNames } from '@/core'

import { Icon } from '..'

export type BadgeSelectableProps = {
  text: string
  selected?: boolean
  onClick?: () => void
}

export const BadgeSelectable: React.FC<BadgeSelectableProps> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset gap-1',
        props.selected
          ? 'bg-primary-50 text-primary-600 ring-primary-500/10'
          : 'gap-x-1.5 text-primary-900 ring-gray-200',
      )}
    >
      {props.selected ? (
        <Icon icon="check" className="fill-primary-600 size-2" />
      ) : null}
      <span>{props.text}</span>
    </button>
  )
}
