import { t } from 'i18next'
import React from 'react'

import i18nKeys from '@/common/i18nKeys'

export const ComingSoon: React.FC = () => {
  return (
    <div className="bg-white mx-auto max-w-7xl mb-6 px-4 py-6 sm:px-6 sm:py-8  lg:px-8">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900">
        {t(i18nKeys.common.soon)}
      </h1>
      <p className="mt-2 text-sm text-gray-500">
        {t(i18nKeys.comingSoonDescription)}
      </p>
    </div>
  )
}
