import { Combobox } from '@headlessui/react'
import { t } from 'i18next'
import React, { useCallback, useDeferredValue } from 'react'

import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

import { Icon } from '../icon'

export interface TypeaheadOption {
  id: string
  text: string
}

export interface TypeaheadProps {
  label: string
  loading: boolean
  options: TypeaheadOption[]
  onFilterChange: (query: string) => void
  onChange: (value: TypeaheadOption) => void
  selected?: TypeaheadOption
  className?: string
}

export const Typeahead: React.FC<TypeaheadProps> = ({
  label,
  loading,
  onFilterChange,
  options,
  onChange,
  selected,
  className,
}) => {
  const displayedOptions = useDeferredValue<TypeaheadOption[]>(options)

  const handleChangeOption = useCallback(
    (option: TypeaheadOption) => onChange(option),
    [onChange],
  )

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={handleChangeOption}
      className={className}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          onChange={(event) => onFilterChange(event.target.value)}
          displayValue={(option: TypeaheadOption) => option?.text ?? ''}
        />
        <ConditionalRendering renderIf={loading}>
          <span>{t(i18nKeys.ui.loading)}...</span>
        </ConditionalRendering>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <Icon icon="upDown" color={colors.gray[400]} size={20} />
        </Combobox.Button>

        {displayedOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {displayedOptions.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {option.text}
                    </span>

                    <ConditionalRendering renderIf={selected}>
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary',
                        )}
                      >
                        <Icon icon="check" color={colors.surface} />
                      </span>
                    </ConditionalRendering>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

Typeahead.displayName = 'Typeahead'
