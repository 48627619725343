export enum ServiceAvailabilityOptionType {
  ExternalLink = 'ExternalLink',
  Calendar = 'Calendar',
}

export type AvailabilitySchedule = {
  days: string[]
  start: string
  end: string
}
