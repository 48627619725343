import React from 'react'

import type { ListProps } from '@/common/types'
import type { CustomerAssetWithPrice } from '@/core'

import { StorefrontProduct } from './StorefrontProduct'

export const ProductSection: React.FC<ListProps<CustomerAssetWithPrice>> = ({
  items,
}) => {
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {items.map((item, itemKey) => (
        <StorefrontProduct key={itemKey} item={item} />
      ))}
    </section>
  )
}
