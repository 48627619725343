import { create } from 'zustand'

import type { WithActions } from '@/common/types'
import type { TaxCategory } from '@/common/types/taxes'
import { storeActions } from '@/core/context-utils'

import type { CardOption } from '..'
import { taxCategories } from '../constants/taxes'

export type ListingState = {
  taxCategoryType: CardOption
  taxCode: TaxCategory | null
}

export const useListingStore = create<WithActions<ListingState>>((set) => ({
  taxCategoryType: taxCategories[0],
  taxCode: null,
  ...storeActions<ListingState>(set),
}))
