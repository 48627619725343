import { Popover, Transition } from '@headlessui/react'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import { classNames } from '@/core'
import { colors } from '@/theme'

import { Icon } from '..'
import type { IconSource } from '..'

export type MegaMenuSubcategory = {
  name: string
  href: string
}

export type MegaMenuCategory = {
  subcategories: MegaMenuSubcategory[]
} & MegaMenuSubcategory

export type MegaMenuProps = {
  categories: MegaMenuCategory[]
  name: string
  icon: IconSource
  viewAllHref: string
}

export const MegaMenu: React.FC<MegaMenuProps> = (props) => {
  const categories = useMemo(() => {
    return props.categories
      .filter((i) => i.subcategories.length > 0)
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 8)
      .map((category) => {
        category.subcategories = category.subcategories.slice(0, 4)
        return category
      })
  }, [props.categories])
  return (
    <Popover key={props.name} className="flex">
      {({ open, close }) => (
        <>
          <div className="relative flex">
            <Popover.Button
              className={classNames(
                'relative -mb-px flex items-center pt-px text-sm font-medium transition-colors duration-200 ease-out gap-2',
              )}
            >
              <Icon
                icon={props.icon}
                color={colors.primary.DEFAULT}
                size={24}
              />
              <span
                className={classNames(
                  'text-base font-semibold',
                  open ? 'text-primary-500' : 'text-black',
                )}
              >
                {props.name}
              </span>
            </Popover.Button>
          </div>

          <Transition
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="absolute inset-x-0 top-full text-gray-500 sm:text-sm z-10">
              <div className="relative bg-white">
                <div className="mx-auto max-w-7xl px-8">
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-8 gap-y-10 pb-12 pt-10 items-start">
                    {categories.map((category, categoryKey) => (
                      <div key={categoryKey}>
                        <Link
                          to={category.href}
                          className="font-medium text-gray-900"
                          onClick={close}
                        >
                          {category.name}
                        </Link>
                        <ul className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                          {category.subcategories.map((item) => (
                            <li key={item.name} className="flex">
                              <Link
                                to={item.href}
                                className="hover:text-gray-800"
                                onClick={close}
                              >
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end items-center py-6 ">
                    <Link
                      to={props.viewAllHref}
                      className="flex gap-2 items-center text-base font-semibold text-primary-500"
                      onClick={close}
                    >
                      <span>{t(i18nKeys.home.viewAll)}</span>
                      <Icon
                        icon="arrowRight"
                        color={colors.primary[500]}
                        size={18}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
