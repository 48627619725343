import { RadioGroup } from '@headlessui/react'
import React from 'react'

import { Icon } from '@/common/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

/** Represents an option on the OptionCards component */
export type CardOption = {
  id: number | string

  /** Option title */
  title: string

  /** Option description */
  description: string

  /** Option action text */
  action?: string

  /** Makes the option disabled */
  disabled?: boolean

  actionClassName?: string
}

export type OptionCardsProps = {
  /** Array of options */
  options: CardOption[]

  /** Currently selected option */
  selected?: CardOption

  /** Style classes */
  className?: string

  /** Event fired when the option has changed */
  onChange?: (value: CardOption) => Promise<void> | void
}

/** Allows to choose one from many options */
export const OptionCards: React.FC<OptionCardsProps> = (
  props: OptionCardsProps,
) => {
  return (
    <RadioGroup
      value={props.selected}
      className={props.className}
      onChange={props.onChange}
    >
      {props.options.map((value) => (
        <RadioGroup.Option
          key={value.title}
          value={value}
          disabled={value.disabled}
          className={({ active }) =>
            classNames(
              active ? 'border-primary ring-2 ring-primary' : 'border-gray-300',
              'relative flex flex-1 cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none',
              value.disabled ? 'text-gray-600 bg-gray-50' : 'bg-white',
            )
          }
        >
          {({ checked, active }) => (
            <>
              <span className="flex flex-1">
                <span className="flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {value.title}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className="mt-1 flex flex-1 text-sm text-gray-500"
                  >
                    {value.description}
                  </RadioGroup.Description>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      'mt-6 text-sm font-medium text-gray-900',
                      value.actionClassName,
                    )}
                  >
                    {value.action}
                  </RadioGroup.Description>
                </span>
              </span>
              <Icon
                icon="checkCircle"
                color={colors.primary.DEFAULT}
                size={20}
                className={!checked ? 'invisible' : ''}
              />
              <span
                className={classNames(
                  active ? 'border' : 'border-2',
                  checked ? 'border-primary' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-lg',
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}
