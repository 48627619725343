import envVariables from '../../common/envVariables'
import ApplicationStorage from '../storage'

import { AuthService } from './auth.service'
import type { AuthBiometricsResponseData } from './interfaces'
import {
  createQueryParams,
  encode,
  generateChallenge,
  generateCodeVerifier,
} from './utils'

const API_BASE_URL = envVariables.API_BASE_URL
const AUTH0_CLIENT_ID = envVariables.AUTH0_CLIENT_ID
const BASE_URL = envVariables.API_BASE_URL

export const getCodeVerifier = () => localStorage.getItem('code_verifier') || ''
export const clearLocalStorage = () => localStorage.clear()

export const buildAuthUri = (
  clientId: string,
  codeChallenge: string,
  state: string,
  action: string,
) =>
  `${API_BASE_URL}/users/v1/end-user/auth/${action}?${createQueryParams({
    clientId,
    codeChallenge,
    state,
  })}`

const buildLogOutUri = (
  clientId: string,
  redirectUri: string,
  userType: string = 'end-user',
) =>
  `${API_BASE_URL}/users/v1/end-user/auth/sign-out?${createQueryParams({
    clientId: clientId,
    redirectUri: redirectUri,
    userType: userType,
  })}`

export interface GenerateStateOpts {
  clientId?: string
  redirectUri: string
  authType: 'sign-up' | 'sign-in'
}

// This function creates the Authentication URL (sign-up / sign-in)
// Why does it need to be "created" here instead of a simple one-liner?
// 1. "state" is required in the query which needs to be a base64 encoded JSON string describing our agent
// 2. "codeVerifier" and "codeChallenge" need to be generated and remembered for the succeeding auth steps (finish-challenge)
export const getAuthUrl = async (opts: GenerateStateOpts) => {
  const { clientId, redirectUri, authType } = opts

  const urlEncodeB64 = (input: string) => {
    const b64Chars = { '+': '-', '/': '_', '=': '' }
    return input.replace(/[+/=]/g, (m) => b64Chars[m])
  }

  // 1. Prepare the "claims" of this "state" token
  // Yes, it needs to be called "redirectUri", not "redirectUrl"
  const state = {
    clientId,
    redirectUri,
    app: '' /*Config.APP_NAME*/,
    version: 1,
  }
  // 2. IDK wtf is going on here, but this is what the backend wants
  const stateBase64Encoded = urlEncodeB64(encode(JSON.stringify(state)))
  // 3. Generate "codeVerifier" and "codeChallenge"
  // "codeVerifier" must be remembered for the "finish-challenge" step,
  // to verify that we're still the same person
  const codeVerifier = generateCodeVerifier()
  ApplicationStorage.codeVerifier = codeVerifier
  const codeChallenge = await generateChallenge(getCodeVerifier())
  // Yes, "clientId" is passed in both the query, and in state, for some reason
  // "state" here must be the JSON string as base64, that's why step 2. was needed
  // No idea what "codeChallenge" is for but the backend wants this
  const query = createQueryParams({
    clientId,
    state: stateBase64Encoded,
    codeChallenge,
  })
  const url = `${API_BASE_URL}/users/v1/end-user/auth/${authType}?${query}`
  return { url }
}

export const logout = async (redirectUri: string) => {
  // 2. Prepare sign-out query params & URL
  const query = createQueryParams({ clientId: AUTH0_CLIENT_ID, redirectUri })
  window.location.href = `${API_BASE_URL}/users/v1/end-user/auth/sign-out?${query}`
}

export const buildAuthBiometricsUrl = (data: AuthBiometricsResponseData) =>
  `${API_BASE_URL}/users/v1/end-user/auth/finish-challenge?${createQueryParams(data)}`

export const logOut = async () => {
  clearLocalStorage()
  sessionStorage.clear()
  const returnURL: string = BASE_URL
  window.location.href = buildLogOutUri(AUTH0_CLIENT_ID, `${returnURL}`)
}

export interface SessionToken {
  iat: number
  iss: string
  sub: string
  exp: number
  ip: string
  clientId: string
  biometricInfo: boolean
  email: string
  biometricsId: string
}

type FYCSignUpData = {
  sessionId: string
  userId: string
}

type FYCSignInData = {
  transactionId: string
  token: string
}

export type FYCData = FYCSignUpData | FYCSignInData | null
export const biometricsAuth = async (
  sessionToken: string,
  state: string,
  fycData: FYCData = null,
) => {
  const service = new AuthService()

  return service.biometricsAuth({
    clientId: AUTH0_CLIENT_ID,
    sessionToken,
    state,
    fycData: fycData || {
      bypassFaceVerification: true,
    },
  })
}

export const finalizeAuth = async (code: string, state: string) => {
  const service = new AuthService()
  const res = await service.createAuthToken(
    {
      clientId: AUTH0_CLIENT_ID,
      code,
      state,
      codeVerifier: getCodeVerifier(),
      sessionId: getSessionId()!,
    },
    'end-user',
  )

  return res
}

export const refreshToken = async () => {
  const service = new AuthService()
  const result = await service.refreshToken(
    {
      clientId: AUTH0_CLIENT_ID,
      refreshToken: getRefreshToken()!,
    },
    'end-user',
  )
  const d = new Date()
  d.setSeconds(d.getSeconds() + result.expiresIn)

  ApplicationStorage.tokens = {
    accessToken: result.accessToken,
    refreshToken: result.refreshToken,
    bitToken: result.bitToken,
    idToken: result.idToken,
    expirationDate: d.toDateString(),
  }
}

export const finalizeDocumentsAuth = async (
  userId: string,
  incodeSessionId: string,
) => {
  const service = new AuthService()
  const res = await service.finalizeDocumentsAuth({
    userId: userId,
    sessionId: incodeSessionId,
  })
  return res
}

export const getSessionId = () => {
  return ApplicationStorage.incodeSessionId
}

export const getRefreshToken = () => {
  return ApplicationStorage.refreshToken
}
