import type {
  AddSellerProfileRequest,
  PaymentInfo,
  AddProfileRequest,
  BuyerProfile,
  SellerProfile,
  PagedResponse,
  ListAddressesRequest,
} from '@bit-ui-libs/common'
import {
  UserService as CommonUserService,
  UserTypeEnum,
} from '@bit-ui-libs/common'

import type { SellerProfileWithTaxIdType } from '@/common/context/auth_context'
import envVariables from '@/common/envVariables'
import type {
  Address,
  BuyerShippingAddress,
  SellerShippingAddress,
} from '@/common/types'
import { doDelete, doGet, doPatch, doPost, doPut, mapQueryParams } from '@/core'
import type {
  CreateUserAddressRequest,
  AddressValidationResponse,
  CreateBuyerShippingAddressRequest,
  CreatePaymentCardParams,
  CreatePaymentDetailsParams,
  CreateSellerPaymentDetailsParams,
  CreateSellerShippingAddressRequest,
  EditBuyerShippingAddressRequest,
  EditSellerShippingAddressRequest,
  EditUserAddressRequest,
  PaymentCard,
  PaymentInfoResponse,
  SearchAddressesRequest,
  SetSellerPaymentDetailRequest,
  ShareableIVDTRequest,
  ShareableIVDTResponse,
  SingleUseCustomerTokenResponse,
  UpdatePaymentCardParams,
  UserDocument,
  UserPaymentDetailsResponse,
  ValidateAddressRequest,
} from '@/core'

import { applicationAxiosClient } from '../axios'
import Logger from '../logger'

const service = new CommonUserService({
  apiUrl: envVariables.API_BASE_URL,
  axiosInstance: applicationAxiosClient,
  userType: UserTypeEnum.EndUser,
})

export interface UserPaginatedRequestInterface {
  $page: number
  $perPage: number
  $ids?: string
}
export class UserService {
  async updateUserInfo(
    data: {
      avatarUrl?: string
      beignId?: string
      username: string
      firstName?: string
      middleName?: string
      lastName?: string
      phone?: string
      phoneVerified: boolean
      privacyAccepted: boolean
      mainProfileCompleted: boolean
      id: string
      storageType?: string
      walletAddress?: string
    },
    userId: string,
  ) {
    return doPut(`/users/v1/end-user/users/${userId}`, data)
  }

  async sendVerificationCode(phone: string) {
    return doPost(`/users/v1/end-user/verifications/send-code`, { phone })
  }

  async verifyCode(data: { phone: string; code: string }) {
    return doPost(`/users/v1/end-user/verifications/verify-code`, data)
  }

  async addUserAddress(data: CreateUserAddressRequest): Promise<Address> {
    const AddressResult = await doPost(`/users/v1/end-user/addresses`, data)
    const userService = new UserService()
    const buyerProfile = await userService.getListUserBuyerProfiles(
      data?.userId,
    )

    if (buyerProfile.length === 0) {
      const result = await userService.addBuyerProfile({
        userId: data?.userId,
        appName: envVariables.APP_NAME,
        isDefault: true,
      })
      buyerProfile.push(result)
    }

    const options = {
      addressId: AddressResult.id,
      profileId: buyerProfile[0]?.id,
      isDefault: true,
      ...AddressResult,
    } as CreateBuyerShippingAddressRequest

    await userService.addShippingAddress(options)

    return AddressResult
  }

  async addShippingAddress(
    data: CreateBuyerShippingAddressRequest,
  ): Promise<BuyerShippingAddress> {
    return doPost(
      `/users/v1/end-user/buyer/${data.profileId}/shipping-addresses`,
      data,
    )
  }

  async editShippingAddress(
    data: EditBuyerShippingAddressRequest,
  ): Promise<BuyerShippingAddress> {
    return doPut(
      `/users/v1/end-user/buyer/${data.profileId}/shipping-addresses/${data.addressId}`,
      data,
    )
  }

  async addBuyerProfile(data: AddProfileRequest): Promise<BuyerProfile> {
    return doPost(`/users/v1/end-user/buyer`, data)
  }

  //TODO: When the user hasn't buyer profile it returns nothing. Need to be refactored to return 404.
  async getDefaultUserBuyerProfile(): Promise<BuyerProfile> {
    return doGet(`/users/v1/end-user/buyer/default`)
  }

  async getListUserBuyerProfiles(userId: string): Promise<BuyerProfile[]> {
    return doGet(
      `/users/v1/end-user/buyer/list?orderBy=id&userId=${userId}&appName=${envVariables.APP_NAME}`,
    )
  }

  async getListUserSellerProfiles(
    userId: string,
  ): Promise<SellerProfileWithTaxIdType[]> {
    return doGet(
      `/users/v1/end-user/seller/list?orderBy=id&userId=${userId}&appName=${envVariables.APP_NAME}`,
    )
  }

  async getUserAddresses(data: SearchAddressesRequest) {
    return doGet(
      `/users/v1/end-user/addresses?userId=${data.userId}`,
    ) as Promise<PagedResponse<Address>>
  }

  async sendEmailCodeVerification(email: string): Promise<boolean> {
    return doPost(`/users/v1/end-user/verifications/send-email-code`, { email })
  }

  async verifyEmailCode(code: string): Promise<{ verified: boolean }> {
    return doPost(`/users/v1/end-user/verifications/verify-email-code`, {
      code,
    })
  }

  async getUserPaymentEmail() {
    return (await doGet(
      `/users/v1/end-user/verifications/payment-email`,
    )) as Promise<{
      email: string
      codeVerified: boolean
      venmoVerified: boolean
    }>
  }

  async listAddresses(data: ListAddressesRequest | null = null) {
    return service.address.listAddresses(data ?? {}) as unknown as Promise<
      Address[]
    >
  }

  async listSellerAddresses(
    profileId: string,
    data: { userId?: string; id?: string } | null = null,
  ) {
    let url = `/users/v1/end-user/seller/${profileId}/shipping-addresses`

    if (data) {
      url = mapQueryParams(url, data)
    }

    return doGet(url) as Promise<SellerShippingAddress[]>
  }

  async createSellerAddress(
    profileId: string,
    data: CreateSellerShippingAddressRequest,
  ) {
    return doPost(`/users/v1/end-user/seller/${profileId}/shipping-addresses`, {
      ...data,
    })
  }

  async listBuyerAddresses(profileId: string) {
    return doGet(`/users/v1/end-user/buyer/${profileId}/shipping-addresses`)
  }

  async getUserAddressesList(userId: string) {
    return doGet(
      `/users/v1/end-user/addresses/list?userId=${userId}`,
    ) as Promise<Address[]>
  }

  async getDefaultBuyerAddress(profileId: string) {
    return doGet(
      `/users/v1/end-user/buyer/${profileId}/shipping-addresses/default`,
    ) as Promise<BuyerShippingAddress | undefined>
  }

  async getUserInfo(userId: string) {
    return doGet(`/users/v1/end-user/users/${userId}`)
  }

  async getUsersPagination(options: UserPaginatedRequestInterface) {
    // const query = createUrlQueryFromObject(options)
    //TODO: improve this method to accept arrays like Ids
    return doGet(
      `/users/v1/end-user/users?$page=${options.$page}&$perpage=${options.$perPage}&$ids=${options.$ids}`,
    )
  }

  async setMainAddress(addressId: string) {
    return doPatch(`/users/v1/end-user/addresses/${addressId}`, {
      id: addressId,
      isMain: true,
    })
  }

  async addSellerProfile(
    data: AddSellerProfileRequest,
    taxIdType: string,
  ): Promise<SellerProfileWithTaxIdType> {
    return doPost(`/users/v1/end-user/seller`, { ...data, taxIdType })
  }

  async editSellerProfile(
    data: AddSellerProfileRequest,
    taxIdType: string,
  ): Promise<SellerProfileWithTaxIdType> {
    return doPut(`/users/v1/end-user/seller/${data.id}`, { ...data, taxIdType })
  }

  async getPaymentDetails(profileId: string) {
    return doGet(
      `/users/v1/end-user/seller/${profileId}/payment-details`,
    ) as Promise<PaymentInfo[]>
  }

  async getPaymentDetailById(profileId: string, paymentDetailId: string) {
    return doGet(
      `/users/v1/end-user/seller/${profileId}/payment-details/${paymentDetailId}`,
    ) as Promise<PaymentInfo>
  }

  // TODO replace any with type
  async updatePaymentDetail(profileId: string, payment: any) {
    return doPut(
      `/users/v1/end-user/seller/${profileId}/payment-details/${payment.paymentDetailId}`,
      { ...payment },
    ) as Promise<PaymentInfo>
  }

  async updateUserAddress(
    addressId: string,
    data: EditUserAddressRequest,
  ): Promise<Address> {
    const addressResult = await doPut(
      `/users/v1/end-user/addresses/${addressId}`,
      data,
    )

    const userService = new UserService()
    const buyerProfile = await userService.getListUserBuyerProfiles(
      data?.userId,
    )

    if (buyerProfile.length === 0) {
      const result = await userService.addBuyerProfile({
        userId: data?.userId,
        appName: envVariables.APP_NAME,
        isDefault: true,
      })
      buyerProfile.push(result)
    }

    const options = {
      addressId: addressResult.id,
      profileId: buyerProfile[0]?.id,
      isDefault: true,
      ...addressResult,
    } as CreateBuyerShippingAddressRequest

    await userService.editShippingAddress(options)

    return addressResult
  }

  async removeAddress(addressId: string) {
    await service.address.removeAddress(addressId)
  }

  async createPaymentDetails(data: CreatePaymentDetailsParams) {
    return doPost(`/users/v1/payment-details`, {
      ...data,
    }) as Promise<UserPaymentDetailsResponse>
  }

  async getUserPaymentDetails(userId: string) {
    return doGet(`/users/v1/payment-details/list?userId=${userId}`) as Promise<
      PaymentInfo[]
    >
  }

  async createSellerPaymentDetails(
    profileId: string,
    data: CreateSellerPaymentDetailsParams,
  ) {
    return doPost(`/users/v1/end-user/seller/${profileId}/payment-details`, {
      ...data,
    }) as any
  }

  async createBuyerPaymentDetails(
    profileId: string,
    data: CreateSellerPaymentDetailsParams,
  ) {
    return doPost(`/users/v1/end-user/buyer/${profileId}/payment-details`, {
      ...data,
    }) as any
  }

  async patchSellerPaymentDetails(
    profileId: string,
    paymentDetailId: string,
    data: SetSellerPaymentDetailRequest,
  ) {
    return doPatch(
      `/users/v1/end-user/seller/${profileId}/payment-details/${paymentDetailId}`,
      { ...data },
    )
  }

  async getDefaultSellerProfile() {
    try {
      return (await doGet(`/users/v1/end-user/seller/default`)) as Promise<
        SellerProfile | undefined
      >
    } catch (e) {
      Logger.error(
        'Failed to get default seller profile',
        undefined,
        e as Error,
      )
      return undefined
    }
  }
  async createCard(buyerProfileId: string, data: CreatePaymentCardParams) {
    return doPost(`/users/v1/end-user/buyer/${buyerProfileId}/cards`, {
      ...data,
    }) as Promise<PaymentCard>
  }

  async listCards(buyerProfileId: string) {
    return doGet(
      `/users/v1/end-user/buyer/${buyerProfileId}/cards/list`,
    ) as Promise<PaymentCard[]>
  }

  async getCardById(cardId: string, buyerProfileId: string) {
    return doGet(
      `/users/v1/end-user/buyer/${buyerProfileId}/cards/${cardId}`,
    ) as Promise<PaymentCard>
  }

  async removeCard(cardId: string, buyerProfileId: string) {
    return doDelete(
      `/users/v1/end-user/buyer/${buyerProfileId}/cards/${cardId}`,
    )
  }

  async updateCard(params: UpdatePaymentCardParams) {
    return doPut(
      `/users/v1/end-user/buyer/${params.profileId}/cards/${params.id}`,
      { ...params },
    ) as Promise<PaymentCard>
  }

  async validateAddress(params: ValidateAddressRequest) {
    return doPost(`/users/v1/end-user/addresses/validate`, {
      ...params,
    }) as Promise<AddressValidationResponse>
  }
  async getSingleUseToken(buyerProfileId: string) {
    return doPost(
      `/users/v1/end-user/buyer/${buyerProfileId}/single-use-customer-tokens`,
    ) as Promise<SingleUseCustomerTokenResponse>
  }

  async sendResetPasswordMail(userId: string): Promise<boolean> {
    try {
      const response = await doPatch(
        `/users/v1/end-user/users/${userId}/request-password`,
        undefined,
        {
          202: async () => {
            return true
          },
        },
      )
      return response as boolean
    } catch {
      return false
    }
  }

  async shareProfileIVDT(
    data: ShareableIVDTRequest | null = null,
  ): Promise<ShareableIVDTResponse> {
    const params = data ?? shareableIVDTRequestDefault
    return doPost(`/users/v1/end-user/users/request-ivdt-shareable`, {
      ...params,
    })
  }

  async getUserVdtPublic() {
    return doGet(`/users/v1/end-user/cipher/nonce`)
  }

  async updateNonce() {
    return doPatch(`/users/v1/end-user/cipher/nonce`)
  }

  async cipherEncrypt(encodedFileNonce: string) {
    return doPost(`/users/v1/end-user/cipher/encrypt`, { encodedFileNonce })
  }

  async getUserSalt() {
    return doGet(`/users/v1/end-user/cipher/nonce`)
  }

  async getAWSSessionID() {
    return doGet(`/users/v1/end-user/users/liveness-session`)
  }

  async postSignatureID(sessionData: any) {
    return doPost(`/users/v1/end-user/users/liveness-session`, sessionData)
  }

  async getDefaultShippingAddress(profileId: string) {
    try {
      return (await doGet(
        `/users/v1/end-user/seller/${profileId}/shipping-addresses/default`,
      )) as Promise<SellerShippingAddress | undefined>
    } catch (e) {
      Logger.error(
        'Failed to get default shipping address',
        undefined,
        e as Error,
      )
      return undefined
    }
  }

  async updateShippingAddress(
    profileId: string,
    addressId: string,
    data: EditSellerShippingAddressRequest,
  ) {
    return doPut(
      `/users/v1/end-user/seller/${profileId}/shipping-addresses/${addressId}`,
      { ...data },
    ) as Promise<SellerShippingAddress | undefined>
  }

  async getMainAddress() {
    try {
      return (await doGet(`/users/v1/end-user/addresses/main`)) as Promise<
        Address | undefined
      >
    } catch (e) {
      Logger.error('Failed to get main address', undefined, e as Error)
      return undefined
    }
  }

  static async getDefaultBuyerPaymentProfile(profileId: string) {
    return doGet(
      `/users/v1/end-user/buyer/${profileId}/payment-details/default`,
    ) as Promise<PaymentInfoResponse>
  }

  static async getMainProfilePaymentDetails(profileId: string) {
    return doGet(
      `/users/v1/payment-details/list?userId=${profileId}`,
    ) as Promise<PaymentInfoResponse>
  }

  static async getDocuments(userId: string) {
    return doGet(
      `/users/v1/end-user/users/list-fyc-documents?userId=${userId}`,
    ) as Promise<UserDocument[]>
  }
}

const shareableIVDTRequestDefault: ShareableIVDTRequest = {
  all: true,
  who: {
    age: true,
    dateOfBirth: true,
    fullName: true,
    firstName: true,
    middleName: true,
    lastName: true,
    beingIdHistory: true,
  },
  what: {},
  where: {
    fullAddress: true,
    city: true,
    state: true,
    country: true,
    addressOnId: true,
    zipCode: true,
  },
  tokens: {
    currentlyOwned: true,
    associated: true,
    previouslyOwned: true,
  },
}
