import { t } from 'i18next'
import jwtDecode from 'jwt-decode'
import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import i18nKeys from '../../common/i18nKeys'
import Spinner from '../../components/Spinner'
import { biometricsAuth, buildAuthBiometricsUrl } from '../../core/auth/index'
import type { SessionToken } from '../../core/auth/index'
import type { AuthBiometricsResponseData } from '../../core/auth/interfaces'
import ApplicationStorage from '../../core/storage'

export const Biometric = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sessionToken = searchParams.get('sessionToken')
  const state = searchParams.get('state')
  let res: AuthBiometricsResponseData

  useEffect(() => {
    start()
  }, [])

  if (!sessionToken || !state) {
    // TODO present a better error to the user
    throw new Error('Missing sessionToken or state')
  }

  if (sessionToken) {
    ApplicationStorage.sessionToken = sessionToken
  }

  if (state) {
    ApplicationStorage.state = state
  }

  const start = async () => {
    const decodedSessionToken = jwtDecode(sessionToken!) as SessionToken
    if (!decodedSessionToken.biometricInfo) {
      navigate(
        `/personalInformation?sessionToken=${sessionToken}&state=${state}`,
      )
    } else {
      // TODO techdebt: move hardcoded values to another place
      if (decodedSessionToken.email.includes('+dev')) {
        res = await biometricsAuth(sessionToken, state, {
          transactionId: 'testmode',
          token: 'testmode',
        })
      } else {
        res = await biometricsAuth(sessionToken, state)
      }
    }

    if (res) {
      const redirectUrl = buildAuthBiometricsUrl(res)
      window.location.href = redirectUrl
    }
  }

  return (
    <div>
      <div className="w-full p-6">
        <Spinner
          text={t(i18nKeys.onboarding.verifyingInformation)}
          textClassName="text-sm mt-3 text-center"
          className="m-auto"
        />
      </div>
    </div>
  )
}
