import axios from 'axios'
import { v4 as uuid } from 'uuid'

import { EPaymentType } from '@/common/constants'
import {
  ECountryCode,
  getStateCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import type { Address } from '@/common/types'
import { Environment } from '@/common/types'
import { PaymentsService } from '@/core/marketplace/payments.service'
import type { PaymentHandleToken } from '@/features/buyProcess'
import { colors } from '@/theme'

export type TokenizationResponse = {
  amount: number
  currencyCode: string
  merchantRefNum: string
  paymentHandleToken: string
  paymentType: EPaymentType
}

export type PaysafeBillingAddress = {
  address: string
  city: string
  state: string
  country: string
  zip: string
  name: string
  type: string
}

export interface PaysafeCreditCardFormProps {
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: (error?: string) => void | Promise<void>
  amount: number
  billingAddress: PaysafeBillingAddress
  holderName: string
  submitText: string
  isNewCard?: boolean
  handleToken?: PaymentHandleToken
}

export const getPaysafeInstance = async (isNewCard: boolean = false) => {
  const paymentCredentials = await PaymentsService.getPaymentsCredentials()
  const accounts = {
    default: Number.parseInt(paymentCredentials.accountId, 10),
  }
  return (window as any).paysafe.fields.setup(paymentCredentials.publicKey, {
    currencyCode: 'USD',
    accounts: accounts,
    environment:
      envVariables.ENVIRONMENT === Environment.PRODUCTION ? 'LIVE' : 'TEST',
    fields: {
      cardNumber: {
        selector: '#card-number',
        placeholder: '****************',
        separator: '-',
        optional: !isNewCard,
      },
      cvv: {
        selector: '#cvv',
        placeholder: '****',
        mask: false,
        optional: false,
      },
      expiryDate: {
        selector: '#expiry-date',
        placeholder: '**/**',
        optional: !isNewCard,
      },
    },
    style: {
      input: {
        'font-family': 'robotoregular,Helvetica,Arial,sans-serif',
        'font-weight': 'normal',
        'font-size': '14px',
      },
      '.valid:focus': {
        color: colors.primary.DEFAULT,
      },
    },
  })
}

export const startPaysafeTokenize = async (
  holderName: string,
  billingAddress: PaysafeBillingAddress,
  amount: number,
  paysafeInstance: any,
  handleTokens?: PaymentHandleToken,
) => {
  const merchantRefNum = uuid()
  const tokenizationOptions: any = {
    amount,
    merchantRefNum,
    transactionType: 'PAYMENT',
    paymentType: EPaymentType.Card,
    card: {
      holderName,
    },
    customerDetails: {
      billingDetails: {
        state: getStateCode(billingAddress.state),
        city: billingAddress.city,
        zip: billingAddress.zip,
        street: billingAddress.address,
        country: ECountryCode.UnitedStates, // TODO: Add support for other countries
      },
    },
  }

  if (handleTokens) {
    tokenizationOptions.singleUseCustomerToken =
      handleTokens!.profileHandleToken
    tokenizationOptions.paymentTokenFrom = handleTokens!.cardHandleToken
  }

  try {
    const tokenizeResult = await paysafeInstance.tokenize(tokenizationOptions)
    if (!tokenizeResult.token) {
      return
    }
    return {
      amount: amount,
      currencyCode: 'USD',
      merchantRefNum,
      paymentHandleToken: tokenizeResult.token,
    } as TokenizationResponse
  } catch (error) {
    throw error
  }
}

export const startVenmoTokenize = async (
  holderName: string,
  billingAddress: Address,
  amount: number,
) => {
  const paymentCredentials = await PaymentsService.getPaymentsCredentials()
  const merchantRefNum = uuid()
  const requestBody = {
    merchantRefNum,
    amount,
    transactionType: 'PAYMENT',
    paymentType: EPaymentType.Venmo,
    venmo: {
      consumerId: 'maksym.shafarenko@swansoftwaresolutions.com',
    },
    currencyCode: 'USD',
    billingDetails: {
      nickName: holderName,
      state: getStateCode(billingAddress.state),
      city: billingAddress.city,
      zip: billingAddress.zip,
      street: billingAddress.address,
      country: selectCountryFromSplitString(billingAddress.country),
    },
    returnLinks: [
      {
        rel: 'default',
        href: window.location.origin,
        method: 'GET',
      },
      {
        rel: 'on_completed',
        href: window.location.origin + '/venmo-payment-complete',
        method: 'GET',
      },
      {
        rel: 'on_failed',
        href: window.location.origin + '/venmo-payment-error',
        method: 'GET',
      },
      {
        rel: 'on_error',
        href: window.location.origin + '/venmo-payment-error',
        method: 'GET',
      },
    ],
  }
  try {
    const response = await axios.post(
      'https://api.test.paysafe.com/paymenthub/v1/singleusepaymenthandles',
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + paymentCredentials.publicKey,
          Simulator: 'EXTERNAL',
        },
      },
    )

    // For Venmo users in the USA, we need to redirect the user to the Venmo QR page to authorize the payment
    if (response.data.links && response.data.links.length > 0) {
      const redirectUrl = response.data.links.find(
        (link: any) => link.rel === 'redirect_payment',
      ).href
      window.location.href = redirectUrl
    } else if (response.data.status === 'PAYABLE') {
      // User has authorized the payment
      return {
        amount: amount,
        currencyCode: 'USD',
        merchantRefNum: merchantRefNum,
        paymentHandleToken: response.data.paymentHandleToken,
      } as TokenizationResponse
    }
  } catch (error) {
    throw error
  }
}
