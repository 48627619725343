import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescription } from '@/common/types'
import { translateKey } from '@/core'

import type { CardOption } from '..'
import { AuctionType, SellingMethodType, ServiceListingTerms } from '../types'

export const AUCTION_TYPES: DisplayItemWithDescription[] = [
  {
    id: AuctionType.Timed,
    name: i18nKeys.sell.nav.method.auction.timed,
    description: i18nKeys.sell.nav.method.auction.timedDescription,
  },
  {
    id: AuctionType.Dutch,
    name: i18nKeys.sell.nav.method.auction.dutch,
    description: i18nKeys.sell.nav.method.auction.dutchDescription,
  },
  {
    id: AuctionType.Inverse,
    name: i18nKeys.sell.nav.method.auction.inverse,
    description: i18nKeys.sell.nav.method.auction.inverseDescription,
    disabled: true,
  },
]

export const SELL_METHODS: DisplayItemWithDescription[] = [
  {
    id: SellingMethodType.FixedPrice,
    name: i18nKeys.sell.nav.method.methods.fixed,
    description: i18nKeys.sell.nav.method.methods.fixedDescription,
  },
  {
    id: SellingMethodType.Auction,
    name: i18nKeys.sell.nav.method.methods.auction,
    description: i18nKeys.sell.nav.method.methods.auctionDescription,
  },
]

export const serviceListingTerms: CardOption[] = [
  {
    id: ServiceListingTerms.OneTime,
    title: translateKey((i) => i.sell.nav.serviceTerms.options.oneTime.title),
    description: translateKey(
      (i) => i.sell.nav.serviceTerms.options.oneTime.description,
    ),
  },
  {
    id: ServiceListingTerms.Recurring,
    title: translateKey((i) => i.sell.nav.serviceTerms.options.recurring.title),
    description: translateKey(
      (i) => i.sell.nav.serviceTerms.options.recurring.description,
    ),
  },
]
