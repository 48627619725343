import { t } from 'i18next'
import React from 'react'

import { Icon } from '@/common/components'
import { useNotify, useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { UserService } from '@/core'
import { colors } from '@/theme'

import { ProfileInputWrapper } from './ProfileRowCard'

// TODO missing translations
export const AccountInformation: React.FC = () => {
  const { profile } = useProfile()
  const { warning, info, error } = useNotify()

  const handleChangePassword = async () => {
    try {
      if (!profile?.userId) {
        warning('Profile is loading, please wait a moment')
        return
      }
      const service = new UserService()
      if (await service.sendResetPasswordMail(profile.userId)) {
        info(
          'An email was sent to your email address with instructions on how to reset your password.',
        )
        return
      }
    } catch (e) {
      Logger.error('Failed to send reset password mail', undefined, e as Error)
    }
    error('Failed to send reset password mail')
  }

  return (
    <div className="w-full lg:max-w-full bg-white md:p-12 p-3 my-8">
      <h3 className="text-xl font-semibold">
        {t(i18nKeys.profile.digitalIdentity.accountInformation.subTitle)}
      </h3>
      <ProfileInputWrapper
        iconLeft={<Icon icon="email" color={colors.primary[500]} />}
        // iconRight={<Icon icon="edit" size={18} color={colors.iconGray} />}
        type="text"
        text={profile?.email ? profile?.email : ''}
        label="Email"
      />

      {/* TODO: replace this mocked data with backend response */}
      <ProfileInputWrapper
        iconLeft={<Icon icon="lock" color={colors.primary[500]} />}
        iconRight={
          <Icon
            icon="edit"
            size={18}
            color={colors.iconGray}
            onClick={handleChangePassword}
          />
        }
        type="password"
        text="xxxxxxxx"
        label="Password"
      />
    </div>
  )
}
