import { WalletPlatformEnum } from '@bit-ui-libs/common'

import envVariables from '@/common/envVariables'

import { applicationAxiosClient } from './axios'

export const serviceConfiguration = {
  apiUrl: envVariables.API_BASE_URL,
  axiosInstance: applicationAxiosClient,
}

export const WALLET_DEFAULT_PLATFORM = WalletPlatformEnum.Polygon
