import { SellingMethodEnum, type CustomerAsset } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import { Button, type IconSource } from '@/common/components'
import { Identifiers } from '@/common/constants'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import type { CustomerAssetWithPrice, listingResponse } from '@/core'
import { dateToStringFormat, ListingService } from '@/core'
import { AssetService } from '@/core/assets/asset.service'
import { EventService } from '@/core/events'
import { colors } from '@/theme'

export type ListingStatus =
  | 'MINTED'
  | 'LISTING_STARTED'
  | 'LISTING_FAILED'
  | 'LISTED'
  | 'DELIST_STARTED'
  | 'DELIST_FAILED'
  | 'DELISTED'
  | 'ORDER_STARTED'
  | 'ORDER_COMPLETED'
  | 'ORDER_FAILED'

export const statusMapping: Record<
  ListingStatus,
  { label: string; icon: IconSource; iconColor: string }
> = {
  MINTED: {
    label: i18nKeys.bid.detail.status.minted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTING_STARTED: {
    label: i18nKeys.bid.detail.status.listing_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTING_FAILED: {
    label: i18nKeys.bid.detail.status.listing_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTED: {
    label: i18nKeys.bid.detail.status.listed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELIST_STARTED: {
    label: i18nKeys.bid.detail.status.delist_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELIST_FAILED: {
    label: i18nKeys.bid.detail.status.delist_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELISTED: {
    label: i18nKeys.bid.detail.status.delisted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_STARTED: {
    label: i18nKeys.bid.detail.status.order_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_COMPLETED: {
    label: i18nKeys.bid.detail.status.order_completed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_FAILED: {
    label: i18nKeys.bid.detail.status.order_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
}

export type BidItemAssetProps = {
  auction: CustomerAssetWithPrice
  selectedBid?: string
  setSelectedBid: (e: string) => void
  setOpenBidDetails: (e: string | undefined) => void
}

export const BidItemAsset: React.FC<BidItemAssetProps> = ({
  auction,
  selectedBid,
  setSelectedBid,
  setOpenBidDetails,
}) => {
  const [asset, setAsset] = useState<CustomerAsset>()
  const [listing, setListing] = useState<listingResponse>()
  const [currentHigherPrice, setCurrentHigherPrice] = useState<number | string>(
    0,
  )

  useEffect(() => {
    handleFetchAsset()
  }, [auction?.id!])

  const handleFetchAsset = async () => {
    const res = await ListingService.getListingById(auction.id)
    setListing(res)
    try {
      const lastBid = await ListingService.getLastBid(res.id)
      setCurrentHigherPrice(lastBid.price)
    } catch {
      setCurrentHigherPrice(auction.price)
    }

    const assetService = await AssetService.getById(auction?.assetId)
    setAsset(assetService)
  }

  const imageSrc = useMemo(() => {
    if (!asset) return

    let mainImage = asset.serializedImages.find((image) => image.isMain)

    if (!mainImage) {
      mainImage = asset.serializedImages[0]
    }

    if (!mainImage) return

    return EventService.buildDocumentImageUrlById(mainImage.docId)
  }, [asset])

  const description = useMemo(() => {
    if (!asset) return

    return asset.serializedProps.find(
      (p) => p.name === Identifiers.ASSET_DESCRIPTION_PROP,
    )?.value
  }, [asset])

  // const dateCreated = useMemo(() => {
  //   return new Date(auction?.createdAt!).toLocaleDateString()
  // }, [auction?.createdAt])

  const rendererTimer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <span>{t(i18nKeys.bid.auction.timeOver)}</span>
    } else {
      // Render a countdown
      return (
        <div className="flex font-bold">
          <span className="mb-4 mr-2">
            {zeroPad(days)} {t(i18nKeys.bid.auction.days)} - {zeroPad(hours)}:
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
          <span>{t(i18nKeys.ui.left)}</span>
        </div>
      )
    }
  }

  return (
    <div
      className={
        (selectedBid === listing?.id
          ? 'border-lime-200 '
          : 'border-gray-200 ') +
        'border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border cursor-pointer'
      }
    >
      <div className="pl-4 pt-4 pr-0 pb-0 sm:pt-6 sm:pr-0 sm:pl-6 sm:pb-0">
        <div className="flex items-center sm:items-start">
          <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
            {imageSrc ? (
              <button
                className="h-full w-full p-0"
                onClick={() => setOpenBidDetails(listing?.id)}
              >
                <img
                  src={imageSrc}
                  alt={auction?.name}
                  className="h-full w-full object-cover object-center"
                />
              </button>
            ) : (
              <div className="flex items-center justify-center h-full">
                <Spinner />
              </div>
            )}
          </div>
          <div className="ml-6 flex-1 text-sm pr-6">
            <div className="flex justify-between">
              <h5 className="text-xl font-bold mb-2">{auction?.name}</h5>
              <p>
                {listing ? (
                  <Countdown
                    date={Date.parse(listing.endAt)}
                    renderer={rendererTimer}
                  />
                ) : null}
              </p>
            </div>
            <button onClick={() => setOpenBidDetails(listing?.id)}>
              <p className="hidden text-gray-500 sm:mt-2 sm:block mb-3 cursor-pointer">
                {description}
              </p>
            </button>
            <p className="hidden text-gray-500 sm:mt-2 sm:block mb-3">
              {dateToStringFormat(listing?.startAt)}
            </p>
          </div>
        </div>

        <div className="mt-6 sm:flex sm:justify-between">
          <div className="flex items-center text-xl">
            <ConditionalRendering
              renderIf={listing?.sellingMethod === SellingMethodEnum.Dutch}
            >
              <span className="px-5 py-2 bg-gray-200 rounded-tl-lg rounded-br-lg align-middle">
                Dutch auction
              </span>
            </ConditionalRendering>
            <ConditionalRendering
              renderIf={listing?.sellingMethod === SellingMethodEnum.Auction}
            >
              <span className="px-5 py-2 bg-gray-200 rounded-tl-lg rounded-br-lg align-middle">
                Auction
              </span>
            </ConditionalRendering>
            <ConditionalRendering
              renderIf={listing?.sellingMethod === SellingMethodEnum.Inverse}
            >
              <span className="px-5 py-2 bg-gray-200 rounded-tl-lg rounded-br-lg align-middle">
                Inverse
              </span>
            </ConditionalRendering>
          </div>

          <div className="flex items-end text-xl py-1">
            <ConditionalRendering renderIf={selectedBid !== listing?.id}>
              <Button
                type="button"
                mode="text"
                onClick={() => setSelectedBid(listing?.id ? listing?.id : '')}
                className="mr-4"
              >
                See all bids
              </Button>
            </ConditionalRendering>

            <ConditionalRendering renderIf={selectedBid === listing?.id}>
              <Button
                type="button"
                mode="text"
                onClick={() => setSelectedBid('')}
                className="mr-4"
              >
                Hide bids
              </Button>
            </ConditionalRendering>

            <Button
              type="button"
              mode="text"
              onClick={() => setOpenBidDetails(listing?.id)}
            >
              View results
            </Button>
          </div>

          <div className="flex items-center text-xl">
            <div className="px-5 py-2 bg-gray-100 rounded-tl-lg h-4/5 self-end align-middle inline-grid grid-cols-1">
              <span className="text-xs">Started</span>
              <span>${auction?.price}</span>
            </div>
            <div className="px-5 py-2 bg-gray-200 rounded-tl-lg rounded-br-lg h-full pt-5 inline-grid grid-cols-1">
              <span className="text-xs">Current Bid</span>
              <span>${currentHigherPrice}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
// TODO: Add All the translates
