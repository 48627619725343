import type { PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { ItemsPagination } from '@/common/components/Pagination'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { AssetsService, ListingService, classNames } from '@/core'
import type {
  ProductListProps,
  pageSelectedInterface,
} from '@/features/profile/myLibrary/LibraryFiltersWrapper'
import { ProductCard } from '@/features/profile/myLibrary/ProductCard'
import First from '@assets/icons/first.svg'

export const ITEM_PER_PAGE = 16

// TODO missing translations
// TODO replace hardcoded values with constants
export const SearchResult: React.FC = () => {
  const { error } = useNotify()
  const [elements, setElements] = useState<PagedResponse<ProductListProps>>()
  const [criteria, setCriteria] = useState<string>('...')

  const [isLoading, setIsLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()
  const { categoryId, assetTypeId } = useParams<{
    categoryId: string
    assetTypeId: string
  }>()

  Logger.info('SearchResult', { categoryId, assetTypeId })

  const search = searchParams.get('search')

  const page = useMemo(() => {
    const _page = searchParams.get('page')
    return _page ? Number(_page) : 1
  }, [searchParams])

  const navigate = useNavigate()

  const handleSearchItems = async () => {
    try {
      setIsLoading(true)
      const bodyRequest = {
        $page: page,
        $perPage: ITEM_PER_PAGE,
        status: 'LISTED', // TODO replace hardcoded value with enum
        // $orderBy:  TODO: need to define order parameters
      } as any

      if (search) {
        bodyRequest.name = search
      }

      if (categoryId) {
        bodyRequest.categories = categoryId
      }

      if (assetTypeId) {
        bodyRequest.types = assetTypeId
      }

      const res = await ListingService.getListingsList(bodyRequest)

      const ids = res.items?.map((x) => {
        return x.assetId
      })
      const assetWithImages = await AssetsService.getPublicByAssetId(ids)
      //TODO: this will change when images is added to the listing endpoint. This is just a temporary approach.

      const elementsResponse = {
        items: res.items?.map((e) => {
          return {
            product: {
              ...e,
              serializedImages: assetWithImages?.items?.find(
                (x) => x.id === e.assetId,
              )?.serializedImages,
            },
          }
        }),
        page: page,
        perPage: ITEM_PER_PAGE,
        totalCount: res.totalCount,
        totalPages: res.totalPages,
      } as PagedResponse<ProductListProps>

      setElements(elementsResponse)
    } catch (err) {
      Logger.error('Failed to search items', undefined, err as Error)
      error(t(i18nKeys.common.somethingWentWrong))
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigateToProduct = (productId: string) => {
    const asset = elements?.items.find((x) => x.product.id === productId)

    if (!asset) {
      return
    }

    navigate(
      `/search/listing/${asset.product.id}/event/${asset.product.eventIdRef}/detail`,
    )
  }

  const handleChangePage = (pageNumber: pageSelectedInterface) => {
    searchParams.set('page', `${pageNumber.selected + 1}`)
    setSearchParams(searchParams)
  }

  const title = useMemo(() => {
    if (search) {
      return elements?.totalCount
        ? `${elements?.totalCount} results for '${search}'`
        : 'No listings were found based on your search query. Be the first to list...'
    }

    if (categoryId) {
      return elements?.totalCount
        ? `${elements?.totalCount} results for '${criteria}'`
        : `Be the first to sell '${criteria}'!`
    }

    if (assetTypeId) {
      return elements?.totalCount
        ? `${elements?.totalCount} results for '${criteria}'`
        : `Be the first to sell '${criteria}'!`
    }

    return elements?.totalCount
      ? `${elements?.totalCount} results`
      : 'No listings were found based on your search query. Be the first to list...'
  }, [search, elements?.totalCount, categoryId, assetTypeId, criteria])

  const handleFetchCategory = async () => {
    const assetService = new AssetsService()
    const res = await assetService.getCategoryById(categoryId!)
    setCriteria(res.name)
  }

  const handleFetchAssetType = async () => {
    const assetService = new AssetsService()
    const res = await assetService.getAssetTypeById(assetTypeId!)
    setCriteria(res.name)
  }

  useEffect(() => {
    if (categoryId) {
      handleFetchCategory()
      return
    }

    if (assetTypeId) {
      handleFetchAssetType()
      return
    }
  }, [categoryId, assetTypeId])

  useEffect(() => {
    const _page = Number(searchParams.has('page'))
    if (!_page || _page < 1) {
      searchParams.set('page', '1')
      setSearchParams(searchParams)
      return
    }

    handleSearchItems()
  }, [searchParams])

  return (
    <div className="w-full lg:max-w-full p-4">
      <section className="mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white py-6 sm:py-8 ">
        {elements?.totalCount && criteria ? (
          <div>
            <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-4">
                {title}
              </h1>
            </div>
          </div>
        ) : null}

        <div className="mt-6">
          <div>
            <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {isLoading ? (
                <Spinner containerClassName="flex items-center justify-center py-12" />
              ) : (
                <div className="flex flex-col">
                  {elements?.totalCount ? (
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                      {elements?.items?.map((p, index) => (
                        <ProductCard
                          data={p}
                          onClick={async () =>
                            handleNavigateToProduct(p.product.id)
                          }
                          index={index}
                          key={`product-${index}`}
                          showFavorite={true}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center gap-6">
                      <h1
                        className={classNames(
                          'text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-4',
                          Boolean(search) && 'text-center',
                        )}
                      >
                        {title}
                      </h1>
                      {!search ? (
                        <img
                          src={First}
                          className="size-60"
                          alt="First to sell"
                        />
                      ) : null}
                      <div className="mt-6">
                        <Link
                          to="/sell"
                          target="_blank"
                          className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        >
                          Start selling now
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <ItemsPagination
                onSearch={handleChangePage}
                totalPages={elements?.totalPages ?? 1}
                currentPage={page - 1}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
