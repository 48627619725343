import { t } from 'i18next'
import React from 'react'

import CartCostSummary from '../components/CartCostSummary'

// TODO replace hardcoded values with real data
const assetsInCart = [
  {
    asset: {
      description: 'Product description',
      id: '1',
      images: [
        'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      ],
      name: 'Basic Tee',
      price: 35,
    },
    id: 'ca1',
    quantity: 3,
    total: 105,
  },
  {
    asset: {
      description: 'Product description',
      id: '3',
      images: [
        'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg',
      ],
      name: 'Basic Tee',
      price: 35,
    },
    id: 'ca2',
    quantity: 7,
    total: 245,
  },
]

const total = assetsInCart.reduce(
  (acc, cartAsset) => acc + cartAsset.asset.price * cartAsset.quantity,
  0,
)

const CartDetail: React.FC = () => {
  return (
    <div className="container mx-auto grid grid-cols-10 gap-8">
      <div className="col-span-8 shadow border-2 p-5">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
          {t`cartDetail.cart`}
        </h1>
        <ul className="divide-y divide-gray-100">
          {assetsInCart.map((cartAsset) => (
            <li
              key={`asset-in-cart-${cartAsset.id}`}
              className="flex justify-between gap-x-6 py-5"
            >
              <div className="flex min-w-0 gap-x-4">
                <img
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                  src={cartAsset.asset.images[0]}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {cartAsset.asset.name}
                  </p>
                </div>
                <div className="flex-end">
                  <p>{cartAsset.total}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <p>
          {t`cartDetail.total`}: ${total}
        </p>
      </div>
      <div className="col-span-2">
        <CartCostSummary />
      </div>
    </div>
  )
}

CartDetail.displayName = 'CartDetail'
export default CartDetail
