import { t } from 'i18next'
import React, { useContext, useState, useCallback } from 'react'

import { Button } from '@/common/components'
import type { UserProfileType } from '@/common/context/auth_context'
import { authContext } from '@/common/context/auth_context'
import i18nKeys from '@/common/i18nKeys'
import type { Address } from '@/common/types'
import { SectionField } from '@/components/SectionField'
import type { CreateUserAddressRequest, EditUserAddressRequest } from '@/core'
import { UserService } from '@/core/users/user.service'
import { PhoneInformation } from '@/pages'
import type { AddressInformationFormResult } from '@/pages/Onboarding/PersonalInformation/AddressInformation'
import AddressInformation from '@/pages/Onboarding/PersonalInformation/AddressInformation'
import Information from '@/pages/Onboarding/PersonalInformation/Information'
import { colors } from '@/theme'

export const PersonalInformation: React.FC = () => {
  const {
    addresses,
    profile,
    removeAddress,
    replaceAddress,
    setUserProfile,
    setUserAddresses,
  } = useContext(authContext)
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false)
  const [locationId, setLocationId] = useState<string>('')
  const [addPhone, setAddPhone] = useState<boolean>(false)
  const [addPersonalInfo, setAddPersonalInfo] = useState<boolean>(false)
  const [isAddAddress, setIsAddAddress] = useState<boolean>(true)

  const submitAddressForm = useCallback(
    async (address: AddressInformationFormResult) => {
      if (!profile?.userId) {
        throw new Error('error') //todo: improve errors
      }

      const userService = new UserService()
      const request = {
        id: '',
        address: address.address,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        country: address.country,
        isMain: false, // new addresses are added as not main by default
        name: address.name,
        state: address.state,
        type: address.type,
        userId: profile?.userId,
        zip: address.zip,
      } as CreateUserAddressRequest

      let result: Address
      if (isAddAddress) {
        result = await userService.addUserAddress(request)
        const newAddresses = [...addresses]
        newAddresses.push(result)
        setUserAddresses(newAddresses)
      } else {
        const existingAddress = addresses.filter((x) => x.id === locationId)[0]
        if (!existingAddress) {
          throw new Error('error') //todo: improve errors
        }
        const editRequest: EditUserAddressRequest = {
          ...request,
          id: existingAddress.id,
          isMain: existingAddress.isMain,
        }

        result = await userService.updateUserAddress(
          existingAddress.id,
          editRequest,
        )
      }

      replaceAddress(locationId, result)
      setShowAddressForm(false)
    },
    [profile, isAddAddress, locationId],
  )

  const submitInformationForm = useCallback(
    async (event: Event) => {
      event.preventDefault()

      if (!profile?.userId || !event.target) {
        throw new Error('error') //todo: improve errors
      }

      const { firstName, lastName, middleName } = event.target as any

      const data = {
        id: profile.userId,
        firstName: firstName.value,
        lastName: lastName.value,
        middleName: middleName.value,
        username: profile.username ?? '',
        address: addresses ?? [],
        mainProfileCompleted: profile.mainProfileCompleted
          ? profile?.mainProfileCompleted
          : false,
        userId: profile?.userId,
        avatarUrl: '',
        beignId: '',
        privacyAccepted: true,
        phoneVerified: Boolean(profile.phone),
        phone: profile.phone ?? '',
        addresses: profile.addresses,
        email: profile.email,
        createdAt: profile.createdAt,
      } as UserProfileType

      const userService = new UserService()
      await userService.updateUserInfo(data, profile.userId)
      setUserProfile(data)
      setAddPersonalInfo(false)
    },
    [profile],
  )

  const onShowAddressForm = (
    addressId: string,
    showForm: boolean,
    isAdd: boolean,
  ) => {
    setIsAddAddress(isAdd)
    setLocationId(addressId)
    setShowAddressForm(showForm)
  }

  const setMainAddress = async (id: string) => {
    const userService = new UserService()
    await userService.setMainAddress(id)
    const result = await userService.getUserAddresses({
      userId: profile?.userId!,
    })
    setUserAddresses(result.items)
  }

  const handleDeleteAddress = async (id: string) => {
    const userService = new UserService()
    await userService.removeAddress(id)
    removeAddress(id)
  }

  const completeName = `${profile?.firstName} ${profile?.middleName || ''} ${profile?.lastName}`
  let bodyContent = (
    <>
      <div className="mx-auto mb-5 p-5 bg-white">
        <div className="w-full h-fit mx-auto">
          <h2 className="mb-2 text-2xl">
            {t(i18nKeys.profile.personalInformation.title)}
          </h2>
          <SectionField
            icon="user"
            infoCompleted={!!(profile?.firstName && profile?.lastName)}
            label={t(i18nKeys.profile.personalInformation.name)}
            defaultAction={{ onClick: () => setAddPersonalInfo(true) }}
            placeholder={t(
              i18nKeys.onboarding.personalInformation.tellUsAboutYou,
            )}
            info={completeName}
          />

          <SectionField
            className="mt-2"
            icon="phone"
            infoCompleted={!!profile?.phone}
            label={t(i18nKeys.onboarding.personalInformation.contactDetails)}
            defaultAction={{ onClick: () => setAddPhone(true) }}
            placeholder={t(
              i18nKeys.onboarding.personalInformation.howToReachYou,
            )}
            info={profile?.phone ?? ''}
          />
        </div>
      </div>

      <div className="mx-auto p-5 bg-white">
        <div className="w-full h-fit mx-auto ">
          <div className="flex items-center justify-between mb-5">
            <h2 className="mb-2 text-2xl">
              {t(i18nKeys.profile.personalInformation.addresses)}
            </h2>
            <Button
              className="text-lg"
              mode="text"
              color={colors.primary.DEFAULT}
              onClick={() => onShowAddressForm('', true, true)}
            >
              {t(i18nKeys.profile.personalInformation.addAddress)}
            </Button>
          </div>
          <div className="flex flex-col gap-2">
            {addresses?.map((c) => (
              <SectionField
                key={`address-${c.id}`}
                icon="home"
                infoCompleted={!!c?.address}
                label={c.name}
                editAction={{
                  onClick: () => onShowAddressForm(c.id, true, false),
                }}
                placeholder={t(
                  i18nKeys.onboarding.personalInformation.whereToFindYou,
                )}
                info={c?.address ?? ''}
                favoriteAction={{
                  onClick: async () => setMainAddress(c.id),
                  title: t(
                    i18nKeys.profile.personalInformation.markAddressAsMain,
                  ),
                }}
                deleteAction={{
                  // TODO ask for confirmation?
                  onClick: async () => handleDeleteAddress(c.id),
                  title: t(i18nKeys.ui.delete),
                }}
                isDefault={c.isMain}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )

  if (showAddressForm) {
    bodyContent = (
      <div className="mx-auto p-8 bg-white">
        <AddressInformation
          onClose={() => setShowAddressForm(false)}
          onSave={submitAddressForm}
          defaultValues={
            isAddAddress
              ? undefined
              : addresses.filter((x) => x.id === locationId)[0]
          }
        />
      </div>
    )
  } else if (addPersonalInfo) {
    bodyContent = (
      <div className="mx-auto p-8 bg-white">
        <Information
          onSave={async (e: any) => submitInformationForm(e)}
          onCancel={() => setAddPersonalInfo(false)}
          defaultValues={{
            firstName: profile?.firstName,
            middleName: profile?.middleName,
            lastName: profile?.lastName,
          }}
        />
      </div>
    )
  } else if (addPhone) {
    bodyContent = (
      <div className="mx-auto p-8 bg-white">
        <PhoneInformation
          onClose={() => setAddPhone(false)}
          defaultValue={profile?.phone}
        />
      </div>
    )
  }

  return bodyContent
}
