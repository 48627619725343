import React from 'react'

export type SellPageProps = {
  /** Page content */
  children: React.ReactNode

  /** Page title */
  title?: string
}

/** Wrapper for sell pages */
export const SellPage: React.FC<SellPageProps> = (props: SellPageProps) => {
  return (
    <div className="bg-white flex flex-col pt-14 pb-20 mt-4 rounded items-center px-3 sm:px-32 relative min-h-[520px]">
      {!!props.title && (
        <h2 className="font-medium sm:mx-16 text-center">{props.title}</h2>
      )}

      {props.children}
    </div>
  )
}
