import React from 'react'

import { ConditionalRendering } from '@/components'
import { colors } from '@/theme'

import { Icon } from '..'

type ErrorLabelProps = {
  error?: string
  hideIcon?: boolean
}

export const ErrorLabel: React.FC<ErrorLabelProps> = (props) => {
  return (
    <ConditionalRendering renderIf={Boolean(props.error)}>
      <p className="mt-2 text-sm text-red-600 flex items-center gap-2">
        <ConditionalRendering renderIf={!props.hideIcon}>
          <Icon icon="error" color={colors.error} size={12} />
        </ConditionalRendering>
        <span>{props.error}</span>
      </p>
    </ConditionalRendering>
  )
}
