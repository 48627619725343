import { StepEnum } from '@bit-ui-libs/common'
import React from 'react'

import i18nKeys from '@/common/i18nKeys'
import SellCategory from '@/features/listing/pages/SellCategory'
import SellTaxPage from '@/features/listing/pages/SellTaxPage'

import { Icon } from '../common/components'
import {
  SellDeliveryPage,
  SellDetailsPage,
  SellImagesPage,
  SellMethodPage,
  SellMintingAssetPage,
  SellPaymentPage,
  SellPreviewPage,
  SellPrivacyPage,
  SellTransactionPage,
  SellVdtPage,
} from '../features/listing'
import type { RouteWithMetadata } from '../types'

export const sellItemsRoutes: RouteWithMetadata[] = [
  {
    route: {
      path: 'vdt',
      element: <SellVdtPage />,
    },
    label: i18nKeys.sell.nav.vdt.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.vdt.description,
    hideOnMenu: true,
  },
  {
    route: {
      path: 'categories',
      element: <SellCategory />,
    },
    hideOnMenu: true,
  },
  {
    route: {
      path: 'details',
      element: <SellDetailsPage />,
    },
    label: i18nKeys.sell.nav.details.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.details.description,
  },
  {
    route: {
      path: 'images',
      element: <SellImagesPage />,
    },
    label: i18nKeys.sell.nav.images.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.images.description,
  },
  {
    route: {
      path: 'privacy',
      element: <SellPrivacyPage />,
    },
    label: i18nKeys.sell.nav.privacy.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.privacy.description,
  },
  {
    route: {
      path: 'minting',
      element: <SellMintingAssetPage />,
    },
    hideOnMenu: true,
  },
  {
    route: {
      path: 'method',
      element: <SellMethodPage />,
    },
    label: i18nKeys.sell.nav.method.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.method.description,
  },
  {
    route: {
      path: 'payment',
      element: <SellPaymentPage />,
    },
    label: i18nKeys.sell.nav.payment.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.payment.description,
  },
  {
    route: {
      path: 'transaction',
      element: <SellTransactionPage />,
    },
    label: i18nKeys.sell.nav.transaction.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.transaction.description,
  },
  {
    route: {
      path: 'delivery',
      element: <SellDeliveryPage />,
    },
    label: i18nKeys.sell.nav.delivery.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.delivery.description,
  },
  {
    route: {
      path: 'taxes',
      element: <SellTaxPage />,
    },
    label: 'Taxes',
    navIcon: <Icon icon="category" size={20} />,
    description: 'Choose the taxes you want to apply to your listing',
  },
  {
    route: {
      path: 'preview',
      element: <SellPreviewPage />,
    },
    hideOnMenu: true,
  },
]

// todo: add other pages mappings
export const mintAssetStepMapping: Record<StepEnum, RouteWithMetadata> = {
  [StepEnum.DATA]: {
    route: {
      path: 'asset/details',
      element: <SellDetailsPage />,
    },
    label: i18nKeys.sell.nav.details.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.details.description,
  },
  [StepEnum.PHOTO]: {
    route: {
      path: 'asset/images',
      element: <SellImagesPage />,
    },
    label: i18nKeys.sell.nav.images.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.images.description,
  },
  [StepEnum.CONFIRM]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.OFFICIAL]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.QRCODE]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.RFID]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.PRIVACY]: {
    route: {
      path: 'asset/privacy',
      element: <SellPrivacyPage />,
    },
    label: i18nKeys.sell.nav.privacy.label,
    navIcon: <Icon icon="category" size={20} />,
    description: i18nKeys.sell.nav.privacy.description,
  },
  [StepEnum.WITNESS]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.DOCUMENT]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.REFERENCE_VDTS]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
  [StepEnum.VDT_MINTED]: {
    route: {},
    label: undefined,
    title: undefined,
    navIcon: undefined,
    favIconSrc: undefined,
    description: undefined,
    hideOnMenu: undefined,
  },
}
