import React, { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { EPaymentType } from '@/common/constants'
import {
  getStateCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import type { Address } from '@/common/types'
import { Environment } from '@/common/types'
import { Logger } from '@/config'
import { PaymentsService } from '@/core/marketplace/payments.service'

type ApplePayProps = {
  totalPrice: number
  billingAddress: Address
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed: (error?: string) => void | Promise<void>
  onLoading: (isLoading: boolean) => void
}

export const ApplePay: React.FC<ApplePayProps> = ({
  totalPrice,
  onPaymentComplete,
  onPaymentFailed,
  billingAddress,
  onLoading,
}: ApplePayProps) => {
  const applePayInstance = useRef<any>(null)
  const amountRef = useRef(totalPrice)
  const merchantRefNum = uuid()

  useEffect(() => {
    amountRef.current = totalPrice
  }, [totalPrice])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://hosted.paysafe.com/js/v1/latest/paysafe.min.js'
    script.async = true
    document.body.appendChild(script)
    script.onload = async () => setup()
    document.body.removeChild(script)
  }, [])

  async function setup() {
    onLoading(true)
    try {
      const paymentCredentials = await PaymentsService.getPaymentsCredentials()
      const request = {
        currencyCode: 'USD',
        accounts: { default: Number(paymentCredentials.accountId) },
        environment:
          envVariables.ENVIRONMENT === Environment.PRODUCTION
            ? 'PRODUCTION'
            : 'TEST',
        fields: {
          applePay: {
            selector: '#apple-pay',
            type: 'buy',
            label: 'Apple Pay',
            color: 'black',
          },
        },
      }
      applePayInstance.current = await (window as any).paysafe.fields.setup(
        paymentCredentials.publicKey,
        request,
      )
      if (
        applePayInstance.current.paymentMethods.applePay &&
        !applePayInstance.current.paymentMethods.applePay.error
      ) {
        applePayInstance.current.show()
        const applePayButton = document.getElementById('apple-pay')
        applePayButton?.addEventListener('click', tokenize, false)
        Logger.info('ApplePay setup completed: ', applePayInstance.current)
      } else {
        Logger.error(
          'ApplePay setup failed. Instance: ',
          applePayInstance.current,
        )
      }
    } catch (error) {
      Logger.error('Setup Apple Pay error: ', Logger)
    } finally {
      onLoading(false)
    }
  }

  async function tokenize() {
    const amount = amountRef.current
    const tokenizationOptions = {
      amount,
      merchantRefNum,
      transactionType: 'PAYMENT',
      paymentType: EPaymentType.ApplePay,
      applePay: { country: 'US' },
      customerDetails: {
        billingDetails: {
          nickName: 'Home',
          state: getStateCode(billingAddress.state),
          city: billingAddress.city,
          zip: billingAddress.zip,
          street: billingAddress.address,
          country: selectCountryFromSplitString(billingAddress.country),
        },
      },
    }
    try {
      Logger.info('tokenizationOptions: ', tokenizationOptions)
      const result =
        await applePayInstance.current.tokenize(tokenizationOptions)
      Logger.info('result: ', result)
      applePayInstance.current.complete('success')
      onPaymentComplete({
        amount,
        currencyCode: 'USD',
        merchantRefNum,
        paymentHandleToken: result?.token,
        paymentType: EPaymentType.ApplePay,
      })
    } catch (error) {
      Logger.error('Apple Pay tokenize error: ', undefined, error as Error)
      applePayInstance.current.complete('fail')
      onPaymentFailed()
    }
  }

  return (
    <div className={'cursor-pointer flex w-[240px] h-[40px]'} id="apple-pay" />
  )
}
