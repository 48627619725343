import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { isSafariOniOSorMac } from '@/common/components/Paysafe/utils'
import { EPaymentMethod } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import type { BuyerShippingAddress } from '@/common/types'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { type PaymentCard, UserService } from '@/core'
import { AddPaymentCardStep } from '@/features/buyProcess/AddPaymentCardStep'
import { SelectCard } from '@/features/buyProcess/SelectCard'
import type { PaymentHandleToken } from '@/features/profile/subscription/PurchaseSubscriptionPage'
import ApplePayIcon from '@assets/icons/apple-pay.svg'
import GooglePayIcon from '@assets/icons/google-pay.svg'
import VenmoIcon from '@assets/icons/venmo.svg'

interface PaymentMethodsProps {
  shippingAddress?: BuyerShippingAddress
  selectedCard?: PaymentCard
  handleToken?: PaymentHandleToken
  onSelectCard: (card: PaymentCard) => void
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: () => void | Promise<void>
  setStep: (e?: number) => void
  paymentMethod?: EPaymentMethod
  onPaymentSelect: (paymentMethod: EPaymentMethod) => void
  paymentEmail: string
  isLoading?: boolean
}

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  shippingAddress,
  selectedCard,
  handleToken,
  onSelectCard,
  onPaymentComplete,
  onPaymentFailed,
  setStep,
  paymentMethod,
  onPaymentSelect,
  paymentEmail,
  isLoading,
}: PaymentMethodsProps) => {
  const { profile, buyerProfile } = useContext(authContext)
  const { error } = useNotify()
  const [cards, setCards] = useState<PaymentCard[]>()
  const [isAddCard, setIsAddCard] = useState(false)
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  async function fetchCards() {
    setIsLoadingCard(true)
    try {
      const usersService = new UserService()
      const _cards = await usersService.listCards(buyerProfile?.id!)
      setCards(_cards)
    } catch (e) {
      Logger.error('Failed to fetch cards', undefined, e as Error)
      setCards([])
    } finally {
      setIsLoadingCard(false)
    }
  }

  const handleAddCard = async (result: TokenizationResponse) => {
    try {
      if (isLoadingCard) {
        return
      }

      setIsLoadingCard(true)

      const userService = new UserService()
      const createdCard = await userService.createCard(buyerProfile?.id!, {
        isDefault: false,
        profileId: buyerProfile?.id!,
        paymentToken: result.paymentHandleToken,
      })

      setCards([...cards!, createdCard])
      onSelectCard(createdCard)
      setIsAddCard(false)
      // setStep(purchaseSubscriptionStep.confirm)
      setStep()
    } catch (e) {
      Logger.error('Failed to add a card', undefined, e as Error)
      error('Failed to add a card.')
    } finally {
      setIsLoadingCard(false)
    }
  }

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined

    const segments = [profile.firstName, profile.middleName, profile.lastName]

    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  useEffect(() => {
    if (buyerProfile && !cards) {
      fetchCards()
    }
  }, [buyerProfile])

  if (isLoading || isLoadingCard) {
    return (
      <div className="w-full justify-center flex">
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      <div className={'mt-4'}>
        <div
          className={`flex items-center mb-3 p-2 border rounded-lg bg-gray-100 cursor-pointer ${
            paymentMethod === EPaymentMethod.GooglePay
              ? 'bg-blue-50 border-blue-500'
              : ''
          }`}
        >
          <input
            type="radio"
            id="google-pay"
            name="payment"
            value={EPaymentMethod.GooglePay}
            checked={paymentMethod === EPaymentMethod.GooglePay}
            onChange={() => onPaymentSelect(EPaymentMethod.GooglePay)}
          />
          <label
            htmlFor="google-pay"
            className="flex-grow text-lg ml-2 cursor-pointer"
          >
            Google Pay
          </label>
          <img src={GooglePayIcon} alt="Google Pay" className="w-10 h-7 ml-2" />
        </div>

        <div
          className={`flex items-center mb-3 p-2 border rounded-lg bg-gray-100 cursor-pointer 
          ${!isSafariOniOSorMac() ? 'hidden' : ''} 
          ${
            paymentMethod === EPaymentMethod.ApplePay
              ? 'bg-blue-50 border-blue-500'
              : ''
          }`}
        >
          <input
            type="radio"
            id="apple-pay-option"
            name="payment"
            value={EPaymentMethod.ApplePay}
            checked={paymentMethod === EPaymentMethod.ApplePay}
            onChange={() => onPaymentSelect(EPaymentMethod.ApplePay)}
          />
          <label
            htmlFor="apple-pay-option"
            className="flex-grow text-lg ml-2 cursor-pointer"
          >
            Apple Pay
          </label>
          <img src={ApplePayIcon} alt="Apple Pay" className="w-10 h-7 ml-2" />
        </div>

        <div
          className={`flex items-center mb-3 p-2 border rounded-lg bg-gray-100 cursor-pointer ${
            paymentMethod === EPaymentMethod.Venmo
              ? 'bg-blue-50 border-blue-500'
              : ''
          }
            ${!paymentEmail ? 'disabled' : ''}
          `}
        >
          <input
            type="radio"
            id="venmo-pay"
            name="payment"
            value={EPaymentMethod.Venmo}
            checked={paymentMethod === EPaymentMethod.Venmo}
            onChange={() => onPaymentSelect(EPaymentMethod.Venmo)}
            disabled={!paymentEmail}
          />
          <label
            htmlFor="venmo-pay"
            className={`flex-grow text-lg ml-2 cursor-pointer ${!paymentEmail ? 'text-gray-400' : ''}`}
          >
            VENMO
          </label>
          {!paymentEmail ? (
            <Link
              to={'/profile/chainItPay'}
              style={{
                color: isHovered ? '#2563eb' : '#3b82f6',
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              verify payment email
            </Link>
          ) : null}

          <img src={VenmoIcon} alt="VENMO" className="w-10 h-7 ml-2" />
        </div>

        <div
          className={`flex items-center mb-3 p-2 border rounded-lg bg-gray-100 cursor-pointer ${
            paymentMethod === EPaymentMethod.Card
              ? 'bg-blue-50 border-blue-500'
              : ''
          }`}
        >
          <input
            type="radio"
            id="card"
            name="payment"
            value={EPaymentMethod.Card}
            checked={paymentMethod === EPaymentMethod.Card}
            onChange={() => onPaymentSelect(EPaymentMethod.Card)}
          />
          <label
            htmlFor="card"
            className="flex-grow text-lg ml-2 cursor-pointer"
          >
            Card
          </label>
        </div>
      </div>
      {paymentMethod === EPaymentMethod.Card && isAddCard ? (
        <AddPaymentCardStep
          profile={profile!}
          shippingAddress={shippingAddress}
          onComplete={handleAddCard}
          totalPrice={0}
          onBack={() => setIsAddCard(false)}
        />
      ) : paymentMethod === EPaymentMethod.Card && !isAddCard ? (
        <SelectCard
          selectedCard={selectedCard}
          cards={cards}
          onSelectCard={onSelectCard}
          onCreateCard={() => setIsAddCard(true)}
          billingAddress={shippingAddress!}
          cardHolderName={cardHolderName!}
          totalPrice={0}
          onPaymentComplete={onPaymentComplete}
          onPaymentFailed={onPaymentFailed}
          handleToken={handleToken}
          onContinue={() => setStep()}
        />
      ) : null}
    </div>
  )
}
