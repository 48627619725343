import { t } from 'i18next'
import React from 'react'

import { Icon } from '../../../common/components'
import tKeys from '../../../common/i18nKeys'
import { colors } from '../../../theme'

import { ProfileInputWrapper } from './ProfileRowCard'

export const UserAccounts: React.FC = () => {
  return (
    <div className="w-full lg:max-w-full bg-white p-12 my-8">
      <h3 className="text-xl font-semibold">
        {t(tKeys.profile.digitalIdentity.userAccounts.subTitle)}
      </h3>
      <p>{t(tKeys.profile.digitalIdentity.userAccounts.subMessage)}</p>
      {/* TODO: replace this mocked data with backend response */}
      <ProfileInputWrapper
        iconLeft={<Icon icon="google" color={colors.redGoogle} />}
        iconRight={<Icon icon="trash" size={16} color={colors.iconGray} />}
        type="text"
        text="example@gmail.com"
        label="Jhon Doe"
      />
      {/* TODO: replace this mocked data with backend response */}
      <ProfileInputWrapper
        iconLeft={<Icon icon="email" color={colors.primary.DEFAULT} />}
        iconRight={<Icon icon="trash" size={16} color={colors.iconGray} />}
        type="text"
        text="example@mail.com"
        label="Jhon Doe"
      />
      {/* TODO: replace this mocked data with backend response */}
      <ProfileInputWrapper
        iconLeft={<Icon icon="google" color={colors.redGoogle} />}
        iconRight={<Icon icon="trash" size={16} color={colors.iconGray} />}
        type="text"
        text="example@gmail.com"
        label="Jhon Doe"
      />
      {/* TODO: replace this mocked data with backend response */}
      <ProfileInputWrapper
        iconLeft={<Icon icon="facebook" color={colors.blue} size={26} />}
        iconRight={<Icon icon="trash" size={16} color={colors.iconGray} />}
        type="text"
        text="example@mail.com"
        label="Jhon Doe"
      />
    </div>
  )
}
