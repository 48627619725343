import React from 'react'

import Spinner from '@/components/Spinner'
import { classNames } from '@/core'

import styles from './PaysafeCreditCardFormStyles.module.css'

interface PaymentFormProps {
  submitText: string
  payButtonEnabled?: boolean
  isNewCard?: boolean
  onPayment: () => void
  isLoading: boolean
}

export const PaymentForm: React.FC<PaymentFormProps> = (
  props: PaymentFormProps,
) => {
  if (props.isNewCard) {
    return <NewCardForm {...props} />
  }

  return <ExistingCardForm {...props} />
}

function ExistingCardForm(props: PaymentFormProps) {
  return (
    <div>
      <div hidden id="card-number"></div>
      <div hidden id="expiry-date"></div>

      <div className={styles.singleLineItem}>
        <span className={styles.label}>
          Enter the CVV of the previously selected card
        </span>
        {/* TODO: add translations */}
        <div id="cvv" className={styles.paysafeField}></div>
      </div>

      <div className={styles.singleLineItem}>
        {props.isLoading ? (
          <div className="w-full justify-center flex">
            <Spinner />
          </div>
        ) : (
          <button
            className={classNames(
              styles.payNowButton,
              'bg-primary hover:bg-primary-600',
            )}
            id="payButton"
            onClick={props.onPayment}
            disabled={!props.payButtonEnabled}
            style={{
              cursor: props.payButtonEnabled ? 'pointer' : 'not-allowed',
            }}
          >
            {props.submitText}
          </button>
        )}
      </div>
    </div>
  )
}

function NewCardForm(props: PaymentFormProps) {
  return (
    <div>
      <div className={styles.singleLineItem}>
        <span className={styles.label}>Card Number:</span>
        <div id="card-number" className={styles.paysafeField}></div>
      </div>
      <div className={styles.singleLineItem}>
        <div className={styles.flexRow}>
          <div className={styles.halfLineItem}>
            <span className={styles.label}>Expiry Date:</span>
            <div id="expiry-date" className={styles.paysafeField}></div>
          </div>
          <div className={styles.halfLineItem}>
            <span className={styles.label}>CVV:</span>
            <div id="cvv" className={styles.paysafeField}></div>
          </div>
        </div>
      </div>
      <div className={styles.singleLineItem}>
        <button
          className={classNames(
            styles.payNowButton,
            'bg-primary hover:bg-primary-600',
          )}
          id="payButton"
          onClick={props.onPayment}
          disabled={!props.payButtonEnabled}
          style={{ cursor: props.payButtonEnabled ? 'pointer' : 'not-allowed' }}
        >
          {props.submitText}
        </button>
      </div>
    </div>
  )
}
