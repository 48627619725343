import { StatusType, datadogLogs } from '@datadog/browser-logs'

import envVariables from '@/common/envVariables'
import { Environment } from '@/common/types'

// We don't want to log anything in DataDog when in local environment
const environmentNotSupported = envVariables.ENVIRONMENT === Environment.LOCAL

export const startDatadogConfiguration = () => {
  if (environmentNotSupported) {
    return
  }

  datadogLogs.init({
    clientToken: envVariables.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}

type messageContextType = string | Object | undefined

const logToConsole = (
  message: string,
  statusType: StatusType,
  messageContext?: messageContextType,
  error?: Error,
) => {
  switch (statusType) {
    case StatusType.error:
      console.error(message, messageContext, error)
      break
    case StatusType.warn:
      console.warn(message, messageContext, error)
      break
    case StatusType.info:
      console.info(message, messageContext, error)
      break
    case StatusType.debug:
      console.debug(message, messageContext, error)
      break
    default:
      console.log(message, messageContext, error)
      break
  }
}

export class Logger {
  private static logMessage = (
    message: string,
    statusType: StatusType,
    messageContext?: messageContextType,
    error?: Error | undefined,
  ) => {
    if (environmentNotSupported) {
      logToConsole(message, statusType, messageContext, error)
      return
    }

    // DataDog expects an object
    const dataDogMessage =
      typeof messageContext === 'string'
        ? { message: messageContext }
        : messageContext
    datadogLogs.logger.log(message, dataDogMessage, statusType, error)
  }
  public static info = (
    message: string,
    messageContext?: messageContextType,
    error?: Error | undefined,
  ) => Logger.logMessage(message, StatusType.info, messageContext, error)

  public static error = (
    message: string,
    messageContext?: messageContextType,
    error?: Error | undefined,
  ) => Logger.logMessage(message, StatusType.error, messageContext, error)

  public static warn = (
    message: string,
    messageContext?: messageContextType,
    error?: Error | undefined,
  ) => Logger.logMessage(message, StatusType.warn, messageContext, error)

  public static debug = (
    message: string,
    messageContext?: messageContextType,
    error?: Error | undefined,
  ) => Logger.logMessage(message, StatusType.debug, messageContext, error)
}
