import React from 'react'

import { Button, CreditDebitCard, Icon } from '@/common/components'
import type { PaymentCard } from '@/core'
import { colors } from '@/theme'

export type EditPaymentCardProps = {
  card: PaymentCard
  isLoading?: boolean
  onClickFavorite?: () => void
  onDelete?: () => void
}

export const EditPaymentCard: React.FC<EditPaymentCardProps> = (props) => {
  const handleConfirmDelete = () => {
    if (!props.onDelete) return

    // TODO replace with a modal
    const result = confirm('Are you sure you want to delete this card?')
    if (result) {
      props.onDelete()
    }
  }
  return (
    <div className="flex flex-col w-full">
      <button
        className="flex items-center self-end gap-2"
        onClick={props.onClickFavorite}
      >
        <p className="font-semibold">
          {props.card.isDefault ? 'Favorite card' : 'Set as favorite'}
        </p>

        <Icon
          icon={props.card.isDefault ? 'starFilled' : 'star'}
          color={colors.primary.DEFAULT}
          size={20}
        />
      </button>

      <CreditDebitCard card={props.card} className="mt-4 self-center" />

      <Button
        color={colors.red[500]}
        className="max-w-60 flex items-center justify-center gap-3 rounded-md self-center mt-16"
        onClick={handleConfirmDelete}
        disabled={props.isLoading}
      >
        <Icon icon="delete" color={colors.red[50]} size={14} />
        {/* TODO add translation */}
        <span className="text-red-50">Delete Card</span>
      </Button>
    </div>
  )
}
