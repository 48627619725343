import { t } from 'i18next'
import React from 'react'

import { Button } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { convertISOToMMDDYYYY } from '@/core'
import { colors } from '@/theme'
import what from '@assets/icons/qr.svg'
import verified from '@assets/icons/verified.svg'

interface WhatProps {
  handleClickTouchAudit: () => void
  documents: {}
}

export const What: React.FC<WhatProps> = (props) => {
  return (
    <div className="bg-white">
      <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2">
        <span className="border-l-2 border-white h-6 inline-block"></span>
        <img src={what} alt="What" width={16} />
        <h2 className="font-semibold">{t(i18nKeys.profile.individual.what)}</h2>
      </div>
      <div className="border-l-2 border-gray-200 pl-2 mt-2">
        <div className="pt-2">
          {props.documents?.govIdName ? (
            <div className="border-l-2 border-primary pl-1 flex items-center justify-between py-1">
              <div>
                <p className="text-gray-500">{props.documents.govIdName}</p>
                <p className="font-semibold">
                  {convertISOToMMDDYYYY(props.documents?.expireAt)}
                </p>
              </div>
              <div className="bg-primary-100 text-primary rounded-full px-2 py-1 text-sm flex items-center gap-1 whitespace-nowrap">
                <img
                  src={verified}
                  alt="Verified"
                  className="w-[15px] h-[15px]"
                />
                {props.documents?.governmentScoreStatus === 'OK' && 'Verified'}
              </div>
            </div>
          ) : (
            <p className="text-gray-500 text-center">
              {t(i18nKeys.profile.individual.noDocument)}
            </p>
          )}
        </div>
        <div className="mt-4">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-2xl w-full"
            textColor={colors.surface}
            onClick={props.handleClickTouchAudit}
          >
            {t(i18nKeys.profile.individual.touchAudit)}
          </Button>
        </div>
      </div>
    </div>
  )
}
