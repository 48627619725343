import type { CreateAttachmentInputData } from '@bit-ui-libs/common'
import { AttachmentClassification } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useMemo } from 'react'
import type { ImageListType } from 'react-images-uploading'
import ImageUploading from 'react-images-uploading'

import { UploadedFileBadge } from '@/common/components/form/UploadedFileBadge'
import envVariables from '@/common/envVariables'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { classNames, translate } from '@/core'
import { CoreEventService } from '@/core/core-events'

import { SellPage, PageActionButtons } from '..'
import { useProductListing } from '../context'
import { useServiceListing } from '../context/service-listing-context'
import type { AssetImage } from '../types'

const MAX_IMAGES_COUNT = 5

export default function ServiceLicensurePage() {
  const { warning, error } = useNotify()
  const { event } = useProductListing()
  const licensures = useServiceListing((state) => state.licensures)
  const update = useServiceListing((state) => state.update)

  const imagesLoading = useMemo(
    () => licensures.filter((i) => i.status === 'loading').length > 0,
    [licensures],
  )

  const handleLoadImage = (value: ImageListType) => {
    const mainName = value.length ? value[0].file?.name : null

    // temp: if other images are loading, don't upload new ones
    if (imagesLoading) {
      // show error
      warning(t(i18nKeys.sell.nav.images.error.loading))
      return
    }

    let _images =
      value.map(
        (i) =>
          ({
            status: i.status ?? 'idle',
            src: i.src ?? i.data_url,
            alt: i.alt ?? i.file?.name,
            isMain: mainName !== null && i.file?.name === mainName,
            file: i.file,
          }) as AssetImage,
      ) ?? []

    // remove images with the same alt
    _images = _images.filter(
      (img, index, self) => index === self.findIndex((t) => t.alt === img.alt),
    )

    update('licensures', _images)
  }
  async function deleteAttachment(image: AssetImage) {
    update(
      'licensures',
      licensures.filter((i) => i.alt !== image.alt),
    )
    try {
      // if the image does not have an id, just remove it by alt from state
      if (!image.id) {
        return
      }

      const service = new CoreEventService() // todo: update export

      // if the image has an id, remove it from the server as well
      await service.service.deleteAttachment(image.id)
    } catch (e) {
      Logger.error('Failed to delete attachment', undefined, e as Error)

      // if the remove fails, add the image back to the state
      update('licensures', [...licensures, image])

      warning(t(i18nKeys.sell.nav.images.error.delete))
    }
  }

  async function uploadImages(_images: AssetImage[]) {
    try {
      const service = new CoreEventService() // todo: update export

      // looks for each image provided as param and updated its state to uploading
      update(
        'licensures',
        licensures.map((i) => {
          const _i = _images.find((img) => img.alt === i.alt)
          return _i ? { ...i, status: 'loading' as any } : i
        }),
      )

      const result = await service.service.initAttachments({
        eventId: event!.id,
        req: {
          application: { id: envVariables.APP_NAME },
          eventId: event!.id,
          device: {} as any,
          docs: _images.map(
            (i) =>
              ({
                // TODO: ALLOW PDFs
                docType: 'PHOTO',
                classification: AttachmentClassification.ServicePhoto,
                name: i.alt,
                description: i.alt,
                data: i.src.split(',')[1] as string,
                isMain: i.isMain,
              }) as CreateAttachmentInputData,
          ),
        },
      })

      update(
        'licensures',
        licensures.map((i) => {
          const _i = result.find((img) => img.name === i.alt)
          return _i ? { ...i, status: 'success' as any, id: _i.docId } : i
        }),
      )
    } catch (e) {
      error(t(i18nKeys.common.somethingWentWrong))
      Logger.error('Failed to upload images', undefined, e as Error)
      update(
        'licensures',
        licensures.map((i) => {
          const _i = _images.find((img) => img.alt === i.alt)
          return _i ? { ...i, status: 'error' as any } : i
        }),
      )
    }
  }

  useEffect(() => {
    const idleImages = licensures.filter((i) => i.status === 'idle')

    if (idleImages.length) {
      uploadImages(idleImages)
    }
  }, [licensures])

  return (
    <SellPage title={translate((i) => i.sell.nav.licensure.title)}>
      <p className="text-center">
        {translate((i) => i.sell.nav.licensure.description)}
      </p>
      <ImageUploading
        multiple
        value={licensures}
        onChange={handleLoadImage}
        maxNumber={MAX_IMAGES_COUNT}
        dataURLKey="data_url"
      >
        {({ onImageUpload, onImageRemove, isDragging, dragProps }) => (
          <>
            <div
              className={classNames(
                'w-full m-3 h-full flex-1 border-2 border-dashed rounded-md flex flex-col items-center justify-center transition-all duration-300 mt-6',
                isDragging && 'border-primary m-0',
                !licensures.length && 'border-gray-500',
              )}
              {...dragProps}
            >
              <button onClick={onImageUpload} className="font-semibold">
                {translate((i) => i.sell.nav.licensure.dropHere)}
              </button>
            </div>

            <div className="flex flex-col gap-4 items-start w-full">
              {licensures.map((image, keyImage) => (
                <UploadedFileBadge
                  key={keyImage}
                  file={image.file!}
                  onDelete={() => {
                    onImageRemove(keyImage)
                    deleteAttachment(image)
                  }}
                />
              ))}
            </div>
          </>
        )}
      </ImageUploading>
      <PageActionButtons />
    </SellPage>
  )
}
