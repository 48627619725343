import { t } from 'i18next'
import React, { useState } from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import type { Address, BuyerShippingAddress } from '@/common/types'
import { classNames } from '@/core'
import { colors } from '@/theme'

import { AddressCard, EmptyCard, SelectAssetSlideOver } from '../listing'

export type SelectAddressProps = {
  addresses: Address[]
  selectedBuyerAddresses?: BuyerShippingAddress
  setSelectedBuyerAddresses: (e: BuyerShippingAddress) => void
  onContinue: () => void
  onAddAddress?: () => void
  onEditAddress?: () => void
}

export const SelectAddress: React.FC<SelectAddressProps> = (props) => {
  const [addressModalOpen, setAddressModalOpen] = useState(false)

  const handleSelectAddress = (address?: Address) => {
    setAddressModalOpen(false)
    if (!address) {
      return
    }

    props.setSelectedBuyerAddresses(address as BuyerShippingAddress)
  }

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div>
        <h2 className="text-2xl">
          {t(i18nKeys.buyerProcess.addressSection.selectShippingAddress)}
        </h2>
        {/* TODO add translations */}
        <p className="mt-2 text-gray-500 text-sm tracking-tight">
          In this step, please select the address where your purchased items
          will be shipped. Ensure the address is correct to avoid delivery
          issues. If your desired shipping address is not listed, you can add a
          new one.
        </p>

        <div className="mx-auto max-w-2xl mt-12">
          <>
            <div
              className={classNames(
                'flex items-center justify-end gap-4',
                props.selectedBuyerAddresses ? 'mb-4' : '-mb-4',
              )}
            >
              {props.selectedBuyerAddresses ? (
                <Button
                  mode="outline"
                  className="flex items-center justify-between gap-4 text-primary"
                  onClick={props.onEditAddress}
                >
                  <Icon icon="edit" size={16} color={colors.primary.DEFAULT} />
                  {/* TODO add translations */}
                  <span>Edit</span>
                </Button>
              ) : null}
            </div>
            {props.selectedBuyerAddresses ? (
              <AddressCard
                address={props.selectedBuyerAddresses.address}
                name={props.selectedBuyerAddresses.name}
                addressType={props.selectedBuyerAddresses.type}
                className="w-full"
                mode="card"
                onClick={() => setAddressModalOpen(true)}
              />
            ) : (
              <EmptyCard
                icon="home"
                text={t(i18nKeys.sell.nav.delivery.emptyAddress)}
                onClick={() => setAddressModalOpen(true)}
              />
            )}
          </>
        </div>
      </div>
      <div className="flex justify-end mt-16">
        <Button
          onClick={props.onContinue}
          disabled={!props.selectedBuyerAddresses}
        >
          {t(i18nKeys.ui.continue)}
        </Button>
      </div>

      <SelectAssetSlideOver
        items={props.addresses}
        onCreate={props.onAddAddress}
        RenderItem={({ index, item }) => (
          <AddressCard
            key={index}
            address={item.address}
            name={item.name}
            addressType={item.type}
            className="w-full"
            mode="button"
            onClick={() => handleSelectAddress(item)}
          />
        )}
        onSearch={(search, item) =>
          item.name.toLowerCase().includes(search) ||
          item.address.toLowerCase().includes(search)
        }
        title={t(i18nKeys.sell.nav.delivery.chooseModal.title)}
        emptyMessage={t(i18nKeys.sell.nav.delivery.chooseModal.empty)}
        emptySearch={(search) =>
          t(i18nKeys.sell.nav.delivery.chooseModal.emptySearch).replace(
            '{SEARCH}',
            search,
          )
        }
        open={addressModalOpen}
        searchPlaceholder={t(
          i18nKeys.sell.nav.delivery.chooseModal.searchPlaceholder,
        )}
        onClose={() => setAddressModalOpen(false)}
      />
    </div>
  )
}
