import React from 'react'

export interface ContentProps {
  children?: React.ReactNode
  className?: string
}

/**
 * Application's main content
 */
export default function Content(props: ContentProps) {
  return <main className={props.className}>{props.children}</main>
}
