import React from 'react'

import { classNames } from '@/core'

interface DescriptionItemProps {
  title: string
  description: string
  className?: string
}

export const DescriptionItem: React.FC<DescriptionItemProps> = (props) => {
  return (
    <div className={classNames('flex flex-col sm:flex-row', props.className)}>
      <h3 className="font-medium text-gray-900 text-sm sm:w-40">
        {props.title}
      </h3>
      <p className="text-sm">{props.description}</p>
    </div>
  )
}
