export enum SellingMethodType {
  FixedPrice,
  Auction,
}

export enum AuctionType {
  Timed,
  Dutch,
  Inverse,
}

export enum ServiceSellMethod {
  FixedPrice = 'fixedPrice',
  HourlyRate = 'auction',
}

export enum ServiceListingTerms {
  OneTime = 'oneTime',
  Recurring = 'recurring',
}
