import React, { useContext, useMemo } from 'react'

import { FavoriteButton } from '@/common/components/productInformation'
import { authContext } from '@/common/context/auth_context'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { EventService } from '@/core/events'

import type { ProductListProps } from './LibraryFiltersWrapper'

export interface ProductCardProps {
  data: ProductListProps
  index: number
  showFavorite?: boolean
  onClick: (id: string) => void
}

export const ProductCard: React.FC<ProductCardProps> = ({
  data,
  index,
  showFavorite,
  onClick,
}) => {
  const { profileLoaded } = useContext(authContext)

  const handleOnKeyDown = (key: string) => {
    if (key === 'Enter') {
      onClick(data.product.id)
    }
  }

  const imageSrc = useMemo(() => {
    if (!data?.product?.serializedImages?.length) return

    let mainImage = data.product.serializedImages.find((image) => image.isMain)

    if (!mainImage) {
      if (!data.product.serializedImages.length) {
        return
      }
      mainImage = data.product.serializedImages[0]
    }

    if (!mainImage) return

    return EventService.buildDocumentImageUrlById(mainImage.docId)
  }, [data?.product?.serializedImages])
  return (
    <div key={data.product.id} className="group relative">
      <div
        className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 cursor-pointer"
        onClick={() => onClick(data.product.id)}
        tabIndex={index}
        onKeyDown={(e) => handleOnKeyDown(e.key)}
        role="tab"
      >
        {imageSrc ? (
          <img
            alt="Not found"
            src={imageSrc}
            className="h-full w-full object-cover object-center cursor-pointer border"
          />
        ) : (
          <Spinner containerClassName="flex items-center justify-center" />
        )}
        <div className="bg-black opacity-0 group-hover:opacity-20 transition-all duration-200"></div>
      </div>

      <FavoriteButton
        assetId={data.product.assetId}
        className="absolute top-0 right-0 opacity-0 group-hover:opacity-100"
        visible={Boolean(showFavorite) && profileLoaded}
      />
      <h3 className="mt-4 text-sm text-gray-700 line-clamp-2">
        {data.product.name}
      </h3>

      <ConditionalRendering
        renderIf={
          data.product.price !== null && data.product.price !== undefined
        }
      >
        <p className="mt-1 text-lg font-medium text-gray-900">
          ${data.product.price}
        </p>
      </ConditionalRendering>
      <p className="mt-1 text-sm text-gray-700 line-clamp-2">
        {data.product.description}
      </p>
    </div>
  )
}
