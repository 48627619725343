import { mockedSections, mockedTabs } from '@/mock'

export class StorefrontService {
  static async getStorefrontByName(shortName: string) {
    return {
      store: shortName,
      tabs: mockedTabs,
      sections: mockedSections,
    }
  }
}
