import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ProductInformation } from '@/common/components/productInformation'
import { authContext } from '@/common/context/auth_context'
import type { CustomerAssetWithPrice } from '@/core'
import { AssetsService } from '@/core'

//TODO Improve de component to allow multiple Products
// TODO add translations

export const PublicAssetInformation: React.FC = () => {
  const [product, setProduct] = useState<CustomerAssetWithPrice>()

  const { listingId, eventIdRef } = useParams()

  const { profileLoaded } = useContext(authContext)

  useEffect(() => {
    getAssetInfo()
  }, [])

  const getAssetInfo = async () => {
    if (!listingId || !eventIdRef) {
      return
    }

    const response = await AssetsService.getPublicByEventId(eventIdRef)
    const res = await AssetsService.getPublicByListingId(listingId)

    const item = response.items.find((x) => x.eventIdRef === eventIdRef)

    const data = {
      ...item,
      ...res,
    } as CustomerAssetWithPrice

    setProduct(data)
  }

  const bodyContent = useMemo(() => {
    if (product) {
      return (
        <ProductInformation
          product={product}
          listingId={listingId}
          isLogged={profileLoaded}
        />
      )
    } else {
      return null
    }
  }, [product])

  return bodyContent
}
