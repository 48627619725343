import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ProductInformation } from '@/common/components/productInformation'
import type { CustomerAssetWithPrice } from '@/core'
import { AssetsService } from '@/core'

export const CustomerAssetInformation: React.FC = () => {
  const [product, setProduct] = useState<CustomerAssetWithPrice>()

  const { assetId } = useParams()

  useEffect(() => {
    getAssetInfo()
  }, [])

  const getAssetInfo = async () => {
    if (!assetId) {
      return
    }

    const res = await AssetsService.getUserAssetById(assetId)
    setProduct(res)
  }

  const bodyContent = useMemo(() => {
    if (product) {
      return <ProductInformation product={product} showTags />
    } else {
      return null
    }
  }, [product])

  return bodyContent
}
