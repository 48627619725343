import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

import companyLogoURL from '../../../assets/icons/chainIT_marketplace_logo.svg'
import { Icon } from '../../common/components'

/**
 * A simplified header including just the app logo and few helpful icons
 */
export default function SimpleHeader() {
  const companyName = t`company`

  return (
    <header className="border-b-2 border-[#E6EEFF] bg-white">
      <nav className="flex p-4 lg:px-8 items-center" aria-label="Global">
        <Link to="/" className="flex-1 flex justify-center">
          <span className="sr-only">{companyName}</span>
          <img className="h-9 w-auto" src={companyLogoURL} alt={companyName} />
        </Link>
        <button>
          <Icon icon="help" />
        </button>
      </nav>
    </header>
  )
}
