import { isUndefined } from 'lodash'
import React, { useMemo } from 'react'

import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'

export interface HorizontalCardProps {
  title: string
  description?: string
  image: React.ReactNode
  className?: string
  action?: string
  mode?: 'button' | 'card'
  onClick?: () => void | Promise<void>
}

export const HorizontalCard: React.FC<HorizontalCardProps> = (props) => {
  const classes = useMemo(
    () =>
      classNames(
        'rounded-lg bg-white shadow hover:shadow-md transition-all',
        props.className,
      ),
    [props.className],
  )

  if (!props.mode || props.mode === 'card') {
    return (
      <div className={classes}>
        <CardContent {...props} />
      </div>
    )
  }

  const { onClick, ...rest } = { ...props }
  return (
    <button
      className={classNames(classes, 'text-start')}
      onClick={props.onClick}
    >
      <CardContent {...rest} />
    </button>
  )
}

const CardContent: React.FC<HorizontalCardProps> = (props) => {
  return (
    <div className="px-4 py-2 flex flex-col sm:flex-row sm:items-center">
      <div className="flex items-center gap-2">
        {props.image}
        <p className="sm:hidden text-sm text-gray-500">{props.title}</p>
      </div>
      <div className="flex flex-col flex-1 gap-1 sm:ms-4 mt-2 sm:mt-0">
        <p className="hidden sm:block text-sm text-gray-500">{props.title}</p>
        <ConditionalRendering renderIf={!isUndefined(props.description)}>
          <p className="font-semibold">{props.description}</p>
        </ConditionalRendering>
      </div>

      <ConditionalRendering renderIf={!isUndefined(props.action)}>
        <button
          className="font-medium text-sm text-primary border-t border-t-gray-200 mt-2 pt-2 sm:mt-0 sm:pt-2 sm:border-t-0"
          onClick={props.onClick}
        >
          {props.action}
        </button>
      </ConditionalRendering>
    </div>
  )
}
