import { doGet, doPost } from '..'

import type {
  ActivePeriodResponse,
  SubscriptionDetailsResponse,
  TransactionModelRequest,
  TransactionModelResponse,
} from './subscriptions.types'

export class SubscriptionService {
  static async getPaymentData(): Promise<SubscriptionDetailsResponse> {
    return doGet('/subscriptions/v1/payments/individual-details')
  }

  static async processPayment(
    params: TransactionModelRequest,
  ): Promise<TransactionModelResponse> {
    return doPost('/subscriptions/v1/payments/individual-complete', {
      ...params,
    })
  }

  static async getActivePeriod(): Promise<ActivePeriodResponse | undefined> {
    try {
      return await doGet(
        '/subscriptions/v1/subscriptions/individual/active-current-period',
      )
    } catch (e) {
      return
    }
  }
}
