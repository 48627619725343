import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescriptionAndAction } from '@/common/types'

export const EXISTING_ITEM_OPTIONS: DisplayItemWithDescriptionAndAction[] = [
  {
    id: 0,
    name: i18nKeys.sell.nav.vdt.itemExists.title,
    description: i18nKeys.sell.nav.vdt.itemExists.description,
    action: i18nKeys.sell.nav.vdt.itemExists.action,
  },
  {
    id: 1,
    name: i18nKeys.sell.nav.vdt.itemNew.title,
    description: i18nKeys.sell.nav.vdt.itemNew.description,
    action: i18nKeys.sell.nav.vdt.itemNew.action,
  },
]
