import type { Bid, PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React from 'react'

import { ItemsPagination } from '@/common/components/Pagination'
import i18nKeys from '@/common/i18nKeys'
import type { CustomerAssetWithPrice } from '@/core'
import { EmptyCard } from '@/features/listing'

import { BidItem } from './BidItem'
import { BidItemAsset } from './BidItemAsset'

export type BidsListProps = PagedResponse<Bid | CustomerAssetWithPrice> & {
  onSearch: (pageNumber: number) => void
  selectedTab: number
  selectedBid?: string
  setSelectedBid: (e: string) => void
  setOpenBidDetails: (e: string) => void
}

export const BidsList: React.FC<BidsListProps> = (props) => {
  if (!props.items.length) {
    return <EmptyCard icon="cart" text={t(i18nKeys.bid.empty)} />
  }

  let contentBody = null

  if (props.selectedTab === 0) {
    //TODO: improve enum
    contentBody = (
      <>
        {props.items.map((bid, key) => (
          <BidItem
            key={key}
            bid={bid as Bid}
            setSelectedBid={(e: string) => props.setSelectedBid(e)}
            selectedBid={props.selectedBid}
            setOpenBidDetails={(e: string) => props.setOpenBidDetails(e)}
          />
        ))}

        <ItemsPagination
          onSearch={(e) => props.onSearch(e.selected)}
          totalPages={props.totalPages}
        />
      </>
    )
  } else {
    contentBody = (
      <>
        {props.items.map((bid, key) => (
          <BidItemAsset
            key={key}
            auction={bid as CustomerAssetWithPrice}
            setSelectedBid={(e: string) => props.setSelectedBid(e)}
            selectedBid={props.selectedBid}
            setOpenBidDetails={(e: string) => props.setOpenBidDetails(e)}
          />
        ))}

        <ItemsPagination
          onSearch={(e) => props.onSearch(e.selected)}
          totalPages={props.totalPages}
        />
      </>
    )
  }

  return contentBody
}
