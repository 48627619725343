import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'

import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import type { listingResponse } from '@/core'
import { ListingService } from '@/core'

export type UseListingHookParams = {
  assetId?: string
  listingId?: string
}

export const useListing = ({ assetId, listingId }: UseListingHookParams) => {
  const { error } = useNotify()

  const [listing, setListing] = useState<listingResponse>()

  const handleRefresh = useCallback(async () => {
    try {
      if (!assetId && !listingId) {
        return
      }

      let _listing: listingResponse | null = null

      if (assetId) {
        _listing = await ListingService.getListingByAssetId(assetId)
      } else if (listingId) {
        _listing = await ListingService.getListingById(listingId)
      }

      setListing(_listing!)
    } catch (err) {
      Logger.error('Failed to fetch listing', undefined, err as Error)
      error(t(i18nKeys.common.somethingWentWrong))
    }
  }, [error, setListing, assetId, listingId, t])

  useEffect(() => {
    if ((!assetId && !listingId) || Boolean(listing)) return

    handleRefresh()
  }, [assetId, listingId])

  return {
    listing,
    isLoading: !listing,
  }
}
