import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDeviceLocation, useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { translate } from '@/core'

import type { CardOption } from '../components'
import {
  EmptyCard,
  InfoAlert,
  OptionCards,
  PageActionButtons,
  SelectableLibraryAsset,
  SellPage,
  SelectAssetSlideOver,
} from '../components'
import { EXISTING_ITEM_OPTIONS } from '../constants'
import { useProductListing, useProductListingDispatch } from '../context'
import type { LibraryAsset } from '../types'

const OPTION_USE_EXISTING_ITEM = 0

export function SellVdtPage() {
  const [isLoading, setIsLoading] = useState(false)
  const { products, useExistingAsset, selectedAsset } = useProductListing()
  const { setSelectedAsset, setUseCurrentAsset, handleFetchSteps } =
    useProductListingDispatch()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const nav = useNavigate()
  const {
    showLoading,
    showPermission,
    permissionState,
    handleAskForPermission,
  } = useDeviceLocation()
  const { errors, changeError } = useError({
    selectedAsset: t(i18nKeys.sell.nav.vdt.error.item),
  })

  const options = useMemo(
    () =>
      EXISTING_ITEM_OPTIONS.map(
        (option) =>
          ({
            id: option.id,
            title: t(option.name),
            description: t(option.description),
            action: t(option.action),
          }) as CardOption,
      ),
    [t],
  )

  const selectedOption = useMemo(
    () => options[useExistingAsset ? 0 : 1],
    [options, useExistingAsset],
  )

  const handleSelectItem = useCallback(
    (asset?: LibraryAsset) => {
      setModalVisible(false)
      if (!asset) {
        return
      }
      setSelectedAsset(asset)
    },
    [setModalVisible, setSelectedAsset],
  )

  const handleSelectOption = useCallback(
    (option: CardOption) => {
      setUseCurrentAsset(option.id === OPTION_USE_EXISTING_ITEM)
    },
    [setUseCurrentAsset],
  )

  const handleContinue = useCallback(async () => {
    try {
      if (isLoading) {
        return false
      }

      setIsLoading(true)

      if (useExistingAsset && !selectedAsset) {
        changeError('selectedAsset', true)
        setIsLoading(false)
        return false
      }

      if (!useExistingAsset) {
        setIsLoading(false)
        return true
      }

      setIsLoading(false)
      nav('/sell/asset/method')
      return false
    } catch (error) {
      Logger.warn('Failed to continue', undefined, error as Error)
      setIsLoading(false)
      return false
    }
  }, [
    selectedAsset,
    handleFetchSteps,
    nav,
    useExistingAsset,
    isLoading,
    setIsLoading,
    changeError,
  ])

  const showEmptyCard = useExistingAsset && selectedAsset === undefined
  const showSelectAsset = useExistingAsset && selectedAsset !== undefined

  useEffect(() => {
    changeError('selectedAsset', false)
  }, [selectedAsset, useExistingAsset])

  let body = null

  if (isLoading || showLoading) {
    return (
      <div className="min-h-[80vh]">
        <section className="mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white transition-all duration-300 ease-in-out flex items-center justify-center py-12 sm:py-48 mb-12">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0 flex flex-col">
            <Spinner />
          </div>
        </section>
      </div>
    )
  } else if (showPermission) {
    body = (
      <div
        className="bg-primary-100 border-t border-b border-blue-500 text-primary-900 px-4 py-3"
        role="alert"
      >
        <p className="text-xl font-semibold">
          {translate((i) => i.sell.nav.vdt.deviceRegistration.title)}
        </p>
        <p className="mb-5">
          {translate((i) => i.sell.nav.vdt.deviceRegistration.description)}
        </p>
        <div className="flex items-center justify-between">
          <p className="font-medium">
            {translate((i) => i.sell.nav.vdt.deviceRegistration.action)}
          </p>
          {permissionState === 'denied' ? (
            <button
              className="font-semibold underline underline-offset-2"
              onClick={handleAskForPermission}
            >
              {translate((i) => i.sell.nav.vdt.deviceRegistration.askAgain)}
            </button>
          ) : null}
        </div>
      </div>
    )
  } else {
    body = (
      <>
        <SellPage title={t(i18nKeys.sell.nav.vdt.pageTitle)}>
          <OptionCards
            options={options}
            selected={selectedOption}
            onChange={handleSelectOption}
            className="flex gap-2 sm:gap-8 w-full mt-8"
          />

          <ConditionalRendering renderIf={showEmptyCard}>
            <EmptyCard
              icon="boxOpen"
              text={t(i18nKeys.sell.nav.vdt.chooseItem)}
              onClick={() => setModalVisible(true)}
              error={errors.selectedAsset?.message}
            />
          </ConditionalRendering>
          <ConditionalRendering renderIf={showSelectAsset}>
            <SelectableLibraryAsset
              asset={selectedAsset!}
              className="bg-primary-50 border border-gray-300 mt-4 w-full"
              onClick={() => setModalVisible(true)}
            />
          </ConditionalRendering>

          <ConditionalRendering renderIf={!useExistingAsset}>
            <InfoAlert
              text={t(i18nKeys.sell.nav.vdt.mintDescription)}
              linkText={t(i18nKeys.sell.nav.vdt.mintDetails)}
              className="mt-8"
            />
          </ConditionalRendering>

          <PageActionButtons
            onContinue={handleContinue}
            continueDisabled={isLoading}
          />
        </SellPage>

        <SelectAssetSlideOver
          items={products ?? []}
          title={t(i18nKeys.sell.nav.vdt.chooseModal.title)}
          emptyMessage={t(i18nKeys.sell.nav.vdt.chooseModal.empty)}
          RenderItem={({ index, item }) => (
            <SelectableLibraryAsset
              key={index}
              asset={item}
              onClick={() => handleSelectItem(item)}
            />
          )}
          open={modalVisible}
          onClose={handleSelectItem}
          searchPlaceholder={t(
            i18nKeys.sell.nav.vdt.chooseModal.searchPlaceholder,
          )}
          emptySearch={(search) =>
            t(i18nKeys.sell.nav.vdt.chooseModal.emptySearch).replace(
              '{SEARCH}',
              search,
            )
          }
          onSearch={(search, item) =>
            item.name.toLowerCase().includes(search) ||
            item.description.toLowerCase().includes(search)
          }
        />
      </>
    )
  }
  return body
}
