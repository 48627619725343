import { type PaymentInfo } from '@bit-ui-libs/common'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { VenmoEmail } from '@/common/components/Paysafe'
import { authContext } from '@/common/context/auth_context'
import { Logger, featureFlags } from '@/config'
import type { PaymentCard } from '@/core'
import { UserService } from '@/core'
import { InfoAlert } from '@/features/listing'

import { CardList } from '../components/CardList'
import { WalletBalance } from '../components/WalletBalance'

// TODO missing translations
export const ChainItPay: React.FC = () => {
  const navigate = useNavigate()
  const { userId, buyerProfile } = useContext(authContext)

  const [linkedAccounts, setLinkedAccounts] = useState<PaymentInfo[]>()
  const [cards, setCards] = useState<PaymentCard[]>()

  async function fetchPayments() {
    try {
      const usersService = new UserService()
      const payments = await usersService.getUserPaymentDetails(userId!)
      setLinkedAccounts(payments)
    } catch (e) {
      Logger.error('Failed to fetch payments', undefined, e as Error)
      // TODO notify user
      setLinkedAccounts([])
    }
  }

  async function fetchCards() {
    try {
      const usersService = new UserService()
      const _cards = await usersService.listCards(buyerProfile?.id!)
      setCards(_cards)
    } catch (e) {
      Logger.error('Failed to fetch cards', undefined, e as Error)
      // TODO notify user
      setCards([])
    }
  }

  const handleClickCard = (card: PaymentCard) => {
    navigate(`/profile/card?cardId=${card.id}`)
  }

  useEffect(() => {
    if (userId && !linkedAccounts) {
      fetchPayments()
    }

    if (buyerProfile && !cards) {
      fetchCards()
    }
  }, [userId])

  return (
    <>
      <h1 className="text-lg font-medium">ChainIT Pay</h1>

      {featureFlags.walletBalance ? <WalletBalance className="mt-4" /> : null}
      <InfoAlert
        text="ChainIT Pay is a wholly owned subsidiary of Black Ink Technologies Corp., which is the developer of the ChainIT Ecosystem."
        linkText="Details"
        className="my-6"
        href="https://www.chainit.com/"
      />
      <WalletBalance className="mt-4" />

      <h2 className="text-base font-medium mt-4">Payment Sources</h2>

      <CardList className="mt-4" cards={cards} onClickCard={handleClickCard} />

      <VenmoEmail />
    </>
  )
}
