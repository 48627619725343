import { useState } from 'react'

type ErrorValue = {
  hasError: boolean
  message?: string
}

type ErrorField = string

type ErrorParams = {
  [key: string]: ErrorField
}
// Record<string, ErrorField>
type ErrorRecord<T extends ErrorParams> = {
  [K in keyof T]: ErrorValue
}
// Record<string, ErrorValue>

function initErrors<T extends ErrorParams>(errors: T): ErrorRecord<T> {
  return Object.keys(errors).reduce((acc, key) => {
    acc[key as keyof T] = { hasError: false, message: undefined }
    return acc as ErrorRecord<T>
  }, {} as ErrorRecord<T>)
}

export function useError<T extends ErrorParams>(errors: T) {
  const [values, setValues] = useState<ErrorRecord<T>>(initErrors(errors))

  const changeError = (
    field: keyof T,
    hasError: boolean,
    message: string | null = null,
  ) => {
    setValues((prev) => ({
      ...prev,
      [field]: {
        hasError,
        message: hasError ? message ?? errors[field] : undefined,
      },
    }))
  }

  return {
    errors: values,
    changeError,
  }
}
