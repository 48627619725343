import React from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

import { PaysafeCreditCardForm } from '@/common/components/Paysafe'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import type { Address } from '@/common/types'

import type { PaymentHandleToken } from './BuyProcess'

export type SelectCardProps = {
  totalPrice: number
  billingAddress: Address
  cardHolderName: string
  handleToken?: PaymentHandleToken
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: (error?: string) => void | Promise<void>
  open: boolean
  onClose: () => void
}
export const CvvModal: React.FC<SelectCardProps> = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose} center>
      <div className="m-12">
        <PaysafeCreditCardForm
          submitText="Confirm purchase"
          amount={props.totalPrice}
          onPaymentComplete={(result) => {
            if (props.onPaymentComplete) {
              props.onPaymentComplete(result)
            }
          }}
          isNewCard={false}
          onPaymentFailed={props.onPaymentFailed}
          holderName={props.cardHolderName}
          billingAddress={{
            address: props.billingAddress.address,
            city: props.billingAddress.city,
            state: props.billingAddress.state,
            country: props.billingAddress.country,
            zip: props.billingAddress.zip,
            name: props.cardHolderName,
            type: props.billingAddress.type,
          }}
          handleToken={props.handleToken}
        />
      </div>
    </Modal>
  )
}
