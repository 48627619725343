import React from 'react'

import { ItemsPagination } from '@/common/components/Pagination'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { ListingService } from '@/core'

import { useWishlist } from '../hooks/use-wishlist'

import { WishlistItem } from './WishlistItem'

export const Wishlist: React.FC = () => {
  const { wishlist, isLoading, handleFetchItems } = useWishlist()

  const handleRemoveFavorite = async (assetId: string) => {
    try {
      await ListingService.removeFavorite(assetId)
      handleFetchItems(wishlist?.page)
    } catch (err) {
      Logger.error('Failed to remove favorite', undefined, err as Error)
      // TODO notify user
    }
  }

  return (
    <section
      id="wishlist"
      className="mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white py-6 sm:py-8 "
    >
      <div>
        <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Wishlist
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            This is your wishlist. Here, you can view the products that you have
            marked as favorites.
          </p>
        </div>
      </div>

      <div className="mt-16">
        <div>
          <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
            {isLoading ? (
              <Spinner containerClassName="flex items-center justify-center py-12" />
            ) : (
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  {wishlist!.items!.map((p, index) => (
                    <WishlistItem
                      key={index}
                      item={p}
                      onRemove={async () => handleRemoveFavorite(p.assetId)}
                    />
                  ))}
                </div>

                <ItemsPagination
                  onSearch={async (e) => handleFetchItems(e.selected + 1)}
                  totalPages={wishlist!.totalPages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
