import React from 'react'

export const Organization: React.FC = () => {
  return (
    <>
      <h2 className="mb-2 text-2xl">Organization</h2>
      {/* TODO: complete this component. This is just for tab tests */}
      <div>TEST TABS</div>
    </>
  )
}
