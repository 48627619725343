import { t } from 'i18next'
import React from 'react'

import i18nKeys from '@/common/i18nKeys'

import { BeingIdHeader } from './BeingIdHeader'
import { DocumentsSection } from './DocumentsSection'
import { PaymentSection } from './PaymentSection'

// TODO complete this section
export const BeingId: React.FC = () => {
  return (
    <div>
      <h2 className="mb-2 text-2xl"> {t(i18nKeys.profile.beingId.title)}</h2>
      <BeingIdHeader />
      <PaymentSection />
      <DocumentsSection />
    </div>
  )
}
