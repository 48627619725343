import type { AssetDetailsForm, DeliveryForm, SellMethodForm } from '../types'
import { AuctionType, SellingMethodType } from '../types'

function productDetails(): AssetDetailsForm {
  return {
    name: '',
    description: '',
    category: '',
    subcategory: '',
    labels: [],
    condition: undefined,
  }
}

function sellMethod(): SellMethodForm {
  return {
    method: SellingMethodType.FixedPrice,
    auctionType: AuctionType.Timed,
    price: '',
    startTime: '',
    endTime: '',
  }
}

function delivery(): DeliveryForm {
  return {
    weight: '',
    length: '',
    width: '',
    height: '',
    address: undefined,
  }
}

export const defaultValues = {
  productDetails,
  sellMethod,
  delivery,
}
