import { isUndefined } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@/common/components'
import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'

export type AlertProps = {
  /** Information text */
  text: string

  /** Alert title */
  title?: string

  /** Link text to view more. Link will be shown only when this has a value */
  linkText?: string

  /** Link text to view more. */
  href?: string

  /** Style classes */
  className?: string

  /** TODO implement other types */
  type: 'warning' | 'info' | 'success' | 'error'
}

/** An information card */
export const Alert: React.FC<AlertProps> = (props) => {
  return (
    <div className={classNames('rounded-md bg-amber-50 p-4', props.className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon icon="warn" className="size-5 fill-amber-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="flex flex-col">
            <ConditionalRendering renderIf={!isUndefined(props.title)}>
              <h3 className="text-sm font-medium text-amber-800">
                {props.title}
              </h3>
            </ConditionalRendering>
            <p
              className={classNames(
                'text-sm text-amber-700',
                props.title && 'mt-2',
              )}
            >
              {props.text}
            </p>
          </div>
          {!!props.linkText && (
            <p className="mt-3 text-sm md:ml-6 md:mt-0 self-center">
              <Link
                to={props.href ?? '#'}
                className="whitespace-nowrap font-medium text-amber-700 hover:text-amber-600 flex items-center gap-1"
              >
                {props.linkText}
                <Icon icon="arrowRight" className="size-4 fill-amber-700" />
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
