import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import { Button } from '@/common/components'
import { PinInput } from '@/common/components/pinInput'
import { colors } from '@/theme'

import i18nKeys from '../../i18nKeys'

interface UserChangePinModalProps {
  handleClose: () => void
  handleGotExistingPin: (userPin: string) => void
  handleGotNewPin: (userPin: string) => void
  isOpen: boolean
  isChangePin: boolean
}

const customStyles = {
  modal: {
    width: '432px',
    borderRadius: '16px',
  },
}

export const UserChangePinModal: React.FC<UserChangePinModalProps> = ({
  handleClose,
  handleGotExistingPin,
  handleGotNewPin,
  isOpen,
  isChangePin,
}) => {
  const [userPin, setUserPin] = useState<string>('')
  const [newPin, setNewPin] = useState<string>('')

  useEffect(() => {
    setUserPin('')
    setNewPin('')
  }, [isOpen, isChangePin])

  return (
    <Modal open={isOpen} center styles={customStyles} onClose={handleClose}>
      <div className="my-4 mx-12">
        {!isChangePin ? (
          <div className="text-center">
            <h1 className="text-lg font-bold mb-4">
              {t(i18nKeys.onboarding.myWallet.enterCurrentPIN)}
            </h1>
            <PinInput
              key="user-pin"
              handleChange={(value) => setUserPin(value)}
              pin={userPin}
            />
            <div className="flex flex-col my-5">
              <Button
                mode="contained"
                type="button"
                color={colors.yellowLight}
                textColor="white"
                className="rounded-[16px]"
                onClick={() => {
                  handleGotExistingPin(userPin)
                }}
              >
                {t(i18nKeys.ui.continue)}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="text-lg font-bold mb-4">
              {t(i18nKeys.onboarding.myWallet.enterNewPIN)}
            </h3>
            <PinInput
              key="new-pin"
              handleChange={(value) => setNewPin(value)}
              pin={newPin}
            />
            <div className="flex flex-col my-5">
              <Button
                mode="contained"
                type="button"
                color={colors.yellowLight}
                textColor="white"
                className="rounded-[16px]"
                onClick={() => {
                  handleGotNewPin(newPin)
                }}
              >
                {t(i18nKeys.ui.continue)}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
