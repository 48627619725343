import { t } from 'i18next'
import React, { useContext } from 'react'

import { Button, Icon } from '@/common/components'
import { authContext } from '@/common/context/auth_context'
import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { convertISOToMMDDYYYY, formatTimeToEST } from '@/core'
import { colors } from '@/theme'
import calendar from '@assets/icons/calendar.svg'
import when from '@assets/icons/clock.svg'

interface WhenProps {
  handleClickTouchAudit: () => void
}

export const When: React.FC<WhenProps> = (props) => {
  const { profile } = useProfile()
  const { userVdt } = useContext(authContext)

  const createdAtDate = convertISOToMMDDYYYY(profile?.beingId?.updatedAt)
  const createdAtTime = formatTimeToEST(profile?.beingId?.updatedAt)
  const vdtMintedDate = convertISOToMMDDYYYY(userVdt?.immutableTime)
  const vdtMintedTime = formatTimeToEST(userVdt?.immutableTime)
  return (
    <div className=" bg-white">
      <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2">
        <span className="border-l-2 border-white h-6 inline-block"></span>
        <img src={when} alt="When" width={16} />
        <h2 className="font-semibold">{t(i18nKeys.profile.individual.when)}</h2>
      </div>
      <div className="border-l-2 border-gray-200 pl-2 mt-2">
        <p className="text-gray-500">
          {t(i18nKeys.profile.individual.createdAt)}
        </p>
        <div className="flex items-center gap-3">
          <p className="flex items-center gap-1">
            <img src={calendar} alt="Calendar" width={16} />
            {createdAtDate}
          </p>
          <p className="flex items-center gap-1">
            <Icon icon="regClock" size={15} color={colors.primary.DEFAULT} />
            {createdAtTime}
          </p>
        </div>
        <p className="text-gray-500 pt-3">
          {t(i18nKeys.profile.individual.vdtMintedAt)}
        </p>
        <div className="flex items-center gap-3 ">
          <p className="flex items-center gap-1">
            <img src={calendar} alt="Calendar" width={16} />
            {vdtMintedDate}
          </p>
          <p className="flex items-center gap-1">
            <Icon icon="regClock" size={15} color={colors.primary.DEFAULT} />
            {vdtMintedTime}
          </p>
        </div>
        <div className="mt-4">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-2xl w-full"
            textColor={colors.surface}
            onClick={props.handleClickTouchAudit}
          >
            {t(i18nKeys.profile.individual.touchAudit)}
          </Button>
        </div>
      </div>
    </div>
  )
}
