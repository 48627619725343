import { useNavigate } from 'react-router-dom'

/**
 * The parameters for the goToSearchResult function.
 */
export type GoToSearchResultParams = {
  /**
   * The category ID to search for.
   */
  category?: string

  /**
   * The search query to search for.
   */
  search?: string
}

const GoToSearchResultDefaultParams: GoToSearchResultParams = {
  category: '',
  search: '',
}

/**
 * Application navigation. Used to avoid routes when navigating to another page.
 *
 * @returns {object} The navigation functions.
 */
const useApplicationNavigate = () => {
  const navigate = useNavigate()

  const goTo = (path: string) => {
    navigate(path, { replace: true })
  }

  const goToSearchResult = (
    params: GoToSearchResultParams = GoToSearchResultDefaultParams,
  ) => {
    const populatedKeys = Object.keys(params).filter(
      (key: string) => params[key as keyof typeof params]?.trim() !== '',
    )

    const url = new URLSearchParams()
    populatedKeys.forEach((key) =>
      url.append(key, params[key as keyof typeof params]!),
    )
    goTo(`/search?${url.toString()}`)
  }

  const goToCartDetail = () => {
    goTo('/cart')
  }

  return {
    goToCartDetail,
    goToSearchResult,
  }
}

export default useApplicationNavigate
