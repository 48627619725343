export const getDeviceLocation = async (): Promise<GeolocationPosition> => {
  // TODO We should be returning a better Error and let the client handle it
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        { timeout: 10000 },
      )
    } else {
      reject(new Error('Geolocation not available'))
    }
  })
}

export const getGeolocationPermission = async (): Promise<PermissionStatus> => {
  if (!navigator.permissions) {
    throw new Error('Permissions API not available')
  }

  return navigator.permissions.query({ name: 'geolocation' })
}
