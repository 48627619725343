import React, { useMemo } from 'react'

import { classNames } from '@/core'

import type { IconProps } from '.'
import { Icon } from '.'

export type IconInCircleProps = IconProps & {
  circleSize?: number
  circleClassName?: string
}

export const IconInCircle: React.FC<IconInCircleProps> = (props) => {
  const iconProps = useMemo(() => {
    const { circleSize, circleClassName, ...iconProps } = props
    return iconProps
  }, [props])

  return (
    <div
      className={classNames(
        'rounded-[50%] flex items-center justify-center bg-primary',
        props.circleClassName,
      )}
      style={{ width: props.circleSize ?? 24, height: props.circleSize ?? 24 }}
    >
      <Icon {...iconProps} />
    </div>
  )
}
