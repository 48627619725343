import { t } from 'i18next'
import React, { useMemo } from 'react'

import { Button } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import type { UserProfileType } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { BuyerShippingAddress } from '@/common/types'
import { ConditionalRendering } from '@/components'

import { AddPaymentCard } from '../profile/chainItPay/components/AddPaymentCard'

export type AddPaymentCardStepProps = {
  profile: UserProfileType
  shippingAddress?: BuyerShippingAddress
  totalPrice: number
  onComplete?: (result: TokenizationResponse) => void
  onBack?: () => void
}

export const AddPaymentCardStep: React.FC<AddPaymentCardStepProps> = (
  props,
) => {
  const { error } = useNotify()

  const handleComplete = (result: TokenizationResponse) => {
    if (props.onComplete && result) {
      props.onComplete(result)
    }
  }

  const handleFailed = (errorMessage?: string) => {
    // TODO add translation
    error(errorMessage ?? 'Payment failed. Please try again.')
  }

  const cardHolderName = useMemo(() => {
    if (!props.profile) return undefined

    const segments = [
      props.profile.firstName,
      props.profile.middleName,
      props.profile.lastName,
    ]

    return segments.filter(Boolean).join(' ')
  }, [
    props.profile?.firstName,
    props.profile?.middleName,
    props.profile?.lastName,
  ])

  return (
    <div className="mt-6">
      <ConditionalRendering
        renderIf={Boolean(props.shippingAddress) && Boolean(cardHolderName)}
      >
        <AddPaymentCard
          billingAddress={props.shippingAddress!}
          cardHolderName={cardHolderName!}
          onPaymentComplete={handleComplete}
          onPaymentFailed={handleFailed}
          totalPrice={props.totalPrice}
          submitText="Save card & continue"
        />
      </ConditionalRendering>

      <div className="flex justify-start mt-16">
        <Button onClick={props.onBack} mode="text">
          {t(i18nKeys.ui.back)}
        </Button>
      </div>
    </div>
  )
}
