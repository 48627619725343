export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('safari') && !userAgent.includes('chrome')
}

export const isIOS = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera
  return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream
}

export const isMacOS = () => {
  return navigator.platform.toLowerCase().includes('mac')
}

export const isSafariOniOSorMac = () => {
  const safari = isSafari()
  const ios = isIOS()
  const macos = isMacOS()
  return safari && (ios || macos)
}

export const validateEmail = (email: string): boolean => {
  const re =
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?!$)|$)){4}\]))$/
  return re.test(email)
}
