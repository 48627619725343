import { createContext } from 'react'

import type { ProductListingDispatchActions, ProductListingState } from '.'

export const ProductListingContext = createContext<ProductListingState>(
  {} as ProductListingState,
)

export const ProductListingDispatch =
  createContext<ProductListingDispatchActions>(
    {} as ProductListingDispatchActions,
  )
