/**
 * Returns a copy of an object but converting its keys from camel case or pascal case
 * into snake case with dashes.
 * @param obj
 * @returns A new object with updated keys
 */
export function convertToSnakeCase(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key
        // replaces any UPPER CASE character with a DASH and that same char as lowercase
        .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
        // removes consecutive DASHES
        .replace(/--+/g, '-')
        // removes any DASH at the beginning of the string
        .replace(/^-/, ''),
      value,
    ]),
  )
}

export function dateToStringFormat(timeStamp?: string) {
  if (!timeStamp) {
    return ''
  }
  const res = new Date(timeStamp).toLocaleDateString()
  return res
}

export function dateToTimeFormat(timeStamp?: string) {
  if (!timeStamp) {
    return ''
  }
  const date = new Date(timeStamp)
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  const minutesStr = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutesStr + ' ' + ampm
  return strTime
}

export function createUrlQueryFromObject(obj: any) {
  let s = ''
  for (const key in obj) {
    if (s !== '') {
      s += '&'
    }
    s += key + '=' + encodeURIComponent(obj[key])
  }
  return s
}

export function toNumber(value: string | null | undefined): number | null {
  if (!value) return null
  const parsed = parseFloat(value)
  return isNaN(parsed) ? null : parsed
}

export function formatExpirationDate(month: number, year: number) {
  return `${month.toString().padStart(2, '0')}/${year.toString().slice(-2)}`
}

export function mapQueryParams(
  url: string,
  params: Record<string, string | number | null | undefined> | null = null,
) {
  if (!params) {
    return url
  }

  const queries = Object.entries(params)
    .filter(([_, value]) => Boolean(value?.toString()?.trim()))
    .map(([key, value]) => `${key}=${value}`)

  return queries.length ? `${url}?${queries.join('&')}` : url
}

export const convertISOToMMDDYYYY = (isoDate: string | undefined) => {
  if (!isoDate) return '-'
  const date = new Date(isoDate)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const year = date.getFullYear()
  return `${month}/${day}/${year}`
}

export const calculateAge = (date: string | undefined) => {
  if (!date) return '-'
  const parsedDate = new Date(date)
  if (isNaN(parsedDate.getTime())) {
    return '-'
  }
  const today = new Date()
  let age = today.getFullYear() - parsedDate.getFullYear()
  const monthDiff = today.getMonth() - parsedDate.getMonth()
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < parsedDate.getDate())
  ) {
    age--
  }
  return age
}

export const formatDateToEST = (date: string | undefined) => {
  if (!date) return '-'
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  }
  return new Date(date).toLocaleString('en-US', options)
}

export const formatTimeToEST = (date: string | undefined) => {
  if (!date) return '-'
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  }
  return new Date(date).toLocaleString('en-US', options)
}
