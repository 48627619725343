const TOKEN_KEY = 'token'
const BIT_TOKEN_KEY = 'bitToken'
const ID_TOKEN_KEY = 'id_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const CODE_VERIFIER_KEY = 'code_verifier'
const SESSION_TOKEN_KEY = 'session_token'
const STATE_KEY = 'state'
const SESSION_ID_KEY = 'session_id'
const ENCRYPTED_PRIVATE_KEY = 'encrypted_private_key'
const SOCIAL_TOKEN_KEY = 'social_token'
const EXPIRATION_DATE_KEY = 'expiration_date'

export interface ApplicationTokens {
  accessToken: string
  bitToken: string
  idToken: string
  refreshToken: string
  expirationDate: string
}

/**
 * A Facade for the storage API.
 */
export default class ApplicationStorage {
  /**
   * Sets an item in the storage.
   * @param key The key of the item to set.
   * @param value The value of the item to set.
   */
  static setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  /**
   * Gets an item from the storage.
   * @param key The key of the item to get.
   * @returns The item or null if it does not exist.
   */
  static getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  /**
   * Gets multiple items from the storage.
   * @param keys The keys of the items to get.
   * @returns The items or null if they do not exist.
   */
  static getItems(keys: string[]): Record<string, string | null> {
    return Object.fromEntries(keys.map((key) => [key, this.getItem(key)]))
  }

  /**
   * Removes an item from the storage.
   * @param key The key of the item to remove.
   */
  static removeItem(key: string) {
    localStorage.removeItem(key)
  }

  /**
   * Clears all the items in the storage.
   */
  static clear() {
    localStorage.clear()
  }

  /**
   * Gets the tokens from the storage.
   * @returns The tokens or null if they do not exist.
   */
  static get tokens(): ApplicationTokens | null {
    const accessToken = this.getItem(TOKEN_KEY)
    const bitToken = this.getItem(BIT_TOKEN_KEY)
    const idToken = this.getItem(ID_TOKEN_KEY)
    const refreshToken = this.getItem(REFRESH_TOKEN_KEY)
    const expirationDate = this.getItem(EXPIRATION_DATE_KEY)

    return accessToken && bitToken && idToken && refreshToken && expirationDate
      ? { accessToken, bitToken, idToken, refreshToken, expirationDate }
      : null
  }

  /**
   * Sets the tokens in the storage.
   * @param tokens The tokens to set.
   */
  static set tokens(tokens: ApplicationTokens) {
    this.setItem(TOKEN_KEY, tokens.accessToken)
    this.setItem(BIT_TOKEN_KEY, tokens.bitToken)
    this.setItem(ID_TOKEN_KEY, tokens.idToken)
    this.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken)
    this.setItem(EXPIRATION_DATE_KEY, tokens.expirationDate)
  }

  static clearAuthKeys() {
    ApplicationStorage.removeItem(TOKEN_KEY)
    ApplicationStorage.removeItem(BIT_TOKEN_KEY)
    ApplicationStorage.removeItem(ID_TOKEN_KEY)
    ApplicationStorage.removeItem(REFRESH_TOKEN_KEY)
    ApplicationStorage.removeItem(EXPIRATION_DATE_KEY)
    ApplicationStorage.removeItem(CODE_VERIFIER_KEY)
    ApplicationStorage.removeItem(SESSION_TOKEN_KEY)
    ApplicationStorage.removeItem(STATE_KEY)
    ApplicationStorage.removeItem(SESSION_ID_KEY)
    ApplicationStorage.removeItem(ENCRYPTED_PRIVATE_KEY)
    ApplicationStorage.removeItem(SOCIAL_TOKEN_KEY)
  }

  /**
   * Gets the code verifier from the storage.
   */
  static get codeVerifier(): string | null {
    return this.getItem(CODE_VERIFIER_KEY)
  }

  /**
   * Sets the code verifier in the storage.
   */
  static set codeVerifier(codeVerifier: string) {
    this.setItem(CODE_VERIFIER_KEY, codeVerifier)
  }

  /**
   * Gets the session token from the storage.
   */
  static get sessionToken(): string | null {
    return this.getItem(SESSION_TOKEN_KEY)
  }

  /**
   * Sets the session token in the storage.
   */
  static set sessionToken(sessionToken: string) {
    this.setItem(SESSION_TOKEN_KEY, sessionToken)
  }

  /**
   * Gets the state from the storage.
   */
  static get state(): string | null {
    return this.getItem(STATE_KEY)
  }

  /**
   * Sets the state in the storage.
   */
  static set state(state: string) {
    this.setItem(STATE_KEY, state)
  }

  /**
   * Gets the session id from the storage. If no value
   * is found, then it is created.
   */
  static get incodeSessionId(): string | null {
    return this.getItem(SESSION_ID_KEY)
  }

  /**
   * Gets the refresh token from the storage. If no value
   */
  static get refreshToken(): string | null {
    return this.getItem(REFRESH_TOKEN_KEY)
  }

  /**
   * Gets the refresh token from the storage. If no value
   */
  static get expirationDate(): string | null {
    return this.getItem(EXPIRATION_DATE_KEY)
  }

  /**
   * Sets the session id in the storage.
   */
  static set incodeSessionId(incodeSessionId: string) {
    this.setItem(SESSION_ID_KEY, incodeSessionId)
  }

  /**
   * Gets the encrypted private key from the storage. If no value
   * is found, then it is created.
   */
  static get encryptedPrivateKey(): string | null {
    return this.getItem(ENCRYPTED_PRIVATE_KEY)
  }

  /**
   * Sets the encrypted private key in the storage.
   */
  static set encryptedPrivateKey(key: string) {
    this.setItem(ENCRYPTED_PRIVATE_KEY, key)
  }

  /**
   * Gets the  social token  from the storage. If no value
   * is found, then it is created.
   */
  static get socialToken(): string | null {
    return this.getItem(SOCIAL_TOKEN_KEY)
  }

  /**
   * Sets the social token in the storage.
   */
  static set socialToken(key: string) {
    this.setItem(SOCIAL_TOKEN_KEY, key)
  }
}
