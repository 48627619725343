import { t } from 'i18next'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { BEING_ID_LEVELS } from '@/common/constants'
import { useProfile, useProfileShare } from '@/common/hooks'
import { classNames } from '@/core'

import { Icon } from '../../../common/components'
import tKeys from '../../../common/i18nKeys'
import { UserProfileImage } from '../../../components/UserProfileImage'

export const DigitalIdentityHeader: React.FC = () => {
  const { profileLoaded, profile } = useProfile()

  const { token, copyIVDT } = useProfileShare()

  let userName = 'No user names set'
  if (profileLoaded && profile?.firstName && profile?.lastName) {
    userName = `${profile.firstName} ${profile.lastName}`
  }

  const beingIdLevel = useMemo(() => {
    if (!profile?.beingId?.level) {
      return
    }

    return BEING_ID_LEVELS.find(
      (level) => +profile.beingId!.level! === level.level,
    )
  }, [profile?.beingId?.level])

  return (
    <div className="w-full lg:max-w-full lg:flex bg-white p-4 grid">
      <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover overflow-hidden inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <UserProfileImage size={120} />
      </div>
      <div className="w-100 bg-white p-4 leading-normal my-auto w-full text-center md:text-start">
        <h3 className="text-xl">{userName}</h3>
        <span>{profile?.email}</span>
      </div>
      <div className="bg-white p-4 justify-between leading-normal border border-black w-72 md:w-60 m-auto">
        <div className="flex justify-between">
          <p className="flex my-auto">{t(tKeys.profile.beingId.title)}</p>
          <button onClick={copyIVDT} className="flex my-auto" disabled={!token}>
            <Icon
              icon="copy"
              className={classNames(
                'size-4',
                token ? 'fill-primary hover:fill-primary-300' : 'fill-gray-300',
              )}
            />
          </button>
        </div>
        <div className="flex h-20">
          <p className="m-auto text-5xl">{beingIdLevel?.level}</p>
        </div>
        <div className="flex justify-between">
          <p className="my-auto font-semibold">{t(beingIdLevel?.name ?? '')}</p>
          <Link
            to="https://www.chainit.com/being-id"
            target="_blank"
            rel="noopener"
            className="flex my-auto"
          >
            <Icon
              icon="question"
              className="size-4 fill-info hover:fill-info-300"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
