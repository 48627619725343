import type {
  CreateAttachmentInputData,
  UploadedAttachmentData,
} from '@bit-ui-libs/common'
import { AttachmentClassification } from '@bit-ui-libs/common'
import { Tab } from '@headlessui/react'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo } from 'react'
import type { ImageListType } from 'react-images-uploading'
import ImageUploading from 'react-images-uploading'
import { useParams } from 'react-router-dom'

import { Icon } from '@/common/components'
import { ErrorLabel } from '@/common/components/display'
import envVariables from '@/common/envVariables'
import { useError, useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { classNames, translate } from '@/core'
import { CoreEventService } from '@/core/core-events'
import { colors } from '@/theme'

import { LibraryAssetCard, PageActionButtons, SellPage } from '..'
import { useProductListing, useProductListingDispatch } from '../context'
import type { AssetImage } from '../types'
import { ListingType } from '../types/listing-type'

const MAX_IMAGES_COUNT = 5
const MIN_IMAGE_SIZE = 160
const MAX_FILE_SIZE = 5242880 // 5 * 1024 * 1024 (5MB)

async function getImageDimensions(
  file: File,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const { width, height } = img
      resolve({ width, height })
    }
    img.onerror = reject
    img.src = URL.createObjectURL(file)
  })
}

// TODO replace hardcoded values with constants or enums
export function SellImagesPage() {
  const { type } = useParams<{ type: string }>()
  const { selectedAsset, images, event } = useProductListing()
  const { setImages } = useProductListingDispatch()
  const { errors, changeError } = useError({
    images: t(i18nKeys.sell.nav.images.error.required),
  })
  const { error: notify } = useNotify()

  const maxImageSizeMB = useMemo(() => MAX_FILE_SIZE / 1024 / 1024, [])

  const imagesLoading = useMemo(
    () => images.filter((i) => i.status === 'loading').length > 0,
    [images],
  )

  const canContinue = useMemo(
    () =>
      images.filter((i) => i.status === 'success').length > 0 && !imagesLoading,
    [images, imagesLoading],
  )

  const handleLoadImage = useCallback(
    async (value: ImageListType) => {
      changeError('images', false)

      const mainName = value.length ? value[0].file?.name : null

      // temp: if other images are loading, don't upload new ones
      if (imagesLoading) {
        // show error
        notify(t(i18nKeys.sell.nav.images.error.loading))
        return
      }

      let _images: AssetImage[] = []

      for (const i of value) {
        if (i.status) {
          _images.push({
            status: i.status,
            src: i.src ?? i.data_url,
            alt: i.alt ?? i.file?.name,
            isMain: mainName !== null && i.file?.name === mainName,
          } as AssetImage)
          continue
        }

        if (!i.file) {
          continue
        }

        const img = await getImageDimensions(i.file!)
        const errors: { code: string; detail: string }[] = []

        if (img.width < MIN_IMAGE_SIZE || img.height < MIN_IMAGE_SIZE) {
          errors.push({
            code: translate((i) => i.sell.nav.images.error.tooSmall.code),
            detail: translate((i) => i.sell.nav.images.error.tooSmall.detail, {
              MIN_IMAGE_SIZE,
            }),
          })
        }

        if (i.file!.size > MAX_FILE_SIZE) {
          errors.push({
            code: translate((i) => i.sell.nav.images.error.tooBig.code),
            detail: translate((i) => i.sell.nav.images.error.tooBig.detail, {
              MAX_IMAGE_SIZE: maxImageSizeMB,
            }),
          })
        }

        _images.push({
          status: errors.length > 0 ? 'invalid' : 'idle',
          errors,
          src: i.src ?? i.data_url,
          alt: i.alt ?? i.file?.name,
          isMain: mainName !== null && i.file?.name === mainName,
        } as AssetImage)
      }

      // remove images with the same alt
      _images = _images.filter(
        (img, index, self) =>
          index === self.findIndex((t) => t.alt === img.alt),
      )

      setImages(_images)
    },
    [setImages, uploadImages, changeError],
  )

  async function deleteAttachment(image: AssetImage) {
    setImages(images.filter((i) => i.alt !== image.alt))
    try {
      // if the image does not have an id, just remove it by alt from state
      if (!image.id) {
        return
      }

      const service = new CoreEventService() // todo: update export

      // if the image has an id, remove it from the server as well
      if (type === ListingType.Asset) {
        await service.deleteAttachment(image.id)
      } else {
        await service.service.deleteAttachment(image.id)
      }
    } catch (e) {
      Logger.error('Failed to delete attachment', undefined, e as Error)

      // if the remove fails, add the image back to the state
      setImages([...images, image])

      notify(t(i18nKeys.sell.nav.images.error.delete))
    }
  }

  async function uploadImages(_images: AssetImage[]) {
    try {
      const service = new CoreEventService() // todo: update export

      // looks for each image provided as param and updated its state to uploading
      setImages(
        images.map((i) => {
          const _i = _images.find((img) => img.alt === i.alt)
          return _i ? { ...i, status: 'loading' } : i
        }),
      )

      const attachmentData = {
        eventId: event!.id,
        req: {
          application: { id: envVariables.APP_NAME },
          eventId: event!.id,
          device: {} as any,
          docs: _images.map(
            (i) =>
              ({
                docType: 'PHOTO',
                classification:
                  type === ListingType.Asset
                    ? AttachmentClassification.AssetPhoto
                    : AttachmentClassification.ServicePhoto,
                name: i.alt,
                description: i.alt,
                data: i.src.split(',')[1] as string,
                isMain: i.isMain,
              }) as CreateAttachmentInputData,
          ),
        },
      }

      let result: UploadedAttachmentData[] = []

      if (type === ListingType.Asset) {
        result = await service.initAttachments(attachmentData)
      } else {
        result = await service.service.initAttachments(attachmentData)
      }

      setImages(
        images.map((i) => {
          const _i = result.find((img) => img.name === i.alt)
          return _i ? { ...i, status: 'success', id: _i.docId } : i
        }),
      )
    } catch (e) {
      notify(t(i18nKeys.common.somethingWentWrong))
      Logger.error('Failed to upload images', undefined, e as Error)
      setImages(
        images.map((i) => {
          const _i = _images.find((img) => img.alt === i.alt)
          return _i ? { ...i, status: 'error' } : i
        }),
      )
    }
  }

  const handleContinue = () => {
    try {
      if (!images.length) {
        changeError('images', true)
        return false
      }
      return true
    } catch (e) {
      // TODO explain why we have a try-catch here if we are not doing any side-effect request
      Logger.error('Failed to continue', undefined, e as Error)
      return false
    }
  }

  const handleRetryUpload = (image: AssetImage) => {
    // look for the image in the state and update its status to idle
    setImages(
      images.map((i) => {
        if (i.alt === image.alt) {
          return { ...i, status: 'idle' }
        }
        return i
      }),
    )
  }

  useEffect(() => {
    const idleImages = images.filter((i) => i.status === 'idle')

    if (idleImages.length) {
      uploadImages(idleImages)
    }
  }, [images])

  return (
    <>
      {!!selectedAsset && <LibraryAssetCard asset={selectedAsset} />}
      <SellPage title={t(i18nKeys.sell.nav.images.label)}>
        <ImageUploading
          multiple
          value={images}
          onChange={handleLoadImage}
          maxNumber={MAX_IMAGES_COUNT}
          dataURLKey="data_url"
        >
          {({ onImageUpload, onImageRemove, isDragging, dragProps }) => (
            <div
              className={classNames(
                'w-full h-full flex-1 border-dashed rounded-md flex flex-col mt-2',
                isDragging && 'border-2 border-primary',
                !images.length && 'border-2 border-gray-500',
              )}
              {...dragProps}
            >
              {images.length > 0 && (
                <Tab.Group as="div" className="flex flex-col-reverse">
                  {/* Image selector */}
                  <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                    <Tab.List className="grid grid-cols-4 gap-6">
                      {images.map((image, key) => (
                        <Tab
                          key={key}
                          className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                          {({ selected }) => {
                            return (
                              <>
                                <span className="sr-only">{image.alt}</span>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img
                                    src={image.src}
                                    alt=""
                                    className="h-full w-full object-contain object-center"
                                  />
                                </span>
                                <span
                                  className={classNames(
                                    selected
                                      ? 'ring-primary-500'
                                      : 'ring-transparent',
                                    'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                                  )}
                                  aria-hidden="true"
                                />
                                {image.status === 'error' && (
                                  <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 text-white rounded-md">
                                    <Icon
                                      icon="retry"
                                      size={20}
                                      color={colors.gray[50]}
                                      onClick={() => handleRetryUpload(image)}
                                    />
                                  </div>
                                )}
                                {image.status === 'invalid' && (
                                  <div
                                    title={image.errors![0].detail}
                                    className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 text-white rounded-md flex-col"
                                  >
                                    <Icon
                                      icon="warn"
                                      size={20}
                                      color={colors.gray[50]}
                                    />
                                    <p className="text-xs text-white">
                                      {image.errors![0].code}
                                    </p>
                                  </div>
                                )}
                                {image.status === 'loading' && (
                                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
                                    <Spinner />
                                  </div>
                                )}
                                {image.status !== 'loading' && (
                                  <button
                                    className="size-6 bg-white shadow rounded-full absolute top-0 right-0 flex items-center justify-center"
                                    onClick={() => {
                                      onImageRemove(key)
                                      deleteAttachment(image)
                                    }}
                                  >
                                    <Icon
                                      icon="close"
                                      size={18}
                                      color={colors.details}
                                    />
                                  </button>
                                )}
                              </>
                            )
                          }}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <button
                    onClick={onImageUpload}
                    disabled={images.length >= MAX_IMAGES_COUNT}
                    className="w-full py-2 flex items-center justify-center flex-1 border border-dashed rounded-md text-gray-400 hover:text-gray-500"
                  >
                    {images.length < MAX_IMAGES_COUNT
                      ? t(i18nKeys.sell.nav.images.drop)
                      : translate((i) => i.sell.nav.images.error.limit, {
                          MAX_IMAGES_COUNT,
                        })}
                  </button>

                  <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                    {images.map((image, key) => (
                      <Tab.Panel key={key}>
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="h-full w-full object-contain object-center sm:rounded-lg"
                        />
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              )}
              {!images.length && (
                <div className="h-full w-full flex items-center justify-center flex-1">
                  <button onClick={onImageUpload}>
                    {t(i18nKeys.sell.nav.images.drop)}
                  </button>
                </div>
              )}
            </div>
          )}
        </ImageUploading>

        <ErrorLabel error={errors.images.message} />

        <div className="flex items-center self-end gap-2 my-2">
          <p className="text-sm text-gray-400">
            {translate((i) => i.sell.nav.images.minResolution, {
              MIN_IMAGE_SIZE,
            })}
          </p>
          <div className="size-[2px] rounded-full bg-gray-400"></div>
          <p className="text-sm text-gray-400">
            {translate((i) => i.sell.nav.images.maxSize, {
              MAX_IMAGE_SIZE: maxImageSizeMB,
            })}
          </p>
        </div>

        <PageActionButtons
          onContinue={handleContinue}
          continueDisabled={!canContinue}
        />
      </SellPage>
    </>
  )
}
