import React, { useEffect, useState } from 'react'

import { PaymentForm } from '@/common/components/Paysafe/PaymentForm'
import type { PaysafeCreditCardFormProps } from '@/common/components/Paysafe/PaysafeClient'
import {
  getPaysafeInstance,
  startPaysafeTokenize,
} from '@/common/components/Paysafe/PaysafeClient'

import styles from './PaysafeCreditCardFormStyles.module.css'

export const PaysafeCreditCardForm: React.FC<PaysafeCreditCardFormProps> = (
  props: PaysafeCreditCardFormProps,
) => {
  const [paysafeInstance, setPaysafeInstance] = useState<any>(null)
  const [payButtonEnabled, setPayButtonEnabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    startPaysafeIntegration().catch((error) => {
      if (props.onPaymentFailed) {
        props.onPaymentFailed(error)
      }
    })
  }, [])

  async function startPaysafeIntegration() {
    const newPaysafeInstance = await getPaysafeInstance(props.isNewCard)

    // Validation event listeners
    newPaysafeInstance
      .fields('cvv cardNumber expiryDate')
      .on('valid invalid', handleValidationEvent)

    newPaysafeInstance.show()
    setPaysafeInstance(newPaysafeInstance)
  }

  async function startCreditCardPaymentFlow() {
    setIsLoading(true)
    try {
      const tokenizeResult = await startPaysafeTokenize(
        props.holderName,
        props.billingAddress,
        props.amount,
        paysafeInstance,
        !props.isNewCard && props.handleToken ? props.handleToken : undefined,
      )

      if (tokenizeResult) {
        props.onPaymentComplete(tokenizeResult)
        return
      }

      if (props.onPaymentFailed) {
        props.onPaymentFailed('Error starting payment.')
      }
    } catch (error: any) {
      if (props.onPaymentFailed) {
        props.onPaymentFailed(error.message ?? 'Error starting payment.')
      }
    }

    setIsLoading(false)
  }

  const handleValidationEvent = (instance: any, event: any) => {
    const eventName = event.type
    switch (eventName) {
      case 'Valid':
        event.target.containerElement.setAttribute('data-invalid', false)
        event.target.containerElement.setAttribute(
          'class',
          `${styles.paysafeField}`,
        )
        break
      case 'Invalid':
        event.target.containerElement.setAttribute('data-invalid', true)
        event.target.containerElement.setAttribute(
          'class',
          `${styles.paysafeField} ${styles.invalid}`,
        )
        setPayButtonEnabled(false)
        break
    }

    if (instance.areAllFieldsValid()) {
      setPayButtonEnabled(true)
    }
  }

  return (
    <div className={styles.paysafeJsForm}>
      <PaymentForm
        submitText={props.submitText}
        onPayment={async () => startCreditCardPaymentFlow()}
        payButtonEnabled={payButtonEnabled}
        isNewCard={props.isNewCard}
        isLoading={isLoading}
      />
    </div>
  )
}
