// TODO remove this eslint-disable when it can be fixed. Currently is hard due to missing types in common library
/* eslint-disable react/jsx-no-leaked-render */
import {
  RelationTypeEnum,
  type WitnessCustomerAssetResponse,
} from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { UserService } from '@/core/users'
import { colors } from '@/theme'
import WhoIcon from '@assets/icons/who.svg'

import { CardLabel } from '../cardLabel'

interface WhoFullPropsInterface {
  witness: WitnessCustomerAssetResponse[]
  userId: string
  onClickBack: () => void
}

export const WhoFull: React.FC<WhoFullPropsInterface> = ({
  witness,
  userId,
  onClickBack,
}) => {
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    if (!userId) {
      return
    }
    const userService = new UserService()
    userService.getUserInfo(userId).then((res) => {
      setUser(res)
    })
  }, [userId])

  return (
    <>
      <Button
        onClick={() => onClickBack()}
        mode="text"
        color={colors.linkGreen}
      >
        {t(i18nKeys.ui.back)}
      </Button>

      <div className="bg-white rounded mb-5">
        <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 grid grid-row gap-4">
          <div className="border-b border-gray-100">
            <div>
              <label className="w-full text-xl font-semibold flex items-center gap-2">
                <img src={WhoIcon} className="size-8" alt="who-icon" />
                {t(i18nKeys.profile.myLibrary.who.title)}
              </label>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="error" size={20} />
                {t(i18nKeys.profile.myLibrary.who.details)}
              </h2>
            </div>
            {user && (
              <div className="grid grid-cols-4 gap-4 mb-5">
                <div className="border-2 rounded-full border-black h-26 w-26 m-auto">
                  {user.avatarUrl && (
                    <img
                      alt="userImg"
                      src={user.avatarUrl}
                      className="h-24 w-24 m-auto rounded-full"
                    />
                  )}
                </div>
                <CardLabel
                  title={t(i18nKeys.profile.myLibrary.who.chainItId)}
                  value={user.id} // TODO: WitnessCustomerAssetResponse does not include ChainId property
                />
                <CardLabel
                  title={t(i18nKeys.profile.beingId.header.subTitle)}
                  value={user.beingId.level}
                />
                {user.city && user.state && user.country && (
                  <CardLabel
                    title={t(i18nKeys.profile.myLibrary.who.location)}
                    value={`${user.city} ${user.state}, ${user.country}`}
                  />
                )}
              </div>
            )}
          </div>

          {/* TODO: get officers */}
          <div className="border-b border-gray-100">
            <div>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="star" size={20} />
                {t(i18nKeys.profile.myLibrary.who.officers)}
              </h2>
            </div>
            {witness
              ?.filter((x) => x.relationType === RelationTypeEnum.Official)
              .map((x) => (
                <div
                  key={`who-official-${x.id}`}
                  className="grid grid-cols-4 gap-4 mb-5"
                >
                  <div className="border-2 rounded-full border-black h-26 w-26 m-auto">
                    {x.avatarUrl && (
                      <img
                        alt="witnessImg"
                        src={x.avatarUrl}
                        className="h-24 w-24 m-auto rounded-full"
                      />
                    )}
                  </div>
                  <CardLabel
                    title={t(i18nKeys.profile.myLibrary.who.chainItId)}
                    value={x.id} // TODO: WitnessCustomerAssetResponse does not include ChainId property
                  />
                  <CardLabel
                    title={t(i18nKeys.profile.myLibrary.who.location)}
                    value={`${x.city} ${x.state}, ${x.country}`}
                  />
                </div>
              ))}
          </div>

          <div className="border-b border-gray-100">
            <div>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="search" size={20} />
                {t(i18nKeys.profile.myLibrary.who.witness)}
              </h2>
            </div>

            {witness
              ?.filter((x) => x.relationType === RelationTypeEnum.Witness)
              .map((x) => (
                <div
                  key={`who-witness-${x.id}`}
                  className="grid grid-cols-4 gap-4 mb-5"
                >
                  <div className="border-2 rounded-full border-black h-26 w-26 m-auto">
                    {x.avatarUrl && (
                      <img
                        alt="witnessImg"
                        src={x.avatarUrl}
                        className="h-24 w-24 m-auto rounded-full"
                      />
                    )}
                  </div>
                  <CardLabel
                    title={t(i18nKeys.profile.myLibrary.who.chainItId)}
                    value={x.id} // TODO: WitnessCustomerAssetResponse does not include ChainId property
                  />
                  <CardLabel
                    title={t(i18nKeys.profile.myLibrary.who.location)}
                    value={`${x.city} ${x.state}, ${x.country}`}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}
