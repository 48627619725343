import { t } from 'i18next'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import { Button } from '@/common/components'
import { colors } from '@/theme'

import i18nKeys from '../../i18nKeys'

interface ConfirmationModalProps {
  handleClose: () => void
  handleContinue: () => void
  isOpen: boolean
}

const customStyles = {
  modal: {
    width: '432px',
    borderRadius: '16px',
  },
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  handleClose,
  handleContinue,
  isOpen,
}) => {
  return (
    <Modal open={isOpen} center styles={customStyles} onClose={handleClose}>
      <div className="my-4 mx-12">
        <div className="text-center">
          <h1 className="text-lg font-bold mb-4">
            {t(i18nKeys.onboarding.confirmationModal.modalTitle)}
          </h1>
          <p>
            {t(
              i18nKeys.onboarding.confirmationModal.storingBackupFileLocallyIs,
            )}{' '}
            <strong>{t(i18nKeys.onboarding.confirmationModal.not)}</strong>
            {t(i18nKeys.onboarding.confirmationModal.a)}
            <strong>
              {t(i18nKeys.onboarding.confirmationModal.recommended)}
            </strong>{' '}
            {t(i18nKeys.onboarding.confirmationModal.option)}.
          </p>

          <div className="flex flex-col my-5">
            <Button
              mode="contained"
              type="button"
              color={colors.yellowLight}
              textColor="white"
              className="rounded-[16px]"
              onClick={handleContinue}
            >
              {t(i18nKeys.onboarding.confirmationModal.continueAnyway)}
            </Button>

            <Button
              mode="contained"
              type="button"
              textColor={colors.primary.DEFAULT}
              className="rounded-[16px] border mt-5 border-primary-400"
              onClick={handleClose}
            >
              {t(i18nKeys.ui.cancel)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
