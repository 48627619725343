import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import i18nKeys from '../../../common/i18nKeys'
import ApprovedImage from '../../../resources/success-icon.png'

export function PersonalInformationCompleted() {
  const navigate = useNavigate()

  return (
    <div>
      <div>
        <img className="my-6 mx-auto" src={ApprovedImage} alt="Approved icon" />
        <p className="text-center text-4xl font-bold text-primary">
          {t(i18nKeys.onboarding.done.welcomeLine1)}
        </p>
        <p className="text-center text-4xl font-bold text-primary">
          {t(i18nKeys.onboarding.done.welcomeLine2)}
        </p>
        <div className="mt-3 px-5">
          {t(i18nKeys.onboarding.done.description)}
        </div>
      </div>
      <div className="flex bottom-4 w-[calc(100%-40px)] m-auto mt-5">
        <button
          type="button"
          className="bg-primary py-3 px-8 rounded-lg text-white w-full"
          onClick={() => navigate('/')}
        >
          {t(i18nKeys.ui.continue)}
        </button>
      </div>
    </div>
  )
}
