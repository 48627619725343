import React from 'react'
import OtpInput from 'react-otp-input'

import { colors } from '@/theme'

export interface PinInputProps {
  handleChange: (code: string) => void
  pin: string
  error?: boolean
  shouldAutoFocus?: boolean
}

const inputStyle = {
  border: `1px solid ${colors.primary.DEFAULT}`,
  backgroundColor: `${colors.primary[50]}`,
  borderRadius: '10px',
  width: '54px',
  height: '54px',
  fontSize: '24px',
  color: `${colors.primary[950]}`,
  fontWeight: '600',
  caretColor: `${colors.primary[950]}`,
  padding: '0',
  margin: '5px',
}

export const PinInput: React.FC<PinInputProps> = ({
  handleChange,
  pin,
  error,
  shouldAutoFocus,
}) => {
  return (
    <OtpInput
      value={pin}
      onChange={handleChange}
      numInputs={4}
      renderSeparator={<span className="w-2.5"></span>}
      inputType="tel"
      renderInput={(props) => <input {...props} />}
      shouldAutoFocus={shouldAutoFocus || true}
      inputStyle={
        error
          ? {
              ...inputStyle,
              borderColor: `${colors.red[50]}`,
              color: `${colors.red[900]}`,
              background: `${colors.red[50]}`,
            }
          : inputStyle
      }
    />
  )
}
