import React from 'react'

import { colors } from '../../theme'
import Footer from '../main/Footer' // todo: move to common folder

import SimpleHeader from './SimpleHeader'

export interface SimpleLayoutProps {
  children?: React.ReactNode
}

/**
 * A simple minimal layout
 */
export default function SimpleLayout(props: SimpleLayoutProps) {
  return (
    <div
      className="flex flex-col min-h-full"
      style={{ backgroundColor: colors.background }}
    >
      <SimpleHeader />
      <div className="pt-5 flex-1">{props.children}</div>
      <Footer />
    </div>
  )
}
