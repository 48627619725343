import type { CustomerAsset } from '@bit-ui-libs/common'

import { doGet } from '..'

export class ServicesService {
  static async getByEventId(eventId: string) {
    return doGet(
      `/assets/v1/service/${eventId}/event`,
    ) as Promise<CustomerAsset>
  }
}
