import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescriptionAndAction } from '@/common/types'
import { translateKey } from '@/core'

import type { RadioOptionItem } from '..'
import { PayoutCurrencyType, ServicePaymentTerms } from '../types'

export const PAYMENT_METHODS: DisplayItemWithDescriptionAndAction[] = [
  {
    id: PayoutCurrencyType.USDollars,
    name: i18nKeys.sell.nav.payment.usd.name,
    description: i18nKeys.sell.nav.payment.usd.description,
    action: i18nKeys.sell.nav.payment.usd.action,
  },
  {
    id: PayoutCurrencyType.Cryptocurrency,
    name: i18nKeys.sell.nav.payment.crypto.name,
    description: i18nKeys.sell.nav.payment.crypto.description,
    action: i18nKeys.common.soon,
  },
]

export const servicePaymentTerms: RadioOptionItem[] = [
  {
    id: ServicePaymentTerms.TimeOfSale,
    name: translateKey(
      (i) => i.sell.nav.servicePayment.options.timeOfSale.name,
    ),
    description: translateKey(
      (i) => i.sell.nav.servicePayment.options.timeOfSale.description,
    ),
  },
  {
    id: ServicePaymentTerms.FiftyFifty,
    name: translateKey(
      (i) => i.sell.nav.servicePayment.options.fiftyFifty.name,
    ),
    description: translateKey(
      (i) => i.sell.nav.servicePayment.options.fiftyFifty.description,
    ),
  },
  {
    id: ServicePaymentTerms.TimeOfService,
    name: translateKey(
      (i) => i.sell.nav.servicePayment.options.timeOfService.name,
    ),
    description: translateKey(
      (i) => i.sell.nav.servicePayment.options.timeOfService.description,
    ),
  },
  {
    id: ServicePaymentTerms.BuyerInspection,
    name: translateKey(
      (i) => i.sell.nav.servicePayment.options.buyerInspection.name,
    ),
    description: translateKey(
      (i) => i.sell.nav.servicePayment.options.buyerInspection.description,
    ),
  },
]
