import React, { useEffect, useState } from 'react'

import { useNotify } from '@/common/hooks'
import { Logger } from '@/config'
import { ListingService, classNames } from '@/core'

import { Icon } from '..'

export type FavoriteButtonProps = {
  assetId: string
  className?: string
  iconClassName?: string
  visible?: boolean
}

// TODO missing translations
export const FavoriteButton: React.FC<FavoriteButtonProps> = (props) => {
  const { error } = useNotify()

  const [isFavorite, setIsFavorite] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleFetchFavorite = async () => {
    setIsFavorite(await ListingService.getIsFavorite(props.assetId))
    setIsLoading(false)
  }

  const handleToggleFavorite = async () => {
    const currentState = isFavorite
    try {
      setIsFavorite(!currentState)

      if (currentState) {
        await ListingService.removeFavorite(props.assetId)
        return
      }

      await ListingService.addFavorite(props.assetId)
    } catch (e) {
      Logger.error('Failed to toggle favourite', undefined, e as Error)
      error('Failed to toggle favorite')
      setIsFavorite(currentState)
    }
  }

  useEffect(() => {
    if (!props.visible || !isLoading) return

    handleFetchFavorite()
  }, [props.visible])

  if (!props.visible || isLoading) {
    return null
  }

  return (
    <div
      className={classNames(
        'p-3 cursor-pointer transition-all duration-200',
        props.className,
      )}
    >
      <Icon
        icon={isFavorite ? 'heart' : 'heartOutline'}
        size={20}
        className={classNames(
          'fill-primary-200 hover:fill-primary-300',
          props.iconClassName,
        )}
        onClick={handleToggleFavorite}
      />
    </div>
  )
}
