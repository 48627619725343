import React from 'react'

import type { IconSource } from '@/common/components'
import { Icon } from '@/common/components'
import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

export type EmptyCardProps = {
  /** Icon to display  */
  icon: IconSource

  /** Text indicating the expected action */
  text: string

  error?: string

  className?: string

  /** On click event */
  onClick?: () => Promise<void> | void
}

/** Represents a placeholder where content will be, and provides an action to get that content */
export const EmptyCard: React.FC<EmptyCardProps> = (props: EmptyCardProps) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={classNames(
        'relative block w-full rounded-lg border-2 border-dashed p-12 text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 mt-8',
        props.error
          ? 'border-red-400 hover:border-red-600'
          : 'border-gray-300 hover:border-gray-400',
        props.className,
      )}
    >
      <Icon
        icon={props.icon}
        color={colors.gray[500]}
        size={48}
        className="mx-auto"
      />
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        {props.text}
      </span>

      <ConditionalRendering renderIf={Boolean(props.error)}>
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      </ConditionalRendering>
    </button>
  )
}
