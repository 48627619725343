import React, { useMemo } from 'react'
import Modal from 'react-responsive-modal'
import { useSearchParams } from 'react-router-dom'

export const TaxDisclaimerModal: React.FC = () => {
  const [params, setParams] = useSearchParams()
  const disclaimerModalOpen = useMemo(
    () => params.get('disclaimer') === 'true',
    [params],
  )

  return (
    <Modal open={disclaimerModalOpen} onClose={() => setParams({})}>
      <div className="flex flex-col">
        <h3 className="font-medium mt-6">
          Understanding Special vs. General Tax Categories Based on Location:
        </h3>
        <p className="text-sm mt-2">
          When listing an item for sale, it&apos;s important to understand the
          distinction between a special tax category and a general tax category,
          as these can significantly impact how taxes are applied to your
          transactions. General Tax Category: A general tax category typically
          applies to most goods and services and is governed by standard tax
          rates established by local, state, or national authorities. This
          category is straightforward and is applicable to a wide range of
          products, with the same tax rate being applied regardless of the
          item&apos;s specific nature.
        </p>
        <h3 className="font-medium mt-6">Special Tax Category:</h3>
        <p className="text-sm mt-2">
          A special tax category, on the other hand, is designated for certain
          products or services that are subject to different tax rates due to
          their unique nature, usage, or location. For instance, certain luxury
          goods, alcohol, tobacco, or digital products may fall under special
          tax categories, which could have higher or lower tax rates compared to
          the general tax category. These categories are often regulated by
          specific laws or local ordinances and may vary significantly depending
          on the location where the transaction occurs.
        </p>
        <h3 className="font-medium mt-6">Location-Based Variations:</h3>
        <p className="text-sm mt-2">
          It&apos;s crucial to be aware that tax regulations can vary greatly
          depending on your location. A product that falls under a general tax
          category in one jurisdiction might be subject to a special tax
          category in another. Sellers must research and understand the tax laws
          applicable in their location to ensure compliance and avoid potential
          penalties. By selecting the appropriate tax category based on your
          location and the nature of your product, you can ensure accurate tax
          calculation and compliance with local regulations.
        </p>
      </div>
    </Modal>
  )
}
