import type { ShippingAddress, Bid, PagedResponse } from '@bit-ui-libs/common'
import React, { useEffect, useState } from 'react'

import { Button } from '@/common/components'
import { ConditionalRendering } from '@/components'
import type { UserPaginatedRequestInterface } from '@/core'
import { dateToStringFormat, ListingService, UserService } from '@/core'

export interface BidWithAddress extends Bid {
  shippingAddress?: ShippingAddress
}

export type BidsPaginatedHistoryProps = {
  bids?: PagedResponse<BidWithAddress>
  setOpenBidDetails: (e: string | undefined) => void
  selectedBid?: string
  selectedTab: number
}

export const BidsPaginatedHistory: React.FC<BidsPaginatedHistoryProps> = ({
  bids,
  setOpenBidDetails,
  selectedBid,
  selectedTab,
}) => {
  const [userList, setUserList] = useState<any[]>([])
  const [lastBidId, setLastBidId] = useState<string>()

  useEffect(() => {
    getUserName()
    if (bids && bids.items.length > 0 && bids.items[0].listing?.id) {
      getLastBid(bids.items[0].listing?.id)
    }
  }, [bids])

  const getLastBid = async (listingId: string) => {
    const lastBid = await ListingService.getLastBid(listingId)
    setLastBidId(lastBid.id)
  }

  const getUserName = async () => {
    const ids = bids?.items.map((x) => x.createdBy)

    if (!ids || ids.length === 0) {
      return
    }

    const options = {
      $ids: ids.join('&$ids='),
      $page: 1,
      $perPage: 10,
    } as UserPaginatedRequestInterface

    const userService = new UserService()

    userService.getUsersPagination(options).then((res) => {
      setUserList(res?.items)
    })
  }

  return (
    <div>
      <ConditionalRendering
        renderIf={bids !== undefined && bids.items.length > 0}
      >
        <h1 className="text-xl font-bold">{bids?.items[0]?.asset?.name}</h1>
        <h2 className="text-gray-900 mb-3">
          View all the bids for this auction.
        </h2>
        <ul>
          {bids?.items.map((bid, key) => (
            <li
              key={key}
              className={
                (lastBidId === bid.id
                  ? 'border-lime-200 '
                  : 'border-gray-200 ') +
                'flex justify-between gap-x-6 p-0 border mb-2 rounded-xl'
              }
            >
              <div className="flex min-w-0 gap-x-4 p-2">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {selectedTab === 1
                      ? userList.find((x: any) => x.id === bid.createdBy)?.id
                      : userList.find((x: any) => x.id === bid.createdBy)
                          ?.username}
                    <span className="mx-1 rounded-2xl border border-lime-500 text-lime-600 px-1 bg-lime-100">
                      {
                        userList.find((x: any) => x.id === bid.createdBy)
                          ?.beingId?.level
                      }{' '}
                    </span>
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {dateToStringFormat(bid.createdAt)} &#x2022;{' '}
                    {bid.shippingAddress
                      ? bid.shippingAddress.city +
                        ',' +
                        bid.shippingAddress.state
                      : ''}
                  </p>
                </div>
              </div>
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end pt-2">
                <p className="text-xl font-bold leading-6 text-gray-900 h-full pr-1">
                  {bid.price}
                </p>
                <ConditionalRendering renderIf={lastBidId === bid.id}>
                  <div className="px-5 bg-lime-200 rounded-tl-lg rounded-br-lg py-1 inline-grid grid-cols-1">
                    <span className="text-xs">Current Bid</span>
                  </div>
                </ConditionalRendering>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-center mt-5">
          <Button type="button" onClick={() => setOpenBidDetails(selectedBid)}>
            View results
          </Button>
        </div>
      </ConditionalRendering>
    </div>
  )
}
