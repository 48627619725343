import { isUndefined } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@/common/components'
import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

export type InfoAlertProps = {
  /** Information text */
  text: string

  /** Alert title */
  title?: string

  /** Link text to view more. Link will be shown only when this has a value */
  linkText?: string

  /** Link text to view more. */
  href?: string

  /** Style classes */
  className?: string

  linkBottom?: boolean

  redirectExternal?: boolean
}

/** An information card */
export const InfoAlert: React.FC<InfoAlertProps> = (props) => {
  return (
    <div
      className={classNames(
        'rounded-md bg-info-50 p-2 sm:p-4',
        props.className,
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0 hidden sm:block">
          <Icon icon="infoCircle" size={20} color={colors.info.DEFAULT} />
        </div>
        <div className="sm:ml-3 flex-1 md:flex md:justify-between">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0 sm:hidden">
                <Icon icon="infoCircle" size={20} color={colors.info.DEFAULT} />
              </div>
              <ConditionalRendering renderIf={!isUndefined(props.title)}>
                <h3 className="text-sm font-medium text-info-800">
                  {props.title}
                </h3>
              </ConditionalRendering>
            </div>
            <p
              className={classNames(
                'text-sm text-info-700',
                props.title && 'mt-2',
              )}
            >
              {props.text}
            </p>

            {!!props.linkText && props.linkBottom ? (
              <p className="mt-3 text-sm self-end">
                <Link
                  to={props.href ?? '#'}
                  target={props.redirectExternal ? '_blank' : undefined}
                  rel={props.redirectExternal ? 'noreferrer' : undefined}
                  className="font-medium text-info-700 hover:text-info-600 flex items-center gap-1"
                >
                  {props.linkText}
                  <Icon icon="arrowRight" color={colors.info[700]} size={16} />
                </Link>
              </p>
            ) : null}
          </div>
          {!!props.linkText && !props.linkBottom && (
            <p className="mt-3 text-sm md:ml-6 md:mt-0 self-center">
              <Link
                to={props.href ?? '#'}
                target={props.redirectExternal ? '_blank' : undefined}
                rel={props.redirectExternal ? 'noreferrer' : undefined}
                className="whitespace-nowrap font-medium text-info-700 hover:text-info-600 flex items-center gap-1"
              >
                {props.linkText}
                <Icon icon="arrowRight" color={colors.info[700]} size={16} />
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
