import { t } from 'i18next'
import { toast } from 'react-toastify'

import { HttpError } from '@/core/errors'

import i18nKeys from '../i18nKeys'

type FromErrorParams = {
  error: Error
  defaultMessage?: string
  hideInternalError?: boolean
}

type UseNotifyHook = {
  error: (message: string) => void
  success: (message: string) => void
  warning: (message: string) => void
  info: (message: string) => void
  basic: (message: string) => void
  soon: () => void
  fromError: (params: FromErrorParams) => void
  toast: typeof toast
}

export function useNotify(): UseNotifyHook {
  const error = (message: string) => toast(message, { type: 'error' })
  const success = (message: string) => toast(message, { type: 'success' })
  const warning = (message: string) => toast(message, { type: 'warning' })
  const info = (message: string) => toast(message, { type: 'info' })
  const basic = (message: string) => toast(message, { type: 'default' })
  const soon = () => toast(t(i18nKeys.common.soon), { type: 'info' })
  const fromError = ({
    error: err,
    defaultMessage,
    hideInternalError,
  }: FromErrorParams) => {
    let message = err.message
    if (err instanceof HttpError) {
      message = defaultMessage || t(i18nKeys.common.somethingWentWrong)

      if (
        Array.isArray(err.data?.message) &&
        err.data?.message.length &&
        err.data?.message[0]
      ) {
        message = err.data?.message[0]
      } else if (err.data?.message) {
        message = err.data?.message
      }
    }

    if (
      hideInternalError &&
      message.toLowerCase().includes('internal server error')
    ) {
      message = t(i18nKeys.common.somethingWentWrong)
    }

    error(message)
  }

  return {
    fromError,
    error,
    success,
    warning,
    info,
    basic,
    soon,
    toast,
  }
}
