import { t } from 'i18next'
import React, { useState, useContext } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { ConditionalRendering } from '@/components'

import { Button, Input } from '../../../common/components'
import type { UserProfileType } from '../../../common/context/auth_context'
import { authContext } from '../../../common/context/auth_context'
import i18nKeys from '../../../common/i18nKeys'
import ChainLink from '../../../components/Buttons/Link'
import { UserService } from '../../../core/users/user.service'
import 'react-phone-number-input/style.css'

export interface PhoneInformationProps {
  onClose: () => void | Promise<void>
  defaultValue?: string
}

interface VerificationCodeProps {
  show: boolean
  codeError?: string
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const VerificationCode: React.FC<VerificationCodeProps> = ({
  show,
  codeError,
  handleInputChange,
}) => {
  return (
    <ConditionalRendering renderIf={show}>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <Input
            name="verificationCode"
            id="verificationCode"
            label={t(i18nKeys.onboarding.phone.verificationCode)}
            type="text"
            placeholder={t(i18nKeys.onboarding.phone.codePlaceholder)}
            onChange={handleInputChange}
            error={codeError}
          />
        </div>
      </div>
    </ConditionalRendering>
  )
}

export function PhoneInformation(props: PhoneInformationProps) {
  const [codeSent, setCodeSent] = useState(false)
  const [phoneError, setPhoneError] = useState<string>() // todo: find a better way to handle errors
  const [codeError, setCodeError] = useState<string>()
  const [data, setData] = useState({
    phoneNumber: props.defaultValue ?? '',
    verificationCode: '',
  })
  const { profile, setUserProfile } = useContext(authContext)

  function handleSendCode() {
    if (!data.phoneNumber || !isValidPhoneNumber(data.phoneNumber)) {
      setPhoneError(t(i18nKeys.onboarding.phone.phoneError))
      return
    }

    const userService = new UserService()
    userService.sendVerificationCode(data.phoneNumber)
    setCodeSent(true)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!!phoneError || !!codeError) {
      setPhoneError(undefined)
      setCodeError(undefined)
    }

    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  function handleChangePhoneNumber(value: string) {
    setPhoneError(undefined)
    setCodeError(undefined)
    setData({
      ...data,
      phoneNumber: value,
    })
  }

  async function handleValidate() {
    const userService = new UserService()

    if (!data.verificationCode || !data.phoneNumber) {
      return
    }

    const result = await userService.verifyCode({
      phone: data.phoneNumber,
      code: data.verificationCode,
    })

    if (!result.verified || !!result.error) {
      setCodeError(result.error ?? t(i18nKeys.onboarding.phone.codeError))
      return
    }

    setUserProfile({
      ...profile,
      phone: data.phoneNumber,
      phoneVerified: true,
    } as UserProfileType)
    props.onClose()
  }

  let sendMessageAlert = null

  if (codeSent) {
    const msj = t(
      i18nKeys.profile.personalInformation.sendMessageAlert,
    ).replace('{NUMBER}', data.phoneNumber)
    sendMessageAlert = (
      <div
        className="bg-primary-100 border-t border-b border-blue-500 text-primary-900 px-4 py-3"
        role="alert"
      >
        <p>{msj}</p>
      </div>
    )
  }
  // TODO Change the hardcoded height
  return (
    <form>
      <div className="space-y-12 h-[400px]">
        <div className="pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <div className="relative">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t(i18nKeys.onboarding.phone.phoneNumber)}
                </label>
                <PhoneInput
                  onChange={(v) => handleChangePhoneNumber(v?.toString() ?? '')}
                  value={data.phoneNumber}
                  defaultCountry="US"
                  className="flex items-end"
                  numberInputProps={{
                    className:
                      'block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset text-gray-900 focus:shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-primary ms-4',
                  }}
                  placeholder={t(i18nKeys.onboarding.phone.phonePlaceholder)}
                />
                {Boolean(phoneError) && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="phoneNumber-error"
                  >
                    {phoneError}
                  </p>
                )}

                <div className="flex justify-end">
                  <ChainLink
                    onClick={handleSendCode}
                    text={t(i18nKeys.onboarding.phone.sendCode)}
                    size={16}
                  />
                </div>
              </div>
            </div>
          </div>

          {sendMessageAlert}

          <VerificationCode
            codeError={codeError}
            show={codeSent}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>

      <div className="flex justify-between relative bottom-0">
        <Button mode="text" onClick={props.onClose}>
          {t(i18nKeys.ui.back)}
        </Button>
        <Button onClick={handleValidate} disabled={!data.verificationCode}>
          {t(i18nKeys.ui.validate)}
        </Button>
      </div>
    </form>
  )
}
