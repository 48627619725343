export type PayoutAccount = {
  id: string
  entity: string
  isFavorite: boolean
  owner: string
  imageSrc?: string
}

export enum PayoutCurrencyType {
  USDollars,
  Cryptocurrency,
}

export enum ServicePaymentTerms {
  TimeOfSale = 'TimeOfSale',
  FiftyFifty = 'FiftyFifty',
  TimeOfService = 'TimeOfService',
  BuyerInspection = 'BuyerInspection',
}
