import React from 'react'

import { IconMapping, type IconSource } from '.'

export interface IconProps {
  /**
   * Icon name
   */
  icon: IconSource

  /**
   * Icon size in pixels (default: 24px)
   */
  size?: number

  /**
   * Icon color (default: black)
   */
  color?: string

  /**
   * Style classes
   */
  className?: string

  /**
   * On click event
   */
  onClick?: React.MouseEventHandler<SVGElement>

  /**
   * Title attribute
   */
  title?: string
}

/**
 * A wrapper to display icons.
 */
export function Icon(props: IconProps) {
  const Element = IconMapping[props.icon]

  if (!Element) {
    return <span>{props.icon}</span>
  }
  return (
    <Element
      size={props.size ?? 24}
      color={props.color ?? '#000000'}
      className={props.className}
      onClick={props.onClick}
      title={props.title}
    />
  )
}
