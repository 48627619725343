import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'

import { Button, Icon } from '@/common/components'
import type { ListProps, StorefrontMenuItem } from '@/common/types'

import { MobileCategoryMenuItem } from './MobileCategoryMenuItem'

export const MobileCategoriesMenu: React.FC<ListProps<StorefrontMenuItem>> = ({
  items,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        className="flex items-center gap-2 w-fit md:hidden self-end"
        onClick={() => setOpen(true)}
      >
        <Icon icon="category" className="size-4 fill-white" />
        <span>Categories</span>
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} className="md:hidden">
        <Dialog.Panel className="fixed z-10 inset-0 w-screen h-screen overflow-y-scroll">
          <div className="w-full h-full flex flex-col gap-6 bg-white px-4 py-8">
            <div className="flex items-center justify-center relative">
              <button
                className="absolute left-0"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
              <h3 className="font-bold text-2xl text-center">Categories</h3>
            </div>

            <div className="flex flex-col divide-y">
              {items.map((item, key) => (
                <MobileCategoryMenuItem
                  key={key}
                  item={item}
                  onClick={() => setOpen(false)}
                />
              ))}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
