import React from 'react'

import { Input } from '@/common/components'

export type FieldType =
  | 'text'
  | 'checkbox'
  | 'radio-group'
  | 'select'
  | 'file'
  | 'number'

export type Option = {
  id: string
  text: string
}

export type FieldProps = {
  name: string
  type: FieldType
  label: string
  placeholder?: string
  options?: Option[]
  value?: string
  className?: string
  disabled?: boolean
  error?: string
  maxLength?: number
  onChange?: (value: string) => void
}

export const TemplateField: React.FC<FieldProps> = ({
  name,
  type,
  label,
  options,
  value,
  placeholder,
  className,
  disabled,
  error,
  maxLength,
  onChange,
}) => {
  // todo: improve each field type implementation and mapping
  const renderField = () => {
    switch (type) {
      case 'text':
        return (
          <Input
            id={name}
            name={name}
            label={label}
            placeholder={placeholder}
            onChange={(v) => onChange && onChange(v.target.value)}
            value={value}
            className={className}
            disabled={disabled}
            error={error}
            maxLength={maxLength}
          />
        )
      case 'checkbox':
        return (
          <input
            type="checkbox"
            checked={value === 'true'}
            onChange={(e) => onChange?.(e.target.checked.toString())}
            disabled={disabled}
          />
        )
      case 'radio-group':
        return (
          <div>
            {options?.map((option) => (
              <label key={option.id}>
                <input
                  type="radio"
                  value={option.id}
                  checked={value === option.id}
                  onChange={(e) => onChange?.(e.target.value)}
                  disabled={disabled}
                />
                {option.text}
              </label>
            ))}
          </div>
        )
      case 'select':
        return (
          <select
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          >
            {options?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            ))}
          </select>
        )
      case 'file':
        return (
          <input
            type="file"
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          />
        )
      case 'number':
        return (
          <input
            type="number"
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          />
        )
      default:
        return null
    }
  }

  return renderField()
}
