import { t } from 'i18next'
import React from 'react'

import { Icon } from '@/common/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

export interface StepperInterface {
  step: number
}

export const stepperProcess = Object.freeze({
  selectAddress: 0,
  selectCarrier: 1,
  selectPayment: 2,
  summary: 3,
})

type BuyProcessStep = {
  index: number
  label: string
  description: string
}

const steps = [
  {
    index: stepperProcess.selectAddress,
    label: 'Address',
    description: 'Select your address for shipping',
  },
  {
    index: stepperProcess.selectCarrier,
    label: 'Shipping',
    description: 'Choose your preferred carrier',
  },
  {
    index: stepperProcess.selectPayment,
    label: 'Payment',
    description: 'Select your payment method',
  },
  {
    index: stepperProcess.summary,
    label: 'Summary',
    description: 'Verify your purchase information',
  },
] as BuyProcessStep[]

// TODO duplicated component on listing feature
export const Stepper: React.FC<StepperInterface> = ({ step }) => {
  return (
    <nav aria-label="Progress">
      {/* TODO: mobile navbar */}
      <ol className="overflow-hidden sm:min-w-40 flex sm:block">
        {steps.map((_step, stepIdx) => {
          let status = 'upcoming'
          if (step === stepIdx) {
            status = 'current'
          } else if (stepIdx < step) {
            status = 'complete'
          }

          return (
            <li
              key={stepIdx}
              className={classNames(
                'relative flex-1 sm:flex-none',
                stepIdx !== steps.length - 1 && 'pb-10',
              )}
            >
              {status === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute left-1/2 sm:left-4 top-4 -ml-px mt-0.5 h-0.5 sm:h-full w-full sm:w-0.5 bg-primary"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="group relative flex items-center sm:items-start flex-col sm:flex-row">
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary-800">
                        <Icon icon="check" size={20} color={colors.surface} />
                      </span>
                    </span>
                    <span className="sm:ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">
                        {t(_step.label)}
                      </span>
                      <span className="hidden sm:block text-sm text-gray-500">
                        {t(_step.description)}
                      </span>
                    </span>
                  </div>
                </>
              ) : status === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute left-1/2 sm:left-4 top-4 -ml-px mt-0.5 h-0.5 sm:h-full w-full sm:w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div
                    className="group relative flex items-center sm:items-start flex-col sm:flex-row"
                    aria-current="step"
                  >
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-primary" />
                      </span>
                    </span>
                    <span className="sm:ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-primary">
                        {t(_step.label)}
                      </span>
                      <span className="hidden sm:block text-sm text-gray-500">
                        {t(_step.description)}
                      </span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute left-1/2 sm:left-4 top-4 -ml-px mt-0.5 h-0.5 sm:h-full w-full sm:w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="group relative flex items-center sm:items-start flex-col sm:flex-row">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="sm:ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">
                        {t(_step.label)}
                      </span>
                      <span className="hidden sm:block text-sm text-gray-500">
                        {t(_step.description)}
                      </span>
                    </span>
                  </div>
                </>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
