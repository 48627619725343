import type { AddressTypeEnum } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React from 'react'

import { Icon } from '@/common/components'
import { HorizontalCard } from '@/common/components/display'
import i18nKeys from '@/common/i18nKeys'
import { colors } from '@/theme'

export interface AddressCardProps {
  name: string
  addressType: AddressTypeEnum
  address: string
  className?: string
  mode?: 'button' | 'card'
  onClick?: () => void | Promise<void>
}

export const AddressCard: React.FC<AddressCardProps> = (props) => {
  return (
    <HorizontalCard
      mode={props.mode}
      image={
        <div className="bg-primary size-6 sm:size-10 rounded-full flex items-center justify-center">
          <Icon
            icon="home"
            color={colors.surface}
            className="size-4 sm:size-7"
          />
        </div>
      }
      title={props.name}
      description={props.address}
      onClick={props.onClick}
      className={props.className}
      action={props.mode === 'button' ? undefined : t(i18nKeys.ui.change)}
    />
  )
}
