import { Combobox as Combo } from '@headlessui/react'
import React, { useCallback, useMemo, useState } from 'react'

import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

import { Icon } from '..'

export type ComboboxOption = {
  id: string
  text: string
}

/*
 * TODO missing props "required" and "error" (optional) in Combobox. The "required"
 * prop should be used to indicate that the field is required. The "error" prop
 * should be a string that indicates the error message to display when the field is invalid.
 */
export interface ComboboxProps {
  options: ComboboxOption[]
  selected?: ComboboxOption
  label: string
  onChange?: (value: ComboboxOption) => void | Promise<void>
  /**
   * Error / validation message. Null to not show an error
   */
  error?: string
}

export const Combobox: React.FC<ComboboxProps> = (props) => {
  const [query, setQuery] = useState('')

  const handleChangeOption = useCallback(
    async (option: ComboboxOption) => props.onChange && props.onChange(option),
    [props.onChange],
  )

  const filtered = useMemo(
    () =>
      query === ''
        ? props.options
        : props.options.filter((option) => {
            return option.text.toLowerCase().includes(query.toLowerCase())
          }),
    [props.options, query],
  )

  return (
    <Combo as="div" value={props.selected} onChange={handleChangeOption}>
      <Combo.Label className="block text-sm font-medium leading-6 text-gray-900">
        {props.label}
      </Combo.Label>
      <div className="relative mt-2">
        <Combo.Input
          className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${!props.error ? 'text-gray-900 focus:shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-primary' : 'text-red-900  ring-red-300 placeholder:text-red-300 focus:ring-red-500'}`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option: ComboboxOption) => option?.text ?? ''}
        />
        <Combo.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <Icon icon="upDown" color={colors.gray[400]} size={20} />
        </Combo.Button>

        {filtered.length > 0 && (
          <Combo.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filtered.map((option) => (
              <Combo.Option
                key={`combobox-option-${option.id}`}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {option.text}
                    </span>

                    <ConditionalRendering renderIf={selected}>
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary',
                        )}
                      >
                        <Icon icon="check" color={colors.surface} />
                      </span>
                    </ConditionalRendering>
                  </>
                )}
              </Combo.Option>
            ))}
          </Combo.Options>
        )}
      </div>
      {!!props.error && (
        <p className="mt-2 text-sm text-red-600" id={`error`}>
          {props.error}
        </p>
      )}
    </Combo>
  )
}
