import { Loader, ThemeProvider } from '@aws-amplify/ui-react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/common/components'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { UserService } from '@/core/users/user.service'
import { colors } from '@/theme'

export const AwsRekognition = () => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sessionData, setSessionData] = useState<{
    sessionId: string
    signature: string
  }>({
    sessionId: '',
    signature: '',
  })
  const [showCustomError, setShowCustomError] = useState<boolean>(false)

  const navigate = useNavigate()

  const userService = new UserService()

  useEffect(() => {
    handleGenerateSession()
  }, [])

  const { error } = useNotify()

  const handleShowError = (msg?: string) => {
    error(msg || t(i18nKeys.common.somethingWentWrong))
  }

  const handleGenerateSession = async () => {
    try {
      const res = await userService.getAWSSessionID()
      setSessionData(res)
    } catch (err) {
      setShowCustomError(true)
      handleShowError()
    } finally {
      setLoading(false)
    }
  }

  const handleAnalysisComplete = async () => {
    const res = await userService.postSignatureID(sessionData)
    if (res) {
      if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            statusCode: 200,
            status: 'success',
            result: sessionData,
          }),
        )
      } else {
        navigate('/')
      }
    } else {
      setShowCustomError(true)
      handleGetError({ error: 'something went wrong' })
    }
  }

  const handleGetError = async (error: any) => {
    setShowCustomError(true)
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ statusCode: 400, status: 'error', error }),
      )
    }
  }

  const handleRetry = async () => {
    setShowCustomError(false)
    handleGenerateSession()
  }

  return (
    <ThemeProvider key={sessionData?.sessionId}>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          disableStartScreen={true}
          sessionId={sessionData.sessionId}
          region="us-east-1"
          onUserCancel={() => setShowCustomError(true)}
          onAnalysisComplete={handleAnalysisComplete}
          displayText={{
            tryAgainText: 'OK',
            serverMessageText: '',
            serverHeaderText: 'Something went wrong, please try again',
          }}
          onError={(error) => {
            handleGetError(error)
          }}
        />
      )}
      {showCustomError ? (
        <div className="text-center mt-20">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-2xl w-[270px]"
            textColor={colors.surface}
            onClick={handleRetry}
          >
            Try again
          </Button>
        </div>
      ) : null}
    </ThemeProvider>
  )
}
