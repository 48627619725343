import { AddressTypeEnum } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type {
  AddressAutoCompleteResult,
  ComboboxOption,
} from '@/common/components'
import {
  AddressAutoComplete,
  Button,
  Combobox,
  Input,
} from '@/common/components'
import {
  CountriesAsString,
  ECountryCode,
  StatesAsString,
} from '@/common/constants/countries'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'

// TODO this should be a generic component that can be reused for adding/modifying addresses.
// TODO check if country should be a dropdown where the user can select the country from a list of countries

// We cannot use "t" outside a component, so we have to pass it as a parameter
const addressTextToTypeMapping = (translator: typeof t) => {
  return {
    [translator(i18nKeys.onboarding.address.home)]: AddressTypeEnum.Home,
    // TODO missing type 'Billing' in library. Ask if it is something that has to be added into the library or removed from here
    [translator(i18nKeys.onboarding.address.billing)]: AddressTypeEnum.Others,
    [translator(i18nKeys.onboarding.address.office)]: AddressTypeEnum.Office,
    [translator(i18nKeys.onboarding.address.warehouse)]:
      AddressTypeEnum.Warehouse,
    [translator(i18nKeys.onboarding.address.company)]: AddressTypeEnum.Company,
    [translator(i18nKeys.onboarding.address.factory)]: AddressTypeEnum.Factory,
    [translator(i18nKeys.onboarding.address.others)]: AddressTypeEnum.Others,
  }
}

// We cannot use "t" outside a component, so we have to pass it as a parameter
const ADDRESS_TYPE_OPTIONS = (translator: typeof t): ComboboxOption[] => {
  return [
    {
      id: AddressTypeEnum.Home,
      text: translator(i18nKeys.onboarding.address.home),
    },
    {
      id: 'Billing',
      text: translator(i18nKeys.onboarding.address.billing),
    }, // TODO missing type in library
    {
      id: AddressTypeEnum.Office,
      text: translator(i18nKeys.onboarding.address.office),
    },
    {
      id: AddressTypeEnum.Warehouse,
      text: translator(i18nKeys.onboarding.address.warehouse),
    },
    {
      id: AddressTypeEnum.Company,
      text: translator(i18nKeys.onboarding.address.company),
    },
    {
      id: AddressTypeEnum.Factory,
      text: translator(i18nKeys.onboarding.address.factory),
    },
    {
      id: AddressTypeEnum.Others,
      text: translator(i18nKeys.onboarding.address.others),
    },
  ]
}

const USA_ID = 'United States'

const countryOptions = CountriesAsString.map((country: string) => ({
  id: country,
  text: country,
}))

const stateOptionsUSA = StatesAsString.map((country: string) => ({
  id: country,
  text: country,
}))

const defaultCountry =
  countryOptions.find((i) => i.id === USA_ID) ?? countryOptions[0]

const selectAddressTypeOptionFromValue = (
  type?: string,
): ComboboxOption | undefined => {
  if (!type) {
    return undefined
  }
  return ADDRESS_TYPE_OPTIONS(t).find((option) => option.text === type)
}

const selectCountryOptionFromValue = (
  country?: string,
): ComboboxOption | undefined => {
  if (!country) {
    return undefined
  }
  return countryOptions.find((option) => option.text === country)
}

export interface AddressInformationFormValues {
  name?: string
  country?: string
  state?: string
  city?: string
  addressLine1?: string
  addressLine2?: string
  type?: string
  zip?: string
}

export interface AddressInformationFormResult {
  name: string
  country: string
  state: string
  city: string
  // TODO: remove `address`
  address: string
  addressLine1: string
  addressLine2?: string
  type: AddressTypeEnum
  zip: string
}

export type AddressInformationProps = {
  defaultValues?: AddressInformationFormValues
  onClose: Function
  onSave: Function
}

function includesLetter(str: string) {
  const regex = /[a-zA-Z]/
  return regex.test(str)
}

const AddressInformation: React.FC<AddressInformationProps> = ({
  defaultValues,
  onClose,
  onSave,
}) => {
  const { error } = useNotify()

  const skipStateUpdate = useRef(true)

  const [values, setValues] = useState(
    defaultValues || {
      address: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: ECountryCode.UnitedStates,
      name: '',
      state: '',
      zip: '',
    },
  )
  const [addressTypeOption, setAddressTypeOption] = useState<ComboboxOption>(
    selectAddressTypeOptionFromValue(defaultValues?.type) ||
      ADDRESS_TYPE_OPTIONS(t)[0],
  )

  const [countryOption, setCountryOption] = useState<ComboboxOption>(
    selectCountryOptionFromValue(defaultValues?.country) || defaultCountry,
  )

  const isUSA = useMemo(() => countryOption.id === USA_ID, [countryOption])

  const stateOption = useMemo(() => {
    if (!isUSA) {
      return
    }

    const state = values.state

    if (!state) {
      return
    }

    return { id: state, text: state }
  }, [isUSA, values.state])

  const handleSave = useCallback(
    (formEvent: React.FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault()
      const requiredValues = [
        { name: 'Name', value: values.name?.trim() ?? '', type: 'text' },
        { name: 'City', value: values.city?.trim() ?? '', type: 'text' },
        {
          name: 'Address Line 1',
          value: values.addressLine1?.trim() ?? '',
          type: 'text',
        },
        { name: 'Zip', value: values.zip?.trim() ?? '', type: 'number' },
        { name: 'State', value: values.state?.trim() ?? '', type: 'text' },
      ]

      for (const field of requiredValues) {
        if (!field.value) {
          error(`Field '${field.name}' is required.`)
          return
        }

        if (field.type === 'text' && !includesLetter(field.value)) {
          error(`Field '${field.name}' must contain at least one letter.`)
          return
        }
      }

      const valuesAsResult: AddressInformationFormResult = {
        address: values.addressLine1 || '',
        addressLine1: values.addressLine1 || '',
        addressLine2: values.addressLine2 || '',
        city: values.city || '',
        // We can ensure a value since we are using options from the expected enum
        country: countryOption.id,
        name: values.name || '',
        state: values.state || '',
        zip: values.zip || '',
        type: addressTextToTypeMapping(t)[addressTypeOption.text],
      } as any

      onSave(valuesAsResult)
    },
    [values, addressTypeOption, countryOption, onSave],
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues((prev: object) => ({ ...prev, [name]: value }))
  }

  const handleAddressAutoCompleteChange = (
    value: AddressAutoCompleteResult,
  ) => {
    setValues((prev: object) => ({
      ...prev,
      addressLine1:
        `${value.streetNumber || ''} ${value.streetName || ''}`.trim() || '',
      city: value.city || '',
      state: value.state || '',
      zip: value.zipCode || '',
    }))

    if (value.country) {
      const countryOption = selectCountryOptionFromValue(value.country)
      if (countryOption) {
        setCountryOption(countryOption)
      }
    }
  }

  useEffect(() => {
    if (skipStateUpdate.current) {
      skipStateUpdate.current = false
      return
    }
    setValues({ ...values, state: '' })
  }, [countryOption])

  return (
    <form onSubmit={handleSave}>
      <div className="space-y-12 min-h-[400px]">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <AddressAutoComplete
              onChange={handleAddressAutoCompleteChange}
              label={t(i18nKeys.onboarding.address.searchYourAddress)}
            />
          </div>
        </div>
        <hr></hr>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <Input
              label={t(i18nKeys.onboarding.address.name)}
              name="name"
              onChange={handleChange}
              placeholder={t(i18nKeys.onboarding.address.namePlaceholder)}
              required
              value={values.name}
            />
          </div>

          <div className="sm:col-span-3">
            <Combobox
              label={t(i18nKeys.onboarding.address.country)}
              options={countryOptions}
              onChange={setCountryOption}
              selected={countryOption}
            />
          </div>

          <div className="sm:col-span-3">
            {isUSA ? (
              <Combobox
                label={t(i18nKeys.onboarding.address.state)}
                options={stateOptionsUSA}
                onChange={(value) => setValues({ ...values, state: value.id })}
                selected={stateOption}
              />
            ) : (
              <Input
                label={t(i18nKeys.onboarding.address.state)}
                name="state"
                onChange={handleChange}
                placeholder={t(i18nKeys.onboarding.address.statePlaceholder)}
                required
                value={values.state}
              />
            )}
          </div>

          <div className="col-span-full">
            <Input
              label={t(i18nKeys.onboarding.address.city)}
              name="city"
              onChange={handleChange}
              placeholder={t(i18nKeys.onboarding.address.cityPlaceholder)}
              required
              value={values.city}
            />
          </div>

          <div className="col-span-full">
            <Input
              label="Address line 1"
              name="addressLine1"
              onChange={handleChange}
              placeholder="Combination of number + street name"
              required
              value={values.addressLine1}
            />
          </div>

          <div className="col-span-full">
            <Input
              label="Address line 2"
              name="addressLine2"
              onChange={handleChange}
              placeholder="Apartment, block, entrance, floor or other details"
              value={values.addressLine2}
            />
          </div>
          <div className="sm:col-span-3">
            <Combobox
              label={t(i18nKeys.onboarding.address.type)}
              onChange={setAddressTypeOption}
              options={ADDRESS_TYPE_OPTIONS(t)}
              selected={addressTypeOption}
            />
          </div>

          <div className="sm:col-span-3">
            <Input
              label={t(i18nKeys.onboarding.address.zip)}
              name="zip"
              onChange={handleChange}
              placeholder={t(i18nKeys.onboarding.address.zipPlaceholder)}
              required
              value={values.zip}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <Button mode="text" onClick={() => onClose()}>
          {t(i18nKeys.ui.back)}
        </Button>
        <Button mode="contained" type="submit">
          {t(i18nKeys.ui.save)}
        </Button>
      </div>
    </form>
  )
}

export default AddressInformation
