import { t } from 'i18next'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Icon, Input, Switch } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { authContext } from '@/common/context/auth_context'
import { useForm, useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import type { PaymentCard } from '@/core'
import { UserService } from '@/core'

import { AddPaymentCard } from '../components/AddPaymentCard'
import { EditPaymentCard } from '../components/EditPaymentCard'

// TODO missing translations
export function AddPaymentCardPage() {
  const { error, success, info } = useNotify()
  const [params] = useSearchParams()
  const cardId = useMemo(() => params.get('cardId'), [params])
  const isEdit = useMemo(() => Boolean(cardId), [cardId])
  const [editCard, setEditCard] = useState<PaymentCard>()
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigate()
  const { profile, mainAddress, buyerProfile } = useContext(authContext)

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined

    const segments = [profile.firstName, profile.middleName, profile.lastName]

    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])
  const billingAddress = mainAddress

  const handlePaymentComplete = async (result: TokenizationResponse) => {
    try {
      setIsLoading(true)
      const userService = new UserService()
      await userService.createCard(buyerProfile?.id!, {
        isDefault: false,
        profileId: buyerProfile?.id!,
        paymentToken: result.paymentHandleToken,
      })

      navigation('/profile/chainItPay')
    } catch (e) {
      Logger.error('Failed to add a card', undefined, e as Error)
      error('Failed to add a card.')
    } finally {
      setIsLoading(true)
    }
  }

  const handlePaymentFailed = (errorMessage?: string) => {
    Logger.error(
      errorMessage ?? 'Failed to add a card. No error message provided.',
    )
    error(errorMessage ?? 'Failed to add a card.')
  }

  const fetchCard = async () => {
    try {
      const userService = new UserService()
      const card = await userService.getCardById(cardId!, buyerProfile?.id!)
      setEditCard(card)
    } catch (e) {
      error('Failed to fetch card.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteCard = async () => {
    try {
      setIsLoading(true)
      const userService = new UserService()
      await userService.removeCard(cardId!, buyerProfile?.id!)
      success('Card deleted successfully.')
    } catch (e) {
      Logger.error('Failed to delete card', undefined, e as Error)
      error('Failed to delete card.')
    } finally {
      // TODO delete card always throws an error, even on success
      setIsLoading(false)
      navigation('/profile/chainItPay')
    }
  }

  const handleSetFavorite = async () => {
    info('Feature coming soon')
  }

  useEffect(() => {
    if (!cardId || !buyerProfile) return

    fetchCard()
  }, [cardId])

  return (
    <>
      <Link
        to="/profile/chainItPay"
        className="flex items-center gap-2 text-gray-400 font-semibold mt-6"
      >
        <Icon icon="arrowRight" className="fill-gray-400 rotate-180 size-4" />
        <span>{t(i18nKeys.ui.back)}</span>
      </Link>
      <div className="bg-white px-6 py-8 rounded-md flex flex-col gap-4 mt-2">
        <div className="flex flex-col gap-6 max-w-lg self-center w-full">
          <h2 className="font-semibold text-xl">
            {isEdit ? 'Edit Card' : 'Add card'}
          </h2>

          {!isEdit && cardHolderName && billingAddress ? (
            <AddPaymentCard
              cardHolderName={cardHolderName}
              billingAddress={billingAddress}
              onPaymentComplete={handlePaymentComplete}
              onPaymentFailed={handlePaymentFailed}
              submitText="Save card"
            />
          ) : null}

          {isEdit && editCard ? (
            <EditPaymentCard
              card={editCard}
              onDelete={handleDeleteCard}
              onClickFavorite={handleSetFavorite}
              isLoading={isLoading}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export const AddCardForm = () => {
  const { data, handleChangeInput, handleChangeField } = useForm({
    cardNumber: '',
    cardHolder: '',
    expirationDate: '',
    cvv: '',
    favorite: false,
  })

  return (
    <div className="flex flex-col gap-6 max-w-lg self-center">
      <h2 className="font-semibold text-xl">Add payment</h2>
      <Switch
        label="Favorite payment"
        enabled={data.favorite}
        onChange={(v) => handleChangeField('favorite', v)}
      />
      <Input
        label="Name on card"
        id="cardHolder"
        name="cardHolder"
        value={data.cardHolder}
        onChange={handleChangeInput}
      />
      <Input
        label="Card number"
        id="cardNumber"
        name="cardNumber"
        value={data.cardNumber}
        onChange={handleChangeInput}
      />
      <div className="flex items-center gap-4">
        <Input
          label="Valid until"
          id="expirationDate"
          name="expirationDate"
          value={data.expirationDate}
          onChange={handleChangeInput}
          type="month"
          className="w-full"
        />
        <Input
          label="CVV"
          id="cvv"
          name="cvv"
          value={data.cvv}
          onChange={handleChangeInput}
          className="w-full"
        />
      </div>
      <Button>Save payment</Button>
    </div>
  )
}
