import type { PopupRequest } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, useMsal } from '@azure/msal-react'
import React, { forwardRef, useImperativeHandle } from 'react'

import { Scopes } from '@/common/constants'
import envVariables from '@/common/envVariables'

const msalConfig = {
  auth: {
    clientId: envVariables.ONE_DRIVE_CLIENT_ID,
    authority: envVariables.ONE_DRIVE_AUTHORITY_URL,
    redirectUri: `${window.location.origin}/wallet`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

const loginRequest: PopupRequest = {
  scopes: Scopes.ONE_DRIVE_SCOPES,
}

const msalInstance = new PublicClientApplication(msalConfig)

interface OneDriveFileUploaderProps {
  onAccessToken: (token: string) => void
}

interface OneDriveFileUploaderHandles {
  handleGetMicrosoftAccessToken: () => void
}

const OneDriveFileUploader = forwardRef<
  OneDriveFileUploaderHandles,
  OneDriveFileUploaderProps
>((props, ref) => {
  const { instance } = useMsal()

  const handleGetMicrosoftAccessToken = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest)
      props.onAccessToken(loginResponse.accessToken)
    } catch (e) {
      props.onAccessToken('failed')
    }
  }

  useImperativeHandle(ref, () => ({
    handleGetMicrosoftAccessToken,
  }))

  return null
})

OneDriveFileUploader.displayName = 'OneDriveFileUploader'

interface AuthOneDriveProps {
  onAccessToken: (token: string) => void
}

export const AuthOneDrive = forwardRef<
  OneDriveFileUploaderHandles,
  AuthOneDriveProps
>((props, ref) => (
  <MsalProvider instance={msalInstance}>
    <OneDriveFileUploader ref={ref} onAccessToken={props.onAccessToken} />
  </MsalProvider>
))

AuthOneDrive.displayName = 'AuthOneDrive'
