import { t } from 'i18next'
import React, { useState } from 'react'

import { Icon } from '@/common/components'
import { IncodeIntegration } from '@/common/components/Incode/IncodeIntegration'
import envVariables from '@/common/envVariables'
import i18nKeys from '@/common/i18nKeys'
import ChainITLogo from '@assets/icons/chainIT_marketplace_logo.svg'
import LicenseScan from '@assets/icons/license_scan.svg'

export interface IdentityVerificationProps {
  setRenderIdentityVerification: Function
}

export const IdentityVerification: React.FC<IdentityVerificationProps> = (
  props,
) => {
  const [cameraRenderType, setCameraRenderType] = useState<string>('')
  const [openIncodeIntegration, setOpenIncodeIntegration] =
    useState<boolean>(false)

  const handleOnContinueThisDevice = () => {
    setCameraRenderType('desktop')
    setOpenIncodeIntegration(true)
  }

  const handleOnContinueOtherDevice = () => {
    setCameraRenderType('mobile')
    setOpenIncodeIntegration(true)
  }

  return (
    <>
      {openIncodeIntegration ? (
        <div className="bg-white">
          <IncodeIntegration
            onClose={() => setOpenIncodeIntegration(false)}
            onDocumentVerified={() => setOpenIncodeIntegration(false)}
            renderType={cameraRenderType}
            incodeFlowId={envVariables.INCODE_DOCUMENT_UPLOAD_FLOW_ID}
            onBack={() => props.setRenderIdentityVerification()}
          />
        </div>
      ) : (
        <StartFaceScan
          onContinueThisDevice={handleOnContinueThisDevice}
          onContinueOtherDevice={handleOnContinueOtherDevice}
          onBack={() => props.setRenderIdentityVerification()}
        />
      )}
    </>
  )
}

type StartFaceScanProps = {
  onContinueThisDevice: () => void
  onContinueOtherDevice: () => void
  onBack: () => void
}

const StartFaceScan: React.FC<StartFaceScanProps> = (props) => {
  return (
    <div className="max-w-md mx-auto">
      <button
        onClick={props.onBack}
        className="flex items-center gap-2 text-gray-400 font-semibold mt-6"
      >
        <Icon icon="arrowRight" className="fill-gray-400 rotate-180 size-4" />
        <span>{t(i18nKeys.ui.back)}</span>
      </button>
      <div className="bg-white p-6 rounded-lg mt-2">
        <div className="max-w-72 mx-auto flex flex-col items-center text-center gap-4">
          <img className="w-40" src={ChainITLogo} alt="ChainIT Logo"></img>

          <h2 className="font-medium text-2xl">Verify ID</h2>

          <p className="font-light text-sm">
            Scan your driver license to verify your identity. You will need a
            camera to proceed.
          </p>

          <img className="my-12" src={LicenseScan} alt=""></img>

          <button
            className="bg-primary p-2 w-64 text-base text-white rounded-lg font-medium"
            onClick={props.onContinueThisDevice}
          >
            {t(i18nKeys.onboarding.personalInformation.continueOnThisDevice)}
          </button>
          <button
            className="text-primary m-3 text-base font-medium"
            onClick={props.onContinueOtherDevice}
          >
            {t(i18nKeys.onboarding.personalInformation.continueOnAnotherDevice)}
          </button>
        </div>
      </div>
    </div>
  )
}
