import React from 'react'

import type { Address } from '@/common/types'
import type { AddressInformationFormResult } from '@/pages/Onboarding/PersonalInformation/AddressInformation'
import AddressInformation from '@/pages/Onboarding/PersonalInformation/AddressInformation'

export type AddEditAddressProps = {
  setShowAddressForm: (e: boolean) => void
  isAddAddress: boolean
  addresses: Address[]
  locationId: string
  submitAddressForm: (address: AddressInformationFormResult) => Promise<void>
}

export const AddEditAddress: React.FC<AddEditAddressProps> = (props) => {
  return (
    <>
      <h2 className="text-2xl">
        {props.isAddAddress ? 'Create address' : 'Update address'}
      </h2>
      {/* TODO add translations */}
      <p className="mt-2 text-gray-500 text-sm tracking-tight mb-12">
        {props.isAddAddress
          ? 'Enter the details of the new address where you want your purchases to be shipped. Make sure to provide accurate information, including the street name, city, state, and zip code, to ensure successful delivery.'
          : "Update the details of your existing shipping address. Make sure all information is current and accurate to ensure your purchases reach the right destination. Don't forget to save your changes when you're done."}
      </p>
      <AddressInformation
        onClose={() => props.setShowAddressForm(false)}
        onSave={props.submitAddressForm}
        defaultValues={
          !props.isAddAddress
            ? props.addresses.find((x) => x.id === props.locationId)
            : undefined
        }
      />
    </>
  )
}
