import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { TaxCategory } from '@/common/types/taxes'
import { AssetsService } from '@/core'

import type { TypeaheadOption } from '..'
import { Typeahead } from '..'

export interface TaxCodeAutocompleteProps {
  label?: string
  selected?: TaxCategory
  onChange?: (selected: TaxCategory) => void | Promise<void>
  className?: string
}

const mapTaxCategoryOption = (category?: TaxCategory) =>
  category
    ? ({ id: category.taxCode, text: category.name } as TypeaheadOption)
    : undefined

const assetService = new AssetsService()

export const TaxCodeAutocomplete: React.FC<TaxCodeAutocompleteProps> = (
  props,
) => {
  const defaultLabel = 'Tax code' // todo: add translation

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<TaxCategory[]>([])

  const options = useMemo(
    () => items.map(mapTaxCategoryOption) as TypeaheadOption[],
    [items],
  )

  const selectedOption = useMemo(
    () => mapTaxCategoryOption(props.selected),
    [props.selected],
  )

  const handleChange = useCallback(
    (value: TypeaheadOption) => {
      if (props.onChange) {
        props.onChange(items.find((i) => i.taxCode === value.id)!)
      }
    },
    [props.onChange, items],
  )

  const handleChangeFilter = useCallback(
    async (search: string) => {
      setIsLoading(true)
      const _categories = await assetService.getTaxCategories({
        search,
      })
      setIsLoading(false)
      setItems(_categories.items)
    },
    [setIsLoading, setItems],
  )

  useEffect(() => {
    handleChangeFilter('')
  }, [])

  return (
    <Typeahead
      label={props.label ?? defaultLabel}
      loading={isLoading}
      onChange={handleChange}
      onFilterChange={handleChangeFilter}
      options={options}
      selected={selectedOption}
      className={props.className}
    />
  )
}
