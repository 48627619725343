import {
  SellingMethodEnum,
  type Bid,
  type PagedResponse,
} from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'

import type { IconSource } from '@/common/components'
import { Button, Icon } from '@/common/components'
import { EAuctionStatus } from '@/common/components/productInformation'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import type {
  CustomerAssetWithPrice,
  ListAssetParams,
  UserBidHistoryRequestInterface,
  UserBidPaginatedRequestInterface,
} from '@/core'
import { ListingService, classNames, translate } from '@/core'

import { BidsList } from '../components/bidTracking/BidsList'
import type { BidWithAddress } from '../components/bidTracking/BidsPaginatedHistory'
import { BidsPaginatedHistory } from '../components/bidTracking/BidsPaginatedHistory'

import { AuctionDetails } from './AuctionDetails'

const tabs = [
  { name: 'My Bids', value: 0, icon: 'idCard' },
  { name: 'My Auctions', value: 1, icon: 'rectangleList' },
]

export const AuctionHistoryPage: React.FC = () => {
  const { profile } = useContext(authContext)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [bids, setBids] =
    useState<PagedResponse<BidWithAddress | CustomerAssetWithPrice>>()
  const { error } = useNotify()
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [selectedFiltersSellingMethod, setSelectedFiltersSellingMethod] =
    useState<SellingMethodEnum | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<string | undefined>(
    undefined,
  )
  const [selectedBid, setSelectedBid] = useState<string>()
  const [bidHistory, setBidHistory] = useState<PagedResponse<Bid>>()
  const [openBidDetails, setOpenBidDetails] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    searchWithFilters()
  }, [profile, selectedTab])

  useEffect(() => {
    searchBidsPaginatedFilters()
  }, [selectedBid])

  const searchBidsPaginatedFilters = async () => {
    if (!selectedBid) {
      setBidHistory(undefined)
      return
    }
    let options: UserBidPaginatedRequestInterface
    if (selectedTab === 0) {
      options = {
        $page: 1,
        $perPage: 10,
        listingId: selectedBid,
        createdBy: profile?.userId,
        $orderBy: 'createdAt',
        $order: 'desc',
      } as UserBidPaginatedRequestInterface
    } else {
      options = {
        $page: 1,
        $perPage: 10,
        listingId: selectedBid,
        $orderBy: 'createdAt',
        $order: 'desc',
      } as UserBidPaginatedRequestInterface
    }

    const bids = await ListingService.getBidsPaginated(options)
    setBidHistory(bids)
  }

  const handleFetchMyCollection = async (page: number = 1) => {
    if (!profile?.userId) {
      return
    }

    try {
      const options = {
        createdBy: profile.userId,
        $page: page,
      } as ListAssetParams

      if (selectedFiltersSellingMethod) {
        options.sellingMethod = selectedFiltersSellingMethod
      }

      if (selectedFilters) {
        options.auctionStatus = selectedFilters
      }

      const bids = await ListingService.getListingsList(options)

      setBids(bids)
    } catch (err) {
      // TODO check if the message is correct
      Logger.error('Failed to fetch my collection', undefined, err as Error)
      error('Failed to fetch my collection')
    }
  }

  const handleFetchHistory = async (page: number = 1) => {
    if (!profile?.userId) {
      return
    }

    try {
      const options = {
        createdBy: profile.userId,
        page: page,
      } as UserBidHistoryRequestInterface

      if (selectedFiltersSellingMethod) {
        options.sellingMethod = selectedFiltersSellingMethod
      }

      if (selectedFilters) {
        options.auctionStatus = selectedFilters
      }

      const bids = await ListingService.getAllbidsFromUser(options)

      setBids(bids)
    } catch (err) {
      // TODO check if the message is correct
      Logger.error('Failed to fetch bid history', undefined, err as Error)
      error('Failed to fetch bid history')
    }
  }

  const handleSelectTab = (selected: number) => {
    setSelectedBid(undefined)
    setBids(undefined)
    setBidHistory(undefined)
    setSelectedFilters(undefined)
    setSelectedFiltersSellingMethod(undefined)
    setSelectedTab(selected)
    setOpenBidDetails(undefined)
  }

  const handleSelectFiltersSellingMethod = (selected: SellingMethodEnum) => {
    if (selectedFiltersSellingMethod === selected) {
      setSelectedFiltersSellingMethod(undefined)
    } else {
      setSelectedFiltersSellingMethod(selected)
    }
  }

  const handleSelectFilters = (selected: string) => {
    if (selectedFilters === selected) {
      setSelectedFilters(undefined)
    } else {
      setSelectedFilters(selected)
    }
  }

  const searchWithFilters = () => {
    if (selectedTab === 0) {
      handleFetchHistory()
    } else {
      handleFetchMyCollection()
    }
  }

  const resetFilters = () => {
    setSelectedFilters(undefined)
    setSelectedFiltersSellingMethod(undefined)
  }

  return (
    <div className="flex">
      <aside
        id="default-sidebar"
        className="-mt-5 top-0 left-0 z-40 w-64 h-screen"
        aria-label="Sidebar"
      >
        <div className="h-full overflow-y-auto pt-5">
          <ul className="space-y-2 font-medium">
            {tabs.map((tab, key) => (
              <li key={key}>
                <button
                  key={tab.name}
                  onClick={() => handleSelectTab(tab.value)}
                  className={classNames(
                    tab.value === selectedTab
                      ? 'border-gray-200 bg-gray-200 '
                      : 'border-transparent hover:border-gray-300 hover:text-gray-700',
                    'w-full px-1 py-4 text-center text-md font-medium',
                  )}
                  aria-current={tab.value === selectedTab ? 'page' : undefined}
                >
                  <span className="flex">
                    <Icon className="mx-3" icon={tab.icon as IconSource} />{' '}
                    {tab.name}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </aside>
      <ConditionalRendering renderIf={!openBidDetails}>
        <section
          id="auctionList"
          className="mx-auto w-3/5 sm:px-2 lg:px-8 bg-white py-6 sm:py-8 "
        >
          <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0 flex justify-between">
            <div className="max-w-2xl px-4 lg:max-w-4xl lg:px-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t(i18nKeys.bid.title)}
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                {t(i18nKeys.bid.description)}
              </p>
            </div>
            <Icon
              onClick={() => setOpenFilterModal(true)}
              icon={'filter'}
              className="my-auto mx-3 text-lg cursor-pointer"
            />
          </div>

          <div className="mt-16">
            <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
              <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
                {bids ? (
                  <BidsList
                    {...bids}
                    onSearch={async (page: number) =>
                      handleFetchHistory(page + 1)
                    }
                    selectedTab={selectedTab}
                    selectedBid={selectedBid}
                    setSelectedBid={setSelectedBid}
                    setOpenBidDetails={setOpenBidDetails}
                  />
                ) : (
                  <Spinner containerClassName="flex items-center justify-center py-12" />
                )}
              </div>
            </div>
          </div>
        </section>
      </ConditionalRendering>
      <ConditionalRendering renderIf={openBidDetails !== undefined}>
        <AuctionDetails
          setOpenBidDetails={setOpenBidDetails}
          openBidDetails={openBidDetails}
          userId={selectedTab === 0 ? profile?.userId : undefined}
        />
      </ConditionalRendering>
      <ConditionalRendering renderIf={!openBidDetails}>
        <section
          id="auctionList"
          className="mx-auto w-1/6 sm:px-2 lg:px-8 bg-white py-6 sm:py-8 "
        >
          <BidsPaginatedHistory
            bids={bidHistory}
            setOpenBidDetails={setOpenBidDetails}
            selectedBid={selectedBid}
            selectedTab={selectedTab}
          />
        </section>
      </ConditionalRendering>

      <Modal open={openFilterModal} onClose={() => setOpenFilterModal(false)}>
        <div className="flex flex-col">
          <h2 className="text-xl font-bold ">
            {translate((i) => i.bid.filterBids)}
          </h2>

          <h3 className="font-medium mt-6">
            {translate((i) => i.bid.auctionType)}
          </h3>
          <div className="text-sm mt-2 flex">
            <Button
              mode={
                selectedFiltersSellingMethod === SellingMethodEnum.Auction
                  ? 'contained'
                  : 'outline'
              }
              type="button"
              textColor="white"
              className="rounded-[16px]"
              onClick={() =>
                handleSelectFiltersSellingMethod(SellingMethodEnum.Auction)
              }
            >
              {translate((i) => i.bid.timed)}
            </Button>
            <Button
              mode={
                selectedFiltersSellingMethod === SellingMethodEnum.Dutch
                  ? 'contained'
                  : 'outline'
              }
              type="button"
              textColor="white"
              className="rounded-[16px] mx-3"
              onClick={() =>
                handleSelectFiltersSellingMethod(SellingMethodEnum.Dutch)
              }
            >
              {translate((i) => i.bid.dutch)}
            </Button>
            <Button
              mode={
                selectedFiltersSellingMethod === SellingMethodEnum.Inverse
                  ? 'contained'
                  : 'outline'
              }
              type="button"
              textColor="white"
              className="rounded-[16px]"
              onClick={() =>
                handleSelectFiltersSellingMethod(SellingMethodEnum.Inverse)
              }
            >
              {translate((i) => i.bid.inverse)}
            </Button>
          </div>
          <h3 className="font-medium mt-6">{translate((i) => i.bid.status)}</h3>
          <div className="text-sm mt-2 flex">
            <Button
              mode={
                selectedFilters === EAuctionStatus.Available
                  ? 'contained'
                  : 'outline'
              }
              type="button"
              textColor="white"
              className="rounded-[16px]"
              onClick={() => handleSelectFilters(EAuctionStatus.Available)}
            >
              {translate((i) => i.bid.active)}
            </Button>
            <Button
              mode={
                selectedFilters === EAuctionStatus.BidAccepted
                  ? 'contained'
                  : 'outline'
              }
              type="button"
              textColor="white"
              className="rounded-[16px] ml-3"
              onClick={() => handleSelectFilters(EAuctionStatus.BidAccepted)}
            >
              {translate((i) => i.bid.finished)}
            </Button>
          </div>
        </div>
        <div className="flex w-full justify-between mt-20">
          <Button mode={'text'} type="button" onClick={() => resetFilters()}>
            {translate((i) => i.common.resetFilters)}
          </Button>
          <Button
            mode={'contained'}
            type="button"
            onClick={() => searchWithFilters()}
          >
            {translate((i) => i.common.apply)}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
