import { useEffect, useMemo, useState } from 'react'

import { ListingService } from '@/core'

import { useBeingIDCheck } from '.'

export const useLevelCheck = (listingId?: string) => {
  const { allowed, currentLevel, required, loading } = useBeingIDCheck()

  const [requiredLevel, setRequiredLevel] = useState<number>()

  const fetchListing = async () => {
    if (!listingId) return
    const listing = await ListingService.getListingById(listingId)
    setRequiredLevel(listing?.buyerBeingId)
  }

  const purchaseAllowed = useMemo(() => {
    return requiredLevel && currentLevel && currentLevel <= requiredLevel
  }, [requiredLevel, currentLevel])

  useEffect(() => {
    if (!listingId) return

    fetchListing()
  }, [listingId])

  return {
    purchaseAllowed: allowed && purchaseAllowed,
    loading: !requiredLevel || loading,
    requiredLevel: Math.min(required, requiredLevel ?? required),
    currentLevel,
  }
}
