import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { Icon } from '../../../common/components'
import i18nKeys from '../../../common/i18nKeys'
import { colors } from '../../../theme'

export interface OnboardingStepperProps {
  initialStep: number
}

export const OnboardingStepper: React.FC<OnboardingStepperProps> = ({
  initialStep,
}) => {
  const [step, setStep] = useState<number>(initialStep)

  useEffect(() => {
    setStep(initialStep)
  }, [initialStep])

  return (
    <div className="w-[432px] flex my-0 mx-auto relative p-3">
      <div>
        <div className="border border-primary rounded-[50%] p-1 size-[59px] text-xl text-primary m-auto">
          <div className="border-[5px] border-primary rounded-[50%] p-2 size-[49px]">
            <Icon
              icon="lock"
              color={colors.primary.DEFAULT}
              className="m-auto"
              size={20}
            />
          </div>
        </div>
        <span className="text-xs text-gray-400">
          {t(i18nKeys.onboarding.steps.credentials)}
        </span>
      </div>

      <hr
        className={`align-middle w-1/5 inline-block border border-dashed my-[8%] mx-[11px] ${step >= 2 ? 'border-primary' : 'border-gray-400'}`}
      />

      <div>
        <div
          className={`border rounded-[50%] p-1 size-[59px] text-xl m-auto ${step >= 2 ? 'text-primary border-primary' : 'text-gray-400  border-gray-400'}`}
        >
          <div
            className={`border-[5px] rounded-[50%] p-2 size-[49px] ${step >= 2 ? 'border-primary' : 'border-gray-400'}`}
          >
            <div
              className={`border-2  border-dashed  rounded-lg w-[26px] py-px px-0 -ms-px -mt-px ${step >= 2 ? 'border-primary' : 'border-gray-400'}`}
            >
              <Icon
                icon="faceScan"
                className="m-auto"
                color={step >= 2 ? colors.primary.DEFAULT : colors.disabled}
                size={20}
              />
            </div>
          </div>
        </div>
        <span className="text-xs text-gray-400">
          {t(i18nKeys.onboarding.steps.faceScan)}
        </span>
      </div>

      <hr
        className={`align-middle w-1/5 inline-block border border-dashed my-[8%] mx-[11px] ${step >= 3 ? 'border-primary' : 'border-gray-400'}`}
      />

      <div className="text-center">
        <div
          className={`border  rounded-[50%] p-1 size-[59px] text-xl  m-auto ${step >= 3 ? 'border-primary text-primary' : 'border-gray-400 text-gray-400'}`}
        >
          <div
            className={`border-[5px] rounded-[50%] p-2 size-[49px] ${step >= 3 ? 'border-primary' : 'border-gray-400'}`}
          >
            <Icon
              icon="user"
              className="m-auto"
              color={step >= 3 ? colors.primary.DEFAULT : colors.disabled}
              size={20}
            />
          </div>
        </div>
        <span className="text-xs text-gray-400">
          {t(i18nKeys.onboarding.steps.personalInfo)}
        </span>
      </div>
      <hr
        className={`align-middle w-1/5 inline-block border border-dashed my-[8%] mx-[11px] ${step === 4 ? 'border-primary' : 'border-gray-400'}`}
      />

      <div className="text-center">
        <div
          className={`border  rounded-[50%] p-1 size-[59px] text-xl  m-auto ${step === 4 ? 'border-primary text-primary' : 'border-gray-400 text-gray-400'}`}
        >
          <div
            className={`border-[5px] rounded-[50%] p-2 size-[49px] ${step === 4 ? 'border-primary' : 'border-gray-400'}`}
          >
            <Icon
              icon="wallet"
              className="m-auto"
              color={step === 4 ? colors.primary.DEFAULT : colors.disabled}
              size={20}
            />
          </div>
        </div>
        <span className="text-xs text-gray-400">
          {t(i18nKeys.onboarding.steps.wallet)}
        </span>
      </div>
    </div>
  )
}

OnboardingStepper.displayName = 'Stepper'
