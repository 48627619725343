import React from 'react'

import { Icon } from '@/common/components'
import { HorizontalCard } from '@/common/components/display'
import { colors } from '@/theme'

export const slugMappings: Record<string, string> = {
  fedex: 'FedEx',
  ups: 'UPS',
}

export interface CarrierCardProps {
  slug: string
  type: string
  description: string
  price: string
  className?: string
  mode?: 'button' | 'card'
  onClick?: () => void | Promise<void>
}

export const CarrierCard: React.FC<CarrierCardProps> = (props) => {
  return (
    <HorizontalCard
      mode="button"
      image={
        <div className="bg-primary size-6 sm:size-10 rounded-full flex items-center justify-center">
          <Icon
            icon="car"
            color={colors.surface}
            className="size-4 sm:size-7"
          />
        </div>
      }
      title={slugMappings[props.slug] || props.slug}
      description={props.description}
      onClick={props.onClick}
      className={props.className}
      action={props.price}
    />
  )
}
