import { Disclosure, Tab } from '@headlessui/react'
import { t } from 'i18next'
import React from 'react'

import { Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { classNames } from '@/core'
import type { LibraryAsset, AssetImage } from '@/features/listing/types'
import { colors } from '@/theme'

export interface ProductPreviewProps {
  product: LibraryAsset
  images: AssetImage[]
  price: string
}

export const ProductPreview: React.FC<ProductPreviewProps> = (props) => {
  return (
    <div className="bg-white rounded">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {props.images?.map((image, key) => (
                  <Tab
                    key={key}
                    className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={image.src}
                            alt={image.alt ?? ''}
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-primary-500' : 'ring-transparent',
                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
              {props.images?.map((image, key) => (
                <Tab.Panel key={key}>
                  <img
                    src={image.src}
                    alt={image.alt ?? ''}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-xl sm:text-3xl font-bold tracking-tight text-gray-900">
              {props.product.name}
            </h1>

            <div className="mt-3">
              <p className="text-3xl tracking-tight text-gray-900">
                ${props.price}
              </p>
            </div>

            <div className="mt-6">
              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: props.product.description }}
              />
            </div>

            <div className="mt-6">
              <div className="mt-10 flex">
                <button
                  type="submit"
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-primary-600 px-8 py-3 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  {t(i18nKeys.sell.nav.preview.product.addCart)}
                </button>

                <button
                  type="button"
                  className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <Icon
                    icon="heartOutline"
                    size={24}
                    color={colors.gray[400]}
                  />
                </button>
              </div>
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <div className="divide-y divide-gray-200 border-t">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <h3>
                        <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                          <span
                            className={classNames(
                              open ? 'text-primary-600' : 'text-gray-900',
                              'text-sm font-medium',
                            )}
                          >
                            {t(i18nKeys.sell.nav.preview.product.features)}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <Icon
                                icon="minus"
                                color={colors.primary.DEFAULT}
                                size={20}
                              />
                            ) : (
                              <Icon
                                icon="add"
                                color={colors.gray[400]}
                                size={20}
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel
                        as="div"
                        className="prose prose-sm pb-6"
                      >
                        <ul>
                          {props.product.labels?.map((item, key) => (
                            <li key={key}>
                              {item.key}: {item.value}
                            </li>
                          ))}
                        </ul>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
