import type { GetAssetsRequest, PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { authContext } from '@/common/context/auth_context'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { AssetsService } from '@/core'

import type {
  ProductListProps,
  pageSelectedInterface,
} from './LibraryFiltersWrapper'
import { LibraryFiltersWrapper } from './LibraryFiltersWrapper'

export const ITEM_PER_PAGE = 6

export const MyLibrary: React.FC = () => {
  const { profile, loading } = useContext(authContext)
  const [elements, setElements] =
    useState<PagedResponse<ProductListProps> | null>(null)

  const navigate = useNavigate()

  const handleSearchItems = async (pageNumber: pageSelectedInterface) => {
    const page = pageNumber.selected + 1 //We are using +1 because paginated library use 0 as page 1 but backent doesnt allow 0.

    const bodyRequest = {
      ownerId: profile?.userId,
      $page: page,
      $perPage: ITEM_PER_PAGE,
      // $orderBy:  TODO: need to define order parameters
    } as GetAssetsRequest
    const res = await AssetsService.getUserAssets(bodyRequest)

    const elementsResponse = {
      items: res.items.map((e) => {
        return { product: e }
      }),
      page: page,
      perPage: ITEM_PER_PAGE,
      totalCount: res.totalCount,
      totalPages: res.totalPages,
    } as PagedResponse<ProductListProps>
    setElements(elementsResponse)
  }

  useEffect(() => {
    handleSearchItems({ selected: 0 })
  }, [profile])

  const handleNavigateToProduct = (productId: string) => {
    navigate(`/myLibrary/asset/${productId}/detail`)
  }

  const bodyContent = useMemo(() => {
    if (loading) {
      return <Spinner />
    } else {
      if (!profile || !profile.userId) {
        return <div>{t(i18nKeys.ui.error)}</div>
      } else {
        return (
          <LibraryFiltersWrapper
            elements={elements}
            handleSearchItems={async (e) => handleSearchItems(e)}
            handleNavigateToProduct={handleNavigateToProduct}
            title={t(i18nKeys.profile.myLibrary.title)}
            showSell
          />
        )
      }
    }
  }, [profile, loading, elements])

  return <div className="w-full p-4">{bodyContent}</div>
}
