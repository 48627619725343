import type { DeviceData } from '@bit-ui-libs/common'
import {
  AttachmentClassification,
  QrPackageTypeEnum,
} from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Icon } from '@/common/components'
import { authContext } from '@/common/context/auth_context'
import envVariables from '@/common/envVariables'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { AssetsService, DeviceService, Web3Service } from '@/core'
import { CoreEventService } from '@/core/core-events'
import { getUniqueDeviceName } from '@/core/devices/device-utils'
import { getDeviceLocation } from '@/core/location'
import { ServicesService } from '@/core/services/services.service'
import { colors } from '@/theme'

import { SellPage } from '..'
import { useProductListing } from '../context'
import { mockedQrCode } from '../mock'
import { ListingType } from '../types/listing-type'

const DEVICE_IS_PERSONAL_DEFAULT_VALUE = true

// TODO replace hardcoded values with constants or enums
export function SellMintingAssetPage() {
  const { profile } = useContext(authContext)
  const [elapsedTime, setElapsedTime] = useState(0)
  const { event } = useProductListing()
  const { type } = useParams<{ type: string }>()
  const [isPolling, setIsPolling] = useState(false)

  const nav = useNavigate()

  const mintAsset = async () => {
    try {
      const service = new Web3Service()
      const coreEventsService = new CoreEventService()
      const qrPackage = await coreEventsService.createQrCode({
        application: { id: envVariables.APP_NAME },
        type: QrPackageTypeEnum.OBJECTS,
      })
      const devices = await DeviceService.searchDevices({
        isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
        name: getUniqueDeviceName(),
        userId: profile?.userId!,
      })

      const currentDeviceLocation = await getDeviceLocation()
      const ipAddress = await DeviceService.getIpAddress()

      const device = {
        date: new Date().toISOString(),
        deviceId: devices.items[0].id,
        geolocation: {
          latitude: currentDeviceLocation.coords.latitude,
          longitude: currentDeviceLocation.coords.longitude,
          meanSeaLevel: currentDeviceLocation.coords.altitude ?? 0,
        },
        ipAddress: ipAddress,
      } as DeviceData

      await coreEventsService.linkQrCode(qrPackage.id, {
        eventId: event!.id,
        device,
      })

      const uploadQrData = {
        application: { id: envVariables.APP_NAME },
        device,
        docs: [
          {
            docType: 'PHOTO' as any,
            classification: AttachmentClassification.QrCode,
            data: mockedQrCode, // TODO remove mock
          },
        ],
      }

      const vdt = await service.getUserVdt()

      if (type === ListingType.Asset) {
        await coreEventsService.uploadQr(event!.id, uploadQrData)
        await service.mint(vdt.walletAddress, event!.id)
      } else {
        await coreEventsService.service.uploadQr(event!.id, uploadQrData)
        await service.mintService(
          vdt.walletAddress,
          event!.id,
          vdt.nftMetadataUrl,
        )
      }

      setIsPolling(true)
    } catch (e) {
      Logger.error('Failed to mint asset', undefined, e as Error)
      // TODO show error, or retry
    }
  }

  const checkMintedAsset = async () => {
    try {
      if (!isPolling) return

      if (type === ListingType.Asset) {
        const service = new AssetsService()
        const asset = await service.getByEventId(event!.id)
        if (asset.isMinted) {
          nav('/sell/asset/method')
        }
      } else {
        const asset = await ServicesService.getByEventId(event!.id)
        if (asset.isMinted) {
          nav('/sell/service/pricing')
        }
      }

      // This is a polling mechanism, there is no need for checking the false condition
    } catch (e) {
      Logger.error('Failed to check minted asset', undefined, e as Error)
      // TODO show error to user
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    mintAsset()

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (elapsedTime % 5 !== 0) return

    checkMintedAsset()
  }, [elapsedTime])

  const formattedTime = useMemo(() => {
    let formattedTime = ''
    if (elapsedTime < 60) {
      formattedTime = `${elapsedTime} ${t(i18nKeys.common.seconds)}`
    } else {
      const minutes = Math.floor(elapsedTime / 60)
      const seconds = elapsedTime % 60
      formattedTime = `${minutes} ${t(i18nKeys.common.minutes)} ${seconds} ${t(i18nKeys.common.seconds)}`
    }
    return formattedTime
  }, [elapsedTime])

  return (
    <SellPage>
      <div className="flex flex-col items-center text-center px-8 gap-4 h-full">
        <h2 className="font-medium text-lg">
          {t(i18nKeys.sell.nav.minting.title)}
        </h2>
        <p className="text-base">{t(i18nKeys.sell.nav.minting.description)}</p>

        <Icon
          icon="userClock"
          size={76}
          color={colors.primary.DEFAULT}
          className="my-4"
        />

        <p className="text-base">{t(i18nKeys.sell.nav.minting.wait)}</p>

        <p className="text-sm font-medium mt-6">
          {t(i18nKeys.sell.nav.minting.timeElapsed)}: {formattedTime}
        </p>
      </div>
    </SellPage>
  )
}
