import React, { useCallback, useState } from 'react'

import { PaymentCardInfo, SelectPaymentSlideOver } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { startVenmoTokenize } from '@/common/components/Paysafe/PaysafeClient'
import type { Address } from '@/common/types'
import { Logger } from '@/config'
import type { PaymentCard } from '@/core'

import { EmptyCard } from '../listing'

import styles from './SelectCard.module.css'

import type { PaymentHandleToken } from '.'

export type SelectCardProps = {
  selectedCard?: PaymentCard
  cards?: PaymentCard[]
  totalPrice: number
  onSelectCard?: (card: PaymentCard) => void
  onCreateCard?: () => void
  billingAddress: Address
  cardHolderName: string
  handleToken?: PaymentHandleToken
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: (error?: string) => void | Promise<void>
  onContinue: () => void
}

// FIXME is this incomplete? have we removed card payment?
export const SelectCard: React.FC<SelectCardProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleSelectCard = (card?: PaymentCard) => {
    setIsModalOpen(false)

    if (props.onSelectCard && card) {
      props.onSelectCard(card)
    }
  }

  const handleCreateCard = () => {
    setIsModalOpen(false)
    if (props.onCreateCard) {
      props.onCreateCard()
    }
  }

  const handleFilterCard = useCallback(
    (search: string, item: PaymentCard) =>
      item.holderName.toLowerCase().includes(search) ||
      item.lastDigits.includes(search) ||
      item.type.toLowerCase().includes(search),
    [],
  )

  function startVenmoPayment() {
    startVenmoTokenize(
      props.cardHolderName,
      props.billingAddress,
      props.totalPrice,
    )
      .then((result) => {
        if (result && props.onPaymentComplete) {
          props.onPaymentComplete(result)
        }
      })
      .catch((error) => {
        Logger.error('Venmo payment failed', undefined, error as Error)
        if (props.onPaymentFailed) {
          props.onPaymentFailed(error)
        }
      })
  }

  return (
    <>
      <div
        onClick={startVenmoPayment}
        className={styles.venmo}
        onKeyDown={startVenmoPayment}
        role="presentation"
        style={{ display: 'none' }}
      >
        VENMO
      </div>
      <div className="mx-auto max-w-2xl mt-12 w-full h-full flex flex-col justify-between flex-1">
        {props.selectedCard ? (
          <>
            <PaymentCardInfo
              card={props.selectedCard}
              onClick={() => setIsModalOpen(true)}
              className="w-full"
              rightComponent={
                // TODO add translation
                <span className="text-primary-500 font-medium">Change</span>
              }
            />
          </>
        ) : (
          <EmptyCard
            onClick={() => setIsModalOpen(true)}
            icon="card"
            text="Select a credit/debit card"
            className="w-full"
          />
        )}
      </div>

      <SelectPaymentSlideOver
        open={isModalOpen}
        onClose={handleSelectCard}
        // TODO add translations
        emptyMessage="No cards found. Add a new card to continue."
        emptySearch={(search) =>
          `No cards found while looking for \"${search}\".`
        }
        searchPlaceholder="Search for a card"
        RenderItem={({ item, index }) => (
          <PaymentCardInfo
            key={index}
            card={item}
            onClick={() => handleSelectCard(item)}
            rightComponent={
              <span className="text-primary-500 font-medium">Choose</span>
            }
          />
        )}
        items={props.cards ?? []}
        title="Choose a card"
        onSearch={handleFilterCard}
        onCreate={handleCreateCard}
      />
    </>
  )
}
