import React, { useMemo } from 'react'

import { Icon } from '@/common/components'
import { classNames } from '@/core'

import {
  cardClassNames,
  iconClassNames,
  stepClassNames,
} from './steps-card-config'
import type { StepsCardProps } from './steps-card-types'

export const StepsCard: React.FC<StepsCardProps> = (props) => {
  const cardClassName = useMemo(
    () => cardClassNames[props.status],
    [props.status],
  )

  const iconClassName = useMemo(
    () => iconClassNames[props.status],
    [props.status],
  )

  const stepClassName = useMemo(
    () => stepClassNames[props.status],
    [props.status],
  )

  const handleClick = () => {
    if (props.onClick && props.status === 'current') {
      props.onClick()
    }
  }

  return (
    <button
      className={classNames(
        'p-3 border rounded-lg flex-1 flex flex-col gap-2 cursor-default text-start',
        cardClassName,
        props.className,
      )}
      onClick={handleClick}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-2 items-center">
          <Icon
            icon={props.icon}
            className={classNames('size-5', iconClassName)}
          />
          <span className={classNames('text-sm', stepClassName)}>
            Step {props.step}
          </span>
        </div>

        {props.status === 'completed' && (
          <div className="flex flex-row gap-1 items-center">
            <span className="text-primary font-semibold">Done</span>
            <Icon icon="check" className="fill-primary size-4" />
          </div>
        )}

        {props.additionalInfo && props.status !== 'completed' ? (
          <span className="text-lg font-medium">{props.additionalInfo}</span>
        ) : null}
      </div>

      <h3 className="text-xl font-medium text-black">{props.name}</h3>
      <p className="text-sm  text-gray-600">{props.description}</p>
    </button>
  )
}
