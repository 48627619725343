import React from 'react'

import styles from '../index.module.css'

export interface ProfileRowCardProps {
  iconLeft: React.ReactNode
  iconRight?: React.ReactNode
  text: string
  type: 'text' | 'password'
  label: string
}

export const ProfileInputWrapper: React.FC<ProfileRowCardProps> = (props) => {
  let textClass = ''

  if (props.type === 'password') {
    textClass = styles.displayPassword
  }
  return (
    <div className="border-2 md:p-4 p-2 my-2 flex justify-between">
      <div className="flex">
        <div className="my-auto">{props.iconLeft}</div>
        <div className="md:ms-5 ms-2">
          <p className="font-semibold">{props.label}</p>
          <p className={textClass}>{props.text}</p>
        </div>
      </div>
      {props.iconRight ? (
        <button className="my-auto">{props.iconRight}</button>
      ) : null}
    </div>
  )
}
