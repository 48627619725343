import { t } from 'i18next'
import React, { useContext, useEffect } from 'react'

import { authContext } from '../../common/context/auth_context'
import Spinner from '../../components/Spinner'
import { AuthService } from '../../core/auth/auth.service'

export const Signout: React.FC = () => {
  const { clear: clearContext } = useContext(authContext)

  useEffect(() => {
    // Clear auth context and redirect user to the signout url
    clearContext()

    window.location.href = AuthService.signOutUrl
  }, [clearContext])

  return (
    <div className="w-full justify-center flex mt-5">
      <Spinner text={t('signOut')} />
    </div>
  )
}

Signout.displayName = 'Signout'
