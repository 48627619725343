import { t } from 'i18next'
import { isUndefined } from 'lodash'
import React from 'react'

import { classNames } from '@/core'

import type { IconSource } from '../common/components'
import { Icon, IconInCircle } from '../common/components'
import i18nKeys from '../common/i18nKeys'

import { ConditionalRendering } from './ConditionalRendering'

interface SectionFieldActionProps {
  title?: string
  onClick?: () => void
}

interface SectionFieldProps {
  favoriteAction?: SectionFieldActionProps
  editAction?: SectionFieldActionProps
  deleteAction?: SectionFieldActionProps
  defaultAction?: SectionFieldActionProps
  icon: IconSource
  info?: string
  infoCompleted: boolean
  isDefault?: boolean
  label: string
  placeholder: string
  isDisabled?: boolean
  className?: string
}

const ActionsSection: React.FC<SectionFieldProps> = (props) => {
  const handleDefaultIconClick = () => {
    if (props.favoriteAction?.onClick && !props.isDefault) {
      props.favoriteAction.onClick()
    }
  }

  if (!props.infoCompleted) {
    return (
      <button
        className="text-white bg-gray-500 p-[10px] font-medium"
        onClick={props.defaultAction?.onClick}
        disabled={props.isDisabled}
      >
        {t(i18nKeys.ui.add)}
      </button>
    )
  }

  return (
    <div className="flex">
      <ConditionalRendering renderIf={!isUndefined(props.favoriteAction)}>
        <div className="m-auto" title={props.favoriteAction?.title}>
          <Icon
            onClick={handleDefaultIconClick}
            icon={props.isDefault ? 'starFilled' : 'star'}
            className="my-auto mx-3 text-lg cursor-pointer size-5 fill-gray-400 group-hover:fill-gray-600 transition-colors duration-200"
            title={props.favoriteAction?.title}
          />
        </div>
      </ConditionalRendering>

      <ConditionalRendering renderIf={!isUndefined(props.editAction?.onClick)}>
        <Icon
          icon="edit"
          className="my-auto mx-0 text-lg cursor-pointer size-5 fill-gray-400 group-hover:fill-gray-600 transition-colors duration-200"
          onClick={props.editAction?.onClick}
          title={props.editAction?.title}
        />
      </ConditionalRendering>

      <ConditionalRendering renderIf={!isUndefined(props.deleteAction)}>
        <Icon
          icon="trash"
          className="my-auto mx-3 text-lg cursor-pointer size-5 fill-gray-400 group-hover:fill-gray-600 transition-colors duration-200"
          onClick={props.deleteAction?.onClick}
          title={props.deleteAction?.title}
        />
      </ConditionalRendering>
    </div>
  )
}

export const SectionField: React.FC<SectionFieldProps> = (props) => {
  return (
    <div
      className={classNames(
        'flex justify-between p-2 min-h-6 rounded-lg bg-white shadow hover:shadow-md transition-all group',
        props.className,
      )}
    >
      <div className="flex gap-5 items-center">
        <IconInCircle
          icon={props.icon}
          circleSize={40}
          className="fill-white"
        />

        <div>
          <p className="font-medium">{props.label}</p>
          {props.infoCompleted ? (
            <p className="text-sm text-gray-400 text-ellipsis overflow-hidden whitespace-nowrap">
              {props.info}
            </p>
          ) : (
            <p className="text-sm text-gray-400">{props.placeholder}</p>
          )}
        </div>
      </div>
      <ActionsSection {...props} />
    </div>
  )
}
