import { translateKey } from '@/core'

import type { CardOption } from '..'
import { TaxCategoryType } from '../types/taxes'

export const taxCategories: CardOption[] = [
  {
    id: TaxCategoryType.SpecialTaxCategory,
    title: translateKey((i) => i.sell.nav.taxes.options.special.title),
    description: translateKey(
      (i) => i.sell.nav.taxes.options.special.description,
    ),
    action: translateKey((i) => i.sell.nav.taxes.options.special.action),
    actionClassName: 'text-primary italic',
  },
  {
    id: TaxCategoryType.GeneralTaxes,
    title: translateKey((i) => i.sell.nav.taxes.options.general.title),
    description: translateKey(
      (i) => i.sell.nav.taxes.options.general.description,
    ),
    disabled: false,
  },
]
