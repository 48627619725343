import React from 'react'

import { PaysafeCreditCardForm } from '@/common/components/Paysafe'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import type { Address } from '@/common/types'

const AMOUNT_ADD_PAYMENT_CARD = 0

export type AddPaymentCardProps = {
  className?: string
  billingAddress: Address
  cardHolderName: string
  totalPrice?: number
  submitText?: string
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: (error?: string) => void | Promise<void>
}

export const AddPaymentCard: React.FC<AddPaymentCardProps> = (props) => {
  return (
    <PaysafeCreditCardForm
      submitText={props.submitText ?? 'Confirm purchase'}
      // TODO this component should not be used for purchases, just adding a card
      amount={props.totalPrice ?? AMOUNT_ADD_PAYMENT_CARD}
      onPaymentComplete={(result) => {
        if (props.onPaymentComplete) {
          props.onPaymentComplete(result)
        }
      }}
      isNewCard
      onPaymentFailed={props.onPaymentFailed}
      holderName={props.cardHolderName}
      billingAddress={{
        address: props.billingAddress.address,
        city: props.billingAddress.city,
        state: props.billingAddress.state,
        country: props.billingAddress.country,
        zip: props.billingAddress.zip,
        name: props.cardHolderName,
        type: props.billingAddress.type,
      }}
    />
  )
}
