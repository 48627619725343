import type {
  AssetCategory,
  AssetType,
  AssetTypeStep,
  ChainAssetEvent,
} from '@bit-ui-libs/common'

import type { SellerShippingAddress } from '@/common/types'

import type {
  AssetDetailsForm,
  AssetImage,
  DeliveryForm,
  LibraryAsset,
  PayoutAccount,
  SellMethodForm,
} from '../types'
import {
  PayoutCurrencyType,
  PrivacyOption,
  TransactionCompletenessType,
} from '../types'
import { defaultValues } from '../utils'

export const initialState: ProductListingState = {
  useExistingAsset: true,
  productDetails: defaultValues.productDetails(),
  images: [],
  completeness: TransactionCompletenessType.TimeOfSale,
  privacy: PrivacyOption.AlmostNothing,
  bankAccounts: [],
  cryptoAccounts: [],
  payoutCurrency: PayoutCurrencyType.USDollars,
  sellMethod: defaultValues.sellMethod(),
  addresses: [],
  delivery: defaultValues.delivery(),
  buyerLevel: 2,
  categories: [],
  steps: [],
}

export type ProductListingState = {
  products?: LibraryAsset[]
  useExistingAsset: boolean
  selectedAsset?: LibraryAsset
  productDetails: AssetDetailsForm
  images: AssetImage[]
  completeness: TransactionCompletenessType
  privacy: PrivacyOption
  cryptoAccounts: PayoutAccount[]
  bankAccounts: PayoutAccount[]
  payoutCurrency: PayoutCurrencyType
  selectedAccount?: PayoutAccount
  sellMethod: SellMethodForm
  addresses: SellerShippingAddress[]
  delivery: DeliveryForm
  buyerLevel: number
  categories: AssetCategory[]
  assetType?: AssetType
  steps: AssetTypeStep[]
  event?: ChainAssetEvent
}
