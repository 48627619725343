import { ThemeProvider } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import React, { memo } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import '@aws-amplify/ui-react/styles.css'

import awsexports from './aws-exports'
import { AuthContextProvider } from './common/context/auth_context'
import ErrorBoundary from './components/ErrorBoundary'
import router from './routes/router'

import 'react-toastify/dist/ReactToastify.css'

Amplify.configure(awsexports)

const App: React.FC = memo(() => (
  <ErrorBoundary>
    <ThemeProvider>
      <AuthContextProvider>
        <RouterProvider router={router} />
        <ToastContainer />
      </AuthContextProvider>
    </ThemeProvider>
  </ErrorBoundary>
))
App.displayName = 'App'
export default App
