import React, { useMemo } from 'react'

import { classNames } from '@/core'
import { colors } from '@/theme'

export type ButtonMode = 'contained' | 'outline' | 'text'

export interface ButtonProps {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  color?: string
  textColor?: string
  disabled?: boolean
  className?: string
  mode?: ButtonMode
  type?: 'button' | 'submit' | 'reset'
}

const BUTTON_MAPPING: Record<
  ButtonMode,
  ((props: ButtonProps) => JSX.Element) | undefined
> = {
  contained: (props) => <ButtonContained {...props} />,
  outline: (props) => <ButtonOutlined {...props} />,
  text: (props) => <ButtonText {...props} />,
}

/**
 * Represents a button wrapper to use on the app, independent of implementation or library.
 */
export function Button(props: ButtonProps) {
  const Element = useMemo(
    () => BUTTON_MAPPING[props.mode ?? 'contained']!,
    [props.mode],
  )

  return <Element {...props} />
}

function ButtonContained(props: ButtonProps) {
  return (
    <button
      type={props.type ?? 'button'}
      className={classNames(
        'py-2 px-8 font-semibold rounded-lg transition-all duration-300',
        !props.disabled && 'hover:shadow-md hover:bg-primary-300',
        props.className,
      )}
      style={{
        border: '1px solid transparent',
        backgroundColor: props.disabled
          ? colors.gray[300]
          : props.color ?? colors.primary.DEFAULT,
        color: props.disabled
          ? colors.gray[700]
          : props.textColor ?? colors.surface,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

function ButtonOutlined(props: ButtonProps) {
  return (
    <button
      type={props.type ?? 'button'}
      className={classNames(
        'py-2 px-8 rounded-lg font-semibold border-2 border-primary text-primary disabled:bg-gray-400 disabled:text-details',
        props.className,
      )}
      style={{ borderColor: props.color ?? colors.primary.DEFAULT }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

function ButtonText(props: ButtonProps) {
  return (
    <button
      type={props.type ?? 'button'}
      className={classNames(
        'p-1 rounded-lg font-semibold disabled:bg-gray-400 disabled:text-details',
        props.className,
      )}
      style={{ color: props.color ?? colors.primary.DEFAULT }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}
