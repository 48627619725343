import type { BuyerProfile } from '@bit-ui-libs/common'
import { useContext } from 'react'

import type {
  SellerProfileWithTaxIdType,
  UserProfileType,
} from '../context/auth_context'
import { authContext } from '../context/auth_context'

type UseProfileHook = {
  profile: UserProfileType | null
  sellerProfile: SellerProfileWithTaxIdType | null
  buyerProfile: BuyerProfile | null
  profileLoaded: boolean
  mainProfileCompleted: boolean | null
  authenticated: boolean
}

export const useProfile = (): UseProfileHook => {
  const {
    authenticated,
    profile,
    sellerProfile,
    buyerProfile,
    profileLoaded,
    mainProfileCompleted,
  } = useContext(authContext)

  return {
    profile,
    sellerProfile,
    buyerProfile,
    profileLoaded,
    mainProfileCompleted,
    authenticated,
  }
}
