import type {
  AssetCategory,
  AssetType,
  ChainAssetEvent,
} from '@bit-ui-libs/common'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'

import { Identifiers } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import { useForm, useProfile } from '@/common/hooks'
import type { SellerShippingAddress } from '@/common/types'
import { UserService } from '@/core'
import { AssetsService } from '@/core/assets'
import { EventService } from '@/core/events'
import BankOfAmericaLogo from '@assets/images/bank-of-america-logo.png'
import MarketplaceIcon from '@assets/images/chainIT_marketplace_icon.png'

import type {
  AssetDetailsForm,
  AssetImage,
  AssetLabel,
  DeliveryForm,
  LibraryAsset,
  PayoutAccount,
  PayoutCurrencyType,
  PrivacyOption,
  SellMethodForm,
  TransactionCompletenessType,
} from '../types'
import { defaultValues } from '../utils'

import {
  ActionType,
  ProductListingContext,
  ProductListingDispatch,
  initialState,
  productListingReducer,
} from '.'

export const ProductListingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { sellerProfile } = useProfile()
  const [state, dispatch] = useReducer(productListingReducer, initialState)
  const assetDetails = useForm<AssetDetailsForm>(defaultValues.productDetails())
  const sellMethod = useForm<SellMethodForm>(defaultValues.sellMethod())
  const delivery = useForm<DeliveryForm>(defaultValues.delivery())
  const isInitialized = useRef(false)

  const fetchSteps = useCallback(async () => {
    const assetService = new AssetsService()

    const assetType = await assetService.getAssetTypeById(state.assetType!.id)

    dispatch({ type: ActionType.SetSteps, payload: assetType.steps })
  }, [dispatch, state.assetType])

  const setProducts = useCallback(
    (products: LibraryAsset[]) => {
      dispatch({ type: ActionType.SetProducts, payload: products })
    },
    [dispatch],
  )

  const setUseCurrentAsset = useCallback(
    (value: boolean) => {
      dispatch({ type: ActionType.SetUseCurrentAsset, payload: value })
      fetchSteps()
    },
    [dispatch, fetchSteps],
  )

  const setSelectedAsset = useCallback(
    (asset: LibraryAsset) => {
      dispatch({ type: ActionType.SetSelectedAsset, payload: asset })
    },
    [dispatch],
  )

  const setImages = useCallback(
    (images: AssetImage[]) => {
      dispatch({ type: ActionType.SetImages, payload: images })
    },
    [dispatch],
  )

  const addImage = useCallback(
    (image: AssetImage) => {
      dispatch({ type: ActionType.AddImage, payload: image })
    },
    [dispatch],
  )

  const removeImage = useCallback(
    (image: AssetImage) => {
      dispatch({ type: ActionType.RemoveImage, payload: image })
    },
    [dispatch],
  )

  const setCompletenessType = useCallback(
    (completeness: TransactionCompletenessType) => {
      dispatch({ type: ActionType.SetCompletenessType, payload: completeness })
    },
    [dispatch],
  )

  const setPrivacy = useCallback(
    (privacy: PrivacyOption) => {
      dispatch({ type: ActionType.SetPrivacy, payload: privacy })
    },
    [dispatch],
  )

  const setBankAccounts = useCallback(
    (accounts: PayoutAccount[]) => {
      dispatch({ type: ActionType.SetBankAccounts, payload: accounts })
    },
    [dispatch],
  )

  const setCryptoAccounts = useCallback(
    (accounts: PayoutAccount[]) => {
      dispatch({ type: ActionType.SetCryptoAccounts, payload: accounts })
    },
    [dispatch],
  )

  const setSelectedAccount = useCallback(
    (account: PayoutAccount) => {
      dispatch({ type: ActionType.SetSelectedAccount, payload: account })
    },
    [dispatch],
  )

  const setPayoutCurrency = useCallback(
    (payout: PayoutCurrencyType) => {
      dispatch({ type: ActionType.SetPayoutCurrency, payload: payout })
    },
    [dispatch],
  )

  const setAddresses = useCallback(
    (addresses: SellerShippingAddress[]) => {
      dispatch({ type: ActionType.SetAddresses, payload: addresses })
    },
    [dispatch],
  )

  const setBuyerLevel = useCallback(
    (buyerLevel: number) => {
      dispatch({ type: ActionType.SetBuyerLevel, payload: buyerLevel })
    },
    [dispatch],
  )

  const setAssetType = useCallback(
    (assetType: AssetType) => {
      dispatch({ type: ActionType.SetAssetType, payload: assetType })
    },
    [dispatch],
  )

  const { userId } = useContext(authContext)

  const bankImages: Record<string, string> = {
    bankOfAmerica: BankOfAmericaLogo,
  }

  const handleRefresh = async () => {
    isInitialized.current = true
    const assetService = new AssetsService()

    const assets = await assetService.getAssets(userId!, false)
    setProducts([
      ...assets.items.map((i) => ({
        id: i.id,
        category: i.serializedCategories[0],
        subcategory: i.serializedCategories[1],
        name: i.name!,
        grade: i.level,
        description:
          i.serializedProps.find((p) => p.name === 'Description')?.value ?? '',
        imageSrc:
          EventService.buildDocumentImageUrlById(
            i.serializedImages?.find((i) => i.isMain)?.docId,
          ) || MarketplaceIcon,
        labels: i.serializedProps
          .filter((p) => p.name !== 'description')
          .map((p) => ({ key: p.name, value: p.value }) as AssetLabel),
        isSelfVerified: true,
        isVDTMinted: i.isMinted,
        zipCode: -1,
        assetTypeId: i.typeId,
        images:
          i.serializedImages?.map(
            (i) =>
              ({
                src: EventService.buildDocumentImageUrlById(i.docId),
                alt: i.name,
                status: 'success',
              }) as AssetImage,
          ) ?? [],
      })),
    ])

    const category = await assetService.getCategoryById(
      Identifiers.PRODUCTS_CATEGORY_ID,
    )

    dispatch({ type: ActionType.SetCategories, payload: [category] })

    const usersService = new UserService()
    const addresses = await usersService.listAddresses({ userId: userId! }) // TODO: check address types here

    setAddresses(addresses as any)

    const payments = await usersService.getPaymentDetails(sellerProfile?.id!)

    setBankAccounts([
      ...payments.map((i) => ({
        id: i.id,
        isFavorite: i.isDefault,
        entity: i.data.bankName!,
        owner: i.data.username!,
        imageSrc: bankImages[i.data.bankName!],
      })),
    ])
  }

  const handleChangeCategory = useCallback(
    (selected: AssetCategory, key: number) => {
      const _categories = [...state.categories]

      if (key < _categories.length - 1) {
        _categories.splice(key + 1)
      }

      dispatch({
        type: ActionType.SetCategories,
        payload: [..._categories, selected],
      })
    },
    [state.categories, dispatch],
  )

  const setEvent = useCallback(
    (event: ChainAssetEvent) => {
      dispatch({ type: ActionType.SetEvent, payload: event })
    },
    [dispatch],
  )

  const handleFetchSteps = useCallback(
    async (assetTypeId: string) => {
      const assetService = new AssetsService()

      const assetType = await assetService.getAssetTypeById(assetTypeId)

      dispatch({ type: ActionType.SetSteps, payload: assetType.steps })
    },
    [dispatch],
  )

  useEffect(() => {
    if (!userId || !sellerProfile || isInitialized.current) {
      return
    }
    handleRefresh()
  }, [userId, sellerProfile])

  useEffect(() => {
    dispatch({ type: ActionType.SetProductDetails, payload: assetDetails.data })
  }, [assetDetails.data])

  useEffect(() => {
    dispatch({ type: ActionType.SetSellMethod, payload: sellMethod.data })
  }, [sellMethod.data])

  useEffect(() => {
    dispatch({ type: ActionType.SetDelivery, payload: delivery.data })
  }, [delivery.data])

  useEffect(() => {
    if (!state.assetType) return

    fetchSteps()
  }, [state.assetType])

  return (
    <ProductListingContext.Provider
      value={{
        ...state,
        productDetails: assetDetails.data,
        delivery: delivery.data,
        sellMethod: sellMethod.data,
      }}
    >
      <ProductListingDispatch.Provider
        value={{
          setProducts,
          setUseCurrentAsset,
          setSelectedAsset,
          setImages,
          addImage,
          removeImage,
          setCompletenessType,
          setPrivacy,
          setBankAccounts,
          setCryptoAccounts,
          setSelectedAccount,
          setPayoutCurrency,
          setAddresses,
          setBuyerLevel,
          changeCategory: handleChangeCategory,
          setAssetType,
          setEvent,
          handleFetchSteps,
          productDetailsActions: {
            handleChangeField: assetDetails.handleChangeField,
            handleChangeInput: assetDetails.handleChangeInput,
          },
          sellMethodActions: {
            handleChangeInput: sellMethod.handleChangeInput,
            handleChangeField: sellMethod.handleChangeField,
          },
          deliveryActions: {
            handleChangeInput: delivery.handleChangeInput,
            handleChangeField: delivery.handleChangeField,
          },
        }}
      >
        {children}
      </ProductListingDispatch.Provider>
    </ProductListingContext.Provider>
  )
}
