import { t } from 'i18next'
import { isUndefined } from 'lodash'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'

import { ErrorLabel } from '@/common/components/display'
import {
  AssetTypeAutocomplete,
  CategoryAutocomplete,
} from '@/common/components/form/autocomplete'
import { useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'

import { PageActionButtons, SellPage } from '..'
import { useProductListing, useProductListingDispatch } from '../context'

export default function SellCategory() {
  const { categories, assetType } = useProductListing()
  const { changeCategory, setAssetType } = useProductListingDispatch()
  const { errors, changeError } = useError({
    assetType: t(i18nKeys.sell.nav.details.asset.subcategoriesError),
  })

  const finalCategory = useMemo(() => {
    if (!categories.length) {
      return undefined
    }

    const last = categories[categories.length - 1]

    return last.isFinal ? last : undefined
  }, [categories])

  const getSelectedByKey = useCallback(
    (key: number) => categories[key + 1],
    [categories],
  )

  const handleContinue = useCallback(() => {
    if (!finalCategory || !assetType) {
      changeError('assetType', true)
      return false
    }

    return true
  }, [finalCategory, assetType, changeError])

  useEffect(() => {
    changeError('assetType', false)
  }, [categories, assetType])

  return (
    <SellPage title={t(i18nKeys.header.categories)}>
      <div className="flex flex-col gap-4 w-full sm:max-w-80">
        {categories.map((parent, key) => {
          if (parent.isFinal) return <Fragment key={key}></Fragment>
          return (
            <CategoryAutocomplete
              key={key}
              label={t(i18nKeys.sell.nav.details.asset.category)}
              onChange={(selected) => changeCategory(selected, key)}
              parentId={parent.id}
              selected={getSelectedByKey(key)}
              className="w-full"
            />
          )
        })}

        <ConditionalRendering renderIf={!isUndefined(finalCategory)}>
          <AssetTypeAutocomplete
            label={t(i18nKeys.sell.nav.details.asset.assetType)}
            categoryId={finalCategory?.id}
            onChange={setAssetType}
            selected={assetType}
            className="w-full"
          />
        </ConditionalRendering>

        <ErrorLabel error={errors.assetType?.message} />
      </div>
      <PageActionButtons onContinue={handleContinue} />
    </SellPage>
  )
}
