import type { SellerProfile } from '@bit-ui-libs/common'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Logger } from '@/config'
import { UserService } from '@/core'

import type {
  SellerProfileWithTaxIdType,
  UserProfileType,
} from '../context/auth_context'
import type { Address, SellerShippingAddress } from '../types'

import { useNotify, useProfile } from '.'

const userService = new UserService()

export type ProfileValidation<T> = {
  data?: T
  isValid: boolean
  isLoading: boolean
}

export type SellerProfileHookParams = {
  redirectOnFailedValidation?: boolean
  showToasts?: boolean
}

export const useSellerProfile = (params: SellerProfileHookParams = {}) => {
  const navigate = useNavigate()
  const { warning, error } = useNotify()
  const { sellerProfile, profile } = useProfile()

  const [shipping, setShipping] = useState<
    ProfileValidation<SellerShippingAddress>
  >({ isLoading: true, isValid: false })
  const [defaultSellerProfile, setDefaultSellerProfile] = useState<
    ProfileValidation<SellerProfile>
  >({ isLoading: true, isValid: false })

  const fetchDefaultShippingAddress = async (sellerId: string) => {
    try {
      return (await userService.getDefaultShippingAddress(sellerId)) ?? null
    } catch (e) {
      Logger.warn('Failed to get default seller shipping address', e as Error)
      return null
    }
  }

  const fetchUserAddresses = async (userId: string) => {
    try {
      return await userService.getUserAddressesList(userId)
    } catch (e) {
      Logger.warn('Failed to get user addresses', e as Error)
      return []
    }
  }

  const createSellerAddress = async (
    sellerId: string,
    userId: string,
    address: Address,
  ) => {
    try {
      await userService.createSellerAddress(sellerId, {
        ...address,
        addressId: address.id,
        profileId: sellerId,
        isDefault: true,
        userId: userId,
        isMain: true,
        shipInternationally: false,
      })

      return Boolean(address)
    } catch (e) {
      Logger.warn('Failed to create seller address', e as Error)
      return false
    }
  }

  const validateShippingDetails = async (
    seller: SellerProfileWithTaxIdType,
  ) => {
    const defaultShipping = await fetchDefaultShippingAddress(seller.id)

    if (defaultShipping !== null) {
      setShipping({ data: defaultShipping, isValid: true, isLoading: false })
      return
    }

    // Try to set default shipping address based on user addresses
    Logger.info('Setting main or first address as default shipping address.')

    const userAddresses = await fetchUserAddresses(seller.userId)

    if (userAddresses.length === 0) {
      setShipping({ isValid: false, isLoading: false })

      if (params.showToasts) {
        warning(
          'You need to add a shipping address before you can list an item',
        )
      }

      if (params.redirectOnFailedValidation) {
        navigate('/profile/personalInformation')
      }
      return
    }

    const firstAddress = userAddresses.find((i) => i.isMain) ?? userAddresses[0]
    const createAddressResult = await createSellerAddress(
      seller.id,
      seller.userId,
      firstAddress,
    )

    if (!createAddressResult) {
      Logger.error('Failed to set default shipping address.')

      setShipping({ isValid: false, isLoading: false })

      if (params.showToasts) {
        error('Failed to set default shipping address, please try again later.')
      }

      if (params.redirectOnFailedValidation) {
        navigate('/profile/personalInformation')
      }

      return
    }

    // Validate just added default shipping address
    const newDefaultShipping = await fetchDefaultShippingAddress(seller.id)

    if (newDefaultShipping !== null) {
      Logger.info('Default shipping address set successfully.')

      setShipping({
        data: newDefaultShipping,
        isValid: true,
        isLoading: false,
      })
      return
    }

    Logger.error('Failed to set default shipping address.')

    setShipping({ isValid: false, isLoading: false })

    if (params.showToasts) {
      error('Failed to set default shipping address, please try again later.')
    }
    if (params.redirectOnFailedValidation) {
      navigate('/profile/personalInformation')
    }
  }

  const fetchSellerProfile = async () => {
    try {
      return (await userService.getDefaultSellerProfile()) ?? null
    } catch (e) {
      Logger.warn('No default seller profile found.')
      return null
    }
  }

  const fetchSellerProfiles = async (userId: string) => {
    try {
      return await userService.getListUserSellerProfiles(userId)
    } catch (e) {
      Logger.warn('No seller profiles found.')
      return []
    }
  }

  const updateDefaultSellerProfile = async (
    profile: SellerProfileWithTaxIdType,
  ) => {
    try {
      const created = await userService.editSellerProfile(
        { ...profile, isDefault: true },
        profile.taxIdType!,
      )
      return Boolean(created)
    } catch (e) {
      Logger.warn('Failed to update default seller profile', e as Error)
      return false
    }
  }

  const validateSellerProfile = async (profile: UserProfileType) => {
    const defaultSellerProfile = await fetchSellerProfile()

    if (defaultSellerProfile) {
      setDefaultSellerProfile({
        data: defaultSellerProfile,
        isValid: true,
        isLoading: false,
      })
      return
    }

    const profiles = await fetchSellerProfiles(profile.id)

    if (!profiles.length) {
      Logger.warn('No seller profiles found.')

      setDefaultSellerProfile({ isValid: false, isLoading: false })

      if (params.showToasts) {
        warning(
          'You need to create a seller profile before you can list an item',
        )
      }

      if (params.redirectOnFailedValidation) {
        navigate('/profile/seller')
      }

      return
    }

    // set first seller profile as default
    Logger.info('Setting first seller profile as default.')

    const created = await updateDefaultSellerProfile(profiles[0])

    if (!created) {
      Logger.error('Failed to set default seller profile.')

      setDefaultSellerProfile({
        isValid: false,
        isLoading: false,
      })

      if (params.showToasts) {
        warning(
          'You need to create a seller profile before you can list an item',
        )
      }

      if (params.redirectOnFailedValidation) {
        navigate('/profile/seller')
      }

      return
    }

    const newDefaultSellerProfile = await fetchSellerProfile()

    if (newDefaultSellerProfile !== null) {
      Logger.info('Default seller profile set successfully.')
      setDefaultSellerProfile({
        data: newDefaultSellerProfile,
        isValid: true,
        isLoading: false,
      })
      return
    }

    setDefaultSellerProfile({
      isValid: false,
      isLoading: false,
    })

    if (params.showToasts) {
      warning('You need to create a seller profile before you can list an item')
    }

    if (params.redirectOnFailedValidation) {
      navigate('/profile/seller')
    }
  }

  useEffect(() => {
    if (!profile) return

    validateSellerProfile(profile)
  }, [profile])

  useEffect(() => {
    if (!sellerProfile) {
      return
    }

    validateShippingDetails(sellerProfile)
  }, [sellerProfile])

  return {
    shipping,
    defaultSellerProfile,
    isLoading: shipping.isLoading || defaultSellerProfile.isLoading,
    isValid: shipping.isValid && defaultSellerProfile.isValid,
  }
}
