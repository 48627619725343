import React from 'react'
import { Link } from 'react-router-dom'

import type { ListItemProps, StorefrontItem } from '@/common/types'

import { useTags } from '../hooks/use-tags'

export const StorefrontGalleryItem: React.FC<ListItemProps<StorefrontItem>> = ({
  item,
}) => {
  const { url } = useTags(item.tags)

  return (
    <Link
      to={url}
      style={{ backgroundImage: `url(${item.imageSrc})` }}
      className="rounded-2xl h-96 relative bg-cover bg-center bg-no-repeat"
    >
      <div className="absolute inset-0 rounded-2xl opacity-30 bg-black mix-blend-multiply"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center gap-6 p-8">
        <h2 className="text-white text-5xl font-semibold text-center">
          {item.label}
        </h2>
        <button className="bg-white text-primary text-base font-semibold rounded-2xl py-3 px-6 ">
          View
        </button>
      </div>
    </Link>
  )
}
