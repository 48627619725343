import React from 'react'

import type { ListProps, StorefrontItem } from '@/common/types'

import { StorefrontGalleryItem } from './StorefrontGalleryItem'

export const GallerySection: React.FC<ListProps<StorefrontItem>> = ({
  items,
}) => {
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {items.map((item, itemKey) => (
        <StorefrontGalleryItem key={itemKey} item={item} />
      ))}
    </section>
  )
}
