import React from 'react'

import type { IconSource } from '@/common/components'
import { Icon } from '@/common/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

export interface BadgeProps {
  icon?: IconSource
  iconColor?: string
  iconSize?: number
  text: string
  className?: string
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200',
        props.className,
      )}
    >
      {!!props.icon && (
        <Icon
          icon={props.icon}
          color={props.iconColor ?? colors.primary.DEFAULT}
          size={props.iconSize ?? 16}
        />
      )}
      {props.text}
    </span>
  )
}
