import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { UserService } from '@/core'
import { getDeviceLocation } from '@/core/location'
import arrowDown from '@assets/icons/arrow-down.svg'

import { What } from './What'
import { When } from './When'
import { Where } from './Where'
import { Who } from './Who'

export const IndividualDigitalIdentity: React.FC = () => {
  const { profile } = useProfile()

  const [isShowData, setIsShowData] = useState(true)
  const [identityData, setIdentityData] = useState()
  const [documents, setDocuments] = useState({})

  const navigate = useNavigate()

  const handleClickTouchAudit = (id: string) => {
    navigate(`/profile/touchAudit/${id}`, { state: identityData })
  }

  useEffect(() => {
    if (profile?.id) {
      fetchUserAddresses()
      handleFetchDocuments()
    }
  }, [profile?.id])

  const fetchUserAddresses = async () => {
    try {
      const deviceLocation = await getDeviceLocation()
      const where = {
        latitude: deviceLocation.coords.latitude,
        longitude: deviceLocation.coords.longitude,
      }
      setIdentityData(where)
    } catch (e) {
      Logger.warn('Failed to get user addresses', e as Error)
      return []
    }
  }

  const handleFetchDocuments = async () => {
    try {
      const documents = await UserService.getDocuments(profile?.userId!)
      if (documents.length) {
        setDocuments(data[0])
      }
    } catch (e) {
      Logger.error('Error fetching documents', e as Error)
    }
  }

  return (
    <div className="w-full lg:max-w-full bg-white p-3 sm:p-6 my-8 ">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">
          <span className="border-l-4 border-primary mr-2"></span>
          {t(i18nKeys.profile.individual.title)}
        </h3>
        <button onClick={() => setIsShowData(!isShowData)}>
          <img
            src={arrowDown}
            alt="Arrow"
            width={20}
            className={`transform transition-transform duration-300 ${
              isShowData ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </button>
      </div>
      {isShowData ? (
        <div className="container mx-auto mt-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-auto items-start">
            <div className="flex flex-col gap-5">
              <Who handleClickTouchAudit={() => handleClickTouchAudit('1')} />
              <When handleClickTouchAudit={() => handleClickTouchAudit('4')} />
            </div>
            <div className="flex flex-col gap-5">
              <What
                handleClickTouchAudit={() => handleClickTouchAudit('2')}
                documents={documents}
              />
              <Where
                handleClickTouchAudit={() => handleClickTouchAudit('3')}
                identityData={identityData}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
