import { t } from 'i18next'

import type i18nKeys from '@/translations/en.json'

type TranslationObject = typeof i18nKeys
type ContentSelector = (t: TranslationObject) => any

export function translateKey(
  callback: (t: TranslationObject) => string,
): string {
  const path: string[] = []

  const proxy: any = new Proxy(
    {},
    {
      get(_, property: string) {
        path.push(property)
        return proxy
      },
    },
  )

  callback(proxy as TranslationObject)

  return path.join('.')
}

export function translate(
  translateSelector: ContentSelector,
  replace?: Record<string, any>,
): string

// eslint-disable-next-line no-redeclare
export function translate(key: string, replace?: Record<string, any>): string

// eslint-disable-next-line no-redeclare
export function translate(
  keyOrSelector: ContentSelector | string,
  replace: Record<string, any> | null = null,
) {
  const key =
    typeof keyOrSelector === 'string'
      ? keyOrSelector
      : translateKey(keyOrSelector)

  if (!replace || Object.keys(replace).length === 0) {
    return t(key)
  }

  let translated = t(key)

  Object.entries(replace).forEach(([key, value]) => {
    translated = translated.replaceAll(`{${key}}`, `${value}`)
  })

  return translated
}
