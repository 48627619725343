import { t } from 'i18next'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { sellItemsRoutes } from '@/routes'
import { sellServiceRoutes } from '@/routes/sell-service-routes'

import { useProductListing } from '../context'
import { ListingType } from '../types/listing-type'

export interface PageActionButtonsProps {
  continueDisabled?: boolean

  /**
   * Callback to be called when the user clicks the continue button.
   * @returns `true` to continue navigation, `false` to prevent navigation
   */
  onContinue?: () => boolean | Promise<boolean>
}

/** Navigation buttons for a sell page */
export const PageActionButtons: React.FC<PageActionButtonsProps> = (props) => {
  // todo: add navigation logic

  const nav = useNavigate()
  const location = useLocation()
  const { steps } = useProductListing()
  const { type } = useParams<{ type: string }>()

  const routes = useMemo(() => {
    return type === ListingType.Asset ? sellItemsRoutes : sellServiceRoutes
  }, [steps])

  const currentIndex = useMemo(
    () =>
      routes.findIndex(
        (r) => `/sell/${type}/${r.route.path}` === location.pathname,
      ),
    [routes, location],
  )

  const handleNavigate = useCallback(
    async (backwards: boolean = false) => {
      // is last route
      if (!backwards && currentIndex === routes.length - 1) {
        return
      }

      // is first route
      if (backwards && currentIndex === 0) {
        return
      }

      if (!backwards && props.onContinue) {
        const continueResult = await props.onContinue()

        if (!continueResult) {
          return
        }
      }

      const destinationIndex = currentIndex + (backwards ? -1 : 1)

      const destination = `/sell/${type}/${routes[destinationIndex].route.path!}`

      nav(destination)
    },
    [nav, currentIndex, routes, props.onContinue],
  )

  return (
    <div className="flex justify-end gap-7 w-full absolute bottom-0 px-6 py-5">
      <Button onClick={async () => handleNavigate(true)} mode="text">
        {t(i18nKeys.ui.previous)}
      </Button>
      <Button
        onClick={async () => handleNavigate()}
        disabled={props.continueDisabled}
      >
        {t(i18nKeys.ui.continue)}
      </Button>
    </div>
  )
}
