import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo } from 'react'

import { Input } from '@/common/components'
import { ErrorLabel } from '@/common/components/display'
import { useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { toNumber } from '@/core'

import type { CardOption, RadioOptionItem } from '..'
import {
  OptionCards,
  PageActionButtons,
  RadioListWithDescription,
  SellPage,
} from '..'
import { AUCTION_TYPES, SELL_METHODS } from '../constants'
import { useProductListing, useProductListingDispatch } from '../context'
import { AuctionType, SellingMethodType } from '../types'

export function SellMethodPage() {
  const { sellMethod: data } = useProductListing()
  const { sellMethodActions } = useProductListingDispatch()
  const { handleChangeField, handleChangeInput } = sellMethodActions
  const { errors, changeError } = useError({
    method: t(i18nKeys.sell.nav.method.error.method),
    auctionType: t(i18nKeys.sell.nav.method.error.auctionType),
    price: t(i18nKeys.sell.nav.method.error.price),
    startTime: t(i18nKeys.sell.nav.method.error.startTime),
    endTime: t(i18nKeys.sell.nav.method.error.endTime),
    startingPrice: t(i18nKeys.sell.nav.method.error.startingPrice),
    reservePrice: t(i18nKeys.sell.nav.method.error.reservePrice),
  })

  const methods = useMemo(
    () =>
      SELL_METHODS.map(
        (method) =>
          ({
            id: method.id,
            title: t(method.name),
            description: t(method.description),
          }) as CardOption,
      ),
    [],
  )

  const auctionTypes = useMemo(
    () =>
      AUCTION_TYPES.map(
        (auction, key) =>
          ({
            id: key,
            name: t(auction.name),
            description: t(auction.description),
            disabled: Boolean(auction.disabled),
          }) as RadioOptionItem,
      ),
    [t],
  )

  const isFixedPrice = useMemo(
    () => data.method === SellingMethodType.FixedPrice,
    [data],
  )

  const selectedAuction = useMemo(
    () => auctionTypes.find((a) => a.id === data.auctionType),
    [auctionTypes, data.auctionType],
  )

  const selectedMethod = useMemo(
    () => methods.find((m) => m.id === data.method),
    [methods, data.method],
  )

  const handleChangeAuctionType = useCallback(
    (option: RadioOptionItem) => {
      handleChangeField('auctionType', option.id)
    },
    [handleChangeField],
  )

  const handleChangeMethod = useCallback(
    (value: CardOption) => {
      handleChangeField('method', value.id)
    },
    [handleChangeField],
  )

  const handleContinue = () => {
    if (!selectedMethod) {
      changeError('method', true)
      return false
    }

    let hasError = false

    if (isFixedPrice) {
      const price = toNumber(data.price)

      if (!price || price <= 0) {
        changeError('price', true)
        hasError = true
      }
    } else {
      if (!selectedAuction) {
        changeError('auctionType', true)
      } else {
        const price = toNumber(data.price)
        const startTime = new Date(data.startTime)
        const endTime = new Date(data.endTime)
        const today = new Date()

        if (!price || price <= 0) {
          changeError(
            selectedAuction.id === AuctionType.Timed
              ? 'reservePrice'
              : 'startingPrice',
            true,
          )
          hasError = true
        }

        if (!data.startTime) {
          changeError('startTime', true)
          hasError = true
        }

        if (!data.endTime) {
          changeError('endTime', true)
          hasError = true
        }

        if (startTime <= today) {
          changeError(
            'startTime',
            true,
            t(i18nKeys.sell.nav.method.error.startTimeFuture),
          )
          hasError = true
        }

        if (endTime <= today) {
          changeError(
            'endTime',
            true,
            t(i18nKeys.sell.nav.method.error.endTimeFuture),
          )
          hasError = true
        }

        if (startTime >= endTime) {
          changeError(
            'endTime',
            true,
            t(i18nKeys.sell.nav.method.error.endTimeAfterStart),
          )
          hasError = true
        }
      }
    }

    return !hasError
  }

  useEffect(() => {
    changeError('method', false)
  }, [selectedMethod])

  useEffect(() => {
    changeError('auctionType', false)
  }, [selectedAuction, selectedMethod])

  useEffect(() => {
    changeError('price', false)
    changeError('startingPrice', false)
    changeError('reservePrice', false)
  }, [data.price, selectedMethod, selectedAuction])

  useEffect(() => {
    changeError('startTime', false)
  }, [data.startTime, selectedMethod, selectedAuction])

  useEffect(() => {
    changeError('endTime', false)
  }, [data.endTime, selectedMethod, selectedAuction])

  return (
    <SellPage title={t(i18nKeys.sell.nav.method.title)}>
      <OptionCards
        className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full mt-8"
        options={methods}
        selected={selectedMethod}
        onChange={handleChangeMethod}
      />
      <ErrorLabel error={errors.method.message} />

      <div className="flex flex-col w-full mt-12 gap-4">
        <ConditionalRendering renderIf={isFixedPrice}>
          <h3 className="text-center">
            {t(i18nKeys.sell.nav.method.priceTitle)}
          </h3>
          <Input
            id="price"
            name="price"
            label={t(i18nKeys.sell.nav.method.price)}
            placeholder={t(i18nKeys.sell.nav.method.pricePlaceholder)}
            onChange={handleChangeInput}
            value={data.price}
            error={errors.price.message}
            maxLength={4}
          />
        </ConditionalRendering>

        {!isFixedPrice && ( // TODO change to conditional rendering
          <>
            <RadioListWithDescription
              items={auctionTypes}
              onChange={handleChangeAuctionType}
              selected={selectedAuction}
            />
            <ErrorLabel error={errors.auctionType.message} />

            {selectedAuction?.id === AuctionType.Timed && (
              <>
                <Input
                  id="price"
                  name="price"
                  label={t(i18nKeys.sell.nav.method.reservePrice)}
                  placeholder={t(
                    i18nKeys.sell.nav.method.reservePricePlaceholder,
                  )}
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.reservePrice.message}
                  maxLength={4}
                />

                <Input
                  id="startTime"
                  name="startTime"
                  label={t(i18nKeys.sell.nav.method.startTime)}
                  type="datetime-local"
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.startTime.message}
                />
                <Input
                  id="endTime"
                  name="endTime"
                  label={t(i18nKeys.sell.nav.method.endTime)}
                  type="datetime-local"
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.endTime.message}
                />
              </>
            )}

            {selectedAuction?.id === AuctionType.Dutch && (
              <>
                <Input
                  id="price"
                  name="price"
                  label={t(i18nKeys.sell.nav.method.startingPrice)}
                  placeholder={t(
                    i18nKeys.sell.nav.method.startingPricePlaceholder,
                  )}
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.startingPrice.message}
                  maxLength={4}
                />
                <Input
                  id="startTime"
                  name="startTime"
                  label={t(i18nKeys.sell.nav.method.startTime)}
                  type="datetime-local"
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.startTime.message}
                />
                <Input
                  id="endTime"
                  name="endTime"
                  label={t(i18nKeys.sell.nav.method.endTime)}
                  type="datetime-local"
                  className="mt-4"
                  onChange={handleChangeInput}
                  error={errors.endTime.message}
                />
              </>
            )}
          </>
        )}
      </div>
      <PageActionButtons onContinue={handleContinue} />
    </SellPage>
  )
}
