import React from 'react'

export type UploadedFileBadgeProps = {
  file: File
  onDelete?: () => void
}

export const UploadedFileBadge: React.FC<UploadedFileBadgeProps> = ({
  file,
  onDelete,
}) => {
  const formatFileSize = (size: number): string => {
    if (size < 1024) {
      return size + ' B'
    }

    if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    }

    if (size < 1024 * 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + ' MB'
    }

    return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
  }

  return (
    <div className="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 gap-2">
      <span>{file.name}</span>
      <span>({formatFileSize(file.size)})</span>
      {onDelete ? (
        <button
          type="button"
          onClick={onDelete}
          className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      ) : null}
    </div>
  )
}
