import React from 'react'

export interface PreviewCardProps {
  children?: React.ReactNode
  title?: string
}

export const PreviewCard: React.FC<PreviewCardProps> = (props) => {
  return (
    <div className="bg-white p-4 sm:p-8 rounded">
      <h2 className="font-medium text-gray-900">{props.title}</h2>
      <div className="flex flex-col gap-2 mt-4">{props.children}</div>
    </div>
  )
}
