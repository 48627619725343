import type { PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React from 'react'

import { ItemsPagination } from '@/common/components/Pagination'
import i18nKeys from '@/common/i18nKeys'
import type { Order } from '@/common/types'
import { EmptyCard } from '@/features/listing'

import { OrderItem } from './OrderItem'

export type OrderListProps = PagedResponse<Order> & {
  onSearch: (pageNumber: number) => void
}

export const OrdersList: React.FC<OrderListProps> = (props) => {
  if (!props.items.length) {
    return <EmptyCard icon="cart" text={t(i18nKeys.profile.order.empty)} />
  }

  return (
    <>
      {props.items.map((order, key) => (
        <OrderItem key={key} order={order} />
      ))}

      <ItemsPagination
        onSearch={(e) => props.onSearch(e.selected)}
        totalPages={props.totalPages}
      />
    </>
  )
}
