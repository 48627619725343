import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { t } from 'i18next'
import React, { useEffect } from 'react'

import { Logger } from '@/config'

import i18nKeys from '../../../common/i18nKeys'

export type WalletInformationProps = {
  onClose: Function
  onSave: Function
}

// FIXME remove this component when Fireblocks is integrated in the backend. This is a temporary solution for getting a wallet address.
export default function WalletInformation({
  onClose,
  onSave,
}: WalletInformationProps) {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()

  useEffect(() => {
    if (isConnected) {
      Logger.info('Wallet already connected') // Warning: do not log the wallet address
      onSave({ address })
    }
  }, [address, isConnected, onSave])

  const openWalletConnect = () => {
    open({ view: 'Connect' })
  }
  return (
    <div className="flex flex-col justify-between">
      <div className="space-y-12 min-h[400]">
        <button onClick={openWalletConnect}>
          {t(i18nKeys.onboarding.personalInformation.connectWallet)}
        </button>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className="text-sm leading-6 text-gray-900"
          onClick={() => onClose()}
        >
          {t(i18nKeys.ui.back)}
        </button>
        <button
          type="submit"
          className="bg-[#7fc241] py-2 px-8 rounded-lg text-white"
        >
          {t(i18nKeys.ui.save)}
        </button>
      </div>
    </div>
  )
}

WalletInformation.displayName = 'WalletInformation'
