import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import type { StepsCardStatus } from '@/common/components'
import { Button, StepsCard } from '@/common/components'
import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import type { UserDocument } from '@/core'
import { UserService } from '@/core'
import { SubscriptionService } from '@/core/subscriptions/subscriptions.service'
import type { ActivePeriodResponse } from '@/core/subscriptions/subscriptions.types'

type ImproveStatus = {
  payment: StepsCardStatus
  verifyId: StepsCardStatus
}

export interface ProfileRowCardProps {
  setRenderIdentityVerification: () => void
}

export const BeingIdSection: React.FC<ProfileRowCardProps> = (props) => {
  const navigate = useNavigate()

  const { profile } = useProfile()

  const [subscription, setSubscription] = useState<ActivePeriodResponse>()
  const [documents, setDocuments] = useState<UserDocument[]>()

  const improveStatus: ImproveStatus | null = useMemo(() => {
    const payment: StepsCardStatus =
      subscription?.expiresAt && new Date() < new Date(subscription.expiresAt)
        ? 'completed'
        : 'current'

    const docsCount = documents?.length ?? 0

    const verifyId: StepsCardStatus =
      payment === 'current'
        ? 'upcoming'
        : docsCount > 0
          ? 'completed'
          : 'current'

    return { payment, verifyId }
  }, [subscription, documents])

  const handleSubscribe = async () => {
    navigate('/subscription')
  }

  const handleFetchStatus = async () => {
    try {
      const activePeriod = await SubscriptionService.getActivePeriod()
      setSubscription(activePeriod)
    } catch (e) {
      Logger.error('Error fetching active period', e as Error)
    }

    try {
      const documents = await UserService.getDocuments(profile?.userId!)
      const now = new Date()
      setDocuments(documents.filter((doc) => now < new Date(doc.expireAt)))
    } catch (e) {
      Logger.error('Error fetching documents', e as Error)
    }
  }

  useEffect(() => {
    if (!profile?.userId) return

    handleFetchStatus()
  }, [profile?.userId])

  return (
    <section
      id="being-id"
      className="w-full lg:max-w-full bg-white p-3 sm:p-6 my-8"
    >
      <h3 className="text-xl font-semibold">
        {t(i18nKeys.profile.digitalIdentity.beingId.subTitle)}
      </h3>
      <p className="text-sm">
        {t(i18nKeys.profile.digitalIdentity.beingId.subMessage)}
      </p>
      {improveStatus ? (
        <>
          <div className="flex flex-col sm:flex-row justify-between mt-4 gap-6">
            <StepsCard
              name={t(i18nKeys.profile.digitalIdentity.beingId.card1.subTitle)}
              step={1}
              icon="dollar"
              description={t(
                i18nKeys.profile.digitalIdentity.beingId.card1.message,
              )}
              status={improveStatus!.payment}
              additionalInfo="$10"
              onClick={handleSubscribe}
            />

            <StepsCard
              icon="idCard"
              name={t(i18nKeys.profile.digitalIdentity.beingId.card2.subTitle)}
              step={2}
              description={t(
                i18nKeys.profile.digitalIdentity.beingId.card2.message,
              )}
              status={improveStatus!.verifyId}
              onClick={props.setRenderIdentityVerification}
            />
          </div>
          <ConditionalRendering
            renderIf={improveStatus!.verifyId === 'completed'}
          >
            <div className="mt-4 flex justify-end">
              <Button mode="text" onClick={props.setRenderIdentityVerification}>
                {t(i18nKeys.profile.digitalIdentity.beingId.documentsBtn)}
              </Button>
            </div>
          </ConditionalRendering>
        </>
      ) : (
        <Spinner containerClassName="flex items-center justify-center mt-4" />
      )}
    </section>
  )
}
