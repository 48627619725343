import { t } from 'i18next'
import React from 'react'

import { featureFlags } from '@/config'

import { Icon } from '../../../common/components'
import tKeys from '../../../common/i18nKeys'
import styles from '../index.module.css'

export const DocumentsSection: React.FC = () => {
  return (
    <div className="block sm:flex">
      {featureFlags.beingIDGovernmentID ? (
        <div className="w-full lg:max-w-full bg-white p-4 me-5 mb-5 sm:mb-0">
          <h3 className="mb-2 font-bold">
            {t(tKeys.profile.beingId.card1.subTitle)}
          </h3>
          <div>
            <div
              className={
                'rounded-2xl flex bg-white p-4 border-dashed border-2 mr-1 m-auto h-full w-full' +
                styles.yellowCard
              }
            >
              <div className="m-auto grid">
                <div className="mx-auto flex-none bg-cover  overflow-hidden inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <Icon icon="idCard" size={36} color={'black'} />
                </div>

                <p className="mx-auto text-sm">
                  {t(tKeys.profile.beingId.card1.imgText)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="w-full lg:max-w-full bg-white p-4">
        <h3 className="mb-2 font-bold">
          {t(tKeys.profile.beingId.card2.subTitle)}
        </h3>
        <div>
          <div
            className={
              'rounded-2xl flex bg-white p-4 border-dashed border-2 mr-1 m-auto h-full w-full' +
              styles.yellowCard
            }
          >
            <div className="m-auto grid">
              <div className="mx-auto flex-none bg-cover  overflow-hidden inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                <Icon icon="idCard" size={36} color={'black'} />
              </div>

              <p className="mx-auto text-sm">
                {t(tKeys.profile.beingId.card2.imgText)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
