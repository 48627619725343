import { CategoryStatusEnum } from '@bit-ui-libs/common'

import envVariables from '@/common/envVariables'
import type { FeaturedCategory, MarketplaceMainCategory } from '@/common/types'

let id = 0

function createMockCategory(
  name: string,
  subcategories: string[] = [],
): FeaturedCategory {
  return {
    appName: envVariables.APP_NAME,
    name,
    id: `${id++}`,
    href: `/category/${name}`,
    subcategories: subcategories.length
      ? subcategories.map((subcategory) => createMockCategory(subcategory))
      : [],
    deletedAt: null,
    description: '',
    imageUrl: '',
    isFinal: false,
    parentId: '',
    state: CategoryStatusEnum.Normal,
  } as FeaturedCategory
}

export const mockedFeaturedCategories: Record<
  MarketplaceMainCategory,
  FeaturedCategory[]
> = {
  product: [
    createMockCategory('Electronics', [
      'Mobile Phones',
      'Laptops',
      'Tablets',
      'Accessories',
    ]),
    createMockCategory('Fashion', ['Clothing', 'Footwear', 'Accessories']),
    createMockCategory('Home & Furniture', [
      'Living Room',
      'Bedroom',
      'Dining Room',
      'Kitchen',
    ]),
    createMockCategory('Beauty & Health', [
      'Makeup',
      'Skincare',
      'Haircare',
      'Personal Care',
    ]),
  ],
  service: [
    createMockCategory('Home Services', [
      'Cleaning',
      'Plumbing',
      'Electrical',
      'Carpentry',
    ]),
    createMockCategory('Beauty Services', [
      'Haircut',
      'Facial',
      'Manicure',
      'Pedicure',
    ]),
    createMockCategory('Health Services', [
      'Doctor Consultation',
      'Dental Checkup',
      'Physiotherapy',
    ]),
    createMockCategory('Automobile Services', [
      'Car Wash',
      'Car Repair',
      'Car Painting',
    ]),
  ],
  event: [
    createMockCategory('Concerts', ['Rock', 'Pop', 'Jazz', 'Classical']),
    createMockCategory('Sports', [
      'Cricket',
      'Football',
      'Tennis',
      'Basketball',
    ]),
    createMockCategory('Exhibitions', [
      'Art',
      'Photography',
      'Craft',
      'Fashion',
    ]),
    createMockCategory('Workshops', ['Cooking', 'Dance', 'Music', 'Fitness']),
  ],
}
