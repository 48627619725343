import type {
  AssetCategory,
  AssetType,
  AssetTypeStep,
  ChainAssetEvent,
} from '@bit-ui-libs/common'

import type { FormHookActions } from '@/common/hooks'
import type { ActionMap, SellerShippingAddress } from '@/common/types'

import type {
  AssetDetailsForm,
  AssetImage,
  DeliveryForm,
  LibraryAsset,
  PayoutAccount,
  PayoutCurrencyType,
  PrivacyOption,
  SellMethodForm,
  TransactionCompletenessType,
} from '../types'

export type ProductListingActions =
  ActionMap<ProductListingPayload>[keyof ActionMap<ProductListingPayload>]

export enum ActionType {
  SetProducts,
  SetUseCurrentAsset,
  SetSelectedAsset,
  SetImages,
  AddImage,
  RemoveImage,
  SetProductDetails,
  SetCompletenessType,
  SetPrivacy,
  SetBankAccounts,
  SetCryptoAccounts,
  SetPayoutCurrency,
  SetSelectedAccount,
  SetSellMethod,
  SetAddresses,
  SetDelivery,
  SetBuyerLevel,
  SetCategories,
  SetAssetType,
  SetSteps,
  SetEvent,
}

type ProductListingPayload = {
  [ActionType.SetProducts]: LibraryAsset[]
  [ActionType.SetUseCurrentAsset]: boolean
  [ActionType.SetSelectedAsset]: LibraryAsset
  [ActionType.SetImages]: AssetImage[]
  [ActionType.AddImage]: AssetImage
  [ActionType.RemoveImage]: AssetImage
  [ActionType.SetProductDetails]: AssetDetailsForm
  [ActionType.SetCompletenessType]: TransactionCompletenessType
  [ActionType.SetPrivacy]: PrivacyOption
  [ActionType.SetBankAccounts]: PayoutAccount[]
  [ActionType.SetCryptoAccounts]: PayoutAccount[]
  [ActionType.SetPayoutCurrency]: PayoutCurrencyType
  [ActionType.SetSelectedAccount]: PayoutAccount
  [ActionType.SetSellMethod]: SellMethodForm
  [ActionType.SetAddresses]: SellerShippingAddress[]
  [ActionType.SetDelivery]: DeliveryForm
  [ActionType.SetBuyerLevel]: number
  [ActionType.SetCategories]: AssetCategory[]
  [ActionType.SetAssetType]: AssetType
  [ActionType.SetSteps]: AssetTypeStep[]
  [ActionType.SetEvent]: ChainAssetEvent
}

export type ProductListingDispatchActions = {
  setProducts: (products: LibraryAsset[]) => void
  setUseCurrentAsset: (value: boolean) => void
  setSelectedAsset: (asset: LibraryAsset) => void
  setImages: (images: AssetImage[]) => void
  addImage: (image: AssetImage) => void
  removeImage: (image: AssetImage) => void
  setCompletenessType: (completeness: TransactionCompletenessType) => void
  setPrivacy: (privacy: PrivacyOption) => void
  setBankAccounts: (accounts: PayoutAccount[]) => void
  setCryptoAccounts: (accounts: PayoutAccount[]) => void
  setSelectedAccount: (accounts: PayoutAccount) => void
  setPayoutCurrency: (payout: PayoutCurrencyType) => void
  setAddresses: (addresses: SellerShippingAddress[]) => void
  setBuyerLevel: (buyerLevel: number) => void
  changeCategory: (selected: AssetCategory, key: number) => void
  setAssetType: (assetType: AssetType) => void
  setEvent: (event: ChainAssetEvent) => void
  handleFetchSteps: (assetTypeId: string) => Promise<void>
  productDetailsActions: FormHookActions<AssetDetailsForm>
  sellMethodActions: FormHookActions<SellMethodForm>
  deliveryActions: FormHookActions<DeliveryForm>
}
