import axios from 'axios'

import { Logger } from '@/config'

class FileNotFoundError extends Error {
  constructor() {
    super()
    this.name = 'FileNotFoundError'
  }
}

class DownloadFileError extends Error {
  constructor() {
    super()
    this.name = 'DownloadFileError'
  }
}

class UploadFileError extends Error {
  constructor() {
    super()
    this.name = 'UploadFileError'
  }
}

export class OneDriveService {
  static async listAllOneDriveFiles(
    accessToken: string,
  ): Promise<{ id: string; name: string }[]> {
    try {
      const response = await axios.get(
        'https://graph.microsoft.com/v1.0/me/drive/root/children',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      return response.data.value
    } catch (error) {
      Logger.error('File not found', undefined, error as Error)
      throw new FileNotFoundError()
    }
  }

  static async downloadFileFromOneDrive(
    fileId: string,
    accessToken: string,
  ): Promise<string | null> {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], { type: 'text/plain' })
      const text = await blob.text()
      return text
    } catch (error) {
      Logger.error('Error downloading file', undefined, error as Error)
      throw new DownloadFileError()
    }
  }

  static async handleDownloadOneDriveAction(
    accessToken: string,
    fileName: string,
  ): Promise<string | null> {
    try {
      const files = await this.listAllOneDriveFiles(accessToken)
      const file = files.find((f: any) => f.name === fileName)
      if (file) {
        const content = await this.downloadFileFromOneDrive(
          file.id,
          accessToken,
        )
        if (content) {
          return content
        } else {
          Logger.error('File content is empty or not readable')
          throw new FileNotFoundError()
        }
      } else {
        Logger.error('No files found with the specified name')
        throw new FileNotFoundError()
      }
    } catch (error) {
      Logger.error('Error downloading file', undefined, error as Error)
      throw new DownloadFileError()
    }
  }

  static async uploadFileOnOneDrive(
    encryptedText: string,
    accessToken: string,
    fileName: string,
  ): Promise<void> {
    const blob = new Blob([encryptedText], { type: 'text/plain' })
    try {
      const response = await axios.put(
        `https://graph.microsoft.com/v1.0/me/drive/root:/${fileName}:/content`,
        blob,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'text/plain',
          },
        },
      )
      if (response.status === 201 || response.status === 200) {
        return
      } else {
        Logger.error('Error uploading file')
        throw new UploadFileError()
      }
    } catch (error) {
      Logger.error('Error uploading file', undefined, error as Error)
      throw new UploadFileError()
    }
  }
}
