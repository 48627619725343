import React from 'react'

import { Icon } from '@/common/components'
import { classNames } from '@/core'
import { colors } from '@/theme'

export type AlertActionButton = {
  text: string
  onClick?: () => void | Promise<void>
}

export interface AlertWithActionsProps {
  title: string
  text: string
  className?: string
  actions: AlertActionButton[]
}

export const AlertWithActions: React.FC<AlertWithActionsProps> = (props) => {
  return (
    <div className={classNames('rounded-md bg-info-50 p-4', props.className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon icon="infoCircle" size={20} color={colors.info.DEFAULT} />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-info-800">{props.title}</h3>
          <div className="mt-2 text-sm text-info-700">
            <p>{props.text}</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex gap-3 items-center">
              {props.actions.map((action, key) => (
                <button
                  key={key}
                  type="button"
                  onClick={action.onClick}
                  className="rounded-md bg-info-50 px-2 py-1.5 text-sm font-medium text-info-800 hover:bg-info-100 focus:outline-none focus:ring-2 focus:ring-info-600 focus:ring-offset-2 focus:ring-offset-info-50"
                >
                  {action.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
