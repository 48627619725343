export type ChainServiceRequest = {
  application: Application
  subject: Subject
  device: DeviceRequest
  data: Data
}

export type Application = {
  id: string
}

export type Subject = {
  subjectType: SubjectType
  references: SubjectReference[]
  orgId?: string
}

export type SubjectReference = {
  type: ReferenceType
  value: string
}

export type DeviceRequest = {
  deviceId: string
  deviceName?: string
  deviceType?: string
  date: string
  geolocation: Geolocation
  ipAddress: string
}

export enum HardwareLevel {
  LevelA = 'Level A',
  LevelB = 'Level B',
  LevelC = 'Level C',
  LevelD = 'Level D',
}

export type DeviceResponse = DeviceRequest & {
  hardwareLevel: HardwareLevel
  deviceName: string
  deviceType: string
}

export type Geolocation = {
  latitude: number
  longitude: number
  meanSeaLevel: number
}

export type Data = {
  date: string
  service: Service
}

export type Service = {
  serviceType: string
  serviceCategory: string[]
  serviceName: string
  serviceProperties: ServiceProperty[]
  metadata: ServiceMetadata
  isFavorite: boolean
}

export type ServiceMetadata = {
  serviceTypeId: string
  qrId?: string
  timezone?: string
  nftTokenId?: string
  description?: string
  immutableTime?: string
  nftMetadataUrl?: string
  nftSmartContractAddress?: string
  platform?: string
  immutableTransactionHash?: string
  city?: string
  state?: string
  country?: string
  categoryState?: 'NORMAL' | 'FIXED'
}

export type ServiceProperty = {
  id: string
  name: string
  value: string
}

export enum SubjectType {
  EMPLOYEE = 'EMPLOYEE',
  DRIVER = 'DRIVER',
  USER = 'USER',
  ASSET_OWNER = 'ASSET_OWNER',
}

export enum ReferenceType {
  PHONE = 'PHONE',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  AUTH0_IDENTITY = 'AUTH0_IDENTITY',
  END_USER_ID = 'END_USER_ID',
  ADMIN_USER_ID = 'ADMIN_USER_ID',
}

export enum EventType {
  CHECKIN = 'CHECKIN',
  CHAIN_OBJECT = 'CHAIN_OBJECT',
  CHAIN_SERVICES = 'CHAIN_SERVICES',
  CHAIN_USER = 'CHAIN_USER',
  CHAIN_DEVICE = 'CHAIN_DEVICE',
  CHAIN_ORG = 'CHAIN_ORG',
  CHAIN_WITNESS = 'CHAIN_WITNESS',
  QR_ASSIGN = 'QR_ASSIGN',
}

export enum EventStatus {
  INITIALIZED = 'INITIALIZED',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  MINTED = 'MINTED',
}

export type ChainServiceResponse = {
  id: string
  eventType: EventType
  application: string
  serviceType: string
  device: DeviceResponse
  weather: string
  status: EventStatus
  subjectType: SubjectType
  attachments: [
    {
      docId: string
      mimeType: string
      docType: 'PHOTO'
      classification: 'FACE'
      name: string
      description: string
      url: string
      thumbUrl: string
      isMain: true
    },
  ]
  eventDate: string
  data: Data
  subjectReferences: SubjectReference[]
}
