import React, { useCallback, useEffect, useMemo, useState } from 'react'
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import envVariables from '@/common/envVariables'

import type { TypeaheadOption } from './Typeahead'
import { Typeahead } from './Typeahead'

// TODO add i18n
const DEFAULT_LABEL = 'Address'

export interface AddressAutoCompleteResult {
  streetNumber?: string
  streetName?: string
  city?: string
  state?: string
  country?: string
  countryCode?: string
  zipCode?: string
}

export interface AddressAutoCompleteProps {
  /**
   * @param {string} [props.label] The label to display above the input field.
   */

  label?: string

  /**
   * A callback function that is called when the user selects an address.
   *
   * @param value the result of the address selection.
   * @returns {void}
   */
  onChange: (value: AddressAutoCompleteResult) => void
}

const googleResultToAddress = (result: any): AddressAutoCompleteResult => {
  const address: AddressAutoCompleteResult = {}
  result.address_components?.forEach((component: any) => {
    if (component.types.includes('street_number')) {
      address.streetNumber = component.long_name
    } else if (component.types.includes('route')) {
      address.streetName = component.long_name
    } else if (component.types.includes('locality')) {
      address.city = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      address.state = component.long_name
    } else if (component.types.includes('country')) {
      address.country = component.long_name
      address.countryCode = component.short_name
    } else if (component.types.includes('postal_code')) {
      address.zipCode = component.long_name
    }
  })

  return address
}

/**
 * @function
 * @description A function that is called when the user selects an address.
 * @name AddressAutoCompleteOnChangeFunction
 * @argument {AddressAutoCompleteResult} value
 * @returns {void}
 */

/**
 * A React component that displays a {@link Typeahead} component that uses the Google Places API to provide address suggestions.
 * @param {AddressAutoCompleteProps} props The props of type {@link AddressAutoCompleteProps} for the component.
 * @returns {React.ReactElement} The component.
 */
export const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
  label,
  onChange,
}: AddressAutoCompleteProps): React.ReactElement => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({
    apiKey: envVariables.GCP_API_KEY,
  })

  const [selected, setSelected] = useState<TypeaheadOption>()

  useEffect(() => {
    if (selected && selected.id) {
      placesService.getDetails(
        {
          placeId: selected.id,
        },
        (place: any, status: string) => {
          if (status === 'OK') {
            const address = googleResultToAddress(place)
            onChange(address)
          }
        },
      )
    }
  }, [selected, placesService])

  const onFilterChangeHandler = useCallback(
    (query: string) => {
      getPlacePredictions({
        input: query,
      })
    },
    [getPlacePredictions],
  )

  const options = useMemo(() => {
    return placePredictions.map((prediction) => ({
      id: prediction.place_id,
      text: prediction.description,
    }))
  }, [placePredictions])

  return (
    <Typeahead
      label={label ?? DEFAULT_LABEL}
      loading={isPlacePredictionsLoading}
      onChange={setSelected}
      onFilterChange={onFilterChangeHandler}
      options={options}
      selected={selected}
    />
  )
}

AddressAutoComplete.displayName = 'AddressAutoComplete'
