import { t } from 'i18next'
import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { authContext } from '../../common/context/auth_context'
import i18nKeys from '../../common/i18nKeys'
import Spinner from '../../components/Spinner'

export const Verification = () => {
  const { exchangeCode, loading, profileLoaded, authenticated } =
    useContext(authContext)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const state = searchParams.get('state')

  useEffect(() => {
    if (code && state) {
      exchangeCode(code, state)
    }
  }, [])

  useEffect(() => {
    if (!loading && authenticated && profileLoaded) {
      navigate('/personalInformation')
    }
  }, [loading, authenticated, profileLoaded])

  return (
    <div>
      <div className="w-full p-6">
        <Spinner
          text={t(i18nKeys.onboarding.verifyingInformation)}
          textClassName="text-sm mt-3 text-center"
          className="m-auto"
        />
      </div>
    </div>
  )
}
