import type { PaymentInfo } from '@bit-ui-libs/common'
import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@/common/components'
import { useProfile } from '@/common/hooks'
import { classNames } from '@/core'
import { InfoAlert, PayoutAccountCard } from '@/features/listing'
import { colors } from '@/theme'

export type LinkedAccountsProps = {
  className?: string
  accounts: PaymentInfo[]
}

export const LinkedAccounts: React.FC<LinkedAccountsProps> = (props) => {
  const { sellerProfile } = useProfile()

  return (
    <div className={classNames('bg-white px-6 py-5', props.className)}>
      <div className="flex justify-between items-center">
        <h2 className="text-base font-medium">Linked Accounts</h2>
        {sellerProfile ? (
          <Link
            to="/profile/addPayment"
            className="flex items-center gap-2 text-primary font-semibold"
          >
            <Icon icon="card" color={colors.primary.DEFAULT} size={16} />
            Add Account
          </Link>
        ) : (
          <button
            disabled
            className="flex items-center gap-2 bg-gray-200 text-gray-400 font-semibold px-4 py-2 rounded-md"
          >
            <Icon icon="card" className="fill-gray-400 size-4" />
            Add Account
          </button>
        )}
      </div>
      {!sellerProfile ? (
        <InfoAlert
          title="Seller profile required"
          text="You need to setup your seller profile to add new bank accounts."
          className="mt-4"
        />
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
        {props.accounts.map((payment, key) => (
          <PayoutAccountCard
            key={key}
            account={{
              id: payment.id,
              entity: payment.data.bankName!,
              owner: payment.data.username!,
              isFavorite: payment.isDefault,
            }}
            defaultIcon="bank"
            className=""
            mode="button"
          />
        ))}
      </div>
    </div>
  )
}
