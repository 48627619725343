import React from 'react'

import Spinner from '@/components/Spinner'

export const LoadingPage: React.FC = () => {
  return (
    <div className="min-h-[80vh]">
      <section
        className={
          'mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white transition-all duration-300 ease-in-out flex items-center justify-center py-12 sm:py-48 mb-12'
        }
      >
        <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0 flex flex-col">
          <Spinner />
        </div>
      </section>
    </div>
  )
}
