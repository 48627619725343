import { t } from 'i18next'
import React from 'react'

import type { IconSource } from '@/common/components'
import { Icon } from '@/common/components'
import { HorizontalCard } from '@/common/components/display'
import i18nKeys from '@/common/i18nKeys'
import { colors } from '@/theme'

import type { PayoutAccount } from '../types'

export interface PayoutAccountCardProps {
  account: PayoutAccount
  defaultIcon: IconSource
  className?: string
  mode?: 'button' | 'card'
  onClick?: () => void | Promise<void>
}

export const PayoutAccountCard: React.FC<PayoutAccountCardProps> = (props) => {
  return (
    <HorizontalCard
      title={props.account.entity}
      description={props.account.owner}
      action={props.mode === 'card' ? t(i18nKeys.ui.change) : undefined}
      onClick={props.onClick}
      mode={props.mode}
      image={
        props.account.imageSrc ? (
          <img
            src={props.account.imageSrc}
            alt={props.account.entity}
            className="size-14"
          />
        ) : (
          <Icon icon={props.defaultIcon} size={56} color={colors.gray[500]} />
        )
      }
      className={props.className}
    />
  )
}
