import type { Device, DeviceVdt } from '@bit-ui-libs/common'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import {
  DEVICE_IS_PERSONAL_DEFAULT_VALUE,
  DeviceService,
  Web3Service,
} from '@/core'
import {
  getDeviceModel,
  getDeviceOSName,
  getUniqueDeviceName,
} from '@/core/devices/device-utils'
import { getDeviceLocation, getGeolocationPermission } from '@/core/location'

import { authContext } from '../context/auth_context'

import { useNotify } from '.'

export const useDeviceLocation = () => {
  const { fromError } = useNotify()
  const [isDeviceRegistered, setIsDeviceRegistered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [permissionState, setPermissionState] = useState<PermissionState>()

  const alreadyGranted = useRef(false)

  const { profile, userVdt } = useContext(authContext)

  const askForPermission = async () => {
    setPermissionState(undefined)
    alreadyGranted.current = false
  }

  useEffect(() => {
    if (alreadyGranted.current || permissionState === 'denied') {
      return
    }

    if (!permissionState || permissionState !== 'granted') {
      // Just to request the permission
      getDeviceLocation().catch(() => {
        setPermissionState('denied')
      })
      return
    }

    alreadyGranted.current = true
    registerNewDevice()
  }, [permissionState])

  useEffect(() => {
    let permission: PermissionStatus | null = null

    const getPermission = async () => {
      permission = await getGeolocationPermission()
      setPermissionState(permission.state)

      if (permission.state === 'granted' && !isDeviceRegistered) {
        setIsLoading(true)
      }

      permission.onchange = () => {
        setPermissionState(permission?.state)
        if (permission?.state === 'granted' && !isDeviceRegistered) {
          setIsLoading(true)
        }
      }
    }

    getPermission()

    return () => {
      if (permission) {
        permission.onchange = null
      }
    }
  }, [])

  const registerNewDevice = async () => {
    try {
      setIsLoading(true)
      const deviceLocation = await getDeviceLocation()
      const deviceStatus = await getDeviceStatus()
      if (!deviceStatus.deviceExists || !deviceStatus.device) {
        deviceStatus.device = await registerDevice(
          deviceLocation.coords.latitude,
          deviceLocation.coords.longitude,
        )
        deviceStatus.deviceExists = true
      }

      if (!deviceStatus.vdtExists) {
        await mintDevice(deviceStatus.device)
        deviceStatus.vdtExists = true
      }

      if (!userVdt) {
        await initUserVdt(deviceStatus.device)
      }

      setIsDeviceRegistered(true)
      setIsLoading(false)
    } catch (error) {
      fromError({
        error: error as Error,
        defaultMessage: 'Failed to register device',
      })
    }
  }

  const registerDevice = useCallback(
    async (latitude: number, longitude: number) => {
      return DeviceService.registerDevice({
        externalId: uuid(),
        latitude,
        longitude,
        meanSeaLevel: 0, // TODO can we get this value? the current Geolocation API does not support it.
        ownerUserId: profile?.userId!,
        recipientAddress: profile?.walletAddress!,
        model: getDeviceModel(),
        name: getUniqueDeviceName(),
        operativeSystem: getDeviceOSName(),
      })
    },
    [profile?.walletAddress, profile],
  )

  const initUserVdt = useCallback(
    async (device: Device) => {
      return Web3Service.initUserVdt({
        deviceId: device.id,
        latitude: device.latitude ?? 0,
        longitude: device.longitude ?? 0,
        meanSeaLevel: device.meanSeaLevel ?? 0,
        recipientAddress: profile?.walletAddress!,
      })
    },
    [profile?.walletAddress],
  )

  const getDeviceStatus = useCallback(async () => {
    const devices = await DeviceService.searchDevices({
      isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
      name: getUniqueDeviceName(),
      userId: profile?.userId!,
      // TODO add orgId filter when available
    })

    const deviceExists = devices.totalCount > 0
    let deviceVdt: DeviceVdt | null = null

    if (deviceExists) {
      // Verify if it is minted
      const firstDevice = devices.items[0]
      deviceVdt = await Web3Service.getDeviceVdt(firstDevice.id)
    }

    return {
      deviceExists,
      vdtExists: deviceVdt !== null,
      device: deviceExists ? devices.items[0] : null,
    }
  }, [profile])

  const mintDevice = useCallback(
    async (device: Device) => {
      DeviceService.initDeviceVdt({
        deviceId: device.id,
        latitude: device.latitude ?? 0,
        longitude: device.longitude ?? 0,
        meanSeaLevel: device.meanSeaLevel ?? 0,
        recipientAddress: profile?.walletAddress!,
      })
    },
    [profile?.walletAddress],
  )

  return {
    isLoading,
    isDeviceRegistered,
    permissionState,
    showLoading: isLoading && permissionState !== 'prompt',
    showPermission: !isDeviceRegistered && permissionState !== 'granted',
    handleAskForPermission: askForPermission,
  }
}
