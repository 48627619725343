import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'

import { OrderDetail } from '../components/OrderDetail'
import { useOrderDetail } from '../hooks/use-order-detail'

export const OrderDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const [pollingCount, setPollingCount] = useState<number>(0)

  const { order, tracking, isLoading } = useOrderDetail({
    orderId: id!,
    pollingCount,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (pollingCount < 4) {
        setPollingCount((prev) => prev + 1)
      }
    }, 30000)
    return () => clearInterval(intervalId)
  }, [pollingCount])

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 sm:py-8 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t(i18nKeys.profile.order.detail.title)}
        </h1>
        {isLoading && pollingCount === 0 ? (
          <Spinner containerClassName="flex items-center justify-center py-12" />
        ) : (
          <OrderDetail
            order={order!}
            tracking={tracking!}
            pollingCount={pollingCount}
            setPollingCount={setPollingCount}
          />
        )}
      </div>
    </div>
  )
}
