import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@/common/components'
import Spinner from '@/components/Spinner'
import type { WishListListItem } from '@/core'
import { useAsset } from '@/features/profile/tracking/hooks/use-asset'

import { useListing } from '../hooks/use-listing'

export type WishlistItemProps = {
  item: WishListListItem
  onRemove?: () => void | Promise<void>
}

export const WishlistItem: React.FC<WishlistItemProps> = (props) => {
  const { description, imageSrc } = useAsset({
    assetId: props.item.assetId,
  })
  const { listing } = useListing({ assetId: props.item.assetId })

  return (
    <div className="group relative">
      <div
        className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 cursor-pointer"
        role="tab"
      >
        {imageSrc ? (
          <img
            alt={props.item.name}
            src={imageSrc}
            className="h-full w-full object-cover object-center cursor-pointer border"
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        )}
        <div className="bg-black opacity-0 group-hover:opacity-20 transition-all duration-200"></div>
      </div>

      <div className="p-3 cursor-pointer transition-all duration-200 absolute top-0 right-0 opacity-0 group-hover:opacity-100">
        <Icon
          icon="heart"
          size={20}
          className="fill-primary-200 hover:fill-primary-300"
          onClick={props.onRemove}
        />
      </div>

      <Link
        to={`/search/listing/${listing?.id}/event/${props.item.eventIdRef}/detail`}
      >
        <h3 className="mt-4 text-sm text-gray-700">{props.item.name}</h3>

        {listing?.price !== null && listing?.price !== undefined ? (
          <p className="mt-1 text-lg font-medium text-gray-900">
            ${listing?.price}
          </p>
        ) : null}
        <p className="mt-1 text-sm text-gray-700">{description}</p>
      </Link>
    </div>
  )
}
