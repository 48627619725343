import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import type { ListItemProps } from '@/common/types'
import { ConditionalRendering } from '@/components'
import type { CustomerAssetWithPrice } from '@/core'
import { Badge } from '@/features/listing'
import camera from '@assets/images/camera.png'

export const StorefrontProduct: React.FC<
  ListItemProps<CustomerAssetWithPrice>
> = ({ item }) => {
  return (
    <Link
      to={`/search/listing/${item.id}/event/${item.eventIdRef}/detail`}
      className="rounded-2xl md:h-80 bg-white flex flex-col md:flex-row items-center py-4 px-0 md:px-8 gap-6"
    >
      <img
        src={camera} // TODO replace with real image
        alt={item.name}
        className="h-full w-full max-w-72 max-h-52 object-contain"
      />
      <div className="flex flex-col gap-2 flex-1 border-t w-full md:w-auto p-4 md:px-0 md:py-16 border-t-gray-100 md:border-none">
        <div className="flex justify-between">
          <span className="text-secondary text-sm font-bold">Marketplace</span>

          <span className="font-bold text-sm text-primary-500">
            {item.level}
          </span>
        </div>
        <h2 className="text-black text-base font-semibold">{item.name}</h2>
        <p className="text-sm text-gray-400">
          {item.serializedCategories.join(' | ')}
        </p>
        <span className="text-black text-2xl font-bold">${item.price}</span>
        <div className="flex gap-4 items-center">
          <ConditionalRendering
            renderIf={!item.isVerifiedByOfficial && !item.isVerifiedByWitness}
          >
            <Badge text={t(i18nKeys.common.selfVerified)} icon="certificate" />
          </ConditionalRendering>
          <ConditionalRendering renderIf={item.isMinted}>
            <Badge text={t(i18nKeys.common.vdtMinted)} icon="link" />
          </ConditionalRendering>
        </div>
      </div>
    </Link>
  )
}
