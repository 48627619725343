import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { t } from 'i18next'
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { Button, Icon } from '@/common/components'
import { BEING_ID_LEVELS_WHO } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import envVariables from '@/common/envVariables'
import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import {
  convertISOToMMDDYYYY,
  AssetsService,
  UserService,
  calculateAge,
  formatDateToEST,
  formatTimeToEST,
} from '@/core'
import { EventService } from '@/core/events'
import { Web3Service } from '@/core/web3'
import { colors } from '@/theme'
import calendar from '@assets/icons/calendar.svg'
import when from '@assets/icons/clock.svg'
import where from '@assets/icons/global.svg'
import info from '@assets/icons/info.svg'
import locationIcn from '@assets/icons/location.svg'
import what from '@assets/icons/qr.svg'
import who from '@assets/icons/user-scan.svg'
import verified from '@assets/icons/verified.svg'

enum Tabs {
  Who = 'Who',
  What = 'What',
  Where = 'Where',
  When = 'When',
}
const defaultPosition = {
  lat: 0,
  lng: 0,
}

export const TouchAudit: React.FC = () => {
  const [activeTab, setActiveTab] = useState('WHERE')
  const { profile } = useProfile()
  const { userVdt } = useContext(authContext)
  const [qrCode, setQRCode] = useState<string>('')
  const [documents, setDocuments] = useState([])
  const [publicTokens, setPublicTokens] = useState([])
  const [transactionDetails, setTransactionDetails] = useState([])

  const location = useLocation()
  const allData = location.state

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    setActiveTabById(id || '')
  }, [id])

  const setActiveTabById = (id: string) => {
    setActiveTab(
      Tabs[Object.keys(Tabs)[parseInt(id, 10) - 1] as keyof typeof Tabs] ||
        Tabs.Where,
    )
  }

  useEffect(() => {
    if (userVdt?.qrId) {
      handleGetUserVdtPublic(userVdt?.qrId)
    }
    if (userVdt?.userId) {
      handleGetPublicTokens(userVdt?.userId)
      handleFetchDocuments(userVdt?.userId)
      handleGetTransactionData()
    }
  }, [userVdt])

  const handleGetUserVdtPublic = async (qrId: string) => {
    const userVdtPublicRes = await Web3Service.getUserVdtPublic(qrId)
    if (userVdtPublicRes?.ipfsMetadata?.qrCode?.url) {
      setQRCode(userVdtPublicRes?.ipfsMetadata?.qrCode?.url)
    }
  }

  const handleGetPublicTokens = async (ownerId: string) => {
    const publicToken = await AssetsService.getPublicTokens(ownerId)
    if (publicToken?.items?.length) {
      setPublicTokens(publicToken?.items)
    }
  }

  const handleGetTransactionData = async () => {
    if (userVdt?.immutableTransactionHash) {
      const res = await Web3Service.getTransactionData(
        userVdt?.immutableTransactionHash,
      )
      if (res?.result) {
        createTransactionList(res?.result)
      }
    }
  }

  const createTransactionList = (data: any) => {
    const transactionList = [
      {
        label: t(i18nKeys.profile.individual.blockchain),
        value: userVdt?.platform,
      },
      {
        label: t(i18nKeys.profile.individual.transactionHash),
        value: userVdt?.immutableTransactionHash,
      },
      {
        label: t(i18nKeys.profile.individual.status),
        value: 'Completed',
      },
      {
        label: t(i18nKeys.profile.individual.block),
        value: userVdt?.blockNumber,
      },
      {
        label: t(i18nKeys.profile.individual.timestamp),
        value: formatDateToEST(userVdt?.immutableTime),
      },
      {
        label: t(i18nKeys.profile.individual.ownerWallet),
        value: userVdt?.walletAddress,
      },
      {
        label: t(i18nKeys.profile.individual.tokenType),
        value: 'ERC-721',
      },
      {
        label: t(i18nKeys.profile.individual.value),
        value: getMaticValue(data?.value),
      },
      {
        label: t(i18nKeys.profile.individual.transactionFee),
        value: getTransactionFee(data?.gas, data?.gasPrice),
      },
      {
        label: t(i18nKeys.profile.individual.gasPrice),
        value: getGasPrice(data?.gasPrice),
      },
    ]
    setTransactionDetails(transactionList)
  }

  const getMaticValue = (value: string) => {
    const decimalValue = parseInt(value, 16)
    return `${decimalValue} Matic`
  }

  const getGasPrice = (hexGasPrice: string) => {
    const decimalGasPriceWei = parseInt(hexGasPrice, 16)
    const decimalGasPriceMATIC = decimalGasPriceWei / 1e18
    const decimalGasPriceGWei = decimalGasPriceWei / 1e9
    return `${decimalGasPriceMATIC.toFixed(10)} MATIC (${decimalGasPriceGWei.toFixed(1)} GWei)`
  }

  const getTransactionFee = (gasHex: string, maxFeePerGasHex: string) => {
    const gasUsed = parseInt(gasHex, 16)
    const maxFeePerGas = parseInt(maxFeePerGasHex, 16)
    const transactionFeeWei = gasUsed * maxFeePerGas
    const transactionFeeMATIC = transactionFeeWei / 1e18
    const formattedFee = transactionFeeMATIC.toFixed(19)
    const maticToDollarRate = 1
    const dollarValue = (transactionFeeMATIC * maticToDollarRate).toFixed(2)
    return `${formattedFee} Matic ($${dollarValue})`
  }

  const handleFetchDocuments = async (userId: string) => {
    const documents = await UserService.getDocuments(userId)
    if (documents.length) {
      setDocuments(documents)
    }
  }

  // Left panel preview details for each tab
  const renderLeftPanelDetails = () => {
    const data = profile?.addresses?.[0]
    switch (activeTab) {
      case Tabs.Where:
        return (
          <>
            <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2 mt-3">
              <span className="border-l-2 border-[#ffffff] h-6 inline-block"></span>
              <img src={where} alt="where" width={16} />
              <h2 className="font-semibold">
                {t(i18nKeys.profile.individual.where)}
              </h2>
            </div>
            <div className="border-l-2 border-gray-200 pl-2">
              <div className="mt-4 bg-white rounded-md shadow-sm">
                <div className="p-2">
                  <div className="flex items-center gap-3">
                    <p className="text-gray-500">
                      {t(i18nKeys.profile.individual.legalAddress)}
                    </p>
                    {data?.isAddressMatched ? (
                      <p className="font-200 text-primary-500 flex items-center gap-1">
                        <img
                          src={verified}
                          alt="Verified"
                          width={15}
                          height={15}
                        />
                        {t(i18nKeys.profile.individual.verified)}
                      </p>
                    ) : (
                      <p className="font-200 text-red-500 flex items-center gap-1">
                        <Icon icon="error" size={15} color={'colors.red-500'} />
                        {t(i18nKeys.profile.individual.unverified)}
                      </p>
                    )}
                  </div>
                  <p>{data?.address}</p>
                  <p className="py-2">
                    {t(i18nKeys.profile.individual.locationCreated)}
                  </p>
                  <div className="flex gap-3">
                    <p>
                      <span className="text-600">{`${t(i18nKeys.profile.individual.latitude)}`}</span>
                      <span className="flex items-center gap-1">
                        <img src={locationIcn} alt="Location" width={16} />
                        {`${allData?.latitude}`}
                      </span>
                    </p>
                    <p>
                      <span className="text-600">{`${t(i18nKeys.profile.individual.longitude)}`}</span>
                      <span className="flex items-center gap-1">
                        <img src={locationIcn} alt="Location" width={16} />
                        {`${allData?.longitude}`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case Tabs.What:
        return (
          <>
            <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2 mt-3">
              <span className="border-l-2 border-[#ffffff] h-6 inline-block"></span>
              <img src={what} alt="What" width={16} />
              <h2 className="font-semibold">
                {t(i18nKeys.profile.individual.what)}
              </h2>
            </div>
            <div className="border-l-2 border-gray-200 pl-2">
              <div className="mt-4 bg-white rounded-md shadow-sm">
                <div className="p-2">
                  <div className="bg-primary-100 text-primary rounded-full px-2 py-1 text-sm  inline-flex items-center gap-1 mb-3">
                    <img src={verified} alt="Verified" width={15} height={15} />
                    {t(i18nKeys.profile.individual.verifiedByOfficials)}
                  </div>
                  <h3 className="text-md font-semibold">
                    {t(i18nKeys.profile.individual.individualVDTID)}
                  </h3>
                  <p className="text-gray-500">{userVdt?.userId}</p>
                  <div className="flex items-center space-x-4 mt-2">
                    <img src={qrCode} alt="qrCode" className="w-24 h-24" />
                    <p className="text-gray-500 !ml-2">{userVdt?.qrId}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case Tabs.Who:
        return (
          <>
            <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2 mt-3">
              <span className="border-l-2 border-[#ffffff] h-6 inline-block"></span>
              <img src={who} alt="User" width={16} />
              <h2 className="font-semibold">
                {t(i18nKeys.profile.individual.who)}
              </h2>
            </div>
            <div className="border-l-2 border-gray-200 pl-2">
              <div className="mt-4 bg-white rounded-md shadow-sm">
                <div className="p-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-gray-500">
                        {t(i18nKeys.profile.individual.firstName)}
                      </p>
                      <p className="font-semibold">{profile?.firstName}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">
                        {t(i18nKeys.profile.individual.lastName)}
                      </p>
                      <p className="font-semibold">{profile?.lastName}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div>
                      <p className="text-gray-500">
                        {t(i18nKeys.profile.individual.dob)}
                      </p>
                      <p className="font-semibold">
                        {convertISOToMMDDYYYY(profile?.dateOfBirth)}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-500">
                        {t(i18nKeys.profile.individual.age)}
                      </p>
                      <p className="font-semibold">
                        {calculateAge(profile?.dateOfBirth)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case Tabs.When:
        const createdAtDate = convertISOToMMDDYYYY(profile?.beingId?.updatedAt)
        const createdAtTime = formatTimeToEST(profile?.beingId?.updatedAt)
        const vdtMintedDate = convertISOToMMDDYYYY(userVdt?.immutableTime)
        const vdtMintedTime = formatTimeToEST(userVdt?.immutableTime)
        return (
          <>
            <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2 mt-3">
              <span className="border-l-2 border-[#ffffff] h-6 inline-block"></span>
              <img src={when} alt="When" width={16} />
              <h2 className="font-semibold">
                {t(i18nKeys.profile.individual.when)}
              </h2>
            </div>
            <div className="border-l-2 border-gray-200 pl-2">
              <div className="mt-4 bg-white rounded-md shadow-sm">
                <div className="p-2">
                  <p className="text-gray-500">
                    {t(i18nKeys.profile.individual.createdAt)}
                  </p>
                  <div className="flex items-center gap-3">
                    <p className="flex items-center gap-1">
                      <img src={calendar} alt="Calendar" width={16} />
                      {createdAtDate}
                    </p>
                    <p className="flex items-center gap-1">
                      <Icon
                        icon="regClock"
                        size={14}
                        color={colors.primary.DEFAULT}
                      />
                      {createdAtTime}
                    </p>
                  </div>
                  <p className="text-gray-500 pt-3">
                    {t(i18nKeys.profile.individual.vdtMintedAt)}
                  </p>
                  <div className="flex items-center gap-3">
                    <p className="flex items-center gap-1">
                      <img src={calendar} alt="Calendar" width={16} />
                      {vdtMintedDate}
                    </p>
                    <p className="flex items-center gap-1">
                      <Icon
                        icon="regClock"
                        size={14}
                        color={colors.primary.DEFAULT}
                      />
                      {vdtMintedTime}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      default:
        return null
    }
  }

  const renderWhenRight = () => {
    return (
      <div>
        <h2 className="text-xl font-semibold">
          <span className="border-l-4 border-primary mr-2"></span>
          {t(i18nKeys.profile.individual.transactionDetails)}
        </h2>
        <div className="bg-white p-2 rounded-md mt-4">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-md uppercase text-sm !px-3"
            textColor={colors.surface}
          >
            {t(i18nKeys.profile.individual.overview)}
          </Button>
          <div className="mt-4">
            {transactionDetails?.length > 0 &&
              transactionDetails?.map((item, index) => (
                <div
                  className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-1 items-baseline"
                  key={`transactionDetails-${index}`}
                >
                  <p className="col-span-4 text-gray-500 flex items-center gap-1">
                    <img src={info} alt="Info" className="w-[14px]" />
                    {item.label}
                  </p>
                  <h3 className="col-span-8 text-sm font-medium break-words">
                    {item.value}
                  </h3>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  const renderWhoRight = () => {
    const beingData = BEING_ID_LEVELS_WHO.find(
      (obj) => obj.level === Number(profile?.beingId?.level),
    )
    const level = (
      <>
        <span className="bg-[#c99f13] text-white rounded-full w-3.5 h-3.5 font-normal text-sm flex justify-center items-center">
          {beingData?.level}
        </span>{' '}
        {beingData?.name}
      </>
    )
    return (
      <div>
        <h2 className="text-xl font-semibold">
          <span className="border-l-4 border-primary mr-2"></span>
          {t(i18nKeys.profile.individual.beingIDLevel)}
        </h2>
        <div className="bg-white p-2 rounded-md mt-4">
          <div className="sm:flex justify-between items-center">
            <div>
              <div className="flex items-center gap-2 font-semibold text-md">
                <p>{t(i18nKeys.profile.individual.createdAt)}</p>
                <p>{convertISOToMMDDYYYY(profile?.beingId?.updatedAt)}</p>
              </div>
              <p className="text-gray-500">
                Created, Biometric Template Recorded
              </p>
            </div>
            <p className="text-sm flex items-center w-max text-[#c99f13] pl-1 pr-2 rounded-2xl text-right font-bold bg-[#f4ecd0] sm:flex gap-2 h-6 leading-6 sm:mt-0 mt-2">
              {level}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderMap = () => {
    const position = {
      lat: allData?.latitude,
      lng: allData?.longitude,
    }
    return (
      <div className="sm:h-[600px] h-auto w-full">
        {position?.lat ? (
          <APIProvider apiKey={envVariables.GCP_API_KEY}>
            <Map
              defaultCenter={defaultPosition}
              center={position}
              defaultZoom={10}
              className="h-64 sm:h-full"
            >
              <Marker position={position} />
            </Map>
          </APIProvider>
        ) : (
          <span>{t(i18nKeys.ui.loading)}...</span>
        )}
      </div>
    )
  }

  const renderWhereRight = () => {
    return <div className="bg-white rounded-md p-2">{renderMap()}</div>
  }

  const renderWhatRight = () => {
    return (
      <div className="container mx-auto">
        <div className="bg-white p-5 rounded-md">
          <div className="bg-[#dee2e6] p-3 rounded-lg">
            <h3 className="text-xl font-semibold mb-1">
              {t(i18nKeys.profile.individual.individualVDTID)}
            </h3>
            <div className="bg-white rounded-md p-2 inline-block">
              <p className="text-sm">{userVdt?.qrId}</p>
            </div>
          </div>

          {/* Token Details Section */}
          <div className="py-3">
            <h2 className="text-xl font-semibold">
              <span className="border-l-4 border-primary mr-2"></span>
              {t(i18nKeys.profile.individual.tokenDetails)}
            </h2>
          </div>
          <div className="bg-[#dee2e6] p-3 rounded-lg">
            <div className="sm:flex justify-between items-center">
              <div>
                <div className="w-max bg-primary-100 text-primary rounded-full px-2 py-1 text-sm inline-flex items-center gap-1 whitespace-nowrap">
                  <img src={verified} alt="Verified" width={15} height={15} />
                  {t(i18nKeys.profile.individual.verifiedByOfficials)}
                </div>
                <h3 className="text-lg font-semibold mt-3">
                  {t(i18nKeys.profile.individual.individualVDTID)}
                </h3>
              </div>
              <div className="flex items-center gap-1 justify-between">
                <p className="text-sm text-gray-500 sm:text-right">
                  {userVdt?.qrId}
                </p>
                <div className="bg-white p-1 w-20 sm:h-20 h-auto">
                  <img src={qrCode} alt="QR Code" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h4 className="text-md font-semibold">
              {t(i18nKeys.profile.individual.listDocuments)}
            </h4>
            {documents.length ? (
              documents.map((item, index) => (
                <div key={`document-${index}`}>
                  <p className="text-gray-500">{item?.govIdName}</p>
                  <p>{convertISOToMMDDYYYY(item?.expireAt)}</p>
                  {item?.governmentScoreStatus === 'OK' && 'Verified'}
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-center text-sm">
                {t(i18nKeys.profile.individual.noDocument)}
              </p>
            )}
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-xl font-semibold capitalize">
            <span className="border-l-4 border-primary mr-2"></span>
            {t(i18nKeys.profile.individual.tokens)}
          </h3>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 mt-4">
            {/* Token item */}
            {publicTokens.map((item, index) => {
              const image = EventService.buildDocumentImageUrlById(
                item?.serializedImages[0]?.docId,
              )
              return (
                <div
                  className="bg-white py-2 px-3 rounded-md flex items-center relative"
                  key={`publick-token-${index}`}
                >
                  <img
                    src={image}
                    alt="Token"
                    className="w-12 h-12 mr-4 rounded-md"
                  />
                  <div>
                    <h4 className="font-semibold md:text-md text-sm">
                      {item?.name}
                    </h4>
                    <p className="text-sm truncate lg:w-48 sm:w-36 w-28 text-gray-500">
                      {profile?.walletAddress}
                    </p>
                  </div>
                  <div className="ml-auto text-sm absolute top-1 right-2 text-[#f98d47]">{`#${index + 1}`}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="lg:flex w-full">
      <div className="lg:w-1/3 w-full  p-1">
        <div className="space-y-2 flex gap-2 bg-white p-1 rounded-md">
          <Button
            mode="contained"
            color={
              activeTab === Tabs.Who ? colors.primary.DEFAULT : colors.gray[950]
            }
            className="rounded-md w-[120px] !px-2 !font-normal text-sm uppercase !mt-0 !py-1 flex-1"
            textColor={colors.surface}
            onClick={() => setActiveTab(Tabs.Who)}
          >
            {t(i18nKeys.profile.individual.who)}
          </Button>
          <Button
            mode="contained"
            color={
              activeTab === Tabs.What
                ? colors.primary.DEFAULT
                : colors.gray[950]
            }
            className="rounded-md w-[120px] !px-2 !font-normal text-sm uppercase !mt-0 !py-1 flex-1"
            textColor={colors.surface}
            onClick={() => setActiveTab(Tabs.What)}
          >
            {t(i18nKeys.profile.individual.what)}
          </Button>
          <Button
            mode="contained"
            color={
              activeTab === Tabs.When
                ? colors.primary.DEFAULT
                : colors.gray[950]
            }
            className="rounded-md w-[120px] !px-2 !font-normal text-sm uppercase !mt-0 !py-1 flex-1"
            textColor={colors.surface}
            onClick={() => setActiveTab(Tabs.When)}
          >
            {t(i18nKeys.profile.individual.when)}
          </Button>
          <Button
            mode="contained"
            color={
              activeTab === Tabs.Where
                ? colors.primary.DEFAULT
                : colors.gray[950]
            }
            className="rounded-md w-[120px] !px-2 !font-normal text-sm uppercase !py-1 flex-1 !mt-0"
            textColor={colors.surface}
            onClick={() => setActiveTab(Tabs.Where)}
          >
            {t(i18nKeys.profile.individual.where)}
          </Button>
        </div>
        {renderLeftPanelDetails()}
      </div>

      <div className="lg:w-3/4 w-full px-3 py-2">
        {activeTab === Tabs.Who && renderWhoRight()}
        {activeTab === Tabs.What && renderWhatRight()}
        {activeTab === Tabs.When && renderWhenRight()}
        {activeTab === Tabs.Where && renderWhereRight()}
      </div>
    </div>
  )
}
