import { t } from 'i18next'
import React from 'react'

import { Icon } from '@/common/components'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { featureFlags } from '@/config'
import { colors } from '@/theme'

import styles from '../index.module.css'

export const PaymentSection: React.FC = () => {
  const { soon } = useNotify()
  return (
    <div className="w-full lg:max-w-full bg-white my-8">
      <div
        className={
          'w-full flex bg-white p-4 border-2 mt-5 mr-1 ' + styles.yellowCard
        }
      >
        <div className="lg:h-auto flex-none bg-cover  overflow-hidden inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          <Icon icon="dollar" size={36} color={colors.yellow} />
        </div>
        <div className="w-100 bg-white p-4 leading-normal my-auto w-full">
          <div className="flex justify-between font-bold">
            <h3 className="text-xl">
              {t(i18nKeys.profile.digitalIdentity.beingId.card2.subTitle)}
            </h3>
            <h3 className="text-xl">$10</h3>
            {/* TODO: replace this mocked data with backend response */}
          </div>
          <p>{t(i18nKeys.profile.digitalIdentity.beingId.card2.message)}</p>
          <div className="flex justify-end font-bold">
            <button
              onClick={() =>
                featureFlags.yearSubscription ? undefined : soon()
              }
            >
              {t(i18nKeys.profile.digitalIdentity.beingId.card2.continueBtn)}
            </button>
            <span className="pl-2 my-auto">
              <Icon icon="arrowRight" size={16} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
