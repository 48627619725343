import { doGet, doPost } from '..'

export type GetPaymentCredentialsResponse = {
  publicKey: string
  accountId: string
  paypalAccountId: string
  venmoAccountId: string
  googleMerchantId: string
  googleMerchantName: string
  providerName: string
  publicProviderId: string
}

export type CreateVenmoPaymentHandlePayload = {
  email: string
  successRedirectLink: string
  failedRedirectLink: string
  defaultRedirectLink: string
}

export type CreateVenmoPaymentHandleResponse = {
  status: string
  redirectUrl?: string
}

export class PaymentsService {
  static async getPaymentsCredentials(): Promise<GetPaymentCredentialsResponse> {
    return (await doGet(
      '/marketplace/v1/payments/credentials',
    )) as Promise<GetPaymentCredentialsResponse>
  }

  static async createVenmoPaymentHandle(
    payload: CreateVenmoPaymentHandlePayload,
  ): Promise<CreateVenmoPaymentHandleResponse> {
    return (await doPost(
      '/marketplace/v1/payments/end-user/venmo-payment-handle',
      payload,
    )) as Promise<CreateVenmoPaymentHandleResponse>
  }
}
