import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import {
  getStateCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import type { Address } from '@/common/types'
import { Environment } from '@/common/types'
import { Logger } from '@/config'
import type { GetPaymentCredentialsResponse } from '@/core/marketplace/payments.service'
import { PaymentsService } from '@/core/marketplace/payments.service'

type GooglePayProps = {
  totalPrice: number
  billingAddress: Address
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed: (error?: string) => void | Promise<void>
  onLoading: (isLoading: boolean) => void
}

const googlePayConfiguration = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['AMEX', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
        assuranceDetailsRequired: true,
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: '',
          gatewayMerchantId: '',
        },
      },
    },
  ],
}

export const GooglePay: React.FC<GooglePayProps> = ({
  totalPrice,
  billingAddress,
  onPaymentComplete,
  onPaymentFailed,
  onLoading,
}: GooglePayProps) => {
  const googlePayInstance = useRef<any>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    script.async = true
    document.body.appendChild(script)
    script.onload = async () => setupGooglePay()
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const setupGooglePay = async () => {
    onLoading(true)
    try {
      googlePayInstance.current = new window.google.payments.api.PaymentsClient(
        {
          environment:
            envVariables.ENVIRONMENT === Environment.PRODUCTION
              ? 'PRODUCTION'
              : 'TEST',
        },
      )

      const paymentCredentials = await PaymentsService.getPaymentsCredentials()
      googlePayConfiguration.allowedPaymentMethods[0].tokenizationSpecification.parameters =
        {
          gateway: paymentCredentials?.providerName,
          gatewayMerchantId: paymentCredentials?.publicProviderId,
        }

      const client = await googlePayInstance.current.isReadyToPay(
        googlePayConfiguration,
      )
      if (client?.result) {
        const button = googlePayInstance.current.createButton({
          buttonColor: 'default',
          buttonType: 'buy',
          buttonRadius: 4,
          onClick: async () => onGooglePayButtonClicked(paymentCredentials),
        })
        const googlePayContainer = document.getElementById(
          'google-pay-container',
        )
        if (googlePayContainer) {
          googlePayContainer.appendChild(button)
        }
      }
      Logger.info('Google Pay setup completed: ', googlePayInstance)
    } catch (error) {
      Logger.error("Couldn't setup Google Pay", undefined, error as Error)
    } finally {
      onLoading(false)
    }
  }

  async function onGooglePayButtonClicked(
    credentials: GetPaymentCredentialsResponse,
  ) {
    try {
      const paymentDataRequest = {
        ...googlePayConfiguration,
        merchantInfo: {
          merchantName: credentials.googleMerchantName,
          merchantId: credentials.googleMerchantId,
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: (totalPrice / 100).toString(),
          currencyCode: 'USD',
          countryCode: 'US',
        },
      }
      const paymentData =
        await googlePayInstance.current.loadPaymentData(paymentDataRequest)

      await tokenize(paymentData, credentials.accountId, credentials.publicKey)
    } catch (error) {
      Logger.error(
        'Google Pay - onGooglePayButtonClicked error: ',
        undefined,
        error as Error,
      )
    }
  }

  async function tokenize(
    paymentData: any,
    accountId: string,
    publicKey: string,
  ) {
    const merchantRefNum = uuid()

    const requestBody = {
      merchantRefNum: merchantRefNum,
      accountId: accountId,
      transactionType: 'PAYMENT',
      paymentType: 'CARD',
      amount: totalPrice,
      currencyCode: 'USD',
      googlePay: { googlePayPaymentToken: paymentData },
      billingDetails: {
        nickName: 'Home',
        state: getStateCode(billingAddress.state),
        city: billingAddress.city,
        zip: billingAddress.zip,
        street: billingAddress.addressLine1 || billingAddress.address,
        country: selectCountryFromSplitString(billingAddress.country),
      },
    }
    try {
      const paysafeApiUrl = `https://api${envVariables.ENVIRONMENT !== Environment.PRODUCTION ? '.test' : ''}.paysafe.com`
      const response = await axios.post(
        `${paysafeApiUrl}/paymenthub/v1/singleusepaymenthandles`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + publicKey,
          },
        },
      )

      if (onPaymentComplete) {
        onPaymentComplete({
          paymentHandleToken: response?.data?.paymentHandleToken,
          merchantRefNum: merchantRefNum,
          amount: totalPrice,
          currencyCode: 'USD',
          paymentType: 'CARD',
        } as TokenizationResponse)
      }
    } catch (error) {
      Logger.error('Google Pay - tokenize error', undefined, error as Error)
      onPaymentFailed()
    }
  }

  return <div id="google-pay-container" />
}
