import type {
  Device,
  PagedResponse,
  SearchDevicesRequest,
} from '@bit-ui-libs/common'
import { DeviceService as CommonDeviceService } from '@bit-ui-libs/common'
import axios from 'axios'

import envVariables from '@/common/envVariables'

import { doPost } from '../fetch'
import Logger from '../logger'
import {
  WALLET_DEFAULT_PLATFORM,
  serviceConfiguration,
} from '../service-configuration'

const resource = '/devices/v1'

export interface DeviceRegisterOptions {
  externalId: string
  latitude: number
  longitude: number
  meanSeaLevel: number
  recipientAddress: string
  ownerUserId: string
  name: string
  model: string
  operativeSystem: string
}

export interface InitDeviceVDTOptions {
  deviceId: string
  latitude: number
  longitude: number
  meanSeaLevel: number
  recipientAddress: string
}

const commonDeviceService = new CommonDeviceService(serviceConfiguration)
export const DEVICE_IS_PERSONAL_DEFAULT_VALUE = true

export class DeviceService {
  static async registerDevice(options: DeviceRegisterOptions): Promise<Device> {
    return commonDeviceService.registerDevice({
      appName: envVariables.APP_NAME,
      externalId: options.externalId,
      // default values for latitude, longitude and meanSeaLevel are 1 because a device with these values as 0 cannot be minted
      latitude: options.latitude ?? 1,
      longitude: options.longitude ?? 1,
      meanSeaLevel: options.meanSeaLevel ?? 1,
      name: options.name,
      owner: {
        id: options.ownerUserId,
        isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
      },
      platform: WALLET_DEFAULT_PLATFORM,
      recipientAddress: options.recipientAddress,
      typeId: 6, // Unkown. The option is not available in the common library but it is an accecpted value
      deviceInfo: {
        model: options.model,
        imei: '', // Not applicable for browsers
        os: options.operativeSystem,
        macAddress: '', // Cannot get this from the browser
      },
    })
  }

  static async initDeviceVdt(options: InitDeviceVDTOptions) {
    return doPost(`${resource}/devices/init-vdt`, {
      appName: envVariables.APP_NAME,
      deviceId: options.deviceId,
      latitude: options.latitude,
      longitude: options.longitude,
      meanSeaLevel: options.meanSeaLevel,
      platform: WALLET_DEFAULT_PLATFORM,
      recipientAddress: options.recipientAddress,
    })
  }

  static async searchDevices(
    options: SearchDevicesRequest,
  ): Promise<PagedResponse<Device>> {
    return commonDeviceService.searchDevices(options)
  }

  static async getIpAddress() {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json')
      return response.data.ip
    } catch (e) {
      Logger.error('Failed to get IP address', undefined, e as Error)
      return null
    }
  }
}
