import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

export const useTags = (tags: string[] = []) => {
  const { shortName } = useParams<{ shortName: string }>()
  const [query] = useSearchParams()

  const url = useMemo(() => {
    const params = new URLSearchParams()
    tags.forEach((tag) => params.append('tags', tag))

    return `/store/${shortName}?${params.toString()}`
  }, [shortName, tags])

  const pageTags = useMemo(() => query.getAll('tags'), [query])
  const search = useMemo(() => query.get('search'), [query])

  const selected = useMemo(() => {
    if (search?.trim()?.length) return false

    const sortedTags = [...tags].sort().map((tag) => tag.toLowerCase())
    const sortedPageTags = [...pageTags].sort().map((tag) => tag.toLowerCase())

    return JSON.stringify(sortedTags) === JSON.stringify(sortedPageTags)
  }, [tags, pageTags, search])

  return {
    url,
    pageTags,
    selected,
  }
}
