import React from 'react'

import type {
  IncodeCreateOnboardingProps,
  IncodeDocumentVerificationProps,
} from '@/common/components/Incode/IncodeBase'
import IncodeCreateOnboarding from '@/common/components/Incode/IncodeCreateOnboarding'
import IncodeDocumentVerification from '@/common/components/Incode/IncodeDocumentVerification'

export const IncodeIntegration: React.FC<
  IncodeCreateOnboardingProps | IncodeDocumentVerificationProps
> = (props: IncodeCreateOnboardingProps | IncodeDocumentVerificationProps) => {
  if ('onOnboardingComplete' in props) {
    return <IncodeCreateOnboarding {...props} />
  }

  if ('onDocumentVerified' in props) {
    return <IncodeDocumentVerification {...props} />
  }

  return null
}
