import { RadioGroup } from '@headlessui/react'
import React from 'react'

import { classNames } from '@/core'

export type RadioOptionItem = {
  id: number | string
  name: string
  description: string
  disabled?: boolean
}

export interface RadioListWithDescriptionProps {
  items: RadioOptionItem[]
  selected?: RadioOptionItem
  className?: string
  onChange?: (id: RadioOptionItem) => void | Promise<void>
}

export const RadioListWithDescription: React.FC<
  RadioListWithDescriptionProps
> = (props) => {
  return (
    <RadioGroup
      value={props.selected}
      onChange={props.onChange}
      className={props.className}
    >
      <div className="-space-y-px rounded-md bg-white">
        {props.items.map((item, key) => (
          <RadioGroup.Option
            key={item.name}
            value={item}
            disabled={item.disabled}
            className={({ checked }) =>
              classNames(
                key === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                key === props.items.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'z-10 border-primary-200 bg-primary-50'
                  : 'border-gray-200',
                item.disabled && 'text-gray-600 bg-gray-50',
                'relative flex cursor-pointer border p-4 focus:outline-none',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-primary border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-primary' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-primary-900' : 'text-gray-900',
                      'block text-sm font-medium',
                    )}
                  >
                    {item.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-primary-600' : 'text-gray-500',
                      'block text-sm',
                    )}
                  >
                    {item.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
