import React, { useMemo } from 'react'
import { FaMapMarker } from 'react-icons/fa'

import { TaxCodeAutocomplete } from '@/common/components/form/autocomplete/TaxCodeAutocomplete'
import { useNotify } from '@/common/hooks'
import { translate } from '@/core'

import { InfoAlert, OptionCards, PageActionButtons, SellPage } from '..'
import { TaxDisclaimerModal } from '../components/TaxDisclaimerModal'
import { taxCategories } from '../constants/taxes'
import { useProductListing } from '../context'
import { useListingStore } from '../context/listing-context'
import { TaxCategoryType } from '../types/taxes'

export default function SellTaxPage() {
  const { error } = useNotify()
  const { delivery } = useProductListing()
  const taxCategoryType = useListingStore((state) => state.taxCategoryType)
  const taxCode = useListingStore((state) => state.taxCode)
  const update = useListingStore((state) => state.update)

  const options = useMemo(
    () =>
      taxCategories.map((i) => ({
        ...i,
        title: translate(i.title),
        description: translate(i.description),
        action: i.action ? translate(i.action) : undefined,
      })),
    [],
  )

  const canContinue = useMemo(() => {
    if (taxCategoryType.id === TaxCategoryType.GeneralTaxes) {
      return true
    }

    return Boolean(taxCode)
  }, [taxCategoryType, taxCode])

  const handleContinue = () => {
    if (taxCategoryType.id === TaxCategoryType.SpecialTaxCategory && !taxCode) {
      error(translate((i) => i.sell.nav.taxes.error.taxCodeRequired))
      return false
    }
    return true
  }

  return (
    <SellPage title="Select your tax category">
      <InfoAlert
        title={translate((i) => i.sell.nav.taxes.info.title)}
        text={translate((i) => i.sell.nav.taxes.info.text)}
        linkText={translate((i) => i.sell.nav.taxes.info.action)}
        href="?disclaimer=true"
        className="mt-4"
      />
      <TaxDisclaimerModal />

      <OptionCards
        className="mt-4 flex gap-2"
        selected={taxCategoryType}
        options={options}
        onChange={(i) => update('taxCategoryType', i)}
      />

      {taxCategoryType.id === TaxCategoryType.GeneralTaxes ? (
        <div className="fle flex-col gap-1 mt-4">
          <span className="text-sm flex items-center gap-2">
            <FaMapMarker className="fill-primary-400 size-4" />
            {delivery.address?.city}, {delivery.address?.state}
          </span>

          <p className="font-light text-xs text-gray-400">
            {translate((i) => i.sell.nav.taxes.generalTaxes.terms)}
          </p>
        </div>
      ) : null}

      {taxCategoryType.id === TaxCategoryType.SpecialTaxCategory ? (
        <TaxCodeAutocomplete
          label="Tax codes"
          selected={taxCode ?? undefined}
          onChange={(v) => update('taxCode', v)}
          className="w-full mt-6"
        />
      ) : null}

      {taxCategoryType.id === TaxCategoryType.SpecialTaxCategory && taxCode ? (
        <div className="flex flex-col w-full mt-4 gap-1">
          <p className="font-light text-xs text-gray-400">
            {taxCode.description}
          </p>
        </div>
      ) : null}

      <PageActionButtons
        onContinue={handleContinue}
        continueDisabled={!canContinue}
      />
    </SellPage>
  )
}
