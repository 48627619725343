import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Icon, PaymentCardInfo } from '@/common/components'
import type { PaymentCard } from '@/core'
import { classNames } from '@/core'
import { EmptyCard } from '@/features/listing'
import { colors } from '@/theme'

export type CardListProps = {
  className?: string
  cards?: PaymentCard[]
  onClickCard?: (card: PaymentCard) => void
}

export const CardList: React.FC<CardListProps> = (props) => {
  const navigate = useNavigate()

  const noCards = useMemo(
    () => props.cards && !props.cards.length,
    [props.cards],
  )

  const handleCreateCard = () => {
    navigate('/profile/card')
  }

  return (
    <div
      className={classNames(
        'bg-white px-6 py-5 flex flex-col',
        props.className,
      )}
    >
      <div className="flex justify-between items-center">
        <h2 className="text-base font-medium">Cards</h2>
        <Link
          to="/profile/card"
          className="flex items-center gap-2 text-primary font-semibold"
        >
          {/* TODO add translation */}
          <Icon icon="card" color={colors.primary.DEFAULT} size={16} /> Add Card
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
        {props.cards
          ? props.cards?.map((card, key) => (
              <PaymentCardInfo
                key={key}
                card={card}
                onClick={() => {
                  if (props.onClickCard) {
                    props.onClickCard(card)
                  }
                }}
                rightComponent={
                  <Icon icon="arrowRight" color={colors.gray[400]} size={16} />
                }
              />
            ))
          : null}
      </div>
      {noCards ? (
        <EmptyCard
          icon="card"
          text="Enter a credit/debit card"
          onClick={handleCreateCard}
          className="self-center max-w-lg"
        />
      ) : null}
    </div>
  )
}
