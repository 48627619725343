import React from 'react'

import { useServiceListing } from '../context/service-listing-context'

export default function ServiceConfirmPage() {
  const postingTitle = useServiceListing((state) => state.postingTitle)
  const description = useServiceListing((state) => state.description)
  const additionalInfo = useServiceListing((state) => state.additionalInfo)
  const termsOfService = useServiceListing((state) => state.termsOfService)

  return (
    <div>
      <h1>Confirm and publish Service</h1>

      <h2>{postingTitle}</h2>
      <p>{description}</p>
      <p>{additionalInfo}</p>
      <p>{termsOfService}</p>
    </div>
  )
}
