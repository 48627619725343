export const ContractABI = [
  {
    inputs: [
      {
        internalType: 'string',
        name: 'listingId',
        type: 'string',
      },
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'listVDT',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
