import React from 'react'

export type BuyPageProps = {
  children?: React.ReactNode
  heading?: string
  description?: string
}

export const BuyPage: React.FC<BuyPageProps> = (props) => {
  return (
    <div className="min-h-[80vh]">
      <div className="bg-white p-8 max-w-4xl mx-auto sm:min-h-[512px] rounded-md flex flex-col">
        {props.heading ? <h2 className="text-2xl">{props.heading}</h2> : null}
        {props.description ? (
          <p className="mt-2 text-gray-500 text-sm tracking-tight">
            {props.description}
          </p>
        ) : null}
        {props.children}
      </div>
    </div>
  )
}
