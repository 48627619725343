import { translateKey } from '@/core'

import type { CardOption } from '..'
import { ListingType } from '../types/listing-type'

export const listingTypeOptions = [
  {
    id: ListingType.Asset,
    title: translateKey((i) => i.sell.nav.init.listingTypes.product.title),
    description: translateKey(
      (i) => i.sell.nav.init.listingTypes.product.description,
    ),
  },
  {
    id: ListingType.Service,
    title: translateKey((i) => i.sell.nav.init.listingTypes.service.title),
    description: translateKey(
      (i) => i.sell.nav.init.listingTypes.service.description,
    ),
  },
] as CardOption[]
