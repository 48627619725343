import { NIL } from 'uuid'

import envVariables from '../common/envVariables'

import { HttpError } from './errors'
import ApplicationStorage from './storage'

type BodyType = Object | Array<any> | undefined
type StatusCodeHandlersType = { [key: number]: Function }

const getHeaders = (): HeadersInit => {
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-organization-id': NIL, // TODO retrieve the organization id from the storage
  }

  const tokens = ApplicationStorage.tokens
  if (tokens) {
    headers['Authorization'] = `Bearer ${tokens.accessToken}`
    headers['Bit-Token'] = tokens.bitToken
  }

  return headers
}

const buildURL = (resource: string) => {
  const slash = resource.startsWith('/') ? '' : '/'
  return `${envVariables.API_BASE_URL}${slash}${resource}`
}

const doRequest = async (
  method: string,
  resource: string,
  body: BodyType = undefined,
  statusCodeHandlers: StatusCodeHandlersType = {},
) => {
  const response = await fetch(buildURL(resource), {
    method: method,
    headers: getHeaders(),
    credentials: 'omit', // TODO review the value for the local development and production
    body: body ? JSON.stringify(body) : null,
  })

  const status = response.status
  if (statusCodeHandlers[status]) {
    return statusCodeHandlers[status](response)
  }

  if (response.status >= 400) {
    throw await HttpError.fromResponse(response)
  }

  if (response.status >= 300) {
    return null
  }

  return response.json()
}

/**
 * Performs a GET request to the API.
 *
 * @param {string} resource API resource
 * @returns a Promise that resolves to the JSON response.
 */
export const doGet = async (resource: string) => doRequest('GET', resource)

/**
 * Performs a POST request to the API.
 * @param {string} resource API resource.
 * @param {object | array} body request body to send as JSON.
 * @returns a Promise that resolve to the JSON response.
 */
export const doPost = async (
  resource: string,
  body: BodyType = undefined,
  statusCodeHandlers: StatusCodeHandlersType = {},
) => doRequest('POST', resource, body, statusCodeHandlers)

/**
 * Performs a PUT request to the API.
 * @param {string} resource API resource.
 * @param {object | array} [body=undefined] request body to send as JSON.
 * @returns a Promise that resolve to the JSON response.
 */
export const doPut = async (resource: string, body: BodyType = undefined) =>
  doRequest('PUT', resource, body)

/**
 * Performs a PATCH request to the API.
 * @param {string} resource API resource.
 * @param {object | array} [body=undefined] request body to send as JSON.
 * @returns a Promise that resolve to the JSON response.
 */
export const doPatch = async (
  resource: string,
  body: BodyType = undefined,
  statusCodeHandlers: StatusCodeHandlersType = {},
) => doRequest('PATCH', resource, body, statusCodeHandlers)

/**
 * Performs a DELETE request to the API.
 * @param {string} resource API resource.
 * @param {object | array} [body={}] an optional request body to send as JSON.
 * @param {object} [statusCodeHandlers={}] an optional object with status code handlers. Each key is a status code and the
 * value is a function that will be called with the response as argument, the function must return a Promise.
 * @returns a Promise that resolve to the JSON response.
 */
export const doDelete = async (
  resource: string,
  body: BodyType = {},
  statusCodeHandlers = {},
) => doRequest('DELETE', resource, body, statusCodeHandlers)

/**
 * Performs a GET request to the API and downloads the response as a file.
 *
 * The user will be prompted automatically with the save dialog.
 *
 * @param {string} resource API resource.
 */
export const doDownloadFile = async (
  resource: string,
  downloadFilename: string,
) => {
  const response = await fetch(buildURL(resource), {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  })
  const blob = await response.blob()
  const file = window.URL.createObjectURL(blob)
  const aTag = document.createElement('a')
  aTag.href = file
  aTag.download = downloadFilename
  aTag.click()
}

export default {
  getHeaders,
  doGet,
  doPut,
  doPost,
  doDelete,
  doPatch,
}
