import React, { useMemo } from 'react'

import { translate } from '@/core'

import { SellPage, PageActionButtons, RadioListWithDescription } from '..'
import { servicePaymentTerms } from '../constants'
import { useServiceListing } from '../context/service-listing-context'

export default function ServicePaymentTermsPage() {
  const terms = useServiceListing((state) => state.paymentTerms)
  const update = useServiceListing((state) => state.update)

  const options = useMemo(
    () =>
      servicePaymentTerms.map((i) => ({
        ...i,
        name: translate(i.name),
        description: translate(i.description),
      })),
    [],
  )

  return (
    <SellPage title={translate((i) => i.sell.nav.servicePayment.title)}>
      <RadioListWithDescription
        items={options}
        selected={terms}
        onChange={(v) => update('paymentTerms', v)}
        className="mt-6"
      />
      <PageActionButtons />
    </SellPage>
  )
}
