import { useCallback, useState } from 'react'

export type FormValues = {
  /**
   * This can actually be of "any" type, but on usage it will be
   * replaced with the corresponding type "K" for that key.
   */
  [key: string]: any
}

export type FormHook<T> = {
  data: T
} & FormHookActions<T>

export type FormHookActions<T> = {
  handleChangeField: <K extends keyof T>(field: keyof T, value: T[K]) => void
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function useForm<T extends FormValues>(initialValues: T): FormHook<T> {
  const [data, setData] = useState<T>(initialValues)

  const handleChangeField = useCallback(
    <K extends keyof T>(field: keyof T, value: T[K]) => {
      setData((_data) => ({ ..._data, [field]: value }))
    },
    [setData],
  )

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setData((_data) => ({
        ..._data,
        [event.target.name]: event.target.value,
      }))
    },
    [setData],
  )

  return {
    data,
    handleChangeField,
    handleChangeInput,
  }
}
