import type { InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'

import envVariables from '@/common/envVariables'

import ApplicationStorage from './storage'

async function interceptRequest(config: InternalAxiosRequestConfig<any>) {
  const allTokens = ApplicationStorage.tokens
  const token = allTokens?.accessToken
  const bitToken = allTokens?.bitToken
  if (config.headers && token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  if (config.headers && bitToken) {
    config.headers['bit-token'] = bitToken
  }
  // TODO resolve the organization id header
  /*const orgId = useUserStore.getState().orgId || DEFAULT_ORG_ID
  if (config.headers && orgId && !config.headers['x-organization-id']) {
    config.headers['x-organization-id'] = orgId
  }*/

  config.headers['x-organization-id'] = '00000000-0000-0000-0000-000000000000'
  return config
}

export const createAxiosClient = () => {
  const client = axios.create({
    baseURL: envVariables.API_BASE_URL,
    timeout: 0,
    validateStatus: (status) => status >= 200 && status < 300,
  })

  client.interceptors.request.use(interceptRequest)
  return client
}

export const applicationAxiosClient = createAxiosClient()
