import { isUndefined, isEmpty } from 'lodash'
import React, { memo } from 'react'
import DotLoader from 'react-spinners/DotLoader'

import { colors } from '../theme'

import { ConditionalRendering } from './ConditionalRendering'

export type SpinnerProps = {
  className?: string
  size?: number
  color?: string
  text?: string
  textClassName?: string
  containerClassName?: string
}

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = memo(
  ({
    className = '',
    size = 60,
    color = colors.primary.DEFAULT,
    text = '',
    textClassName = '',
    containerClassName = '',
  }: SpinnerProps) => {
    return (
      <div className={containerClassName}>
        <DotLoader className={className} size={size} color={color} />
        <ConditionalRendering
          renderIf={!isUndefined(text) && !isEmpty(text.trim())}
        >
          <p className={textClassName}>{text}</p>
        </ConditionalRendering>
      </div>
    )
  },
  () => true,
)
Spinner.displayName = 'Spinner'

export default Spinner
