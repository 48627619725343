import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import React, { forwardRef, useImperativeHandle } from 'react'

import { Scopes } from '@/common/constants'
import envVariables from '@/common/envVariables'

const CLIENT_ID = envVariables.GOOGLE_DRIVE_CLIENT_ID
const SCOPES = Scopes.GOOGLE_DRIVE_SCOPES

interface GoogleOAuthProps {
  onAccessToken: (token: string) => void
}

interface GoogleOAuthHandles {
  handleGetGoogleAccessToken: () => void
}

const GoogleOAuth = forwardRef<GoogleOAuthHandles, GoogleOAuthProps>(
  (props, ref) => {
    const handleLoginSuccess = (tokenResponse: { access_token: string }) => {
      if ('access_token' in tokenResponse) {
        props.onAccessToken(tokenResponse.access_token)
      }
    }

    const handleLoginFailure = () => {
      props.onAccessToken('failed')
    }

    const handleGetGoogleAccessToken = useGoogleLogin({
      onSuccess: handleLoginSuccess,
      onError: handleLoginFailure,
      scope: SCOPES,
    })

    useImperativeHandle(ref, () => ({
      handleGetGoogleAccessToken,
    }))

    return null
  },
)

GoogleOAuth.displayName = 'GoogleOAuth'

interface AuthGoogleDriveProps {
  onAccessToken: (token: string) => void
}

export const AuthGoogleDrive = forwardRef<
  GoogleOAuthHandles,
  AuthGoogleDriveProps
>((props, ref) => (
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <GoogleOAuth ref={ref} onAccessToken={props.onAccessToken} />
  </GoogleOAuthProvider>
))

AuthGoogleDrive.displayName = 'AuthGoogleDrive'
