import React from 'react'

export const TestingComponent: React.FC = () => {
  return (
    <div>
      <h1>
        This component is used for testing libraries/functionalities without the
        need to go throught different components or routes. Do not push anything
        here. Do changes locally and discard them. Access to this component
        using the route &apos;/local-testing&apos;
      </h1>
    </div>
  )
}
