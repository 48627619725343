import { t } from 'i18next'
import React, { memo } from 'react'

import { Button, Input } from '@/common/components'

import i18nKeys from '../../../common/i18nKeys'

export type InformationProps = {
  onSave: Function
  onCancel: Function
  defaultValues?: PersonalInformationValues
}

interface PersonalInformationValues {
  firstName?: string
  middleName?: string
  lastName?: string
}

const Information: React.FC<React.PropsWithChildren<InformationProps>> = memo(
  ({ onSave, onCancel, defaultValues }: InformationProps) => {
    return (
      <form onSubmit={(e) => onSave(e)}>
        <div className="space-y-12" style={{ height: '400px' }}>
          <div className="pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <Input
                  label={t(i18nKeys.onboarding.info.firstName)}
                  name="firstName"
                  type="text"
                  placeholder={t(i18nKeys.onboarding.info.firstName)}
                  defaultValue={defaultValues?.firstName}
                />
              </div>

              <div className="col-span-full">
                <Input
                  label={t(i18nKeys.onboarding.info.middleName)}
                  name="middleName"
                  type="text"
                  placeholder={t(i18nKeys.onboarding.info.middleName)}
                  defaultValue={defaultValues?.middleName}
                />
              </div>
              <div className="col-span-full">
                <Input
                  label={t(i18nKeys.onboarding.info.lastName)}
                  name="lastName"
                  type="text"
                  placeholder={t(i18nKeys.onboarding.info.lastName)}
                  defaultValue={defaultValues?.lastName}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between  bottom-4">
          <Button mode="text" onClick={() => onCancel()}>
            {t(i18nKeys.ui.back)}
          </Button>
          <Button mode="contained" type="submit">
            {t(i18nKeys.ui.save)}
          </Button>
        </div>
      </form>
    )
  },
  () => true,
)

Information.displayName = 'Information'
export default Information
