import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescription } from '@/common/types'

import { PrivacyOption } from '../types'

export const PRIVACY_OPTIONS: DisplayItemWithDescription[] = [
  {
    id: PrivacyOption.AlmostNothing,
    name: i18nKeys.sell.nav.privacy.option1.name,
    description: i18nKeys.sell.nav.privacy.option1.description,
  },
  {
    id: PrivacyOption.SomeInformation,
    name: i18nKeys.sell.nav.privacy.option2.name,
    description: i18nKeys.sell.nav.privacy.option2.description,
  },
  {
    id: PrivacyOption.Everything,
    name: i18nKeys.sell.nav.privacy.option3.name,
    description: i18nKeys.sell.nav.privacy.option3.description,
  },
]
