import React from 'react'

import type { PaymentCardType } from '@/core'
import { colors } from '@/theme'
import americanExpress from '@assets/icons/american-express.svg'
import discover from '@assets/icons/discover.svg'
import jcb from '@assets/icons/jcb.svg'
import maestro from '@assets/icons/maestro.svg'
import masterCard from '@assets/icons/mastercard.svg'
import soloPay from '@assets/icons/solo-pay.svg'
import visaClassic from '@assets/icons/visa-classic.svg'
import visaElectron from '@assets/icons/visa-electron.svg'

import { Icon } from '..'

export const iconMapping: Record<PaymentCardType, string> = {
  AM: americanExpress,
  DI: discover,
  JC: jcb,
  MC: masterCard,
  MD: maestro,
  SO: soloPay,
  VI: visaClassic,
  VD: visaClassic,
  VE: visaElectron,
}

export type PaymentCardIconProps = {
  type: PaymentCardType
  className?: string
}

export const PaymentCardIcon: React.FC<PaymentCardIconProps> = (props) => {
  const icon = iconMapping[props.type]

  if (!icon) {
    return <Icon icon="card" color={colors.gray[400]} size={24} />
  }
  return <img src={icon} alt={props.type} className={props.className} />
}
