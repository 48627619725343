import { t } from 'i18next'
import React from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { dateToStringFormat, dateToTimeFormat } from '@/core'
import { colors } from '@/theme'
import WhenIcon from '@assets/images/when-icon.png'

import { CardLabel } from '../cardLabel'

interface WhenFullInterface {
  createdAt: string
  mintedAt: string
  onClickBack: () => void
}

export const WhenFull: React.FC<WhenFullInterface> = ({
  createdAt,
  mintedAt,
  onClickBack,
}) => {
  return (
    <>
      <Button
        onClick={() => onClickBack()}
        mode="text"
        color={colors.linkGreen}
      >
        {t(i18nKeys.ui.back)}
      </Button>
      <div className="bg-white rounded mb-5">
        <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 sm:grid sm:grid-cols-2 gap-4">
          <div>
            <div>
              <label className="w-full text-xl font-semibold flex items-center gap-2">
                <img src={WhenIcon} className="size-5" alt="when-icon" />
                {t(i18nKeys.profile.myLibrary.when.title)}
              </label>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="userClock" size={20} />
                {t(i18nKeys.common.createdAt)}
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-5">
              <CardLabel
                title={t(i18nKeys.common.date)}
                value={dateToStringFormat(createdAt)}
              />
              <CardLabel
                title={t(i18nKeys.common.time)}
                value={dateToTimeFormat(createdAt)}
              />
            </div>
          </div>
          <div>
            <div className="mt-12 mb-2">
              <h2 className="text-2xl font-bold flex">
                <Icon className="my-auto me-3" icon="star" size={20} />
                {t(i18nKeys.common.mintedAt)}
              </h2>
              <div className="grid grid-cols-2 gap-4 mb-5">
                <CardLabel
                  title={t(i18nKeys.common.date)}
                  value={dateToStringFormat(mintedAt)}
                />
                <CardLabel
                  title={t(i18nKeys.common.time)}
                  value={dateToTimeFormat(mintedAt)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
