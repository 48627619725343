import React, { useMemo, useState } from 'react'

import { Button, PaymentCardInfo } from '@/common/components'
import { ApplePay, GooglePay, Venmo } from '@/common/components/Paysafe'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { EPaymentMethod } from '@/common/constants'
import { useNotify, useProfile } from '@/common/hooks'
import type { BuyerShippingAddress } from '@/common/types'
import Spinner from '@/components/Spinner'
import type { PaymentCard } from '@/core'
import { BuyPage } from '@/features/buyProcess/BuyPage'
import { CvvModal } from '@/features/buyProcess/CvvModal'
import { AddressCard } from '@/features/listing'
import ApplePayIcon from '@assets/icons/apple-pay.svg'
import GooglePayIcon from '@assets/icons/google-pay.svg'
import VenmoIcon from '@assets/icons/venmo.svg'

import type { PaymentHandleToken } from './PurchaseSubscriptionPage'

export type SubscriptionConfirmationProps = {
  selectedPaymentMethod: EPaymentMethod
  selectedCard: PaymentCard
  billingAddress: BuyerShippingAddress
  price: number
  priceDisplay: string
  paymentHandleToken: PaymentHandleToken
  onBack: () => void
  onConfirm: (result: TokenizationResponse) => void | Promise<void>
  paymentEmail?: string
}

export const SubscriptionConfirmation: React.FC<
  SubscriptionConfirmationProps
> = ({
  selectedPaymentMethod,
  selectedCard,
  billingAddress,
  price,
  paymentHandleToken,
  onConfirm,
  priceDisplay,
  paymentEmail,
}: SubscriptionConfirmationProps) => {
  const [cvvModalOpen, setCvvModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { error } = useNotify()
  const { profile } = useProfile()

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined
    const segments = [profile.firstName, profile.middleName, profile.lastName]
    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  return (
    <>
      <BuyPage
        heading="Confirm subscription"
        description="Make sure everything is correct."
      >
        <h3 className="font-medium text-base mt-4">Payment method</h3>
        {selectedPaymentMethod === EPaymentMethod.Card ? (
          <PaymentCardInfo
            card={selectedCard}
            className="w-full cursor-default"
          />
        ) : selectedPaymentMethod === EPaymentMethod.GooglePay ? (
          <div className={'flex items-center w-full'}>
            <div className={'flex-grow cursor-pointer ml-2 text-lg'}>
              Google Pay
            </div>
            <img
              src={GooglePayIcon}
              alt="Google Pay"
              className={'w-10 h-7 ml-2'}
            />
          </div>
        ) : selectedPaymentMethod === EPaymentMethod.Venmo ? (
          <div className={'flex items-center w-full'}>
            <div className={'flex-grow cursor-pointer ml-2 text-lg'}>VENMO</div>
            <img src={VenmoIcon} alt="Venmo" className={'w-10 h-7 ml-2'} />
          </div>
        ) : selectedPaymentMethod === EPaymentMethod.ApplePay ? (
          <div className={'flex items-center w-full'}>
            <div className={'flex-grow cursor-pointer ml-2 text-lg'}>
              Apple Pay
            </div>
            <img
              src={ApplePayIcon}
              alt="Apple Pay"
              className={'w-10 h-7 ml-2'}
            />
          </div>
        ) : (
          <div className="w-full cursor-default">Unknown payment method</div>
        )}

        <h3 className="font-medium text-base mt-4">Billing address</h3>
        <AddressCard
          address={billingAddress.address}
          name={billingAddress.name}
          addressType={billingAddress.type}
          className="w-full cursor-default"
          mode="button"
        />

        <div className="flex justify-end mt-16">
          {selectedPaymentMethod === EPaymentMethod.Card ? (
            <Button
              onClick={() => setCvvModalOpen(true)}
              disabled={
                !paymentHandleToken &&
                selectedPaymentMethod === EPaymentMethod.Card
              }
            >
              Confirm {priceDisplay}
            </Button>
          ) : selectedPaymentMethod === EPaymentMethod.GooglePay ? (
            <GooglePay
              billingAddress={billingAddress!}
              totalPrice={price}
              onPaymentComplete={onConfirm}
              onPaymentFailed={() => error('Failed to confirm payment.')}
              onLoading={setIsLoading}
            />
          ) : selectedPaymentMethod === EPaymentMethod.ApplePay ? (
            <ApplePay
              billingAddress={billingAddress!}
              totalPrice={price}
              onPaymentComplete={onConfirm}
              onPaymentFailed={() => error('Failed to confirm payment.')}
              onLoading={setIsLoading}
            />
          ) : selectedPaymentMethod === EPaymentMethod.Venmo ? (
            <Venmo
              paymentEmail={paymentEmail!}
              billingAddress={billingAddress!}
              totalPrice={price}
              onPaymentComplete={onConfirm}
              onPaymentFailed={() => error('Failed to confirm payment.')}
              onLoading={setIsLoading}
            />
          ) : null}
        </div>
      </BuyPage>

      <CvvModal
        totalPrice={price}
        billingAddress={billingAddress}
        cardHolderName={cardHolderName!}
        handleToken={paymentHandleToken}
        onPaymentComplete={onConfirm}
        onPaymentFailed={() => error('Failed to confirm payment.')}
        open={cvvModalOpen}
        onClose={() => setCvvModalOpen(false)}
      />

      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <Spinner />
        </div>
      ) : null}
    </>
  )
}
