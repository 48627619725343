import React, { useMemo } from 'react'

import { classNames } from '@/core'

export type CheckboxWithDescriptionProps = {
  checked: boolean
  label: string
  description: string
  className?: string
  onChange?: (value: boolean) => void
}

export const CheckboxWithDescription: React.FC<CheckboxWithDescriptionProps> = (
  props,
) => {
  const id = useMemo(
    () => `checkbox_${Math.random().toString(36).substring(7)}`,
    [],
  )
  const descriptionId = useMemo(() => `description_${id}`, [id])

  const handleChange = (value: boolean) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <div className={classNames('relative flex items-start', props.className)}>
      <div className="flex h-6 items-center">
        <input
          id={id}
          name={id}
          type="checkbox"
          aria-describedby={descriptionId}
          className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
          value={props.checked ? 'checked' : 'unchecked'}
          onChange={(v) => handleChange(v.target.checked)}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {props.label}
        </label>
        <p id={descriptionId} className="text-gray-500">
          {props.description}
        </p>
      </div>
    </div>
  )
}
