import type { PagedResponse } from '@bit-ui-libs/common'
import { useEffect, useState } from 'react'

import type { WishListListItem } from '@/core'
import { ListingService } from '@/core'

export const useWishlist = () => {
  const [wishlist, setWishlist] = useState<PagedResponse<WishListListItem>>()

  const handleFetchItems = async (pageNumber: number = 1) => {
    const response = await ListingService.getFavorites(pageNumber)
    setWishlist(response)
  }

  useEffect(() => {
    if (!wishlist) {
      handleFetchItems()
    }
  }, [])

  return { wishlist, isLoading: !wishlist, handleFetchItems }
}
