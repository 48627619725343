import type { CustomerAsset } from '@bit-ui-libs/common'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import type { IconSource } from '@/common/components'
import { Icon } from '@/common/components'
import { Identifiers } from '@/common/constants'
import i18nKeys from '@/common/i18nKeys'
import type { Order, OrderStatus } from '@/common/types'
import Spinner from '@/components/Spinner'
import { classNames } from '@/core'
import { AssetService } from '@/core/assets/asset.service'
import { EventService } from '@/core/events'
import { colors } from '@/theme'

export const statusMapping: Record<
  OrderStatus,
  { label: string; icon: IconSource; iconColor: string }
> = {
  CREATED: {
    label: i18nKeys.profile.order.detail.status.created,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  PAYMENT_COMPLETED: {
    label: i18nKeys.profile.order.detail.status.paymentCompleted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  PAYMENT_FAILED: {
    label: i18nKeys.profile.order.detail.status.paymentFailed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  CANCELLED: {
    label: i18nKeys.profile.order.detail.status.cancelled,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_SHIPPED: {
    label: i18nKeys.profile.order.detail.status.orderShipped,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_DELIVERED: {
    label: i18nKeys.profile.order.detail.status.orderDelivered,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  OWNERSHIP_TRANSFER_STARTED: {
    label: i18nKeys.profile.order.detail.status.ownershipTransferStarted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  OWNERSHIP_TRANSFER_COMPLETED: {
    label: i18nKeys.profile.order.detail.status.ownershipTransferCompleted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  OWNERSHIP_TRANSFER_FAILED: {
    label: i18nKeys.profile.order.detail.status.ownershipTransferFailed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_UNDELIVERED: {
    label: i18nKeys.profile.order.detail.status.orderUndelivered,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_SUCCESS: {
    label: i18nKeys.profile.order.detail.status.orderSuccess,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  RETURN: {
    label: i18nKeys.profile.order.detail.status.return,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  RETURN_SHIPPED: {
    label: i18nKeys.profile.order.detail.status.returnShipped,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  RETURN_DELIVERED: {
    label: i18nKeys.profile.order.detail.status.returnDelivered,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  RETURN_UNDELIVERED: {
    label: i18nKeys.profile.order.detail.status.returnUndelivered,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  REFUND: {
    label: i18nKeys.profile.order.detail.status.refund,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  REFUND_COMPLETED: {
    label: i18nKeys.profile.order.detail.status.refundCompleted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  REFUND_FAILED: {
    label: i18nKeys.profile.order.detail.status.refundFailed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
}

export type OrderItemProps = {
  order: Order
}

export const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const [asset, setAsset] = useState<CustomerAsset>()

  const handleFetchAsset = async () => {
    const assetService = await AssetService.getById(order.assetId)
    setAsset(assetService)
  }

  const imageSrc = useMemo(() => {
    if (!asset) return

    const mainImage = asset.serializedImages.find((image) => image.isMain)

    if (!mainImage) return

    return EventService.buildDocumentImageUrlById(mainImage.docId)
  }, [asset])

  const description = useMemo(() => {
    if (!asset) return

    return asset.serializedProps.find(
      (p) => p.name === Identifiers.ASSET_DESCRIPTION_PROP,
    )?.value
  }, [asset])

  const dateCreated = useMemo(() => {
    return new Date(order.createdAt).toLocaleDateString()
  }, [order.createdAt])

  useEffect(() => {
    handleFetchAsset()
  }, [order.assetId])

  return (
    <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
      <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
          {/* TODO backend not returning order tracking number */}
          {/* <div>
            <dt className="font-medium text-gray-900">{t(i18nKeys.profile.order.detail.orderNumber)}</dt>
            <dd className="mt-1 text-gray-500">{order.trackingNumber}</dd>
          </div> */}
          <div className="hidden sm:block">
            <dt className="font-medium text-gray-900">
              {t(i18nKeys.profile.order.detail.datePlaced)}
            </dt>
            <dd className="mt-1 text-gray-500">
              <time dateTime={dateCreated}>{dateCreated}</time>
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-900">
              {t(i18nKeys.profile.order.detail.priceTotal)}
            </dt>
            <dd className="mt-1 font-medium text-gray-900">${order.price}</dd>
          </div>
        </dl>

        <Menu as="div" className="relative flex justify-end lg:hidden">
          <div className="flex items-center">
            <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={`/profile/order/${order.id}`}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      {t(i18nKeys.profile.order.detail.viewOrder)}
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
          <Link
            to={`/profile/order/${order.id}`}
            className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <span>{t(i18nKeys.profile.order.detail.viewOrder)}</span>
          </Link>
        </div>
      </div>

      {/* Products */}
      <div className="p-4 sm:p-6">
        <div className="flex items-center sm:items-start">
          <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
            {imageSrc ? (
              <img
                src={imageSrc}
                alt={order.asset.name}
                className="h-full w-full object-cover object-center"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <Spinner />
              </div>
            )}
          </div>
          <div className="ml-6 flex-1 text-sm">
            <div className="font-medium text-gray-900 sm:flex sm:justify-between">
              <h5>{order.asset.name}</h5>
              <p className="mt-2 sm:mt-0">${order.price}</p>
            </div>
            <p className="hidden text-gray-500 sm:mt-2 sm:block">
              {description}
            </p>
          </div>
        </div>

        <div className="mt-6 sm:flex sm:justify-between">
          <div className="flex items-center">
            <Icon
              icon={statusMapping[order.status!].icon}
              color={statusMapping[order.status!].iconColor}
              size={20}
            />
            <p className="ml-2 text-sm font-medium text-gray-500">
              {t(statusMapping[order.status!].label)}
            </p>
          </div>

          <div className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:ml-4 sm:mt-0 sm:border-none sm:pt-0">
            <div className="flex flex-1 justify-center">
              <a
                href={`/myLibrary/asset/${order.assetId}/detail`}
                className="whitespace-nowrap text-primary-600 hover:text-primary-500"
              >
                {t(i18nKeys.profile.order.detail.viewProduct)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
