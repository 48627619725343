import { type Bid, type PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import Modal from 'react-responsive-modal'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import type {
  UserBidPaginatedRequestInterface,
  UserPaginatedRequestInterface,
} from '@/core'
import {
  dateToStringFormat,
  ListingService,
  translate,
  UserService,
} from '@/core'
import { colors } from '@/theme'

import type { BidWithAddress } from '../components/bidTracking/BidsPaginatedHistory'

export type BidsPaginatedHistoryProps = {
  setOpenBidDetails: (e: string | undefined) => void
  openBidDetails?: string
  userId?: string
}

export const AuctionDetails: React.FC<BidsPaginatedHistoryProps> = ({
  setOpenBidDetails,
  openBidDetails,
  userId,
}) => {
  const [bidHistory, setBidHistory] = useState<PagedResponse<BidWithAddress>>()
  const [lastBidId, setLastBidId] = useState<BidWithAddress>()
  const [userList, setUserList] = useState<any[]>([])
  const [topThree, setTopThree] = useState<any[]>([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState<
    Bid | undefined
  >(undefined)

  useEffect(() => {
    searchBidsPaginatedFilters()
  }, [openBidDetails])

  useEffect(() => {
    getUserName()
    if (
      bidHistory &&
      bidHistory.items.length > 0 &&
      bidHistory.items[0].listing?.id
    ) {
      getLastBid(bidHistory.items[0].listing?.id)
    }
  }, [bidHistory])

  const initials = (user: any) => {
    if (user && user?.firstName && user?.lastName && userId !== undefined) {
      return `${user.firstName[0]}${user.lastName[0]}`
    } else {
      return user?.beingId?.level
    }
  }

  const getLastBid = async (listingId: string) => {
    const lastBid = await ListingService.getLastBid(listingId)
    const bid = bidHistory?.items.find((x) => x.price === lastBid.price)
    setLastBidId(bid)
  }

  const getUserName = async () => {
    const ids = bidHistory?.items.map((x) => x.createdBy)

    if (!ids || ids.length === 0) {
      return
    }

    const options = {
      $page: 1,
      $perPage: 10,
      $ids: ids.join('&$ids='),
    } as UserPaginatedRequestInterface

    const userService = new UserService()

    userService.getUsersPagination(options).then((res) => {
      setUserList(res?.items)
    })
  }

  const searchBidsPaginatedFilters = async () => {
    if (!openBidDetails) {
      setBidHistory(undefined)
      return
    }
    let options: UserBidPaginatedRequestInterface
    if (userId) {
      options = {
        $page: 1,
        $perPage: 10,
        listingId: openBidDetails,
        $orderBy: 'createdAt',
        $order: 'desc',
        createdBy: userId,
      } as UserBidPaginatedRequestInterface
    } else {
      options = {
        $page: 1,
        $perPage: 10,
        listingId: openBidDetails,
        $orderBy: 'createdAt',
        $order: 'desc',
      } as UserBidPaginatedRequestInterface
    }
    const bids = await ListingService.getBidsPaginated(options)

    setTopThree(bids.items.slice(0, 3))

    setBidHistory(bids)
  }

  const rendererTimer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <span>{t(i18nKeys.bid.auction.timeOver)}</span>
    } else {
      // Render a countdown
      return (
        <div className="flex font-bold bg-lime-200 text-lime-600 px-4 rounded-xl">
          <span className="mr-2 m-auto">
            {zeroPad(days)} {t(i18nKeys.bid.auction.days)} - {zeroPad(hours)}:
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
          <span className="my-auto">{t(i18nKeys.ui.left)}</span>
        </div>
      )
    }
  }

  const acceptBid = async (id?: string) => {
    if (!id) {
      return
    }

    const acceptedBid = await ListingService.acceptBid(id)
    setLastBidId(acceptedBid)
    setOpenConfirmationModal(undefined)
  }

  return (
    <section id="auctionDetails" className="mx-auto w-3/5 ">
      <div className="flex justify-start">
        <button
          type="button"
          className="text-sm leading-6 text-gray-900 m-2 ml-5 flex"
          onClick={() => setOpenBidDetails(undefined)}
        >
          <Icon
            icon="arrowRight"
            className="fill-gray-400 rotate-180 size-4 mt-1 mr-1"
          />
          <span>{t(i18nKeys.ui.back)}</span>
        </button>
      </div>

      <div className=" w-full h-full bg-white sm:px-2 lg:px-8 py-6 sm:py-8">
        <div className="flex justify-between">
          <div>
            <h1 className="text-xl font-bold">
              {bidHistory?.items[0]?.asset?.name}
            </h1>
            <h2 className="text-gray-900 mb-3">
              View all the bids for this auction.
            </h2>
          </div>

          {bidHistory?.items[0]?.listing ? (
            <Countdown
              date={Date.parse(bidHistory?.items[0].listing.endAt)}
              renderer={rendererTimer}
            />
          ) : null}
        </div>

        <ConditionalRendering renderIf={lastBidId !== undefined}>
          <div
            key={lastBidId?.id}
            className={
              'border-lime-200 flex justify-between gap-x-6 p-0 border mb-2 rounded-xl max-w-5xl mx-auto mt-5'
            }
          >
            <div className="flex min-w-0 gap-x-4 py-2 px-3">
              <div className="min-w-0 flex-auto">
                <span className="text-xl text-bold text-lime-500">
                  Accepted bid
                </span>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {userId !== undefined
                    ? userList.find(
                        (x: any) =>
                          x.id ===
                          bidHistory?.items.find((x) => x.id === lastBidId?.id)
                            ?.createdBy,
                      )?.username
                    : userList.find(
                        (x: any) =>
                          x.id ===
                          bidHistory?.items.find((x) => x.id === lastBidId?.id)
                            ?.createdBy,
                      )?.id}
                  <span className="mx-1 rounded-2xl border border-lime-500 text-lime-600 px-1 bg-lime-100">
                    {
                      userList.find(
                        (x: any) =>
                          x.id ===
                          bidHistory?.items.find((x) => x.id === lastBidId?.id)
                            ?.createdBy,
                      )?.beingId?.level
                    }{' '}
                  </span>
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  {dateToStringFormat(
                    bidHistory?.items.find((x) => x.id === lastBidId?.id)
                      ?.createdAt,
                  )}{' '}
                  &#x2022;{' '}
                  {lastBidId?.shippingAddress
                    ? lastBidId.shippingAddress.city +
                      ',' +
                      lastBidId.shippingAddress.state
                    : ''}
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="text-xl font-bold text-gray-900 pr-3 my-auto ml-5">
                {bidHistory?.items.find((x) => x.id === lastBidId?.id)?.price}
              </p>
            </div>
          </div>
          <div className="flex-1 border-b border-gray-300 my-12"></div>
        </ConditionalRendering>

        <div className="grid grid-cols-3 grid-flow-row gap-2 my-12 justify-center">
          {topThree[2] ? (
            <div
              className={
                'bg-white border border-lime-600 rounded-lg shadow pt-2 pb-2 size-4/5'
              }
            >
              <span className="p-4 my-auto font-lg font-bold"># 3</span>
              <div className="flex flex-col items-center pb-10">
                <div
                  className="rounded-full bg-primary-100 hover:bg-primary-200 transition-all duration-200 border border-primary flex items-center justify-center "
                  style={{ width: 80, height: 80 }}
                >
                  <span
                    className="font-semibold tracking-wide text-primary-600"
                    style={{ fontSize: 40 }}
                  >
                    {initials(
                      userList.find((x: any) => x.id === topThree[2].createdBy),
                    )}
                  </span>
                </div>

                <h5 className="mb-1 p-3 font-medium text-gray-500 ">
                  {userId !== undefined
                    ? userList.find((x: any) => x.id === topThree[2].createdBy)
                        ?.username
                    : userList.find((x: any) => x.id === topThree[2].createdBy)
                        ?.id}
                </h5>
                <span className="font-bold text-2xl text-lime-600">
                  $ {topThree[2].price}
                </span>

                <ConditionalRendering
                  renderIf={!lastBidId && userId === undefined}
                >
                  <button
                    className="py-2 px-8 font-semibold rounded-lg transition-all duration-300 hover:shadow-md hover:bg-primary-300 border border-lime-500 text-lime-500 bg-lime-200"
                    onClick={() => setOpenConfirmationModal(topThree[2])}
                  >
                    {translate((i) => i.common.acceptBid)}
                  </button>
                </ConditionalRendering>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {topThree[0] ? (
            <div
              className={
                'bg-lime-200 w-full max-w-sm border border-lime-600 rounded-lg shadow pt-2'
              }
            >
              <span className="p-4 my-auto font-lg font-bold"># 1</span>
              <div className="flex flex-col items-center pb-10">
                <div
                  className="rounded-full bg-primary-100 hover:bg-primary-200 transition-all duration-200 border border-primary flex items-center justify-center mb-12"
                  style={{ width: 80, height: 80 }}
                >
                  <span
                    className="font-semibold tracking-wide text-primary-600"
                    style={{ fontSize: 40 }}
                  >
                    {initials(
                      userList.find((x: any) => x.id === topThree[0].createdBy),
                    )}
                  </span>
                </div>

                <h5 className="mb-1 font-medium text-gray-500 p-3">
                  {userId !== undefined
                    ? userList.find((x: any) => x.id === topThree[0].createdBy)
                        ?.username
                    : userList.find((x: any) => x.id === topThree[0].createdBy)
                        ?.id}
                </h5>
                <span className="font-bold text-2xl text-lime-600">
                  $ {topThree[0].price}
                </span>
                <ConditionalRendering
                  renderIf={!lastBidId && userId === undefined}
                >
                  <Button
                    className="hover:shadow-md hover:bg-primary-300"
                    onClick={() => setOpenConfirmationModal(topThree[0])}
                  >
                    {translate((i) => i.common.acceptBid)}
                  </Button>
                </ConditionalRendering>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {topThree[1] ? (
            <div
              className={
                'bg-white border border-lime-600 rounded-lg shadow pt-2 size-10/12'
              }
            >
              <span className="p-4 my-auto font-lg font-bold"># 2</span>
              <div className="flex flex-col items-center pb-10">
                <div
                  className="rounded-full bg-primary-100 hover:bg-primary-200 transition-all duration-200 border border-primary flex items-center justify-center mb-6"
                  style={{ width: 80, height: 80 }}
                >
                  <span
                    className="font-semibold tracking-wide text-primary-600"
                    style={{ fontSize: 40 }}
                  >
                    {initials(
                      userList.find((x: any) => x.id === topThree[1].createdBy),
                    )}
                  </span>
                </div>

                <h5 className="mb-1 p-3 font-medium text-gray-500 ">
                  {userId !== undefined
                    ? userList.find((x: any) => x.id === topThree[1].createdBy)
                        ?.username
                    : userList.find((x: any) => x.id === topThree[1].createdBy)
                        ?.id}
                </h5>
                <span className="font-bold text-2xl text-lime-600">
                  $ {topThree[1].price}
                </span>
                <ConditionalRendering
                  renderIf={!lastBidId && userId === undefined}
                >
                  <button
                    className="py-2 px-8 font-semibold rounded-lg transition-all duration-300 hover:shadow-md hover:bg-primary-300 border border-lime-500 text-lime-500 bg-lime-200"
                    onClick={() => setOpenConfirmationModal(topThree[1])}
                  >
                    {translate((i) => i.common.acceptBid)}
                  </button>
                </ConditionalRendering>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <ul className="mt-5">
          {bidHistory?.items.map((bid, key) => (
            <li
              key={key}
              className={
                (key === 0 ? 'border-lime-200 ' : 'border-gray-200 ') +
                'flex justify-between gap-x-6 p-0 border mb-2 rounded-xl max-w-5xl mx-auto'
              }
            >
              <div className="flex min-w-0 gap-x-4 p-2">
                <span className="p-2 my-auto"># {key + 1}</span>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {userId !== undefined
                      ? userList.find((x: any) => x.id === bid.createdBy)
                          ?.username
                      : userList.find((x: any) => x.id === bid.createdBy)?.id}
                    <span className="mx-1 rounded-2xl border border-lime-500 text-lime-600 px-1 bg-lime-100">
                      {
                        userList.find((x: any) => x.id === bid.createdBy)
                          ?.beingId?.level
                      }{' '}
                    </span>
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {dateToStringFormat(bid.createdAt)} &#x2022;{' '}
                    {bid.shippingAddress
                      ? bid.shippingAddress.city +
                        ',' +
                        bid.shippingAddress.state
                      : ''}
                  </p>
                </div>
              </div>
              <div className="flex">
                <ConditionalRendering
                  renderIf={!lastBidId && userId === undefined}
                >
                  <button
                    className="text-lime-500 border-lime-500 p-2 border h-fit rounded-xl my-auto"
                    onClick={() => setOpenConfirmationModal(bid)}
                  >
                    {translate((i) => i.common.acceptBid)}
                  </button>
                </ConditionalRendering>
                <p className="text-xl font-bold text-gray-900 pr-3 my-auto ml-5">
                  {bid.price}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Modal
        open={openConfirmationModal !== undefined}
        onClose={() => setOpenConfirmationModal(undefined)}
      >
        <div className="flex flex-col px-5 mb-5">
          <h2 className="text-xl font-bold mb-2">
            {translate((i) => i.common.acceptBid)}
          </h2>
          <h3 className="mb-5">{translate((i) => i.bid.confirmAcceptBid)}</h3>

          <div
            key={openConfirmationModal?.id}
            className={
              'border-gray-200 flex justify-between gap-x-6 p-0 border mb-2 rounded-xl max-w-5xl mx-auto'
            }
          >
            <div className="flex min-w-0 gap-x-4 p-2">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {userId !== undefined
                    ? userList.find(
                        (x: any) => x.id === openConfirmationModal?.createdBy,
                      )?.id
                    : userList.find(
                        (x: any) => x.id === openConfirmationModal?.createdBy,
                      )?.userName}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  {dateToStringFormat(openConfirmationModal?.createdAt)}
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="text-xl font-bold text-gray-900 pr-3 my-auto ml-5">
                {openConfirmationModal?.price}
              </p>
            </div>
          </div>
        </div>
        <div
          className="w-full text-center bg-orange-100 border border-orange-500  text-orange-500 px-4 py-3 shadow-md rounded-xl"
          role="alert"
        >
          <span>{translate((i) => i.bid.doubleConfirmAcceptBid)}</span>
        </div>
        <div className="flex  gap-5 justify-end my-5">
          <Button
            mode="text"
            type="button"
            textColor={colors.primary.DEFAULT}
            onClick={() => setOpenConfirmationModal(undefined)}
          >
            {t(i18nKeys.ui.cancel)}
          </Button>

          <Button
            mode="contained"
            type="button"
            onClick={async () => acceptBid(openConfirmationModal?.id)}
          >
            {t(i18nKeys.ui.accept)}
          </Button>
        </div>
      </Modal>
    </section>
  )
}
