import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import { classNames } from '@/core'

type BeingIdLevelCardProps = {
  label: string
  level: number
  mode?: 'ok' | 'warn'
  className?: string
  href?: string
}

export const BeingIdLevelCard: React.FC<BeingIdLevelCardProps> = (props) => {
  const mode = props.mode ?? 'ok'
  return (
    <Link
      to={props.href ?? '#'}
      className={classNames(
        'shadow-md rounded-md p-6 flex w-fit gap-4 items-center',
      )}
    >
      <div className="flex flex-col">
        <span className="text-xs text-gray-400">{props.label}</span>
        <h2
          className={classNames(
            'font-semibold text-lg',
            mode === 'ok' ? 'text-primary-700' : 'text-amber-700',
          )}
        >
          {t(i18nKeys.profile.beingId.title)}
        </h2>
      </div>

      <div
        className={classNames(
          'rounded-full size-14 border flex items-center justify-center',
          mode === 'ok'
            ? 'border-primary bg-primary-50 text-primary-700'
            : 'border-amber-400 bg-amber-50 text-amber-700',
        )}
      >
        <span className="font-semibold text-3xl">{props.level}</span>
      </div>
    </Link>
  )
}
