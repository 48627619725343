import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import envVariables from '@/common/envVariables'
import { Environment } from '@/common/types'

export const startSentryConfig = () => {
  // local environment does not need to be monitored
  if (envVariables.ENVIRONMENT === Environment.LOCAL) {
    return
  }

  init({
    dsn: envVariables.SENTRY_DSN,
    environment: envVariables.ENVIRONMENT,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  })
}
