import { cryptoAesDecrypt, cryptoAesEncrypt } from '@/core/auth/utils'

export class EncryptionService {
  static async decryptPrivateKey(
    encryptedValue: string,
    key: string,
  ): Promise<string> {
    return cryptoAesDecrypt(encryptedValue, key)
  }

  static async encryptPrivateKey(
    privateKey: string,
    userPin: string,
  ): Promise<string> {
    return cryptoAesEncrypt(privateKey, userPin)
  }
}
