import axios from 'axios'

import { Logger } from '@/config'

class FileNotFoundError extends Error {
  constructor() {
    super()
    this.name = 'FileNotFoundError'
  }
}

class DownloadFileError extends Error {
  constructor() {
    super()
    this.name = 'DownloadFileError'
  }
}

class UploadFileError extends Error {
  constructor() {
    super()
    this.name = 'UploadFileError'
  }
}

export class GoogleDriveService {
  static async listGoogleDriveFiles(
    accessToken: string,
    query: string,
  ): Promise<{ id: string; name: string }[]> {
    try {
      const response = await axios.get(
        'https://www.googleapis.com/drive/v3/files',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            q: query,
            fields: 'files(id, name)',
          },
        },
      )
      return response.data.files
    } catch (error) {
      Logger.error('File not found', undefined, error as Error)
      throw new FileNotFoundError()
    }
  }

  static async downloadFileFromGoogleDrive(
    fileId: string,
    accessToken: string,
  ): Promise<string | null> {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/drive/v3/files/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            alt: 'media',
          },
          responseType: 'blob',
        },
      )
      const blob = new Blob([response.data], { type: 'text/plain' })
      const text = await blob.text()
      return text
    } catch (error) {
      Logger.error('Error downloading file', undefined, error as Error)
      throw new DownloadFileError()
    }
  }

  static async handleDownloadGoogleDriveAction(
    accessToken: string,
    fileName: string,
  ): Promise<string | null> {
    try {
      const files = await this.listGoogleDriveFiles(
        accessToken,
        `name='${fileName}'`,
      )
      if (files.length > 0) {
        const fileId = files[0].id
        const content = await this.downloadFileFromGoogleDrive(
          fileId,
          accessToken,
        )
        if (content) {
          return content
        } else {
          Logger.error('File not found')
          throw new FileNotFoundError()
        }
      } else {
        Logger.error('No files found')
        throw new FileNotFoundError()
      }
    } catch (error) {
      Logger.error('Error downloading file', undefined, error as Error)
      throw new DownloadFileError()
    }
  }

  static async uploadFileOnGoogleDrive(
    encryptedText: string,
    accessToken: string,
    fileName: string,
  ): Promise<void> {
    const blob = new Blob([encryptedText], { type: 'text/plain' })
    const form = new FormData()
    form.append(
      'metadata',
      new Blob([JSON.stringify({ name: fileName, mimeType: 'text/plain' })], {
        type: 'application/json',
      }),
    )
    form.append('file', blob)
    try {
      await axios.post(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        form,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/related',
          },
        },
      )
    } catch (error) {
      Logger.error('Error uploading file', undefined, error as Error)
      throw new UploadFileError()
    }
  }
}

export type HandleFileUploaded = (message?: string) => void
export type HandleGetFile = (content: string) => void
