import React from 'react'
import { Link } from 'react-router-dom'

import type { ListItemProps, StorefrontMenuItem } from '@/common/types'

import { useTags } from '../hooks/use-tags'

type MobileCategoryMenuItemProps = ListItemProps<StorefrontMenuItem> & {
  onClick: () => void
}

export const MobileCategoryMenuItem: React.FC<MobileCategoryMenuItemProps> = ({
  item,
  onClick,
}: MobileCategoryMenuItemProps) => {
  const { url } = useTags(item.tags)

  return (
    <Link to={url} onClick={onClick} className="p-3">
      {item.label}
    </Link>
  )
}
