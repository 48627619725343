import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescription } from '@/common/types'

import { TransactionCompletenessType } from '../types'

export const TRANSACTION_COMPLETENESS: DisplayItemWithDescription[] = [
  {
    id: TransactionCompletenessType.TimeOfSale,
    name: i18nKeys.sell.nav.transaction.timeOfSale.name,
    description: i18nKeys.sell.nav.transaction.timeOfSale.description,
  },
  {
    id: TransactionCompletenessType.ItemDelivered,
    name: i18nKeys.sell.nav.transaction.itemDelivered.name,
    description: i18nKeys.sell.nav.transaction.itemDelivered.description,
  },
  {
    id: TransactionCompletenessType.DeliveredAndInspected,
    name: i18nKeys.sell.nav.transaction.deliveredAndInspected.name,
    description:
      i18nKeys.sell.nav.transaction.deliveredAndInspected.description,
  },
]
