import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import type { MegaMenuCategory } from '@/common/components'
import { Identifiers } from '@/common/constants'
import { useNotify } from '@/common/hooks'
import { Logger } from '@/config'
import { AssetsService, CategoriesService } from '@/core'

export type CategoryStorefrontType = 'product' | 'service' | 'event'

export type CategoryStorefrontPageProps = {
  category: CategoryStorefrontType
}

const categoryIdMapping: Record<CategoryStorefrontType, string> = {
  product: Identifiers.PRODUCTS_CATEGORY_ID,
  service: '',
  event: '',
}

// TODO missing translations
export const CategoryStorefrontPage: React.FC<CategoryStorefrontPageProps> = (
  props,
) => {
  const categoryId = categoryIdMapping[props.category]

  const { error } = useNotify()

  const [categories, setCategories] = useState<MegaMenuCategory[]>()

  const handleFetchCategories = async () => {
    try {
      const categoriesService = new CategoriesService()
      const response = await categoriesService.getAllByParentId(categoryId)

      const _categories: MegaMenuCategory[] = []
      const assetService = new AssetsService()

      await Promise.all(
        response.map(async (category) => {
          const subcategories = await assetService.getAssetTypes({
            categoryId: category.id,
            pageSize: 5,
          })

          _categories.push({
            name: category.name,
            href: `/category/${category.id}`,
            subcategories: subcategories.items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((subcategory) => ({
                name: subcategory.name,
                href: `/subcategory/${subcategory.id}`,
              })),
          })
        }),
      )
      setCategories(_categories.sort((a, b) => a.name.localeCompare(b.name)))
    } catch (e) {
      Logger.error('Failed to load categories', undefined, e as Error)
      error('Failed to load categories')
    }
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  return (
    <div className="min-h-[80vh]">
      <div className="bg-white py-8 px-14 max-w-7xl mx-auto sm:min-h-[512px] rounded-md flex flex-col mb-12">
        <h2 className="text-2xl">Products</h2>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-8 gap-y-10 pb-12 pt-10 items-start text-gray-500 sm:text-sm">
          {categories
            ? categories.map((category, categoryKey) => (
                <div key={categoryKey}>
                  <Link
                    to={category.href}
                    className="font-medium text-gray-900"
                    onClick={close}
                  >
                    {category.name}
                  </Link>
                  <ul className="mt-10 sm:mt-4 space-y-2">
                    {category.subcategories.map((item) => (
                      <li key={item.name} className="flex">
                        <Link
                          to={item.href}
                          className="hover:text-gray-800"
                          onClick={close}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  )
}
