import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import { Button } from '@/common/components'
import { PinInput } from '@/common/components/pinInput'
import i18nKeys from '@/common/i18nKeys'
import { colors } from '@/theme'

interface UserPinModalProps {
  handleClose: () => void
  handleContinue: (userPin: string) => void
  handleForgotPin: (userPin: string) => void
  isOpen: boolean
}

const customStyles = {
  modal: {
    width: '432px',
    borderRadius: '16px',
  },
}

export const UserPinModal: React.FC<UserPinModalProps> = ({
  handleClose,
  handleContinue,
  handleForgotPin,
  isOpen,
}) => {
  const [userPin, setUserPin] = useState<string>('')
  const [isForgotPinView, setIsForgotPinView] = useState<boolean>(false)

  return (
    <Modal open={isOpen} center styles={customStyles} onClose={handleClose}>
      <div className="my-4 mx-12">
        <div className="text-center">
          <h1 className="text-lg font-bold mb-4">
            {isForgotPinView
              ? t(i18nKeys.onboarding.myWallet.enterNewPIN)
              : t(i18nKeys.onboarding.myWallet.enterPIN)}
          </h1>
          <PinInput
            key="user-pin"
            handleChange={(value) => setUserPin(value)}
            pin={userPin}
          />
          <div className="flex flex-col my-5">
            <Button
              mode="contained"
              type="button"
              color={colors.yellowLight}
              textColor="white"
              className="rounded-[16px]"
              onClick={() => {
                if (isForgotPinView) {
                  handleForgotPin(userPin)
                } else {
                  handleContinue(userPin)
                }
              }}
            >
              {isForgotPinView
                ? t(i18nKeys.onboarding.myWallet.changePIN)
                : t(i18nKeys.ui.continue)}
            </Button>
            {!isForgotPinView && (
              <button
                type="button"
                className="mt-2 text-blue-600"
                onClick={() => setIsForgotPinView(true)}
              >
                {t(i18nKeys.onboarding.myWallet.forgotPIN)}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
