import { t } from 'i18next'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import { Button } from '@/common/components'
import { colors } from '@/theme'

import i18nKeys from '../../i18nKeys'

interface CommonModalProps {
  handleClose: () => void
  handleConfirm: () => void
  isOpen: boolean
  modalTitle: string
  modalSubTitle?: string
  confirmButtonText?: string
  cancelButtonText?: string
}

const customStyles = {
  modal: {
    width: '640px',
    borderRadius: '16px',
  },
}

export const CommonModal: React.FC<CommonModalProps> = ({
  handleClose,
  handleConfirm,
  isOpen,
  modalTitle,
  modalSubTitle,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Modal open={isOpen} center styles={customStyles} onClose={handleClose}>
      <div className="my-4 mx-12">
        <div className="text-center">
          <h1 className="text-lg font-bold mb-4">{modalTitle}</h1>
          <p className="text-left">{modalSubTitle}</p>
          <div className="flex flex-row my-5 justify-end space-x-5 items-stretch">
            <Button
              mode="contained"
              type="button"
              color={colors.primary.DEFAULT}
              textColor="white"
              className="rounded-[16px] ml-auto"
              onClick={handleConfirm}
            >
              {confirmButtonText || t(i18nKeys.ui.ok)}
            </Button>
            <Button
              mode="contained"
              type="button"
              color={colors.primary.DEFAULT}
              textColor="white"
              className="rounded-[16px] border"
              onClick={handleClose}
            >
              {cancelButtonText || t(i18nKeys.ui.cancel)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
