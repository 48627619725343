import { t } from 'i18next'
import React, { useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { Icon, Input } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { colors } from '@/theme'

export type MultipleValuesInputItem = {
  key: string
  value: string
}

export interface MultipleValuesInputProps {
  items: MultipleValuesInputItem[]
  label?: string
  placeholder?: string
  className?: string
  onChange?: (items: MultipleValuesInputItem[]) => void | Promise<void>
}

export const MultipleValuesInput: React.FC<MultipleValuesInputProps> = (
  props,
) => {
  const [currentValue, setCurrentValue] = useState('')

  const handleAddItem = useCallback(() => {
    if (!currentValue || !props.onChange) {
      return
    }

    const items = [
      ...props.items,
      {
        key: uuid(),
        value: currentValue ? currentValue : '-',
      },
    ] as MultipleValuesInputItem[]

    props.onChange(items)

    setCurrentValue('')
  }, [currentValue, props.items, props.onChange, setCurrentValue])

  const removeItemByKey = useCallback(
    (key: string) => {
      if (!props.onChange) {
        return
      }
      const items = [...props.items].filter((i) => i.key !== key)
      props.onChange(items)
    },
    [props.items, props.onChange],
  )

  const handleEditItem = useCallback(
    (item: MultipleValuesInputItem) => {
      removeItemByKey(item.key)
      setCurrentValue(item.value)
    },
    [removeItemByKey, setCurrentValue],
  )

  const handleDeleteItem = useCallback(
    (item: MultipleValuesInputItem) => {
      removeItemByKey(item.key)
    },
    [removeItemByKey],
  )

  return (
    <div className={props.className}>
      <div className="flex items-end gap-4">
        <Input
          placeholder={props.placeholder}
          label={props.placeholder}
          onChange={(e) => setCurrentValue(e.target.value)}
          value={currentValue}
          className="flex-1"
          maxLength={100}
        />

        <button
          type="button"
          onClick={handleAddItem}
          className="rounded-full bg-primary p-1.5 text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-300"
        >
          <Icon icon="add" color={colors.surface} size={20} />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-2 mt-2">
        {props.items.map((item, key) => (
          <span
            key={key}
            className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
          >
            <button
              onClick={() => handleEditItem(item)}
              className="cursor-pointer"
            >
              {item.value}
            </button>
            <button
              type="button"
              onClick={() => handleDeleteItem(item)}
              className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
            >
              <span className="sr-only">{t(i18nKeys.ui.remove)}</span>
              <Icon icon="close" size={12} color={colors.gray[300]} />
              <span className="absolute -inset-1" />
            </button>
          </span>
        ))}
      </div>
    </div>
  )
}
