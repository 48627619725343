import { translateKey } from '@/core'

import type { CardOption } from '..'
import { ServiceAvailabilityOptionType } from '../types/availability'

export const serviceAvailabilityOptionTypes: CardOption[] = [
  {
    id: ServiceAvailabilityOptionType.Calendar,
    title: translateKey((i) => i.sell.nav.availability.options.calendar.title),
    description: translateKey(
      (i) => i.sell.nav.availability.options.calendar.description,
    ),
  },
  {
    id: ServiceAvailabilityOptionType.ExternalLink,
    title: translateKey((i) => i.sell.nav.availability.options.link.title),
    description: translateKey(
      (i) => i.sell.nav.availability.options.link.description,
    ),
  },
]
