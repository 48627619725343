import React from 'react'

import CategoriesContextProvider from '@/common/context/categories-context'

import { colors } from '../../theme'

import Content from './Content'
import Footer from './Footer'
import Header from './Header'

export interface MainLayoutProps {
  children?: React.ReactNode
}

/**
 * Default layout to use on the application
 */
export default function MainLayout(props: MainLayoutProps) {
  return (
    <CategoriesContextProvider>
      <div
        className="flex flex-col min-h-full"
        style={{ backgroundColor: colors.background }}
      >
        <Header />
        <Content className="pt-5 flex-1">{props.children}</Content>
        <Footer />
      </div>
    </CategoriesContextProvider>
  )
}
