import {
  ECountryCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import { Environment } from '@/common/types'
import ApplicationStorage from '@/core/storage'

export interface IncodeBaseProps {
  onClose: () => void | Promise<void>
  renderType: string
  incodeFlowId: string
}

export interface IncodeCreateOnboardingProps extends IncodeBaseProps {
  nextStep: () => void | Promise<void>
  onOnboardingComplete: () => void | Promise<void>
}

export interface IncodeDocumentVerificationProps extends IncodeBaseProps {
  onDocumentVerified: () => void | Promise<void>
  onBack: () => void | Promise<void>
}

export const incodeConfig = {
  apiURL: envVariables.INCODE_API_URL,
  apiKey: envVariables.INCODE_API_KEY,
}

export const INCODE_NUMBER_OF_TRIES = 3

export enum IncodeCameraType {
  FRONT = 'front',
  BACK = 'back',
  SELFIE = 'selfie',
}

export enum IncodeRenderType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

const INCODE_DEFAULT_COUNTRY_CODE = 'ALL'

export const startIncodeClient = async (
  incodeFlowId: string,
  country?: string,
) => {
  const incodeClient = await (window as any).OnBoarding.create(incodeConfig)
  await incodeClient.warmup()

  // TODO change here when supporting more countries in production. Currently only USA is supported as required by Tim Hines.
  // For development and testing purposes, we will use the country from the address.
  let countryCode = ECountryCode.UnitedStates
  if (envVariables.ENVIRONMENT !== Environment.PRODUCTION && country) {
    countryCode = selectCountryFromSplitString(country) ?? countryCode
  }

  const incodeSession = await incodeClient.createSession(
    country ? countryCode.valueOf() : INCODE_DEFAULT_COUNTRY_CODE,
    null,
    {
      configurationId: incodeFlowId,
    },
  )
  ApplicationStorage.incodeSessionId = incodeSession.interviewId

  await incodeClient.sendGeolocation({ token: incodeSession.token })

  return { ...incodeClient, session: incodeSession }
}
