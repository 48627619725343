import type React from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { authContext } from '@/common/context/auth_context'

interface PrivateRouteInterface {
  children: any
}

export const PrivateRoute: React.FC<PrivateRouteInterface> = ({ children }) => {
  const navigate = useNavigate()

  const { authenticated, loading } = useContext(authContext)

  useEffect(() => {
    if (!authenticated && !loading) {
      navigate('/')
    }
  }, [authenticated, loading])

  if (!authenticated) {
    return null
  }

  return children
}
