import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PaymentCardIcon } from '@/common/components'
import { EPaymentMethod } from '@/common/constants'
import type { Order } from '@/common/types'
import { AssetsService, OrderService } from '@/core'
import { EventService } from '@/core/events'
import ApplePayIcon from '@assets/icons/apple-pay.svg'
import GooglePayIcon from '@assets/icons/google-pay.svg'
import VenmoIcon from '@assets/icons/venmo.svg'

// TODO add translations

export const OrderConfirmationPage: React.FC = () => {
  const [product, setProduct] = useState<Order>()
  const { orderId } = useParams()

  useEffect(() => {
    getProductInfo()
  }, [orderId])

  const getProductInfo = async () => {
    if (!orderId) {
      return
    }

    const order = await OrderService.getOrderById(orderId)
    const asset = await AssetsService.getUserAssetById(order.assetId)

    const data = {
      ...order,
      asset: asset,
    } as Order

    setProduct(data)
  }

  let bodyContent = null

  if (product) {
    bodyContent = (
      <main className="relative lg:min-h-full -mt-5">
        <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <img
            src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
            alt="Not Found"
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div>
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="lg:col-start-2">
              <h1 className="text-sm font-medium text-primary-600">
                Payment successful
              </h1>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Thanks for ordering
              </p>
              <p className="mt-2 text-base text-gray-500">
                We appreciate your order, we’re currently processing it. So hang
                tight and we’ll send you confirmation very soon!
              </p>

              <dl className="mt-16 text-sm font-medium">
                <dt className="text-gray-900">Order number</dt>
                <dd className="mt-2 text-primary-600">{product.id}</dd>
              </dl>

              <ul className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
                <li key={product.id} className="flex space-x-6 py-6">
                  <img
                    src={EventService.buildDocumentImageUrlById(
                      product.asset.serializedImages?.find((x) => x.isMain)
                        ?.docId,
                    )}
                    alt="Not found" //TODO: improve broken image msg
                    className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                  />
                  <div className="flex-auto space-y-1">
                    <h3 className="text-gray-900 text-xl text-bold">
                      <a
                        href={`/search/listing/${product.listingId}/event/${product.asset.eventIdRef}/detail`}
                      >
                        {product.asset.name}
                      </a>
                    </h3>
                    <p key={'-prop'}>{product.asset.description}</p>
                  </div>
                </li>
              </ul>

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">${product?.expenses.price}</dd>
                </div>

                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">
                    ${product?.expenses.shippingCost}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Taxes</dt>
                  <dd className="text-gray-900">
                    ${product?.expenses.costTax}
                  </dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">${product?.expenses.amount}</dd>
                </div>
              </dl>

              <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                <div>
                  <dt className="font-medium text-gray-900">
                    Tracking Number:
                  </dt>
                  <dd className="mt-2">
                    <address className="not-italic">
                      <span className="block">
                        {product?.shipment.trackingNumber}
                      </span>
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    Payment Information
                  </dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    {product?.transactionDetails.paymentType !==
                    EPaymentMethod.Venmo ? (
                      <div className="flex-none">
                        <PaymentCardIcon
                          type={product.transactionDetails?.card?.type}
                          className="w-10"
                        />
                      </div>
                    ) : null}
                    <div className="flex-auto">
                      {product?.transactionDetails.paymentType !==
                      EPaymentMethod.Venmo ? (
                        <p className="text-gray-900">
                          Ending with{' '}
                          {product?.transactionDetails?.card?.lastDigits}
                        </p>
                      ) : null}

                      {product?.transactionDetails.paymentType ===
                      EPaymentMethod.Card ? (
                        <p>
                          (Expires{' '}
                          {product?.transactionDetails?.card?.cardExpiry?.month}
                          /{product?.transactionDetails?.card?.cardExpiry?.year}
                          )
                        </p>
                      ) : product?.transactionDetails.paymentType ===
                        EPaymentMethod.GooglePay ? (
                        <div>
                          <img
                            src={GooglePayIcon}
                            alt="Google Pay"
                            className={'w-10 h-7 ml-2'}
                          />
                        </div>
                      ) : product?.transactionDetails.paymentType ===
                        EPaymentMethod.ApplePay ? (
                        <div>
                          <img
                            src={ApplePayIcon}
                            alt="Apple Pay"
                            className={'w-10 h-7 ml-2'}
                          />
                        </div>
                      ) : product?.transactionDetails.paymentType ===
                        EPaymentMethod.Venmo ? (
                        <div>
                          <img
                            src={VenmoIcon}
                            alt="Venmo"
                            className={'w-10 h-7 ml-2'}
                          />
                          <p className="text-gray-600 mt-2.5">
                            {
                              product?.transactionDetails?.gatewayResponse
                                ?.profile?.firstName
                            }{' '}
                            {
                              product?.transactionDetails?.gatewayResponse
                                ?.profile?.lastName
                            }
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </dd>
                </div>
              </dl>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <a
                  href="/"
                  className="text-sm font-medium text-primary-600 hover:text-primary-500"
                >
                  <span>Continue Shopping</span>
                  <span>&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }

  return bodyContent
}
