import { t } from 'i18next'
import React from 'react'

import { Button, Icon } from '@/common/components'
import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { colors } from '@/theme'
import where from '@assets/icons/global.svg'
import location from '@assets/icons/location.svg'

interface WhereProps {
  handleClickTouchAudit: () => void
  identityData: any
}

export const Where: React.FC<WhereProps> = (props) => {
  const { identityData, handleClickTouchAudit } = props

  const { profile } = useProfile()
  if (!identityData) {
    return <h1>{t(i18nKeys.ui.loading)}...</h1>
  }
  const data = profile?.addresses?.[0]
  return (
    <div className="bg-white">
      <div className="bg-black text-white px-4 py-2 pl-0 rounded-md flex items-center gap-2 mb-2">
        <span className="border-l-2 border-white h-6 inline-block"></span>
        <img src={where} alt="where" width={16} />
        <h2 className="font-semibold">
          {t(i18nKeys.profile.individual.where)}
        </h2>
      </div>
      <div className="border-l-2 border-gray-200 pl-2 mt-2">
        <div className="flex items-center gap-3">
          <p className="text-gray-500">
            {t(i18nKeys.profile.individual.legalAddress)}
          </p>
          <p className="font-200 text-red-500 flex items-center gap-1">
            <Icon icon="error" size={15} color={'colors.red-500'} />
            {data?.isAddressMatched
              ? t(i18nKeys.profile.individual.verified)
              : t(i18nKeys.profile.individual.unverified)}
          </p>
        </div>
        <p>{data?.address}</p>
        <p className="py-2">{t(i18nKeys.profile.individual.locationCreated)}</p>
        <div className="flex gap-3">
          <p>
            <span className="text-gray-500 block">{`${t(i18nKeys.profile.individual.latitude)}`}</span>
            <span className="flex items-center gap-1">
              <img src={location} alt="Location" width={16} />
              {`${identityData?.latitude}`}
            </span>
          </p>
          <p>
            <span className="text-gray-500 block">{`${t(i18nKeys.profile.individual.longitude)}`}</span>
            <span className="flex items-center gap-1">
              <img src={location} alt="Location" width={16} />
              {`${identityData?.longitude}`}
            </span>
          </p>
        </div>
        <div className="mt-4">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-2xl w-full"
            textColor={colors.surface}
            onClick={handleClickTouchAudit}
          >
            {t(i18nKeys.profile.individual.touchAudit)}
          </Button>
        </div>
      </div>
    </div>
  )
}
