import type { AddSellerProfileRequest, PaymentInfo } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useContext, useState, useCallback, useEffect } from 'react'

import type { ComboboxOption } from '@/common/components'
import { Button, Input, Combobox } from '@/common/components'
import { authContext } from '@/common/context/auth_context'
import envVariables from '@/common/envVariables'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { UserService } from '@/core/users'
import { InfoAlert } from '@/features/listing'

import { LinkedAccounts } from '../chainItPay/components/LinkedAccounts'

const urlInformation =
  'https://www.irs.gov/businesses/understanding-your-form-1099-k#:~:text=Back%20to%20top-,Reporting%20threshold,or%20how%20tax%20is%20calculated.'
// TODO missing type in library
const TAX_TYPE_OPTIONS = (): ComboboxOption[] => {
  return [
    {
      id: '',
      text: '',
    },
    {
      id: 'SSN',
      text: 'SSN',
    },
    {
      id: 'TIN',
      text: 'TIN',
    },
    {
      id: 'EIN',
      text: 'EIN',
    },
  ]
}

const TAX_NUMBER_REGEX = /^\d{9}$/

export const Sell: React.FC = () => {
  const { profile, sellerProfile, setSellerProfile } = useContext(authContext)
  const { error: notifyError, success: notifySuccess } = useNotify()

  const [linkedAccounts, setLinkedAccounts] = useState<PaymentInfo[]>()
  const [taxTypeOption, setTaxTypeOption] = useState<ComboboxOption>(
    TAX_TYPE_OPTIONS()[0],
  )
  const [taxNumberError, setTaxNumberError] = useState<string>()
  const [taxIdTypeError, setTaxIdTypeError] = useState<string>()
  const [defaultTaxNumber, setDefaultTaxNumber] = useState<string>()

  useEffect(() => {
    setDefaultTaxNumber(sellerProfile?.taxId ?? '')
    setTaxTypeOption(
      TAX_TYPE_OPTIONS().find((x) => x.id === sellerProfile?.taxIdType) ??
        TAX_TYPE_OPTIONS()[0],
    )
  }, [sellerProfile])

  const onSave = useCallback(
    async (event: Event) => {
      event.preventDefault()
      let result = null
      if (!profile?.userId) {
        throw new Error('error') //todo: improve errors
      }

      if (!TAX_NUMBER_REGEX.test(event.target['taxNumber'].value)) {
        setTaxNumberError(t(i18nKeys.sell.tax.number.error))
        notifyError(t(i18nKeys.sell.tax.number.error))
        return
      } else {
        setTaxNumberError('')
      }

      if (!taxTypeOption.id) {
        setTaxIdTypeError(t(i18nKeys.sell.tax.type.error))
        notifyError(t(i18nKeys.sell.tax.type.error))
        return
      } else {
        setTaxIdTypeError('')
      }

      var userService = new UserService()

      const data = {
        userId: profile.userId,
        appName: envVariables.APP_NAME,
        type: 'Individual',
        taxId: event.target['taxNumber'].value,
        isDefault: true,
      } as AddSellerProfileRequest
      try {
        if (sellerProfile?.id) {
          result = await userService.editSellerProfile(
            {
              id: sellerProfile.id,
              ...data,
            },
            taxTypeOption.id,
          )
        } else {
          result = await userService.addSellerProfile(data, taxTypeOption.id)
        }
        setSellerProfile(result)
        notifySuccess('Success saving data') //TODO: Improve messages and add translates
      } catch {
        notifyError('Error while saving data') //TODO: Improve messages and add translates
      }
    },
    [taxTypeOption, profile],
  )

  const clearForm = () => {
    setDefaultTaxNumber('')
    setTaxTypeOption(TAX_TYPE_OPTIONS()[0])
  }

  async function fetchPayments() {
    try {
      const usersService = new UserService()
      const payments = await usersService.getPaymentDetails(sellerProfile?.id!)
      setLinkedAccounts(payments)
    } catch (e) {
      Logger.error('Failed to fetch payments', undefined, e as Error)
      notifyError('Could not find your bank accounts.')
      setLinkedAccounts([])
    }
  }

  useEffect(() => {
    if (sellerProfile && !linkedAccounts) {
      fetchPayments()
    }
  }, [sellerProfile])

  return (
    <>
      <div className="mx-auto mb-5 p-5 bg-white">
        <div className="w-full h-fit mx-auto">
          <h2 className="mb-2 text-2xl">Sell</h2>
          <InfoAlert
            title="Form 1099-K"
            text={
              t(i18nKeys.sell.text.paragraph1) +
              t(i18nKeys.sell.text.paragraph2)
            }
            linkText={`${t(i18nKeys.sell.text.paragraph3)} ${t(i18nKeys.ui.here)}`}
            href={urlInformation}
            linkBottom
            redirectExternal
            className="mt-4 flex flex-col"
          />

          <form onSubmit={async (e: any) => onSave(e)}>
            <div className="space-y-12">
              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <Input
                      label={t(i18nKeys.sell.tax.number.label)}
                      name="taxNumber"
                      type="password"
                      placeholder={t(i18nKeys.sell.tax.number.placeholder)}
                      error={taxNumberError}
                      defaultValue={defaultTaxNumber}
                    />
                  </div>
                  <div className="col-span-full">
                    <Combobox
                      label={t(i18nKeys.sell.tax.type.label)}
                      onChange={setTaxTypeOption}
                      options={TAX_TYPE_OPTIONS()}
                      selected={taxTypeOption}
                      error={taxIdTypeError}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end bottom-4">
              <Button mode="text" onClick={() => clearForm()} type="reset">
                {t(i18nKeys.ui.clear)}
              </Button>
              <Button className="ms-3" mode="contained" type="submit">
                {t(i18nKeys.ui.save)}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <LinkedAccounts className="mt-8" accounts={linkedAccounts ?? []} />
    </>
  )
}
