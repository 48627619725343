import { BidService } from '@bit-ui-libs/common'
import type {
  AddressTypeEnum,
  AddBidRequest,
  Bid,
  CustomerAsset,
  GetAssetsRequest,
  OrderPaymentDetailsRequest,
  PagedResponse,
  AssetStatus,
} from '@bit-ui-libs/common'
import axios from 'axios'

import type { ECountryCode } from '@/common/constants/countries'
import envVariables from '@/common/envVariables'

import { applicationAxiosClient } from '../axios'
import { doDelete, doGet, doPatch, doPost } from '../fetch'
import { createUrlQueryFromObject, mapQueryParams } from '../format'

const bidService = new BidService({
  apiUrl: envVariables.API_BASE_URL,
  axiosInstance: applicationAxiosClient,
})

function addQueryParams(url: string, params: Record<string, string>) {
  const newUrl = url

  const queries = Object.entries(params)
    .filter(([_, value]) => Boolean(value?.trim()))
    .map(([key, value]) => `${key}=${value}`)

  return queries.length ? `${newUrl}?${queries.join('&')}` : newUrl
}

export interface CustomerAssetWithPrice extends CustomerAsset {
  price: string
  description: string
  assetId: string
  endAt: string
  tags: string[]
  auctionStatus?: string
}

export interface AddBidRequestWithAddress extends AddBidRequest {
  shippingAddressId: string
}
export interface PaymentOrderShippingAddress {
  name: string
  type: AddressTypeEnum
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  countryCode: ECountryCode
}

export interface PaymentOrderBillingAddress {
  name: string
  type: AddressTypeEnum
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  countryCode: ECountryCode
}

export interface PaymentOrder {
  listingId: string
  concurrencyStamp: string
  shippingAddress: PaymentOrderShippingAddress
  billingAddress: PaymentOrderBillingAddress
  paymentType: string
  merchantRefNum: string
  currencyCode: string
  paymentHandleToken: string
  slug: string
  serviceType: string
}

export interface listingResponse {
  id: string
  assetId: string
  createdBy: string
  price: number
  sellingMethod: string
  status: string
  startAt: string
  endAt: string
  concurrencyStamp: string
  isFreeShipping: true
  description: string
  buyerBeingId: number
  completeness: string
}

export interface CarrierResponseInterface {
  id: string
  slug: string
  status: string
  description: string
  type: string
  timezone: string
  createdAt: string
  updatedAt: string
}

export interface ShippingCostResponseInterface {
  carrierAccountId: string
  serviceType: string
  slug: string
  serviceName: string
  totalCharge: number
  currency: string
  deliveryDate: string
  transitTime: number
  infoMessage: string
  errorMessage: string
}

export interface GeoLocationRequestInterface {
  state: string
  country: string
  city: string
}

export type ListAssetParams = GetAssetsRequest & {
  status: AssetStatus | 'LISTED'
  auctionStatus: string | undefined
  createdBy: string
}

export interface UserBidHistoryRequestInterface {
  page: number
  createdBy: string
  sellingMethod?: string
  auctionStatus?: string
}

export interface UserBidPaginatedRequestInterface {
  listingId?: string
  $page: number
  createdBy?: string
  $perPage: number
}

export class ListingService {
  static async getListingsList(
    options: ListAssetParams,
  ): Promise<PagedResponse<CustomerAssetWithPrice>> {
    const query = createUrlQueryFromObject(options)

    return doGet(`/marketplace/v1/listings?${query}`)
  }

  static async validateOrderPayment(options: PaymentOrder): Promise<any> {
    //todo replace for correct type
    return doPost(`/marketplace/v1/orders/order-payment`, options)
  }

  static async getListingById(id: string): Promise<listingResponse> {
    //todo replace for correct type
    return doGet(`/marketplace/v1/listings/${id}`)
  }

  static async getListingByAssetId(assetId: string): Promise<listingResponse> {
    //todo replace for correct type
    return doGet(`/marketplace/v1/listings/${assetId}/asset`)
  }

  static async getCarriers(): Promise<CarrierResponseInterface[]> {
    return doGet(`/marketplace/v1/orders/carriers`)
  }

  static async getPaymentDetails(
    params: PaymentDetailsParams,
  ): Promise<PaymentDetailsResponse> {
    let url = `/marketplace/v1/orders/payment-details`

    if (params) {
      url = addQueryParams(url, params)
    }

    return doGet(url)
  }

  static async calculateShippingCost(
    option: OrderPaymentDetailsRequest,
  ): Promise<ShippingCostResponseInterface[]> {
    //todo replace for correct type
    return doPost(`/marketplace/v1/orders/calculate-shipping-cost`, option)
  }

  static async placeNewBid(option: AddBidRequestWithAddress): Promise<Bid> {
    return bidService.create(option)
    // return doPost(`/marketplace/v1/bids`, option)
  }

  static async getIsFavorite(assetId: string): Promise<boolean> {
    try {
      const response = (await doGet(
        `/marketplace/v1/wishlists/${assetId}`,
      )) as GetWishlistItemByIdResponse
      return !!response.userId
    } catch (e) {
      return false
    }
  }

  static async addFavorite(assetId: string) {
    return doPost(`/marketplace/v1/wishlists`, {
      assetId,
    })
  }

  static async removeFavorite(assetId: string) {
    return doDelete(`/marketplace/v1/wishlists/${assetId}`)
  }

  static async getFavorites(
    pageNumber: number = 1,
  ): Promise<PagedResponse<WishListListItem>> {
    return doGet(
      mapQueryParams(`/marketplace/v1/wishlists`, { $page: pageNumber }),
    ) as Promise<PagedResponse<WishListListItem>>
  }

  static async getLastBid(listingId: string): Promise<Bid> {
    return doGet(`/marketplace/v1/bids/accepted/${listingId}`)
  }

  static async getGeolocation(
    options: GeoLocationRequestInterface,
  ): Promise<any> {
    return axios.get(
      `https://geocode.search.hereapi.com/v1/geocode?q=${options.city},${options.state},${options.country}&apiKey=VBUE3tkAab5OscfG2jwEAG7nQe8WkvNKbfpy1F4tt-Y`,
    )
  }

  static async getAllbidsFromUser(
    options: UserBidHistoryRequestInterface,
  ): Promise<PagedResponse<Bid>> {
    const query = createUrlQueryFromObject(options)
    return doGet(`/marketplace/v1/bids/userBids?${query}`)
  }

  static async getBidsPaginated(
    options: UserBidPaginatedRequestInterface,
  ): Promise<PagedResponse<Bid>> {
    const query = createUrlQueryFromObject(options)
    return doGet(`/marketplace/v1/bids?${query}`)
  }

  static async acceptBid(listingId: string): Promise<Bid> {
    return doPatch(`/marketplace/v1/bids/${listingId}`)
  }
}

export type WishListListItem = {
  name: string
  assetId: string
  level: string
  orgId: string
  eventIdRef: string
  createdAt: string
  isListed: boolean
  isVerifiedByOfficial: boolean
  isVerifiedByWitness: boolean
  qrCodeRef: string
  platform: string
  typeId: string
}

type GetWishlistItemByIdResponse = {
  userId: string
  assetId: string
  createdAt: string
}

export type PaymentDetailsParams = {
  listingId: string
  slug: string
  serviceType: string
  country: string
  city: string
  state: string
  zip: string
}

export type PaymentDetailsResponse = {
  publicKey: string
  merchantRefNum: string
  sellingPrice: number
  amount: number
  amountPaysafe: number
  accountId: string
  gasFee: number
  costTax: number
  shippingCost: number
  handlingFee: number
}
