import React from 'react'

interface ConditionalRenderingProps extends React.PropsWithChildren {
  renderIf: boolean
}
export const ConditionalRendering: React.FC<ConditionalRenderingProps> = ({
  renderIf,
  children,
}) => {
  if (!renderIf) {
    return null
  }

  return <>{children}</>
}

ConditionalRendering.displayName = 'ConditionalRendering'
