import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Input } from '@/common/components'
import { authContext } from '@/common/context/auth_context'
import { useForm, useNotify } from '@/common/hooks'
import { Logger } from '@/config'
import { UserService } from '@/core'

import 'react-toastify/dist/ReactToastify.css'

type PaymentType = 'BankAccount' | 'NetBanking' | 'Card' | 'Paypal'

const fields: { name: string; label: string }[] = [
  { name: 'bankName', label: 'Bank name' },
  { name: 'accountNumber', label: 'Account number' },
  { name: 'routingNumber', label: 'Routing number' },
]

// TODO missing translations
export function AddPaymentDetailsPage() {
  const navigate = useNavigate()
  const { fromError } = useNotify()
  const { userId, buyerProfile, sellerProfile } = useContext(authContext)
  const { data, handleChangeInput } = useForm<Record<string, string>>({
    // We can consider using https://lodash.com/docs/4.17.15#groupBy here to make it more readable, if this is building an object
    ...fields.reduce((acc, i) => ({ ...acc, [i.name]: '' }), {}),
    paymentType: 'BankAccount',
  })

  async function handleSubmit() {
    try {
      const userService = new UserService()
      const values = fields
        .filter((i) => i.name !== 'paymentType')
        // We can consider using https://lodash.com/docs/4.17.15#groupBy here to make it more readable, if this is building an object
        .reduce(
          (acc, i) => ({ ...acc, [i.name]: data[i.name] }),
          {} as Record<string, string>,
        ) as any

      const result = await userService.createPaymentDetails({
        userId: userId!,
        type: 'BankAccount' as PaymentType,
        data: values,
      })

      try {
        await userService.createSellerPaymentDetails(sellerProfile?.id!, {
          userId: userId!,
          type: data.paymentType as PaymentType,
          data: values,
          isDefault: true,
          profileId: sellerProfile?.id!,
          paymentDetailId: result.id,
        })
      } catch (e) {
        Logger.error(
          'Failed to add payment on seller profile',
          undefined,
          e as Error,
        )
        fromError({
          error: e as Error,
          defaultMessage: 'Failed to add a payment on seller profile.',
        })
      }

      try {
        await userService.createBuyerPaymentDetails(buyerProfile?.id!, {
          userId: userId!,
          type: data.paymentType as PaymentType,
          data: values,
          isDefault: true,
          profileId: buyerProfile?.id!,
          paymentDetailId: result.id,
        })
      } catch (e) {
        Logger.error(
          'Failed to add payment on buyer profile',
          undefined,
          e as Error,
        )
        fromError({
          error: e as Error,
          defaultMessage: 'Failed to add a payment on buyer profile.',
        })
      }

      navigate('/profile/seller')
    } catch (e) {
      Logger.error('Failed to add payment', undefined, e as Error)
      fromError({
        error: e as Error,
        defaultMessage: 'Failed to add a payment.',
      })
    }
  }

  return (
    <div className="bg-white px-6 py-8 mt-6 rounded-md flex flex-col gap-4">
      <div className="flex flex-col gap-6 max-w-lg self-center w-full">
        <h2 className="font-semibold text-xl">Add payment</h2>
        {fields.map((field) => (
          <Input
            key={field.name}
            label={field.label}
            id={field.name}
            name={field.name}
            value={data[field.name]}
            onChange={handleChangeInput}
            className="w-full"
          />
        ))}
        <Button onClick={handleSubmit} disabled={!userId || !sellerProfile}>
          Save payment
        </Button>
      </div>
    </div>
  )
}
