import { create } from 'zustand'

import type { WithActions } from '@/common/types'
import { storeActions } from '@/core/context-utils'

import type { CardOption, RadioOptionItem } from '..'
import { serviceListingTerms, servicePaymentTerms } from '../constants'
import { serviceAvailabilityOptionTypes } from '../constants/availability'
import { servicePricing } from '../constants/service-pricing'
import type { AssetImage } from '../types'
import type { AvailabilitySchedule } from '../types/availability'

export type SellServiceState = {
  postingTitle: string
  description: string
  additionalInfo: string
  termsOfService: string
  licensures: AssetImage[]
  pricingType: CardOption
  price: string
  isNegotiable: boolean
  areMaterialsIncluded: boolean
  location: string
  completeness: CardOption
  paymentTerms: RadioOptionItem
  scheduleUrl: string
  availabilityType: CardOption
  availability: AvailabilitySchedule[]
}

export const useServiceListing = create<WithActions<SellServiceState>>(
  (set) => ({
    postingTitle: '',
    description: '',
    additionalInfo: '',
    termsOfService: '',
    licensures: [],
    pricingType: servicePricing[0],
    price: '',
    isNegotiable: false,
    areMaterialsIncluded: false,
    location: '',
    completeness: serviceListingTerms[0],
    paymentTerms: servicePaymentTerms[0],
    scheduleUrl: '',
    availabilityType: serviceAvailabilityOptionTypes[0],
    availability: [],
    ...storeActions<SellServiceState>(set),
  }),
)
