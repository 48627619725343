import React from 'react'

import { colors } from '../../../theme/colors'

import styles from './index.module.css'

export type LinkProps = {
  size?: number
  color?: string
  text: string
  onClick: Function
}

const ChainLink = ({
  size = 14,
  color = colors.linkGreen,
  text,
  onClick,
}: LinkProps) => {
  return (
    <button
      type="button"
      className={styles.link}
      style={{ fontSize: size + 'px', color: color }}
      onClick={() => onClick()}
    >
      {text}
    </button>
  )
}

ChainLink.displayName = 'ChainLink'
export default ChainLink
