import type { CustomerAsset } from '@bit-ui-libs/common'
import { t } from 'i18next'
import { isUndefined, isNull, isEmpty } from 'lodash'
import React from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { EventService } from '@/core/events'
import { colors } from '@/theme'
import WhatIcon from '@assets/images/what-icon.png'

import { CardLabel } from '../cardLabel'

interface WhatInterface {
  product: CustomerAsset
  onClickAudit: () => void
}

export const What: React.FC<WhatInterface> = (props) => {
  return (
    <div className="bg-white rounded">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 sm:grid sm:grid-row gap-4">
        <ConditionalRendering
          renderIf={
            isNull(props.product.qrCodeRef) &&
            isUndefined(props.product.qrCodeRef) &&
            isEmpty(props.product.qrCodeRef)
          }
        >
          <div>
            <label className="w-full text-xl font-semibold flex items-center gap-2">
              <img src={WhatIcon} className="size-7" alt="what-icon" />
              {t(i18nKeys.profile.myLibrary.what.title)}
            </label>
            <ConditionalRendering renderIf={props.product.isVerifiedByOfficial}>
              <h2 className="text-xl mt-3 mb-5 flex">
                <Icon
                  className="my-auto me-3"
                  icon="checkCircle"
                  color={colors.primary[500]}
                  size={16}
                />
                {t(i18nKeys.profile.myLibrary.what.verifiedByOfficials)}
              </h2>
            </ConditionalRendering>
          </div>
        </ConditionalRendering>
        <ConditionalRendering
          renderIf={
            !isNull(props.product.qrCodeRef) &&
            !isUndefined(props.product.qrCodeRef) &&
            !isEmpty(props.product.qrCodeRef)
          }
        >
          <div className="sm:grid sm:grid-cols-2 gap-4">
            <div>
              <div>
                <label className="w-full text-xl font-semibold flex items-center gap-2">
                  <img src={WhatIcon} className="size-5" alt="what-icon" />
                  {t(i18nKeys.profile.myLibrary.what.title)}
                </label>
                <ConditionalRendering
                  renderIf={props.product.isVerifiedByOfficial}
                >
                  <h2 className="text-xl mt-3 mb-5 flex">
                    <Icon
                      className="my-auto me-3"
                      icon="checkCircle"
                      color={colors.primary[500]}
                      size={16}
                    />
                    {t(i18nKeys.profile.myLibrary.what.verifiedByOfficials)}
                  </h2>
                </ConditionalRendering>
              </div>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                {props.product.name}
              </h2>
              <CardLabel
                title="Asset QR Code"
                value={props.product.qrCodeRef!}
              />
              <Button
                className="w-full mt-5"
                mode="outline"
                onClick={() => props.onClickAudit()}
              >
                {t(i18nKeys.profile.myLibrary.touchAudit)}
              </Button>
            </div>
            <img
              src={EventService.buildAssetQrCodeUrl(
                props.product.qrCodeRef!,
                200,
                200,
              )}
              alt="Asset QR Code"
              className="m-auto"
            />
          </div>
        </ConditionalRendering>
      </div>
    </div>
  )
}
