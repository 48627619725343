import React from 'react'
import { Link } from 'react-router-dom'

import { classNames } from '@/core'
import { colors } from '@/theme'

import type { IconSource } from '..'
import { Icon } from '..'

export type HeaderButtonProps = {
  name: string
  icon: IconSource
  href: string
  selected?: boolean
  className?: string
}

export const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  return (
    <Link
      to={props.href}
      className={classNames('flex items-center gap-2', props.className)}
    >
      <Icon icon={props.icon} color={colors.primary.DEFAULT} size={24} />
      <span
        className={classNames(
          'text-base font-semibold',
          props.selected ? 'text-primary-500' : 'text-black',
        )}
      >
        {props.name}
      </span>
    </Link>
  )
}
