export default {
  company: 'company',
  cartDetail: {
    cart: 'cartDetail.cart',
    total: 'cartDetails.total',
  },
  cartSummary: {
    checkout: 'cartSummary.checkout',
    total: 'cartSummary.total',
    shipping: 'cartSummary.shipping',
    summary: 'cartSummary.summary',
    subtotal: 'cartSummary.subtotal',
  },
  header: {
    categories: 'header.categories',
    closeMenu: 'header.closeMenu',
    signIn: 'header.signIn',
    products: 'header.products',
    services: 'header.services',
    events: 'header.events',
    search: 'header.search',
    searchError: 'header.searchError',
  },
  search: {
    categories: 'search.categories',
    closeMenu: 'search.closeMenu',
    filters: 'search.filters',
    products: 'search.products',
  },
  home: {
    carrousel: {
      title: 'home.carrousel.title',
    },
    deals: {
      title: 'home.deals.title',
      subtitle: 'home.deals.subtitle',
    },
    aboutChainIt: {
      heading: 'home.aboutChainIt.heading',
      value1: 'home.aboutChainIt.value1',
      value2: 'home.aboutChainIt.value2',
      value3: 'home.aboutChainIt.value3',
      value4: 'home.aboutChainIt.value4',
      link: 'home.aboutChainIt.link',
    },
    viewAll: 'home.viewAll',
  },
  footer: {
    help: {
      title: 'footer.help.title',
      buy: 'footer.help.buy',
      sell: 'footer.help.sell',
      sellerCenter: 'footer.help.sellerCenter',
    },
    support: {
      title: 'footer.support.title',
      contact: 'footer.support.contact',
      returns: 'footer.support.returns',
    },
    learn: {
      title: 'footer.learn.title',
      vdt: 'footer.learn.vdt',
      ivdt: 'footer.learn.ivdt',
      ovdt: 'footer.learn.ovdt',
    },
    copyright: 'footer.copyright',
  },
  sell: {
    title: 'sell.title',
    text: {
      paragraph1: 'sell.text.paragraph1',
      paragraph2: 'sell.text.paragraph2',
      paragraph3: 'sell.text.paragraph3',
    },
    tax: {
      number: {
        label: 'sell.tax.number.label',
        placeholder: 'sell.tax.number.placeholder',
        error: 'sell.tax.number.error',
      },
      type: {
        label: 'sell.tax.type.label',
        error: 'sell.tax.type.error',
      },
    },
    nav: {
      vdt: {
        label: 'sell.nav.vdt.label',
        description: 'sell.nav.vdt.description',
        itemExists: {
          title: 'sell.nav.vdt.itemExists.title',
          description: 'sell.nav.vdt.itemExists.description',
          action: 'sell.nav.vdt.itemExists.action',
        },
        itemNew: {
          title: 'sell.nav.vdt.itemNew.title',
          description: 'sell.nav.vdt.itemNew.description',
          action: 'sell.nav.vdt.itemNew.action',
        },
        pageTitle: 'sell.nav.vdt.pageTitle',
        chooseItem: 'sell.nav.vdt.chooseItem',
        mintDescription: 'sell.nav.vdt.mintDescription',
        mintDetails: 'sell.nav.vdt.mintDetails',
        chooseModal: {
          title: 'sell.nav.vdt.chooseModal.title',
          empty: 'sell.nav.vdt.chooseModal.empty',
          emptySearch: 'sell.nav.vdt.chooseModal.emptySearch',
          searchPlaceholder: 'sell.nav.vdt.chooseModal.searchPlaceholder',
        },
        error: {
          item: 'sell.nav.vdt.error.item',
        },
      },
      method: {
        label: 'sell.nav.method.label',
        description: 'sell.nav.method.description',
        title: 'sell.nav.method.title',
        priceTitle: 'sell.nav.method.priceTitle',
        price: 'sell.nav.method.price',
        pricePlaceholder: 'sell.nav.method.pricePlaceholder',
        reservePrice: 'sell.nav.method.reservePrice',
        reservePricePlaceholder: 'sell.nav.method.reservePricePlaceholder',
        startingPrice: 'sell.nav.method.startingPrice',
        startingPricePlaceholder: 'sell.nav.method.startingPricePlaceholder',
        duration: 'sell.nav.method.duration',
        durationPlaceholder: 'sell.nav.method.durationPlaceholder',
        startTime: 'sell.nav.method.startTime',
        endTime: 'sell.nav.method.endTime',
        reversePrice: 'sell.nav.method.reversePrice',
        reversePricePlaceholder: 'sell.nav.method.reversePricePlaceholder',
        listingDate: 'sell.nav.method.listingDate',
        listingDatePlaceholder: 'sell.nav.method.listingDatePlaceholder',
        methods: {
          fixed: 'sell.nav.method.methods.fixed',
          fixedDescription: 'sell.nav.method.methods.fixedDescription',
          auction: 'sell.nav.method.methods.auction',
          auctionDescription: 'sell.nav.method.methods.auctionDescription',
        },
        auction: {
          timed: 'sell.nav.method.auction.timed',
          timedDescription: 'sell.nav.method.auction.timedDescription',
          dutch: 'sell.nav.method.auction.dutch',
          dutchDescription: 'sell.nav.method.auction.dutchDescription',
          inverse: 'sell.nav.method.auction.inverse',
          inverseDescription: 'sell.nav.method.auction.inverseDescription',
        },
        error: {
          method: 'sell.nav.method.error.method',
          auctionType: 'sell.nav.method.error.auctionType',
          price: 'sell.nav.method.error.price',
          startTime: 'sell.nav.method.error.startTime',
          endTime: 'sell.nav.method.error.endTime',
          startingPrice: 'sell.nav.method.error.startingPrice',
          reservePrice: 'sell.nav.method.error.reservePrice',
          startTimeFuture: 'sell.nav.method.error.startTimeFuture',
          endTimeFuture: 'sell.nav.method.error.endTimeFuture',
          endTimeAfterStart: 'sell.nav.method.error.endTimeAfterStart',
        },
      },
      details: {
        label: 'sell.nav.details.label',
        description: 'sell.nav.details.description',
        asset: {
          name: 'sell.nav.details.asset.name',
          namePlaceholder: 'sell.nav.details.asset.namePlaceholder',
          description: 'sell.nav.details.asset.description',
          descriptionPlaceholder:
            'sell.nav.details.asset.descriptionPlaceholder',
          category: 'sell.nav.details.asset.category',
          assetType: 'sell.nav.details.asset.assetType',
          subcategoriesError: 'sell.nav.details.asset.subcategoriesError',
          categoryPlaceholder: 'sell.nav.details.asset.categoryPlaceholder',
          subcategory: 'sell.nav.details.asset.subcategory',
          subCategoryPlaceholder:
            'sell.nav.details.asset.subCategoryPlaceholder',
          label: 'sell.nav.details.asset.label',
          labelPlaceholder: 'sell.nav.details.asset.labelPlaceholder',
          labelValue: 'sell.nav.details.asset.labelValue',
          labelValuePlaceholder: 'sell.nav.details.asset.labelValuePlaceholder',
          condition: {
            label: 'sell.nav.details.asset.condition.label',
            excellent: 'sell.nav.details.asset.condition.excellent',
            veryGood: 'sell.nav.details.asset.condition.veryGood',
            acceptable: 'sell.nav.details.asset.condition.acceptable',
            bad: 'sell.nav.details.asset.condition.bad',
            repair: 'sell.nav.details.asset.condition.repair',
          },
          error: {
            fields: 'sell.nav.details.asset.error.fields',
            name: 'sell.nav.details.asset.error.name',
          },
        },
      },
      images: {
        label: 'sell.nav.images.label',
        description: 'sell.nav.images.description',
        title: 'sell.nav.images.title',
        drop: 'sell.nav.images.drop',
        error: {
          loading: 'sell.nav.images.error.loading',
          required: 'sell.nav.images.error.required',
          delete: 'sell.nav.images.error.delete',
        },
      },
      payment: {
        label: 'sell.nav.payment.label',
        description: 'sell.nav.payment.description',
        title: 'sell.nav.payment.title',
        usd: {
          name: 'sell.nav.payment.usd.name',
          description: 'sell.nav.payment.usd.description',
          action: 'sell.nav.payment.usd.action',
          empty: 'sell.nav.payment.usd.empty',
        },
        crypto: {
          name: 'sell.nav.payment.crypto.name',
          description: 'sell.nav.payment.crypto.description',
          action: 'sell.nav.payment.crypto.action',
          empty: 'sell.nav.payment.crypto.empty',
        },
        chooseModal: {
          title: 'sell.nav.payment.chooseModal.title',
          empty: 'sell.nav.payment.chooseModal.empty',
          emptySearch: 'sell.nav.payment.chooseModal.emptySearch',
          searchPlaceholder: 'sell.nav.payment.chooseModal.searchPlaceholder',
        },
        error: {
          account: 'sell.nav.payment.error.account',
        },
      },
      transaction: {
        label: 'sell.nav.transaction.label',
        description: 'sell.nav.transaction.description',
        title: 'sell.nav.transaction.title',
        timeOfSale: {
          name: 'sell.nav.transaction.timeOfSale.name',
          description: 'sell.nav.transaction.timeOfSale.description',
        },
        itemDelivered: {
          name: 'sell.nav.transaction.itemDelivered.name',
          description: 'sell.nav.transaction.itemDelivered.description',
        },
        deliveredAndInspected: {
          name: 'sell.nav.transaction.deliveredAndInspected.name',
          description: 'sell.nav.transaction.deliveredAndInspected.description',
        },
        revenue: {
          title: 'sell.nav.transaction.revenue.title',
          text: 'sell.nav.transaction.revenue.text',
          modify: 'sell.nav.transaction.revenue.modify',
        },
      },
      delivery: {
        label: 'sell.nav.delivery.label',
        description: 'sell.nav.delivery.description',
        shipping: {
          label: 'sell.nav.delivery.shipping.label',
          description: 'sell.nav.delivery.shipping.description',
          offDescription: 'sell.nav.delivery.shipping.offDescription',
        },
        shipFrom: 'sell.nav.delivery.shipFrom',
        emptyAddress: 'sell.nav.delivery.emptyAddress',
        weight: 'sell.nav.delivery.weight',
        weightPlaceholder: 'sell.nav.delivery.weightPlaceholder',
        length: 'sell.nav.delivery.length',
        lengthPlaceholder: 'sell.nav.delivery.lengthPlaceholder',
        width: 'sell.nav.delivery.width',
        widthPlaceholder: 'sell.nav.delivery.widthPlaceholder',
        height: 'sell.nav.delivery.height',
        heightPlaceholder: 'sell.nav.delivery.heightPlaceholder',
        usOnlyWarn: 'sell.nav.delivery.usOnlyWarn',
        zip: 'sell.nav.delivery.zip',
        zipPlaceholder: 'sell.nav.delivery.zipPlaceholder',
        pickup: {
          label: 'sell.nav.delivery.pickup.label',
          description: 'sell.nav.delivery.pickup.description',
          offDescription: 'sell.nav.delivery.pickup.offDescription',
        },
        chooseModal: {
          title: 'sell.nav.delivery.chooseModal.title',
          empty: 'sell.nav.delivery.chooseModal.empty',
          emptySearch: 'sell.nav.delivery.chooseModal.emptySearch',
          searchPlaceholder: 'sell.nav.delivery.chooseModal.searchPlaceholder',
        },
        error: {
          address: 'sell.nav.delivery.error.address',
          weight: 'sell.nav.delivery.error.weight',
          length: 'sell.nav.delivery.error.length',
          width: 'sell.nav.delivery.error.width',
          height: 'sell.nav.delivery.error.height',
        },
      },
      privacy: {
        label: 'sell.nav.privacy.label',
        description: 'sell.nav.privacy.description',
        title: 'What would you like to share about you on this posting?',
        option1: {
          name: 'sell.nav.privacy.option1.name',
          description: 'sell.nav.privacy.option1.description',
        },
        option2: {
          name: 'sell.nav.privacy.option2.name',
          description: 'sell.nav.privacy.option2.description',
        },
        option3: {
          name: 'sell.nav.privacy.option3.name',
          description: 'sell.nav.privacy.option3.description',
        },
      },
      buyer: {
        label: 'sell.nav.buyer.label',
        description: 'sell.nav.buyer.description',
        title: 'sell.nav.buyer.title',
      },
      preview: {
        title: 'sell.nav.preview.title',
        product: {
          addCart: 'sell.nav.preview.product.addCart',
          features: 'sell.nav.preview.product.features',
        },
        info: {
          title: 'sell.nav.preview.info.title',
          text: 'sell.nav.preview.info.text',
        },
        payoutOn: 'sell.nav.preview.payoutOn',
        dimensions: 'sell.nav.preview.dimensions',
        confirmText: 'sell.nav.preview.confirmText',
        privacy: {
          share: 'sell.nav.preview.privacy.share',
          buyerLevel: 'sell.nav.preview.privacy.buyerLevel',
        },
      },

      minting: {
        title: 'sell.nav.minting.title',
        description: 'sell.nav.minting.description',
        wait: 'sell.nav.minting.wait',
        timeElapsed: 'sell.nav.minting.timeElapsed',
      },
    },
  },
  onboarding: {
    phone: {
      phoneNumber: 'onboarding.phone.phoneNumber',
      phonePlaceholder: 'onboarding.phone.phonePlaceholder',
      sendCode: 'onboarding.phone.sendCode',
      codeSent: 'onboarding.phone.codeSent',
      verificationCode: 'onboarding.phone.verificationCode',
      codePlaceholder: 'onboarding.phone.codePlaceholder',
      phoneError: 'onboarding.phone.phoneError',
      codeError: 'onboarding.phone.codeError',
    },
    steps: {
      credentials: 'onboarding.steps.credentials',
      faceScan: 'onboarding.steps.faceScan',
      personalInfo: 'onboarding.steps.personalInfo',
      wallet: 'Wallet',
    },
    address: {
      name: 'onboarding.address.name',
      namePlaceholder: 'onboarding.address.namePlaceholder',
      country: 'onboarding.address.country',
      countryPlaceholder: 'onboarding.address.countryPlaceholder',
      state: 'onboarding.address.state',
      statePlaceholder: 'onboarding.address.statePlaceholder',
      city: 'onboarding.address.city',
      cityPlaceholder: 'onboarding.address.cityPlaceholder',
      address: 'onboarding.address.address',
      addressPlaceholder: 'onboarding.address.addressPlaceholder',
      type: 'onboarding.address.type',
      home: 'onboarding.address.home',
      billing: 'onboarding.address.billing',
      office: 'onboarding.address.office',
      warehouse: 'onboarding.address.warehouse',
      company: 'onboarding.address.company',
      factory: 'onboarding.address.factory',
      others: 'onboarding.address.others',
      zip: 'onboarding.address.zip',
      zipPlaceholder: 'onboarding.address.zipPlaceholder',
      searchYourAddress: 'onboarding.address.searchYourAddress',
      addAddress: 'onboarding.address.addAddress',
    },
    personalInformation: {
      goBackAndLoginAgain: 'onboarding.personalInformation.goBackAndLoginAgain',
      personalInfo: 'onboarding.personalInformation.personalInfo',
      location: 'onboarding.personalInformation.location',
      whereToFindYou: 'onboarding.personalInformation.whereToFindYou',
      contactDetails: 'onboarding.personalInformation.contactDetails',
      howToReachYou: 'onboarding.personalInformation.howToReachYou',
      tellUsAboutYou: 'onboarding.personalInformation.tellUsAboutYou',
      personalInformation: 'onboarding.personalInformation.personalInformation',
      toContinueTellUsAboutYourself:
        'onboarding.personalInformation.toContinueTellUsAboutYourself',
      continueOnAnotherDevice:
        'onboarding.personalInformation.continueOnAnotherDevice',
      continueOnThisDevice:
        'onboarding.personalInformation.continueOnThisDevice',
      scanYourFaceToVerifyYourIdentity:
        'onboarding.personalInformation.scanYourFaceToVerifyYourIdentity',
      scanCodeInAnotherDevice:
        'onboarding.personalInformation.scanCodeInAnotherDevice',
      allowBrowserToAccessCamera:
        'onboarding.personalInformation.allowBrowserToAccessCamera',
      wallet: 'onboarding.personalInformation.wallet',
      connectWallet: 'onboarding.personalInformation.connectWallet',
    },
    info: {
      firstName: 'onboarding.info.firstName',
      firstNamePlaceholder: 'onboarding.info.firstNamePlaceholder',
      middleName: 'onboarding.info.middleName',
      middleNamePlaceholder: 'onboarding.info.middleNamePlaceholder',
      lastName: 'onboarding.info.lastName',
      lastNamePlaceholder: 'onboarding.info.lastNamePlaceholder',
    },
    done: {
      welcomeLine1: 'onboarding.done.welcomeLine1',
      welcomeLine2: 'onboarding.done.welcomeLine2',
      description: 'onboarding.done.description',
    },
    verifyingInformation: 'onboarding.verifyingInformation',
    errors: {
      downloadFileError: 'onboarding.errors.downloadFileError',
      uploadFileError: 'onboarding.errors.uploadFileError',
      pinNotMatched: 'onboarding.errors.pinNotMatched',
      ivdtTimeout: 'onboarding.errors.vdtTimeout',
    },
    myWallet: {
      success: 'onboarding.myWallet.success',
      pinChangeSuccessfully: 'onboarding.myWallet.pinChangeSuccessfully',
      repeatPIN: 'onboarding.myWallet.repeatPIN',
      createPIN: 'onboarding.myWallet.createPIN',
      forgotPIN: 'onboarding.myWallet.forgotPIN',
      enterCurrentPIN: 'onboarding.myWallet.enterCurrentPIN',
      enterNewPIN: 'onboarding.myWallet.enterNewPIN',
      enterPIN: 'onboarding.myWallet.enterPIN',
      changePIN: 'onboarding.myWallet.changePIN',
      myWalletTitle: 'onboarding.myWallet.myWalletTitle',
      newWallet: 'onboarding.myWallet.newWallet',
      whereSecureIt: 'onboarding.myWallet.whereSecureIt',
      howSecureIt: 'onboarding.myWallet.howSecureIt',
      secureContent: 'onboarding.myWallet.secureContent',
      secureCreatePin: 'onboarding.myWallet.secureCreatePin',
    },
    setBackup: {
      setBackupTitle: 'onboarding.setBackup.setBackupTitle',
      setBackupText: 'onboarding.setBackup.setBackupText',
      infoContent: 'onboarding.setBackup.infoContent',
      chooseStorage: 'onboarding.setBackup.chooseStorage',
      storeLocally: 'onboarding.setBackup.storeLocally',
      googleDrive: 'onboarding.setBackup.googleDrive',
      oneDrive: 'onboarding.setBackup.oneDrive',
      iCloud: 'onboarding.setBackup.iCloud',
      dropbox: 'onboarding.setBackup.dropbox',
    },
    confirmationModal: {
      modalTitle: 'onboarding.confirmationModal.modalTitle',
      continueAnyway: 'onboarding.confirmationModal.continueAnyway',
      storingBackupFileLocallyIs:
        'onboarding.confirmationModal.storingBackupFileLocallyIs',
      not: 'onboarding.confirmationModal.not',
      a: 'onboarding.confirmationModal.a',
      recommended: 'onboarding.confirmationModal.recommended',
      option: 'onboarding.confirmationModal.option',
    },
    success: {
      successTitle: 'onboarding.success.successTitle',
      successText: 'onboarding.success.successText',
    },
    creatingWallet: {
      creatingWalletTitle: 'onboarding.creatingWallet.creatingWalletTitle',
      creatingWalletText: 'onboarding.creatingWallet.creatingWalletText',
      creatingWalletContent: 'onboarding.creatingWallet.creatingWalletContent',
    },
  },
  ui: {
    ok: 'ui.ok',
    cancel: 'ui.cancel',
    confirm: 'ui.confirm',
    back: 'ui.back',
    accept: 'ui.accept',
    validate: 'ui.validate',
    continue: 'ui.continue',
    add: 'ui.add',
    previous: 'ui.previous',
    save: 'ui.save',
    remove: 'ui.remove',
    delete: 'ui.delete',
    upload: 'ui.upload',
    change: 'ui.change',
    submit: 'ui.submit',
    loading: 'ui.loading',
    next: 'ui.next',
    clear: 'ui.clear',
    here: 'ui.here',
    error: 'ui.error',
    confirmPurchase: 'ui.confirmPurchase',
    marketplace: 'ui.marketplace',
    remaining: 'ui.remaining',
    getStarted: 'ui.getStarted',
    left: 'ui.left',
    start: 'ui.start',
  },
  common: {
    selfVerified: 'common.selfVerified',
    vdtMinted: 'common.vdtMinted',
    mintedAt: 'common.mintedAt',
    image: 'common.image',
    level: 'common.level',
    emptySearchItems: 'common.emptySearchItems',
    noItemsFound: 'common.noItemsFound',
    beingId: 'common.beingId',
    soon: 'common.soon',
    createdAt: 'common.createdAt',
    date: 'common.date',
    time: 'common.time',
    longitude: 'common.longitude',
    latitude: 'common.latitude',
    somethingWentWrong: 'common.somethingWentWrong',
    success: 'common.success',
    minutes: 'common.minutes',
    seconds: 'common.seconds',
  },
  signOut: 'signOut',
  comingSoonDescription: 'comingSoonDescription',
  settings: 'settings',
  profile: {
    digitalIdentity: {
      title: 'profile.digitalIdentity.title',
      beingId: {
        subTitle: 'profile.digitalIdentity.beingId.subTitle',
        subMessage: 'profile.digitalIdentity.beingId.subMessage',
        card1: {
          subTitle: 'profile.digitalIdentity.beingId.card1.subTitle',
          message: 'profile.digitalIdentity.beingId.card1.message',
          continueBtn: 'profile.digitalIdentity.beingId.card1.continueBtn',
        },
        card2: {
          subTitle: 'profile.digitalIdentity.beingId.card2.subTitle',
          message: 'profile.digitalIdentity.beingId.card2.message',
          continueBtn: 'profile.digitalIdentity.beingId.card2.continueBtn',
        },
        documentsBtn: 'profile.digitalIdentity.beingId.documentsBtn',
      },
      accountInformation: {
        subTitle: 'profile.digitalIdentity.accountInformation.subTitle',
      },
      userAccounts: {
        subTitle: 'profile.digitalIdentity.userAccounts.subTitle',
        subMessage: 'profile.digitalIdentity.userAccounts.subMessage',
      },
    },
    individual: {
      title: 'profile.individual.title',
      who: 'profile.individual.who',
      what: 'profile.individual.what',
      when: 'profile.individual.when',
      where: 'profile.individual.where',
      touchAudit: 'profile.individual.touchAudit',
      showQRCode: 'profile.individual.showQRCode',
      beingIDLevelHistory: 'profile.individual.beingIDLevelHistory',
      firstName: 'profile.individual.firstName',
      lastName: 'profile.individual.lastName',
      dob: 'profile.individual.dob',
      age: 'profile.individual.age',
      verified: 'profile.individual.verified',
      unverified: 'profile.individual.unverified',
      legalAddress: 'profile.individual.legalAddress',
      locationCreated: 'profile.individual.locationCreated',
      latitude: 'profile.individual.latitude',
      longitude: 'profile.individual.longitude',
      noDocument: 'profile.individual.noDocument',
      createdAt: 'profile.individual.createdAt',
      vdtMintedAt: 'profile.individual.vdtMintedAt',
      blockchain: 'profile.individual.blockchain',
      transactionHash: 'profile.individual.transactionHash',
      status: 'profile.individual.status',
      block: 'profile.individual.block',
      timestamp: 'profile.individual.timestamp',
      ownerWallet: 'profile.individual.ownerWallet',
      tokenType: 'profile.individual.tokenType',
      value: 'profile.individual.value',
      transactionFee: 'profile.individual.transactionFee',
      gasPrice: 'profile.individual.gasPrice',
      maticPrice: 'profile.individual.maticPrice',
      overview: 'profile.individual.overview',
      beingIDLevel: 'profile.individual.beingIDLevel',
      individualVDTID: 'profile.individual.individualVDTID',
      verifiedByOfficials: 'profile.individual.verifiedByOfficials',
      tokenDetails: 'profile.individual.tokenDetails',
      listDocuments: 'profile.individual.listDocuments',
      tokens: 'profile.individual.tokens',
      viewAll: 'profile.individual.viewAll',
      transactionDetails: 'profile.individual.transactionDetails',
    },
    personalInformation: {
      name: 'profile.personalInformation.name',
      title: 'profile.personalInformation.title',
      addresses: 'profile.personalInformation.addresses',
      addAddress: 'profile.personalInformation.addAddress',
      markAddressAsMain: 'profile.personalInformation.markAddressAsMain',
      sendMessageAlert: 'profile.personalInformation.sendMessageAlert',
    },
    beingId: {
      title: 'profile.beingId.title',
      header: {
        subTitle: 'profile.beingId.header.subTitle',
        footer: 'profile.beingId.header.footer',
      },
      card1: {
        subTitle: 'profile.beingId.card1.subTitle',
        imgText: 'profile.beingId.card1.imgText',
      },
      card2: {
        subTitle: 'profile.beingId.card2.subTitle',
        imgText: 'profile.beingId.card2.imgText',
      },
    },
    chainItPay: 'profile.chainItPay',
    organization: 'profile.organization',
    seller: 'profile.seller',
    sell: 'profile.sell',
    myLibrary: {
      title: 'profile.myLibrary.title',
      filters: {
        filters: 'profile.myLibrary.filters.filters',
        categories: 'profile.myLibrary.filters.categories',
        closeMenu: 'profile.myLibrary.filters.closeMenu',
        viewGrid: 'profile.myLibrary.filters.viewGrid',
        backToList: 'profile.myLibrary.filters.backToList',
        sort: 'profile.myLibrary.filters.sort',
        products: 'profile.myLibrary.filters.products',
      },
      productInfo: {
        sellAsset: 'profile.myLibrary.productInfo.sellAsset',
        publishAsset: 'profile.myLibrary.productInfo.publishAsset',
        selfVerified: 'profile.myLibrary.productInfo.selfVerified',
        onSaleAsset: 'profile.myLibrary.productInfo.onSaleAsset',
        cancelSell: 'profile.myLibrary.productInfo.cancelSell',
        buyProduct: 'profile.myLibrary.productInfo.buyProduct',
        placeBid: 'profile.myLibrary.productInfo.placeBid',
      },
      where: {
        title: 'profile.myLibrary.where.title',
        locationDetails: 'profile.myLibrary.where.locationDetails',
        deviceDetail: 'profile.myLibrary.where.deviceDetail',
        deviceId: 'profile.myLibrary.where.deviceId',
        deviceNameType: 'profile.myLibrary.where.deviceNameType',
        ipAddress: 'profile.myLibrary.where.ipAddress',
        trustLevel: 'profile.myLibrary.where.trustLevel',
        network: 'profile.myLibrary.where.network',
      },
      when: {
        title: 'profile.myLibrary.when.title',
        locationDetails: 'Detalles de ubicación',
        deviceDetail: 'Detalles de dispositivo',
      },
      who: {
        title: 'profile.myLibrary.who.title',
        details: 'profile.myLibrary.who.details',
        officers: 'profile.myLibrary.who.officers',
        witness: 'profile.myLibrary.who.witness',
        location: 'profile.myLibrary.who.location',
        beingIdGrade: 'profile.myLibrary.who.beingIdGrade',
        chainItId: 'profile.myLibrary.who.chainItId',
      },
      what: {
        title: 'profile.myLibrary.what.title',
        verifiedByOfficials: 'profile.myLibrary.what.verifiedByOfficials',
        details: 'profile.myLibrary.what.details',
        images: 'profile.myLibrary.what.images',
      },
      touchAudit: 'profile.myLibrary.touchAudit',
    },
    order: {
      nav: 'profile.order.nav',
      title: 'profile.order.title',
      description: 'profile.order.description',
      transferSuccessful: 'profile.order.transferSuccessful',
      transferError: 'profile.order.transferError',
      empty: 'profile.order.empty',
      orderReceived: 'profile.order.orderReceived',
      modalTitle: 'profile.order.modalTitle',
      modalSubTitle: 'profile.order.modalSubTitle',
      detail: {
        title: 'profile.order.detail.title',
        datePlaced: 'profile.order.detail.datePlaced',
        priceTotal: 'profile.order.detail.priceTotal',
        viewOrder: 'profile.order.detail.viewOrder',
        viewProduct: 'profile.order.detail.viewProduct',
        viewShipping: 'profile.order.detail.viewShipping',
        orderNumber: 'profile.order.detail.orderNumber',
        deliveryAddress: 'profile.order.detail.deliveryAddress',
        billingAddress: 'profile.order.detail.billingAddress',
        shippingUpdates: 'profile.order.detail.shippingUpdates',
        statusTimestamp: 'profile.order.detail.statusTimestamp',
        shippingAddress: 'profile.order.detail.shippingAddress',
        paymentInformation: 'profile.order.detail.paymentInformation',
        cardEnding: 'profile.order.detail.cardEnding',
        cardExpires: 'profile.order.detail.cardExpires',
        status: {
          created: 'profile.order.detail.status.created',
          paymentCompleted: 'profile.order.detail.status.paymentCompleted',
          paymentFailed: 'profile.order.detail.status.paymentFailed',
          cancelled: 'profile.order.detail.status.cancelled',
          orderShipped: 'profile.order.detail.status.orderShipped',
          orderDelivered: 'profile.order.detail.status.orderDelivered',
          ownershipTransferStarted:
            'profile.order.detail.status.ownershipTransferStarted',
          ownershipTransferCompleted:
            'profile.order.detail.status.ownershipTransferCompleted',
          ownershipTransferFailed:
            'profile.order.detail.status.ownershipTransferFailed',
          orderUndelivered: 'profile.order.detail.status.orderUndelivered',
          orderSuccess: 'profile.order.detail.status.orderSuccess',
          return: 'profile.order.detail.status.return',
          returnShipped: 'profile.order.detail.status.returnShipped',
          returnDelivered: 'profile.order.detail.status.returnDelivered',
          returnUndelivered: 'profile.order.detail.status.returnUndelivered',
          refund: 'profile.order.detail.status.refund',
          refundCompleted: 'profile.order.detail.status.refundCompleted',
          refundFailed: 'profile.order.detail.status.refundFailed',
        },
        costs: {
          products: 'profile.order.detail.costs.products',
          shipping: 'profile.order.detail.costs.shipping',
          gas: 'profile.order.detail.costs.gas',
          tax: 'profile.order.detail.costs.tax',
          total: 'profile.order.detail.costs.total',
        },
        steps: {
          orderPlaced: 'profile.order.detail.steps.orderPlaced',
          shipped: 'profile.order.detail.steps.shipped',
          delivered: 'profile.order.detail.steps.delivered',
          ownership: 'profile.order.detail.steps.ownership',
        },
      },
    },
  },
  beingId: {
    base10: {
      level1: {
        name: 'beingId.base10.level1.name',
        description: 'beingId.base10.level1.description',
      },
      level2: {
        name: 'beingId.base10.level2.name',
        description: 'beingId.base10.level2.description',
      },
      level3: {
        name: 'beingId.base10.level3.name',
        description: 'beingId.base10.level3.description',
      },
      level4: {
        name: 'beingId.base10.level4.name',
        description: 'beingId.base10.level4.description',
      },
      level5: {
        name: 'beingId.base10.level5.name',
        description: 'beingId.base10.level5.description',
      },
      level6: {
        name: 'beingId.base10.level6.name',
        description: 'beingId.base10.level6.description',
      },
      level7: {
        name: 'beingId.base10.level7.name',
        description: 'beingId.base10.level7.description',
      },
      level8: {
        name: 'beingId.base10.level8.name',
        description: 'beingId.base10.level8.description',
      },
      level9: {
        name: 'beingId.base10.level9.name',
        description: 'beingId.base10.level9.description',
      },
      level10: {
        name: 'beingId.base10.level10.name',
        description: 'beingId.base10.level10.description',
      },
    },
    base6: {
      level1: {
        name: 'beingId.base6.level1.name',
        description: 'beingId.base6.level1.description',
      },
      level2: {
        name: 'beingId.base6.level2.name',
        description: 'beingId.base6.level2.description',
      },
      level3: {
        name: 'beingId.base6.level3.name',
        description: 'beingId.base6.level3.description',
      },
      level4: {
        name: 'beingId.base6.level4.name',
        description: 'beingId.base6.level4.description',
      },
      level5: {
        name: 'beingId.base6.level5.name',
        description: 'beingId.base6.level5.description',
      },
      level6: {
        name: 'beingId.base6.level6.name',
        description: 'beingId.base6.level6.description',
      },
    },
  },
  buyerProcess: {
    addressSection: {
      selectShippingAddress:
        'buyerProcess.addressSection.selectShippingAddress',
      yourAddresses: 'buyerProcess.addressSection.yourAddresses',
      addShipping: 'buyerProcess.addressSection.addShipping',
    },
    stepper: {
      shipping: 'buyerProcess.stepper.shipping',
      payment: 'buyerProcess.stepper.payment',
      confirmation: 'buyerProcess.stepper.confirmation',
      address: 'buyerProcess.stepper.address',
    },
    carrier: {
      shipping: 'buyerProcess.carrier.shipping',
      title: 'buyerProcess.carrier.title',
      empty: 'buyerProcess.carrier.empty',
      emptyCarrier: 'buyerProcess.carrier.emptyCarrier',
      searchPlaceholder: 'buyerProcess.carrier.searchPlaceholder',
    },
    confirmationSection: {
      confirmPurchase: 'buyerProcess.confirmationSection.confirmPurchase',
      confirmationMsg: 'buyerProcess.confirmationSection.confirmationMsg',
      product: 'buyerProcess.confirmationSection.product',
      address: 'buyerProcess.confirmationSection.address',
      payment: 'buyerProcess.confirmationSection.payment',
    },
    summary: {
      title: 'buyerProcess.summary.title',
    },
  },
  bid: {
    nav: 'bid.nav',
    title: 'bid.title',
    description: 'bid.description',
    empty: 'bid.empty',
    detail: {
      current: 'bid.detail.current',
      starting: 'bid.detail.starting',
      title: 'bid.detail.title',
      datePlaced: 'bid.detail.datePlaced',
      priceTotal: 'bid.detail.priceTotal',
      viewBid: 'bid.detail.viewBid',
      viewProduct: 'bid.detail.viewProduct',
      viewShipping: 'bid.detail.viewShipping',
      bidNumber: 'bid.detail.bidNumber',
      deliveryAddress: 'bid.detail.deliveryAddress',
      billingAddress: 'bid.detail.billingAddress',
      shippingUpdates: 'bid.detail.shippingUpdates',
      statusTimestamp: 'bid.detail.statusTimestamp',
      shippingAddress: 'bid.detail.shippingAddress',
      paymentInformation: 'bid.detail.paymentInformation',
      cardEnding: 'bid.detail.cardEnding',
      cardExpires: 'bid.detail.cardExpires',
      status: {
        minted: 'bid.detail.status.minted',
        listing_started: 'bid.detail.status.listing_started',
        listing_failed: 'bid.detail.status.listing_failed',
        listed: 'bid.detail.status.listed',
        delist_started: 'bid.detail.status.delist_started',
        delist_failed: 'bid.detail.status.delist_failed',
        delisted: 'bid.detail.status.delisted',
        order_started: 'bid.detail.status.order_started',
        order_completed: 'bid.detail.status.order_completed',
        order_failed: 'bid.detail.status.order_failed',
      },
      costs: {
        products: 'bid.detail.costs.products',
        shipping: 'bid.detail.costs.shipping',
        gas: 'bid.detail.costs.gas',
        tax: 'bid.detail.costs.tax',
        total: 'bid.detail.costs.total',
      },
      steps: {
        bidPlaced: 'bid.detail.steps.bidPlaced',
        shipped: 'bid.detail.steps.shipped',
        delivered: 'bid.detail.steps.delivered',
        ownership: 'bid.detail.steps.ownership',
      },
    },
    auction: {
      title: 'bid.auction.title',
      subtitle: 'bid.auction.subtitle',
      days: 'bid.auction.days',
      timeOver: 'bid.auction.timeOver',
      paused: 'bid.auction.paused',
    },
    dutchauction: {
      title: 'bid.dutchauction.title',
      body: 'bid.dutchauction.body',
    },
  },
  payout: {
    chainItWallet: 'payout.chainItWallet',
    deposit: 'payout.deposit',
    withdraw: 'payout.withdraw',
    changePIN: 'payout.changePIN',
    myLibrary: 'payout.myLibrary',
    myLibraryWallet: 'payout.myLibraryWallet',
    assets: 'payout.assets',
    paymentMethod: 'payout.paymentMethod',
    currency: 'payout.currency',
    coinbase: 'payout.coinbase',
    usdt: 'payout.usdt',
  },
}
