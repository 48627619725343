import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './global.css'

import App from './App'
import { Logger, startI18nConfig, startSentryConfig } from './config'
import { startLoggerConfiguration } from './core'

startLoggerConfiguration()
Logger.debug('Starting the application')

Logger.debug('Starting Sentry configuration')
startSentryConfig()

Logger.debug('Starting i18next configuration')
startI18nConfig()

// TODO move this to a configuration file and import it from here. Don't worry about this, it is going to be removed when the NCW is implemented.
Logger.debug('Starting Web3Modal configuration')
// 1. Get projectId
const projectId = 'bd11bd720f6b044ade45573dc92cfd9f'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
}

// 3. Create a metadata object
const metadata = {
  name: 'ChainIT Marketplace',
  description: 'My Website description',
  url: 'https://develop-marketplace-new.pairedasset.com', // origin must match your domain & subdomain
  icons: [],
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<App />)
