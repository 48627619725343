import { t } from 'i18next'
import React from 'react'

import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { classNames } from '@/core'

import type { LibraryAsset } from '../types'

import { Badge } from '.'

export interface LibraryAssetCardProps {
  asset: LibraryAsset
  className?: string
}

export const LibraryAssetCard: React.FC<LibraryAssetCardProps> = ({
  asset,
  className,
}: LibraryAssetCardProps) => {
  return (
    <div
      className={classNames(
        'bg-white flex flex-col sm:flex-row px-4 sm:px-8 py-6 rounded items-center relative',
        className,
      )}
    >
      <img
        src={asset.imageSrc}
        alt={asset.name}
        className="w-36 h-20 object-contain"
      />
      <div className="flex flex-col sm:ms-8 gap-2 mt-4 sm:mt-0">
        <h3 className="font-medium text-base text-ellipsis line-clamp-4 sm:line-clamp-1">
          {asset.name}
        </h3>
        {!!asset.description && (
          <p className="text-sm text-ellipsis line-clamp-4 sm:line-clamp-2">
            {asset.description}
          </p>
        )}
        <div className="flex gap-4 items-center">
          <ConditionalRendering renderIf={asset.isSelfVerified}>
            <Badge text={t(i18nKeys.common.selfVerified)} icon="certificate" />
          </ConditionalRendering>
          <ConditionalRendering renderIf={asset.isVDTMinted}>
            <Badge text={t(i18nKeys.common.vdtMinted)} icon="link" />
          </ConditionalRendering>
        </div>
      </div>
    </div>
  )
}
