import { t } from 'i18next'
import React from 'react'

import { Button } from '@/common/components'
import { useProfile } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { calculateAge, convertISOToMMDDYYYY } from '@/core'
import { colors } from '@/theme'
import who from '@assets/icons/user-scan.svg'
interface WhoProps {
  handleClickTouchAudit: () => void
}

export const Who: React.FC<WhoProps> = (props) => {
  const { profile } = useProfile()

  if (!profile) {
    return <h1>{t(i18nKeys.ui.loading)}...</h1>
  }
  return (
    <div className="bg-white">
      <div className="bg-black text-white px-4 pl-0 py-2 rounded-md flex items-center gap-2 mb-2">
        <span className="border-l-2 border-white h-6 inline-block"></span>
        <img src={who} alt="User" width={16} />
        <h2 className="font-semibold">{t(i18nKeys.profile.individual.who)}</h2>
      </div>
      <div className="pt-3 border-l-2 border-gray-200 pl-2 mt-2">
        <div className="grid grid-cols-2 gap-4 pl-3">
          <div>
            <p className="text-gray-500">
              {t(i18nKeys.profile.individual.firstName)}
            </p>
            <p className="font-semibold">{profile.firstName}</p>
          </div>
          <div>
            <p className="text-gray-500">
              {t(i18nKeys.profile.individual.lastName)}
            </p>
            <p className="font-semibold">{profile.lastName}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2 pl-3">
          <div>
            <p className="text-gray-500">
              {t(i18nKeys.profile.individual.dob)}
            </p>
            <p className="font-semibold text-gray-900">
              {convertISOToMMDDYYYY(profile?.dateOfBirth)}
            </p>
          </div>
          <div>
            <p className="text-gray-500">
              {t(i18nKeys.profile.individual.age)}
            </p>
            <p className="font-semibold">
              {calculateAge(profile?.dateOfBirth)}
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p className="text-gray-800">
            {t(i18nKeys.profile.individual.beingIDLevelHistory)}
          </p>

          <button
            className="bg-primary-100 text-primary border border-primary rounded-full px-4 py-1 hover:bg-primary-200 min-h-9 whitespace-nowrap"
            onClick={props.handleClickTouchAudit}
          >
            {t(i18nKeys.profile.individual.viewAll)}
          </button>
        </div>
        <div className="mt-4">
          <Button
            mode="contained"
            type="submit"
            color={colors.primary.DEFAULT}
            className="rounded-2xl w-full"
            textColor={colors.surface}
            onClick={props.handleClickTouchAudit}
          >
            {t(i18nKeys.profile.individual.touchAudit)}
          </Button>
        </div>
      </div>
    </div>
  )
}
