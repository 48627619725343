import React, { useMemo } from 'react'

import { Input } from '@/common/components'
import { CheckboxWithDescription } from '@/common/components/form/CheckboxWithDescription'
import { translate } from '@/core'

import { SellPage, PageActionButtons, OptionCards } from '..'
import { servicePricing } from '../constants/service-pricing'
import { useServiceListing } from '../context/service-listing-context'

export default function ServicePricingPage() {
  const pricingType = useServiceListing((state) => state.pricingType)
  const isNegotiable = useServiceListing((state) => state.isNegotiable)
  const areMaterialsIncluded = useServiceListing(
    (state) => state.areMaterialsIncluded,
  )
  const price = useServiceListing((state) => state.price)
  const update = useServiceListing((state) => state.update)

  const options = useMemo(
    () =>
      servicePricing.map((i) => ({
        ...i,
        title: translate(i.title),
        description: translate(i.description),
      })),
    [],
  )

  return (
    <SellPage title={translate((i) => i.sell.nav.servicePricing.title)}>
      <OptionCards
        className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full mt-8"
        options={options}
        selected={pricingType}
        onChange={(v) => update('pricingType', v)}
      />

      <div className="flex flex-col w-full mt-12 gap-4">
        <Input
          id="price"
          name="price"
          label={translate(pricingType.title)}
          placeholder={translate(pricingType.description)}
          onChange={(e) => update('price', e.target.value)}
          value={price}
        />

        <div className="space-y-5">
          <CheckboxWithDescription
            label={translate(
              (i) => i.sell.nav.servicePricing.isNegotiable.label,
            )}
            description={translate(
              (i) => i.sell.nav.servicePricing.isNegotiable.description,
            )}
            checked={isNegotiable}
            onChange={(v) => update('isNegotiable', v)}
          />
          <CheckboxWithDescription
            label={translate(
              (i) => i.sell.nav.servicePricing.materialsIncluded.label,
            )}
            description={translate(
              (i) => i.sell.nav.servicePricing.materialsIncluded.description,
            )}
            checked={areMaterialsIncluded}
            onChange={(v) => update('areMaterialsIncluded', v)}
          />
        </div>
      </div>
      <PageActionButtons />
    </SellPage>
  )
}
