import { useContext, useEffect } from 'react'

import { login } from '@/pages'

import { authContext } from '../context/auth_context'

export const useOnlyAuthenticated = () => {
  const { authenticated, loading } = useContext(authContext)

  useEffect(() => {
    if (!authenticated && !loading) {
      login()
    }
  }, [authenticated, loading])

  return {
    authenticated: authenticated && !loading,
  }
}
