import { t } from 'i18next'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Button, PaymentCardIcon } from '@/common/components'
import { ApplePay, GooglePay, Venmo } from '@/common/components/Paysafe'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { EPaymentMethod } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { BuyerShippingAddress } from '@/common/types'
import Spinner from '@/components/Spinner'
import type {
  CustomerAssetWithPrice,
  PaymentCard,
  PaymentDetailsResponse,
} from '@/core'
import { AssetsService, formatExpirationDate } from '@/core'
import { EventService } from '@/core/events'
import ApplePayIcon from '@assets/icons/apple-pay.svg'
import GooglePayIcon from '@assets/icons/google-pay.svg'
import VenmoIcon from '@assets/icons/venmo.svg'

import type { PaymentHandleToken } from './BuyProcess'
import { CvvModal } from './CvvModal'

//TODO Improve de component to allow multiple Products
// TODO add translations
// TODO replace icons with custom component

export interface ConfirmationInterface {
  listingId: string
  shippingAddress: BuyerShippingAddress
  selectedCard: PaymentCard
  totalPrice: number
  displayPrice: string
  paymentHandleToken: PaymentHandleToken
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed: (error?: string) => void | Promise<void>
  costs: PaymentDetailsResponse
  selectedPaymentMethod: EPaymentMethod
  paymentEmail: string
}

export const Summary: React.FC<ConfirmationInterface> = ({
  listingId,
  shippingAddress,
  selectedCard,
  totalPrice,
  displayPrice,
  paymentHandleToken,
  onPaymentComplete,
  onPaymentFailed,
  costs,
  selectedPaymentMethod,
  paymentEmail,
}) => {
  const [product, setProduct] = useState<CustomerAssetWithPrice>()
  const [cvvModalOpen, setCvvModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { error } = useNotify()
  const { profile } = useContext(authContext)

  const billingAddress = useMemo(() => {
    return profile?.addresses.find((x) => x.isMain)
  }, [profile?.addresses])

  useEffect(() => {
    getProductInfo()
  }, [listingId])

  const getProductInfo = async () => {
    if (!listingId) {
      return
    }

    const res = await AssetsService.getPublicByListingId(listingId)
    const product = await AssetsService.getUserAssetById(res.assetId)

    const data = {
      ...res,
      ...product,
    } as CustomerAssetWithPrice

    setProduct(data)
  }

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined
    const segments = [profile.firstName, profile.middleName, profile.lastName]
    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  const cardEnding = useMemo(() => {
    if (!selectedCard) return
    return t(i18nKeys.profile.order.detail.cardEnding).replace(
      '{LAST_DIGITS}',
      selectedCard?.lastDigits,
    )
  }, [t, selectedCard?.lastDigits])

  const cardExpiration = useMemo(() => {
    if (!selectedCard) return
    return t(i18nKeys.profile.order.detail.cardExpires).replace(
      '{EXPIRATION}',
      formatExpirationDate(
        selectedCard.cardExpiry?.month,
        selectedCard.cardExpiry?.year,
      ),
    )
  }, [t, selectedCard?.cardExpiry])

  let bodyContent = null

  if (product) {
    bodyContent = (
      <div className="min-h-[80vh]">
        <div className="bg-white p-8 max-w-4xl mx-auto sm:min-h-[512px] rounded-md flex flex-col">
          <ul className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
            <li key={product.id} className="flex space-x-6 py-6">
              <img
                src={EventService.buildDocumentImageUrlById(
                  product.serializedImages?.find((x) => x.isMain)?.docId,
                )}
                alt="Not found" //TODO: improve broken image msg
                className="h-32 w-32 flex-none rounded-md bg-gray-100 object-cover object-center"
              />
              <div className="flex-auto space-y-1">
                <h3 className="text-gray-900 text-xl text-bold">
                  <a
                    href={`/search/listing/${listingId}/event/${product.eventIdRef}/detail`}
                  >
                    {product.name}
                  </a>
                </h3>
                <p key={'-prop'}>{product.description}</p>
              </div>
            </li>
          </ul>

          <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt>Subtotal</dt>
              <dd className="text-gray-900">${costs?.sellingPrice}</dd>
            </div>

            <div className="flex justify-between">
              <dt>Shipping</dt>
              <dd className="text-gray-900">${costs?.shippingCost}</dd>
            </div>

            <div className="flex justify-between">
              <dt>Taxes</dt>
              <dd className="text-gray-900">${costs?.costTax}</dd>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Total</dt>
              <dd className="text-base">{displayPrice}</dd>
            </div>
          </dl>

          <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
            <div>
              <dt className="font-medium text-gray-900">Shipping Address</dt>
              <dd className="mt-2">
                <address className="not-italic">
                  <span className="block">{shippingAddress?.name}</span>
                  <span className="block">{shippingAddress?.address}</span>
                  <span className="block">{shippingAddress?.city}</span>
                </address>
              </dd>
            </div>
            <div>
              <dt className="font-medium text-gray-900">Payment Information</dt>
              {selectedPaymentMethod === EPaymentMethod.Card ? (
                <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                  <div className="flex-none">
                    <PaymentCardIcon
                      type={selectedCard.type}
                      className="w-10"
                    />
                  </div>
                  <div className="flex-auto">
                    <p className="text-gray-900">{cardEnding}</p>
                    <p>{cardExpiration}</p>
                  </div>
                </dd>
              ) : selectedPaymentMethod === EPaymentMethod.GooglePay ? (
                <div>
                  <img
                    src={GooglePayIcon}
                    alt="Google Pay"
                    className={'w-10 h-7 ml-2'}
                  />
                </div>
              ) : selectedPaymentMethod === EPaymentMethod.Venmo ? (
                <div>
                  <img
                    src={VenmoIcon}
                    alt="Venmo"
                    className={'w-10 h-7 ml-2'}
                  />
                </div>
              ) : selectedPaymentMethod === EPaymentMethod.ApplePay ? (
                <div>
                  <img
                    src={ApplePayIcon}
                    alt="Apple Pay"
                    className={'w-10 h-7 ml-2'}
                  />
                </div>
              ) : (
                <div className="w-full cursor-default">Unknown</div>
              )}
            </div>
          </dl>

          <div className="mt-16 border-t border-gray-200 py-6 text-right">
            <div className="flex justify-center">
              {selectedPaymentMethod === EPaymentMethod.Card ? (
                <Button
                  onClick={() => setCvvModalOpen(true)}
                  disabled={
                    !paymentHandleToken &&
                    selectedPaymentMethod === EPaymentMethod.Card &&
                    !costs
                  }
                >
                  {t(i18nKeys.ui.confirmPurchase)}
                </Button>
              ) : selectedPaymentMethod === EPaymentMethod.GooglePay ? (
                <GooglePay
                  billingAddress={billingAddress!}
                  totalPrice={totalPrice}
                  onPaymentComplete={onPaymentComplete}
                  onPaymentFailed={() => error('Failed to confirm payment.')}
                  onLoading={setIsLoading}
                />
              ) : selectedPaymentMethod === EPaymentMethod.ApplePay ? (
                <ApplePay
                  billingAddress={billingAddress!}
                  totalPrice={totalPrice}
                  onPaymentComplete={onPaymentComplete}
                  onPaymentFailed={() => error('Failed to confirm payment.')}
                  onLoading={setIsLoading}
                />
              ) : selectedPaymentMethod === EPaymentMethod.Venmo ? (
                <Venmo
                  paymentEmail={paymentEmail}
                  billingAddress={billingAddress!}
                  totalPrice={totalPrice}
                  onPaymentComplete={onPaymentComplete}
                  onPaymentFailed={() => error('Failed to confirm payment.')}
                  onLoading={setIsLoading}
                />
              ) : null}
            </div>
          </div>
        </div>

        <CvvModal
          totalPrice={totalPrice}
          billingAddress={shippingAddress}
          cardHolderName={cardHolderName!}
          handleToken={paymentHandleToken}
          onPaymentComplete={onPaymentComplete}
          onPaymentFailed={onPaymentFailed}
          open={cvvModalOpen}
          onClose={() => setCvvModalOpen(false)}
        />

        {isLoading ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <Spinner />
          </div>
        ) : null}
      </div>
    )
  }

  return bodyContent
}
