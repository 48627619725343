import React from 'react'

export interface CardLabelProps {
  title: string
  value: string | number
}

export const CardLabel: React.FC<CardLabelProps> = ({ title, value }) => {
  return (
    <div className="inline-grid">
      <span className="text-gray-500">{title}</span>
      <span className="font-semibold">{value}</span>
    </div>
  )
}
