import { StepEnum } from '@bit-ui-libs/common'

import type { AssetTemplate } from '@/common/types'
import type { CustomerAssetWithPrice } from '@/core'

export const mockSteps = [
  {
    order: 0,
    typeId: '',
    step: StepEnum.DATA,
    enabled: true,
  },
  {
    order: 1,
    typeId: '',
    step: StepEnum.PHOTO,
    enabled: true,
  },
  {
    order: 2,
    typeId: '',
    step: StepEnum.PRIVACY,
    enabled: true,
  },
]

function mockField(params: {
  name: string
  label?: string
  maxLength?: number
}) {
  return {
    propTypeId: -1,
    propName: params.name,
    label: params.label ?? params.name,
    defaultValue: '',
    hint: '',
    order: 1,
    maxLength: params.maxLength ?? 255,
    propType: {
      id: -1,
      name: 'text',
      friendlyName: 'Text',
      hasOption: false,
      multiValue: false,
      type: 'string',
    },
    required: true,
    visible: true,
  }
}

// todo: add other pages mocks
export const mockStepTemplate: Record<StepEnum, AssetTemplate> = {
  [StepEnum.DATA]: {
    fields: [
      mockField({ name: 'Name', maxLength: 100 }),
      mockField({ name: 'Description', maxLength: 255 }),
    ],
  } as AssetTemplate,
  [StepEnum.PHOTO]: {} as AssetTemplate,
  [StepEnum.CONFIRM]: {} as AssetTemplate,
  [StepEnum.OFFICIAL]: {} as AssetTemplate,
  [StepEnum.QRCODE]: {} as AssetTemplate,
  [StepEnum.RFID]: {} as AssetTemplate,
  [StepEnum.PRIVACY]: {} as AssetTemplate,
  [StepEnum.WITNESS]: {} as AssetTemplate,
  [StepEnum.DOCUMENT]: {} as AssetTemplate,
  [StepEnum.REFERENCE_VDTS]: {} as AssetTemplate,
  [StepEnum.VDT_MINTED]: {} as AssetTemplate,
}

export const mockedCustomerAssetWithPrice = {
  id: '1033a87b-7099-4654-8761-773c0a9d3cd0',
  appName: 'chainit',
  typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
  orgId: '00000000-0000-0000-0000-000000000000',
  status: 'LISTED',
  level: 'C',
  name: 'Test-12-7',
  tags: ['example', 'xd'],
  serializedSteps: [
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'CONFIRM',
      enabled: true,
      order: 4,
      isRequired: true,
      finished: false,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'DATA',
      enabled: true,
      order: 1,
      isRequired: true,
      finished: true,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'PHOTO',
      enabled: true,
      order: 2,
      isRequired: true,
      finished: true,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'OFFICIAL',
      enabled: true,
      order: 5,
      isRequired: false,
      finished: false,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'QRCODE',
      enabled: true,
      order: 6,
      isRequired: true,
      finished: true,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'PRIVACY',
      enabled: true,
      order: 8,
      isRequired: false,
      finished: true,
    },
    {
      typeId: '31844252-9c10-4dfb-88fe-c54e24bf5aa9',
      step: 'WITNESS',
      enabled: true,
      order: 9,
      isRequired: false,
      finished: false,
    },
  ],
  serializedProps: [
    {
      id: '1',
      name: 'Token Name',
      value: 'Test-12-7',
    },
    {
      id: '1',
      name: 'Description',
      value: 'Test-12-7',
    },
    {
      id: '3',
      name: 'Test Radio buttons',
      value: '2018',
    },
    {
      id: '1',
      name: 'Token Condition',
      value: 'Good',
    },
  ],
  serializedImages: [
    {
      docId: '5df54645-f06c-4b6b-9926-6da4269795b3',
      docType: 'PHOTO',
      classification: 'ASSET_PHOTO',
      name: 'A1',
      description: '',
      isMain: true,
    },
    {
      docId: '7151044b-ef88-47c0-8a3f-e9fcf8456a37',
      docType: 'PHOTO',
      classification: 'ASSET_PHOTO',
      name: 'Second',
      description: '',
      isMain: false,
    },
  ],
  serializedCategories: ['Test_Category_MGs', 'Test_Sub_MG'],
  qrCodeRef: 'cd6d9ee2-167b-41b0-bf71-8f7bc48e181c',
  rfIdRef: null,
  eventIdRef: 'f6d45683-c86a-46f5-be0c-84084277d9e8',
  city: 'Rawalpindi',
  state: 'Punjab',
  country: 'Pakistan',
  isVerifiedByWitness: false,
  createdAt: '2023-07-12T10:10:51.158Z',
  isVerifiedByOfficial: false,
  sellingMethod: 'fixed',
  isMinted: true,
  mintedAt: '2023-07-17T11:24:43.412Z',
  typeName: 'Other',
  assetId: 'dc9c9386-8cc1-40e0-879b-d386875d1a41',
  createdBy: 'e56fe817-d1d6-4e42-98ee-65bf4e703ed7',
  price: '123.00',
  startAt: '2023-10-17T09:57:43.300Z',
  endAt: null,
  concurrencyStamp: 'deb39538-00db-410d-9e10-09e08b237048',
  isFreeShipping: false,
  description: null,
  buyerBeingId: 6,
  completeness: 'OnItemDelivered',
  taxCode: null,
} as unknown as CustomerAssetWithPrice
