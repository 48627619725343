import { Popover, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { FaCaretDown, FaChevronRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

import type { ListItemProps, StorefrontMenuItem } from '@/common/types'
import { classNames } from '@/core'

import { useTags } from '../hooks/use-tags'

export const StorefrontMenu: React.FC<ListItemProps<StorefrontMenuItem>> = ({
  item,
}) => {
  const { url, selected } = useTags(item.tags)

  if (!item.items.length) {
    return (
      <li>
        <Link to={url}>
          <span
            className={classNames(
              'text-base leading-tight py-1',
              selected &&
                item.isRoot &&
                !item.items.length &&
                'font-bold border-b-2 border-b-secondary',
            )}
          >
            {item.label}
          </span>
        </Link>
      </li>
    )
  }

  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button className="w-full">
            <li className="flex items-center gap-1 w-full justify-between">
              <span
                className={classNames(
                  'text-base leading-tight py-1',
                  selected &&
                    item.isRoot &&
                    !item.items.length &&
                    'font-bold border-b-2 border-b-secondary',
                )}
              >
                {item.label}
              </span>
              {item.isRoot ? (
                <FaCaretDown className="fill-gray-700" />
              ) : (
                <FaChevronRight className="fill-gray-700" />
              )}
            </li>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classNames(
                'absolute z-10',
                item.isRoot
                  ? 'left-1/2 -translate-x-1/2'
                  : 'translate-x-full -mx-2 -my-8',
              )}
            >
              <div className="flex flex-col gap-6 bg-white w-60 px-4 py-8 rounded-lg shadow">
                {item.items.map((item, itemKey) => (
                  <StorefrontMenu item={item} key={itemKey} />
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
