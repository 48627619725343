import type {
  ChainBaseEvent,
  ChainBaseEventMetadata,
} from '@bit-ui-libs/common'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'

import { Button, Icon } from '@/common/components'
import envVariables from '@/common/envVariables'
import i18nKeys from '@/common/i18nKeys'
import type { GeoLocationRequestInterface } from '@/core'
import { ListingService } from '@/core'
import { AssetsService } from '@/core/assets'
import WhereIcon from '@assets/icons/where.svg'

import { CardLabel } from '../cardLabel'

const defaultPosition = {
  lat: 0,
  lng: 0,
}

interface WherePropsInterface {
  eventId: string
  onClickAudit: () => void
}

export const Where: React.FC<WherePropsInterface> = (props) => {
  const [position, setPosition] =
    useState<google.maps.LatLngLiteral>(defaultPosition)
  const [eventData, setEventData] = useState<ChainBaseEvent>()

  useEffect(() => {
    AssetsService.getAssetWhereInformation(props.eventId).then((result) => {
      setEventData(result)
    })
  }, [props.eventId])

  useEffect(() => {
    if (eventData && eventData.device?.geolocation) {
      const geolocation = eventData.device?.geolocation
      setPosition({ lat: geolocation.latitude, lng: geolocation.longitude })
    } else {
      getGeoLocation(eventData?.data?.metadata)
    }
  }, [eventData])

  const renderMap = useMemo(() => {
    return (
      <APIProvider apiKey={envVariables.GCP_API_KEY}>
        <Map
          defaultCenter={defaultPosition}
          center={position}
          defaultZoom={10}
          className="h-64 sm:h-full"
        >
          <Marker position={position} />
        </Map>
      </APIProvider>
    )
  }, [position])

  const getGeoLocation = async (data?: Partial<ChainBaseEventMetadata>) => {
    if (!data?.city || !data?.country || !data?.state) {
      return
    }
    const options = {
      city: data.city,
      state: data.state,
      country: data.country,
    } as GeoLocationRequestInterface

    const res = await ListingService.getGeolocation(options)

    if (res?.data?.items?.length > 0) {
      setPosition(res?.data?.items[0].position)
    }
  }

  return (
    <div className="bg-white rounded">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 flex flex-col sm:grid sm:grid-cols-2 gap-4">
        <div>
          <div>
            <label className="w-full text-xl font-semibold flex items-center gap-2">
              <img src={WhereIcon} className="size-7" alt="where-icon" />
              {t(i18nKeys.profile.myLibrary.where.title)}
            </label>
            <h2 className="text-2xl font-bold mt-3 mb-3 flex">
              <Icon className="my-auto me-3" icon="compass" size={16} />
              {t(i18nKeys.profile.myLibrary.where.locationDetails)}
            </h2>
          </div>

          <CardLabel
            title={t(i18nKeys.onboarding.address.address)}
            value={`${eventData?.data?.metadata?.city}, ${eventData?.data?.metadata?.country}`}
          />
          <Button
            className="w-full mt-5 hidden sm:block"
            mode="outline"
            onClick={() => props.onClickAudit()}
          >
            {t(i18nKeys.profile.myLibrary.touchAudit)}
          </Button>
        </div>
        {renderMap}

        <Button
          className="w-full sm:hidden"
          mode="outline"
          onClick={() => props.onClickAudit()}
        >
          {t(i18nKeys.profile.myLibrary.touchAudit)}
        </Button>
      </div>
    </div>
  )
}
