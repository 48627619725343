// TODO remove this eslint-disable when it can be fixed. Currently is hard due to missing types in common library
/* eslint-disable react/jsx-no-leaked-render */
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { UserService } from '@/core/users'
import WhoIcon from '@assets/icons/who.svg'

import { CardLabel } from '../cardLabel'

interface WhoPropsInterface {
  userId: string
  onClickAudit: () => void
}

export const Who: React.FC<WhoPropsInterface> = ({ userId, onClickAudit }) => {
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    if (!userId) {
      return
    }
    const userService = new UserService()
    userService.getUserInfo(userId).then((res) => {
      setUser(res)
    })
  }, [userId])

  return (
    <div className="bg-white rounded mb-5">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 grid grid-row gap-4">
        <div className="border-b border-gray-100">
          <div>
            <label className="w-full text-xl font-semibold flex items-center gap-2">
              <img src={WhoIcon} className="size-8" alt="who-icon" />
              {t(i18nKeys.profile.myLibrary.who.title)}
            </label>
            <h2 className="text-2xl font-bold mt-3 mb-3 flex">
              <Icon className="my-auto me-3" icon="error" size={16} />
              {t(i18nKeys.profile.myLibrary.who.details)}
            </h2>
          </div>
          {user && (
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-5">
              <div className="border-2 rounded-full border-black h-26 w-26 m-auto">
                {user.avatarUrl && (
                  <img
                    alt="userImg"
                    src={user.avatarUrl}
                    className="h-24 w-24 m-auto rounded-full"
                  />
                )}
              </div>
              <CardLabel
                title={t(i18nKeys.profile.myLibrary.who.chainItId)}
                value={user.id} // TODO: WitnessCustomerAssetResponse does not include ChainId property
              />
              <CardLabel
                title={t(i18nKeys.profile.beingId.header.subTitle)}
                value={user.beingId.level}
              />
              {user.city && user.state && user.country && (
                <CardLabel
                  title={t(i18nKeys.profile.myLibrary.who.location)}
                  value={`${user.city} ${user.state}, ${user.country}`}
                />
              )}
            </div>
          )}
        </div>
        <div className="sm:grid sm:grid-cols-2 gap-4">
          <Button
            className="w-full"
            mode="outline"
            onClick={() => onClickAudit()}
          >
            {t(i18nKeys.profile.myLibrary.touchAudit)}
          </Button>
        </div>
      </div>
    </div>
  )
}
