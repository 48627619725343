import { LocationAccuracy } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { translate } from '@/core'
import { CoreEventService } from '@/core/core-events'

import type { RadioOptionItem } from '..'
import { PageActionButtons, RadioListWithDescription, SellPage } from '..'
import { PRIVACY_OPTIONS } from '../constants'
import { useProductListing, useProductListingDispatch } from '../context'
import { PrivacyOption } from '../types'
import { ListingType } from '../types/listing-type'

export function SellPrivacyPage() {
  const { type } = useParams<{ type: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const { privacy, event, addresses } = useProductListing()
  const { setPrivacy } = useProductListingDispatch()
  const { error } = useNotify()
  const navigate = useNavigate()

  const options = useMemo(
    () =>
      PRIVACY_OPTIONS.map(
        (i) =>
          ({
            ...i,
            name: t(i.name),
            description: t(i.description),
          }) as RadioOptionItem,
      ),
    [t],
  )

  const selectedOption = useMemo(
    () => options.find((i) => i.id === privacy),
    [options, privacy],
  )

  const handleContinue = async () => {
    try {
      if (isLoading) {
        return false
      }
      setIsLoading(true)
      const privacyMapping: Record<PrivacyOption, LocationAccuracy> = {
        [PrivacyOption.AlmostNothing]: LocationAccuracy.State,
        [PrivacyOption.SomeInformation]: LocationAccuracy.City,
        [PrivacyOption.Everything]: LocationAccuracy.Exact,
      }

      const mainAddress = addresses.find((a) => a.isMain) ?? addresses[0]

      if (!mainAddress) {
        error(translate((i) => i.sell.nav.privacy.error.noAddress))
        navigate('/profile/personalInformation')
        return false
      }

      const service = new CoreEventService() // todo: update export

      const data = {
        locationAccuracy: privacyMapping[privacy],
        city: mainAddress.city,
        state: mainAddress.state,
        country: mainAddress.country,
      }
      if (type === ListingType.Asset) {
        await service.updatePrivacy(event!.id, data)
      } else {
        await service.service.updatePrivacy(event!.id, data)
      }

      setIsLoading(false)
      return true
    } catch (e) {
      setIsLoading(false)
      Logger.error('Failed to update privacy', undefined, e as Error)
      // TODO notify user
      return false
    }
  }

  return (
    <SellPage title={t(i18nKeys.sell.nav.privacy.title)}>
      <RadioListWithDescription
        className="mt-4"
        selected={selectedOption}
        items={options}
        onChange={(i) => setPrivacy(i.id as PrivacyOption)}
      />

      <PageActionButtons
        onContinue={handleContinue}
        continueDisabled={isLoading}
      />
    </SellPage>
  )
}
