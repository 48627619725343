import React, { Fragment } from 'react'

// TODO retrieve asset from backend
const asset = {
  description: 'Product description',
  id: '1',
  images: [
    'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg',
    'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg',
    'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg',
  ],
  name: 'Basic Tee',
  price: 35,
}

type ImagesListProps = {
  images: string[]
}
const ImagesList: React.FC<ImagesListProps> = ({ images }) => {
  return (
    <Fragment>
      <div>
        {images.map((image) => (
          <img
            key={image}
            src={image}
            alt="Product"
            className="size-36 object-center object-cover mb-3"
          />
        ))}
      </div>
    </Fragment>
  )
}

type SelectedImageProps = {
  image: string
}
const SelectedImage = ({ image }: SelectedImageProps) => {
  return (
    <img
      src={image}
      alt="Product"
      className="w-full h-full object-center object-cover mb-3"
    />
  )
}

type AssetDetailsProps = {
  description: string
  name: string
  price: number
}

const Detail: React.FC<AssetDetailsProps> = ({ description, name, price }) => {
  return (
    <Fragment>
      <h1 className="text-3xl font-bold tracking-tight text-gray-900">
        {name}
      </h1>
      <p className="text-2xl font-bold tracking-tight text-gray-900">{price}</p>
      <p className="text-lg tracking-tight text-gray-900">{description}</p>
    </Fragment>
  )
}

const AssetDetail: React.FC = () => {
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-6 gap-3 sm:grid-cols-1 lg:grid-cols-6 justify-center">
        <div className="col-span-1">
          <ImagesList images={asset.images} />
        </div>
        <div className="col-span-3">
          <SelectedImage image={asset.images[0]} />
        </div>
        <div className="col-span-2">
          <Detail
            description={asset.description}
            name={asset.name}
            price={asset.price}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetDetail
