import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, Input } from '@/common/components'
import { useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { SellerShippingAddress } from '@/common/types'
import { toNumber } from '@/core'

const UNITED_STATES_COUNTRY_CODE = 'US'

import {
  AddressCard,
  EmptyCard,
  PageActionButtons,
  SelectAssetSlideOver,
  SellPage,
} from '..'
import { useProductListing, useProductListingDispatch } from '../context'

export function SellDeliveryPage() {
  const { addresses, delivery: data } = useProductListing()
  const { deliveryActions } = useProductListingDispatch()
  const { handleChangeField, handleChangeInput } = deliveryActions

  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)
  const { errors, changeError } = useError({
    address: t(i18nKeys.sell.nav.delivery.error.address),
    weight: t(i18nKeys.sell.nav.delivery.error.weight),
    length: t(i18nKeys.sell.nav.delivery.error.length),
    width: t(i18nKeys.sell.nav.delivery.error.width),
    height: t(i18nKeys.sell.nav.delivery.error.height),
  })

  const handleSelectAddress = useCallback(
    (address?: SellerShippingAddress) => {
      setAddressModalOpen(false)

      if (!address) {
        return
      }

      handleChangeField('address', address)
    },
    [handleChangeField, setAddressModalOpen],
  )

  const handleContinue = () => {
    let hasError = false

    const weight = toNumber(data.weight)
    const length = toNumber(data.length)
    const width = toNumber(data.width)
    const height = toNumber(data.height)

    if (!data.address) {
      changeError('address', true)
      hasError = true
    }

    if (!weight || weight <= 0) {
      changeError('weight', true)
      hasError = true
    }

    if (!length || length <= 0) {
      changeError('length', true)
      hasError = true
    }

    if (!width || width <= 0) {
      changeError('width', true)
      hasError = true
    }

    if (!height || height <= 0) {
      changeError('height', true)
      hasError = true
    }

    return !hasError
  }

  const isUSAddress = useMemo(() => {
    if (!data.address) {
      return false
    }

    const _address = data.address

    return _address.countryCode === UNITED_STATES_COUNTRY_CODE
  }, [data.address])

  useEffect(() => {
    changeError('address', false)
  }, [data.address])

  useEffect(() => {
    changeError('weight', false)
  }, [data.weight])

  useEffect(() => {
    changeError('length', false)
  }, [data.length])

  useEffect(() => {
    changeError('width', false)
  }, [data.width])

  useEffect(() => {
    changeError('height', false)
  }, [data.height])

  return (
    <>
      <SellPage>
        <div className="w-full flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h3 className="font-medium text-sm ">
              {t(i18nKeys.sell.nav.delivery.shipFrom)}
            </h3>

            {data.address && !isUSAddress ? (
              <Alert
                text={t(i18nKeys.sell.nav.delivery.usOnlyWarn)}
                type="warning"
              />
            ) : null}

            {data.address ? (
              <AddressCard
                address={data.address.address}
                name={data.address.name}
                addressType={data.address.type}
                className="w-full"
                mode="card"
                onClick={() => setAddressModalOpen(true)}
              />
            ) : (
              <EmptyCard
                icon="home"
                text={t(i18nKeys.sell.nav.delivery.emptyAddress)}
                onClick={() => setAddressModalOpen(true)}
                error={errors.address.message}
              />
            )}
          </div>

          <div className="flex flex-col sm:flex-row gap-2">
            <Input
              id="weight"
              name="weight"
              type="number"
              label={t(i18nKeys.sell.nav.delivery.weight)}
              placeholder={t(i18nKeys.sell.nav.delivery.weightPlaceholder)}
              value={data.weight}
              onChange={handleChangeInput}
              error={errors.weight.message}
              rightText="lbs"
              maxLength={4}
            />
            <Input
              id="length"
              name="length"
              label={t(i18nKeys.sell.nav.delivery.length)}
              type="number"
              placeholder={t(i18nKeys.sell.nav.delivery.lengthPlaceholder)}
              value={data.length}
              onChange={handleChangeInput}
              error={errors.length.message}
              rightText="in"
              maxLength={4}
            />
            <Input
              id="width"
              name="width"
              label={t(i18nKeys.sell.nav.delivery.width)}
              type="number"
              placeholder={t(i18nKeys.sell.nav.delivery.widthPlaceholder)}
              value={data.width}
              onChange={handleChangeInput}
              error={errors.width.message}
              rightText="in"
              maxLength={4}
            />
            <Input
              id="height"
              name="height"
              label={t(i18nKeys.sell.nav.delivery.height)}
              type="number"
              placeholder={t(i18nKeys.sell.nav.delivery.heightPlaceholder)}
              value={data.height}
              onChange={handleChangeInput}
              error={errors.height.message}
              maxLength={4}
              rightText="in"
            />
          </div>
        </div>
        <PageActionButtons
          onContinue={handleContinue}
          continueDisabled={!data.address || !isUSAddress}
        />
      </SellPage>

      <SelectAssetSlideOver
        items={addresses}
        RenderItem={({ index, item }) => (
          <AddressCard
            key={index}
            address={item.address}
            name={item.name}
            addressType={item.type}
            className="w-full"
            mode="button"
            onClick={() => handleSelectAddress(item)}
          />
        )}
        onSearch={(search, item) =>
          item.name.toLowerCase().includes(search) ||
          item.address.toLowerCase().includes(search)
        }
        title={t(i18nKeys.sell.nav.delivery.chooseModal.title)}
        emptyMessage={t(i18nKeys.sell.nav.delivery.chooseModal.empty)}
        emptySearch={(search) =>
          t(i18nKeys.sell.nav.delivery.chooseModal.emptySearch).replace(
            '{SEARCH}',
            search,
          )
        }
        open={addressModalOpen}
        searchPlaceholder={t(
          i18nKeys.sell.nav.delivery.chooseModal.searchPlaceholder,
        )}
        onClose={handleSelectAddress}
      />
    </>
  )
}
