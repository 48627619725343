import { t } from 'i18next'
import React from 'react'
import ReactPaginate from 'react-paginate'

import i18nKeys from '@/common/i18nKeys'

import styles from './index.module.css'

export interface PaginationProps {
  onSearch: (pageData: { selected: number }) => void
  totalPages: number
  currentPage?: number
}

export const ItemsPagination: React.FC<PaginationProps> = ({
  onSearch,
  totalPages,
  currentPage,
}) => {
  return (
    <div className={styles.paginationFooter}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={t(i18nKeys.ui.next) + ' >'}
        onPageChange={onSearch}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        renderOnZeroPageCount={() => <></>}
        previousLabel={'< ' + t(i18nKeys.ui.previous)}
        activeClassName={styles.selected}
        disabledClassName={styles.disabled}
        pageLinkClassName={styles.pageOption}
        forcePage={currentPage}
      />
    </div>
  )
}
