import { t } from 'i18next'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@/common/components'
import { BEING_ID_LEVELS } from '@/common/constants/being-id'
import { useProfile, useProfileShare } from '@/common/hooks'
import tKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { classNames, dateToStringFormat } from '@/core'

export const BeingIdHeader: React.FC = () => {
  const { profile } = useProfile()
  const { qrCode, copyQR } = useProfileShare()

  const createdAt = useMemo(() => {
    return dateToStringFormat(profile?.createdAt)
  }, [profile?.createdAt])

  const levels = useMemo(() => {
    const result = BEING_ID_LEVELS.filter(
      (i) => i.level.toString() === profile?.beingId?.level.toString(),
    ).map((i) => ({
      level: i.level,
      name: `${t(i.name)}`,
      description: t(i.description),
    }))

    if (result.length === 0) {
      return { level: 0, name: '', description: '' }
    }

    return result[0]
  }, [t, profile])

  return (
    <div className="w-full lg:max-w-full bg-white ps-4 pr-4 pb-4">
      <div className="p-2 justify-end w-full flex ">
        <button className="max-h-px pt-2" disabled={!qrCode} onClick={copyQR}>
          <Icon
            icon="share"
            className={classNames(
              'size-5',
              qrCode ? 'fill-primary hover:fill-primary-300' : 'fill-gray-300',
            )}
          />
        </button>
      </div>
      <div className="grid lg:flex justify-center md:justify-start">
        <div className="me-5 lg:h-auto flex-none bg-cover overflow-hidden inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 justify-center md:justify-start">
          {qrCode ? (
            <img
              src={qrCode}
              alt="QR code"
              style={{ height: '150px', width: '150px' }}
            />
          ) : (
            <Spinner containerClassName="w-[150px] flex items-center justify-center" />
          )}
        </div>
        <div className="bg-white p-4 justify-between leading-normal my-auto">
          <div className="flex justify-between">
            <p className="flex my-auto mx-auto sm:mx-0">
              {t(tKeys.profile.beingId.header.subTitle)}
            </p>
          </div>
          <div className="block sm:flex h-20">
            {levels.level ? (
              <p className="my-auto text-5xl me-5 text-center sm:text-start">
                {levels.level}
              </p>
            ) : null}
            <p className="my-auto font-semibold text-center sm:text-start">
              {levels.description}
              <Link
                to="https://www.chainit.com/being-id"
                target="_blank"
                rel="noopener"
                className="ms-3 my-auto inline-block"
              >
                <Icon icon="question" className="fill-info size-4" />
              </Link>
            </p>
          </div>
          <p>
            {t(tKeys.profile.beingId.header.footer)} {createdAt}
          </p>
        </div>
      </div>
    </div>
  )
}
