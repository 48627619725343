import React from 'react'

import { LoadingPage } from '@/common/components'
import { useOnlyAuthenticated } from '@/common/hooks/use-only-authenticated'
import { featureFlags } from '@/config'

import { Wishlist } from '../components/Wishlist'

import CartDetail from './CartDetail'

export const CartAndWishlistPage: React.FC = () => {
  const { authenticated } = useOnlyAuthenticated()

  if (!authenticated) {
    return <LoadingPage />
  }

  return (
    <div className="min-h-[80vh]">
      {featureFlags.cart ? <CartDetail /> : null}

      <Wishlist />
    </div>
  )
}
