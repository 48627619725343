import { t } from 'i18next'
import React, { useMemo, useState } from 'react'

import { featureFlags } from '@/config'

import tKeys from '../../../common/i18nKeys'

import { AccountInformation } from './AccountInformation'
import { BeingIdSection } from './BeingIdSection'
import { DigitalIdentityHeader } from './DigitalIdentityHeader'
import { IdentityVerification } from './IdentityVerification'
import { IndividualDigitalIdentity } from './IndividualDigitalIdentity'
import { UserAccounts } from './UserAccounts'

export const DigitalIdentity: React.FC = () => {
  const [renderIdentityVerification, setRenderIdentityVerification] =
    useState(false)

  const componentBody = useMemo(() => {
    if (renderIdentityVerification) {
      return (
        <IdentityVerification
          setRenderIdentityVerification={() =>
            setRenderIdentityVerification(false)
          }
        />
      )
    } else {
      return (
        <>
          <h2 className="mb-2 text-2xl">
            {t(tKeys.profile.digitalIdentity.title)}
          </h2>
          <DigitalIdentityHeader />
          <BeingIdSection
            setRenderIdentityVerification={() =>
              setRenderIdentityVerification(true)
            }
          />
          <AccountInformation />
          {featureFlags.profileSection ? <UserAccounts /> : null}
          <IndividualDigitalIdentity />
        </>
      )
    }
  }, [renderIdentityVerification])

  return componentBody
}
