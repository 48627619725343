import type { PagedResponse } from '@bit-ui-libs/common'

import type {
  MarketplaceCategoryResponse,
  MarketplaceTypeResponse,
} from '@/common/types'

import { createQueryParams } from '../auth/utils'
import { doGet } from '../fetch'

// TODO: add this interface into the common library and import it from there
export interface GetAssetTypesByCategoryIdRequest {
  categoryId?: string
  name?: string
  $ids?: string[]
  $orderBy?: string
  $order?: string
  $page?: number
  $perPage?: number
}

// TODO All this methods can be static, there is not need to create an instance of this class
export class CategoriesService {
  async getAll(parentId: string | undefined = undefined) {
    // TODO add this endpoint to the common library and import it from there
    let resource = '/marketplace/v1/categories/list'

    if (parentId) {
      resource += `?assetCategoryId=${parentId}`
    }

    return doGet(resource) as Promise<MarketplaceCategoryResponse[]>
  }

  async getAllByParentId(parentId: string) {
    // TODO add this endpoint to the common library and import it from there
    return doGet(
      `/marketplace/v1/categories/list?parentId=${parentId}`,
    ) as Promise<MarketplaceCategoryResponse[]>
  }

  static async getAssetTypesByCategoryId(
    request: GetAssetTypesByCategoryIdRequest,
  ): Promise<PagedResponse<MarketplaceTypeResponse>> {
    const queryParams = createQueryParams(request)
    // TODO add this endpoint to the common library and import it from there
    return doGet(`/marketplace/v1/types?${queryParams}`) as Promise<
      PagedResponse<MarketplaceTypeResponse>
    >
  }
}
