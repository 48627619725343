import type {
  ChainBaseEvent,
  CustomerAsset,
  GetAssetsRequest,
  IpfsMetadata,
  PagedResponse,
} from '@bit-ui-libs/common'
import { AssetService as CommonAssetService } from '@bit-ui-libs/common'

import { doGet } from '../fetch'
import { serviceConfiguration } from '../service-configuration'

const commonAssetService = new CommonAssetService({
  ...serviceConfiguration,
  route: 'asset',
})

//Added assetVdt because it doesnt exist on common library
export interface AssetVdt {
  platform: string
  immutableTime: string
  nftTokenId: string
  nftMetadataUrl: string
  nftSmartContractAddress: string
  immutableTransactionHash: string
  walletAddress: string
  qrId: string
  assetId: string
  ipfsMetadata: IpfsMetadata
}

export class AssetService {
  static async getUserAssets(
    options: GetAssetsRequest,
  ): Promise<PagedResponse<CustomerAsset>> {
    return commonAssetService.list(options)
  }

  static async getAssetVDT(assetId: string): Promise<AssetVdt> {
    return doGet(`/web3/v1/nft/asset-vdt/${assetId}`)
  }

  static async getAssetWhereInformation(
    eventId: string,
  ): Promise<ChainBaseEvent> {
    return commonAssetService.getAssetChainDetails(eventId)
  }

  static async getById(assetId: string): Promise<CustomerAsset> {
    return doGet(`/assets/v1/asset/${assetId}`) as Promise<CustomerAsset>
  }
}
