export const purchaseSubscriptionStep = Object.freeze({
  payment: 0,
  confirm: 1,
})

export type PurchaseSubscriptionStep = {
  index: number
  label: string
  description: string
}

export const subscriptionSteps = [
  {
    index: purchaseSubscriptionStep.payment,
    label: 'Payment',
    description: 'Select your payment method',
  },
  {
    index: purchaseSubscriptionStep.confirm,
    label: 'Summary',
    description: 'Verify your purchase information',
  },
] as PurchaseSubscriptionStep[]
