import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { FaCartShopping, FaScaleBalanced } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

import { translate } from '@/core'
import HowItWorks from '@assets/images/how_it_works.png'
import Trust from '@assets/images/trust.png'

export const StaticHomePage: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <SubHero />
      <ContentSection />

      <section id="get-started" className="flex flex-col sm:flex-row">
        <CallToAction />
        <CallToActionBuyer />
      </section>
    </div>
  )
}

function HeroSection() {
  return (
    <div className="bg-white -mt-5">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-primary-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-primary-600/10 ring-1 ring-primary-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
              {translate((i) => i.home.hero.header)}
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                {translate((i) => i.home.hero.subtitle)}
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="#get-started"
                  className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {translate((i) => i.ui.getStarted)}
                </a>
                <Link
                  to="https://www.chainit.com"
                  target="_blank"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {translate((i) => i.ui.learnMore)}
                  <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
            <img
              src={Trust}
              alt=""
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}

function ContentSection() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base font-semibold leading-7 text-primary-600">
            {translate((i) => i.home.content.meetVdt)}
          </p>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {translate((i) => i.home.content.howItWorks)}
          </h2>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>{translate((i) => i.home.content.p1)}</p>
              <p className="mt-8">{translate((i) => i.home.content.p2)}</p>
            </div>
            <div>
              <p>{translate((i) => i.home.content.p3)}</p>
            </div>
          </div>
          <div className="mt-10 flex">
            <a
              href="#get-started"
              className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {translate((i) => i.ui.getStarted)}
            </a>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            className="mx-auto rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            src={HowItWorks}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

function SubHero() {
  const features = [
    {
      name: translate((i) => i.home.subhero.card1.name),
      description: translate((i) => i.home.subhero.card1.description),
      href: '#',
      icon: FaScaleBalanced,
    },
    {
      name: translate((i) => i.home.subhero.card2.name),
      description: translate((i) => i.home.subhero.card2.description),
      href: '#',
      icon: LockClosedIcon,
    },
    {
      name: translate((i) => i.home.subhero.card3.name),
      description: translate((i) => i.home.subhero.card3.description),
      href: '#',
      icon: FaCartShopping,
    },
  ]

  return (
    <div className="bg-white py-24 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon
                    className="h-5 w-5 flex-none text-primary-600"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

function CallToAction() {
  const benefits = [
    translate((i) => i.home.callToAction.item1),
    translate((i) => i.home.callToAction.item2),
    translate((i) => i.home.callToAction.item3),
    translate((i) => i.home.callToAction.item4),
  ]

  return (
    <div className="bg-primary-50/20 py-24 flex-1">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 xl:gap-x-20 xl:px-20">
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">
                {translate((i) => i.home.callToAction.sellers.title)}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-900">
                {translate((i) => i.home.callToAction.sellers.description)}
              </p>
              <ul className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-gray-900 ">
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon
                      className="h-7 w-5 flex-none fill-primary"
                      aria-hidden="true"
                    />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-6">
                <Link
                  to="/sell"
                  className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {translate((i) => i.home.callToAction.sellers.callToAction)}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-primary-100 to-primary-200 opacity-25"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

function CallToActionBuyer() {
  return (
    <div className="bg-primary-50/20 py-24 flex-1">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 xl:gap-x-20 xl:px-20">
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">
                {translate((i) => i.home.callToAction.buyers.title)}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-900">
                {translate((i) => i.home.callToAction.buyers.description)}
              </p>
              <div className="mt-6">
                <Link
                  to="/products"
                  className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {translate((i) => i.home.callToAction.buyers.callToAction)}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-primary-100 to-primary-200 opacity-25"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
