import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Icon } from '@/common/components'
import type {
  StorefrontItem,
  StorefrontMenuItem,
  StorefrontSection,
} from '@/common/types'
import type { CustomerAssetWithPrice } from '@/core'
import { StorefrontService } from '@/core'
import storefront_banner from '@assets/images/storefront_banner.png'

import { GallerySection } from '../components/GallerySection'
import { MobileCategoriesMenu } from '../components/MobileCategoriesMenu'
import { ProductSection } from '../components/ProductSection'
import { StorefrontContent } from '../components/StorefrontContent'
import { StorefrontMenu } from '../components/StorefrontMenu'
import { useTags } from '../hooks/use-tags'

export const StorefrontPage: React.FC = () => {
  const navigate = useNavigate()

  const { shortName } = useParams<{ shortName: string }>()
  const [query] = useSearchParams()

  const [search, setSearch] = useState('')
  const [tabs, setTabs] = useState<StorefrontMenuItem[]>([])
  const [sections, setSections] = useState<StorefrontSection[]>([])

  const searchQuery = useMemo(() => query.get('search'), [query])

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const _search = search.trim()

    if (e.key !== 'Enter' || _search.length < 3) {
      return
    }

    navigate(`/store/${shortName}?search=${_search}`)
  }

  const handleFetchStorefront = async () => {
    const storefront = await StorefrontService.getStorefrontByName(shortName!)
    setTabs(storefront.tabs)
    setSections(storefront.sections)
  }

  useEffect(() => {
    if (searchQuery && !search) {
      navigate(`/store/${shortName}`)
    }
  }, [search])

  useEffect(() => {
    handleFetchStorefront()
  }, [])

  const { pageTags } = useTags()

  return (
    <div>
      <img
        alt="storefront_banner"
        src={storefront_banner}
        className="w-full h-96 bg-center object-cover"
      />

      <nav className="bg-white py-4">
        <div className="max-w-[1441px] mx-auto flex flex-col sm:flex-row sm:items-center justify-between gap-6 md:gap-24 px-2">
          <ul className="hidden md:flex items-center flex-1 gap-6 justify-between">
            {tabs.map((tab, tabKey) => (
              <StorefrontMenu item={tab} key={tabKey} />
            ))}
          </ul>
          <MobileCategoriesMenu items={tabs} />
          <div className="rounded-2xl border border-gray-500 items-center px-2 flex md:max-w-80 flex-1">
            <Icon icon="search" className="fill-primary size-4" />

            <input
              type="search"
              placeholder="Search all in Fujifilm"
              className="flex-1 border-0 rounded-2xl text-gray-600 placeholder:text-gray-600 placeholder:text-base placeholder:font-medium lg:text-sm lg:leading-6 focus:ring-0"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={onSearchKeyDown}
            />
          </div>
        </div>
      </nav>

      <div className="max-w-[1441px] mx-auto mt-12 mb-20 p-2">
        {pageTags.length || searchQuery ? (
          <StorefrontContent tags={pageTags} search={searchQuery} />
        ) : (
          <div className="flex flex-col gap-12">
            {sections.map((section, sectionKey) => {
              if (section.type === 'product') {
                return (
                  <ProductSection
                    key={sectionKey}
                    items={section.items as CustomerAssetWithPrice[]}
                  />
                )
              }

              if (section.type === 'gallery') {
                return (
                  <GallerySection
                    key={sectionKey}
                    items={section.items as StorefrontItem[]}
                  />
                )
              }
              return null
            })}
          </div>
        )}
      </div>
    </div>
  )
}
