import type { CustomerAsset } from '@bit-ui-libs/common'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Identifiers } from '@/common/constants'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { Logger } from '@/config'
import { AssetService } from '@/core/assets/asset.service'
import { EventService } from '@/core/events'

export type AssetHookParams = {
  assetId?: string
}

export const useAsset = ({ assetId }: AssetHookParams) => {
  const { error } = useNotify()

  const [asset, setAsset] = useState<CustomerAsset>()

  const imageSrc = useMemo(() => {
    if (!asset) return

    let mainImage = asset.serializedImages.find((image) => image.isMain)

    if (!mainImage) {
      if (!asset.serializedImages.length) {
        return
      }
      mainImage = asset.serializedImages[0]
    }

    if (!mainImage) return

    return EventService.buildDocumentImageUrlById(mainImage.docId)
  }, [asset])

  const description = useMemo(() => {
    if (!asset) return

    return asset.serializedProps.find(
      (p) => p.name === Identifiers.ASSET_DESCRIPTION_PROP,
    )?.value
  }, [asset])

  const handleRefresh = useCallback(async () => {
    try {
      if (!assetId) return

      const _assetService = await AssetService.getById(assetId)
      setAsset(_assetService)
    } catch (err) {
      Logger.error('Failed to fetch asset', undefined, err as Error)
      error(t(i18nKeys.common.somethingWentWrong))
    }
  }, [error, setAsset, assetId, t])

  useEffect(() => {
    if (!assetId) return

    handleRefresh()
  }, [assetId])

  return {
    asset,
    imageSrc,
    description,
    isLoading: !asset,
  }
}
