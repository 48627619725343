import React, { useContext, useMemo } from 'react'

import type { UserProfileType } from '../common/context/auth_context'
import { authContext } from '../common/context/auth_context'

import Spinner from './Spinner'

export type UserProfileImageProps = {
  size?: number
  section?: 'header'
}

export const UserProfileImage: React.FC<
  React.PropsWithChildren<UserProfileImageProps>
> = ({ size = 40 }: UserProfileImageProps) => {
  const { profileLoaded, profile } = useContext(authContext)

  const initials = useMemo(() => {
    if (profileLoaded && profile?.firstName && profile?.lastName) {
      return `${profile.firstName[0]}${profile.lastName[0]}`
    }
  }, [profileLoaded, profile])

  const fontSize = useMemo(() => size / 2.5, [size])

  if (!initials) {
    return <Spinner size={size / 2} />
  }

  return (
    <div
      className="rounded-full bg-primary-100 hover:bg-primary-200 transition-all duration-200 border border-primary flex items-center justify-center"
      style={{ width: size, height: size }}
    >
      <span
        className="font-semibold tracking-wide text-primary-600"
        style={{ fontSize }}
      >
        {initials}
      </span>
    </div>
  )
}

type PlaceHoldItAvatarProps = UserProfileImageProps & {
  profileLoaded: boolean
  profile?: UserProfileType
}

export const PlaceHoldItAvatar: React.FC<PlaceHoldItAvatarProps> = ({
  profileLoaded,
  profile,
  size,
  section,
}) => {
  const pictureURL = useMemo(() => {
    let url = `https://placehold.it/${size}x${size}?text=??`
    if (profileLoaded && profile?.avatarUrl) {
      url = profile?.avatarUrl
    } else if (profileLoaded && profile?.firstName && profile?.lastName) {
      url = `https://placehold.it/${size}x${size}?text=${profile.firstName[0]}${profile.lastName[0]}`
    }

    return url
  }, [profileLoaded, profile, size, section])

  const containerSizeClasses = useMemo(() => {
    let classes = 'h-40 w-40'

    if (section === 'header') {
      classes = 'h-10 w-10'
    }
    return classes
  }, [section])

  return (
    <img
      className={`inline-block rounded-full m-auto ${containerSizeClasses}`}
      src={pictureURL}
      alt="Avatar"
    />
  )
}
