import CryptoJS from 'crypto-js'

// @ts-nocheck
// const crypto = require('crypto');
export const getCrypto = () => {
  // ie 11.x uses msCrypto
  return crypto || window.Crypto
}

export const getCryptoSubtle = () => {
  const crypto = getCrypto()
  // safari 10.x uses webkitSubtle
  return crypto.subtle
}

export const createRandomString = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.'
  let random = ''
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43)),
  )
  randomValues.forEach((v) => (random += charset[v % charset.length]))
  return random
}

export const createRandomBytes = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.'
      .split('')
      .map((x) => x.charCodeAt(0))
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43)),
  )
  const bytes: any[] = []
  for (const val of randomValues) {
    bytes.push(charset[val % charset.length])
  }
  return bytes
}

export const encode = (value: string) => btoa(value)
export const decode = (value: string) => atob(value)

export const createQueryParams = (params: Object) => {
  return Object.keys(params)
    .filter((k) => typeof params[k] !== 'undefined')
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
}

export const sha256Async = async (s: any) =>
  getCryptoSubtle().digest({ name: 'SHA-256' }, new TextEncoder().encode(s))

export const urlEncodeB64 = (input: string) => {
  const b64Chars = { '+': '-', '/': '_', '=': '' }
  // @ts-ignore
  return input.replace(/[+/=]/g, (m) => b64Chars[m])
}

const decodeB64 = (input: string) =>
  decodeURIComponent(
    decode(input)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

export const urlDecodeB64 = (input: string) =>
  decodeB64(input.replace(/_/g, '/').replace(/-/g, '+'))

export const bufferToBase64UrlEncoded = (input: unknown) => {
  // @ts-ignore
  const ie11SafeInput = new Uint8Array(input)
  return urlEncodeB64(encode(String.fromCharCode(...Array.from(ie11SafeInput))))
}

export const stringToBase64UrlEncoded = (input: string) => {
  return urlEncodeB64(encode(input))
}

export const generateCodeVerifier = () =>
  bufferToBase64UrlEncoded(createRandomBytes())
// @ts-ignore
export const generateChallenge = async (verifier: string) =>
  bufferToBase64UrlEncoded(await sha256Async(verifier))

export const generateState = (
  clientId: string,
  redirectUri: string,
  app: string,
  version: string,
  rnd = null,
) => {
  // @ts-ignore
  rnd = rnd || createRandomString()
  return stringToBase64UrlEncoded(
    JSON.stringify({ clientId, redirectUri, app, version, rnd }),
  )
}

export const cryptoAesEncrypt = async (key: string, value: string) => {
  return CryptoJS.AES.encrypt(key, value).toString()
}

export const cryptoAesDecrypt = async (encryptedValue: string, key: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, key)
  const decoded = bytes.toString(CryptoJS.enc.Utf8)
  return decoded
}
