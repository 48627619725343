import type { Device, DeviceVdt } from '@bit-ui-libs/common'
import * as elliptic from 'elliptic'
import { ethers } from 'ethers'
import { t } from 'i18next'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'

import {
  Icon,
  Button,
  ConfirmationModal,
  AuthGoogleDrive,
  AuthOneDrive,
  PinInput,
} from '@/common/components'
import { NCW } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { cryptoAesEncrypt } from '@/core/auth/utils'
import { DEVICE_IS_PERSONAL_DEFAULT_VALUE, DeviceService } from '@/core/devices'
import {
  getDeviceModel,
  getDeviceOSName,
  getUniqueDeviceName,
} from '@/core/devices/device-utils'
import { GoogleDriveService } from '@/core/googleDrive'
import { getDeviceLocation } from '@/core/location'
import { OneDriveService } from '@/core/oneDrive'
import ApplicationStorage from '@/core/storage'
import { UserService } from '@/core/users/user.service'
import { Web3Service } from '@/core/web3'
import { PersonalInformationCompleted, OnboardingStepper } from '@/pages'
import { colors } from '@/theme'
import googleDriveIcon from '@assets/images/Google_Drive.png'
import oneDriveIcon from '@assets/images/Microsoft_Office.png'
import walletIcon from '@assets/images/wallet-icon.png'

interface WalletKeys {
  privateKey: string
  publicKey: string
  address: string
}

export interface OneDriveFileUploaderHandles {
  handleGetMicrosoftAccessToken: () => void
}

export interface GoogleOAuthHandles {
  handleGetGoogleAccessToken: () => void
}

enum Screen {
  Backup = 'backup',
  Pin = 'pin',
}
enum WalletStatus {
  Creating = 'creating',
  Success = 'success',
}
enum StorageOption {
  GoogleDrive = 'googleDrive',
  OneDrive = 'oneDrive',
  Locally = 'locally',
}

const messages = [
  'Creating wallet...',
  'Generating keys...',
  'Securing your keys...',
  'Almost there...',
  'Finalizing...',
]

export const Wallet = () => {
  const [renderScreen, setRenderScreen] = useState<Screen | string>('')
  const [storageOption, setStorageOption] = useState<StorageOption | string>('')
  const [wallet, setWallet] = useState<WalletKeys>({
    privateKey: '',
    publicKey: '',
    address: '',
  })
  const [walletStatus, setWalletStatus] = useState<WalletStatus | string>('')
  const [isShowConfirmationModal, setIsShowConfirmationModal] =
    useState<boolean>(false)

  const [userPin, setUserPin] = useState<string>('')
  const [repeatUserPin, setRepeatUserPin] = useState<string>('')
  const [loadingMessage, setLoadingMessage] = useState(messages[0])

  const googleAuthRef = useRef<GoogleOAuthHandles>(null)
  const microsoftAuthRef = useRef<OneDriveFileUploaderHandles>(null)
  const userService = new UserService()

  const { profile, userVdt } = useContext(authContext)

  const { error } = useNotify()

  useEffect(() => {
    const fetchWalletData = async () => {
      const walletData = await loadWallet()
      setWallet(walletData)
    }
    if (profile?.id) {
      fetchWalletData()
    }
  }, [profile])

  useEffect(() => {
    if (walletStatus !== WalletStatus.Creating) return

    let currentMessageIndex = 0

    const intervalId = setInterval(() => {
      if (currentMessageIndex < messages.length - 1) {
        currentMessageIndex = (currentMessageIndex + 1) % messages.length
        setLoadingMessage(messages[currentMessageIndex])
      }
    }, 4000)

    return () => clearInterval(intervalId)
  }, [walletStatus])

  const loadWallet = async () => {
    const EC = elliptic.ec
    const ec = new EC(NCW.SECP256K1)
    const key = ec.genKeyPair()
    const privateKey = key.getPrivate(NCW.HEX)
    const publicKey = key.getPublic(NCW.HEX).slice(2)
    const address = ethers.computeAddress(NCW['0X'] + publicKey)
    const encryptedPrivateKey = await cryptoAesEncrypt(
      privateKey,
      profile?.id || '',
    )
    ApplicationStorage.encryptedPrivateKey = encryptedPrivateKey
    return { privateKey, publicKey, address }
  }

  const handleShowError = (msg?: string) => {
    error(msg || t(i18nKeys.common.somethingWentWrong))
  }

  const handleUpdateUserInfo = async () => {
    if (!profile?.userId) return
    try {
      const data = {
        storageType: storageOption,
        id: profile?.userId,
        walletAddress: wallet.address,
      }
      const res = await userService.updateUserInfo(data, profile?.userId)
      return res
    } catch (err) {
      Logger.error('Update user error', undefined, err as Error)
      handleShowError()
    }
  }

  const handleClickContinue = () => {
    if (storageOption === StorageOption.GoogleDrive && googleAuthRef.current) {
      googleAuthRef.current.handleGetGoogleAccessToken()
    } else if (
      storageOption === StorageOption.OneDrive &&
      microsoftAuthRef.current
    ) {
      microsoftAuthRef.current.handleGetMicrosoftAccessToken()
    }
  }
  const handleAccessToken = async (token: string) => {
    if (token === 'failed') return
    ApplicationStorage.socialToken = token
    handleUpdateUserInfo()
    setWalletStatus(WalletStatus.Creating)
    const fileName = 'chainIT-' + wallet.address
    const encryptKey = await encrypt()
    if (storageOption === StorageOption.GoogleDrive) {
      try {
        await GoogleDriveService.uploadFileOnGoogleDrive(
          encryptKey,
          token,
          fileName,
        )
        handleFileUploaded()
      } catch {
        handleShowError(t(i18nKeys.onboarding.errors.uploadFileError))
      }
    } else if (storageOption === StorageOption.OneDrive) {
      try {
        await OneDriveService.uploadFileOnOneDrive(encryptKey, token, fileName)
        handleFileUploaded()
      } catch {
        handleShowError(t(i18nKeys.onboarding.errors.uploadFileError))
      }
    }
  }

  const handleFileUploaded = () => {
    completeOnboarding()
  }
  const mapStorageOption = () => {
    const storageOptionsMap: { [key: string]: string } = {
      googleDrive: 'Google Drive',
      oneDrive: 'One Drive',
      dropbox: 'Drop Box',
      iCloud: 'iCloud',
      locally: 'Store Locally',
    }
    return storageOptionsMap[storageOption] || ''
  }

  const encrypt = async () => {
    const salt = await handleGetUserNonce()
    const encrypted1 = await cryptoAesEncrypt(wallet.privateKey, userPin)
    const encrypted2 = await cryptoAesEncrypt(wallet.privateKey, salt)
    const finalEncrypted = await handleCipherEncryptToBE(
      wallet.address + '.' + encrypted1 + '.' + encrypted2,
    )
    return finalEncrypted
  }

  const handleGetUserNonce = async () => {
    try {
      const res = await userService.getUserSalt()
      return res.nonce
    } catch (err) {
      Logger.error('Get cipher nonce error', undefined, err as Error)
      handleShowError()
    }
  }

  const handleCipherEncryptToBE = async (encryptedkey: string) => {
    try {
      const res = await userService.cipherEncrypt(encryptedkey)
      return res.cipherString
    } catch (err) {
      Logger.error('Get cipher encrypt error', undefined, err as Error)
      handleShowError()
    }
  }

  const initUserVdt = async (device: Device) => {
    const deviceLocation = await getDeviceLocation()
    return Web3Service.initUserVdt({
      deviceId: device.id,
      latitude: deviceLocation.coords.latitude ?? 0,
      longitude: deviceLocation.coords.longitude ?? 0,
      meanSeaLevel: 0,
      recipientAddress: wallet.address,
    })
  }

  const waitForUserVdtToBeCreated = (
    onCreated: () => void,
    onTimeout: () => void,
  ) => {
    let attempts = 0
    return setInterval(async () => {
      const userVdt = await Web3Service.getUserVDT({ userId: profile?.userId! })
      if (userVdt) {
        onCreated()
      } else {
        if (attempts > 5) {
          onTimeout()
        } else {
          attempts++
        }
      }
    }, 5000)
  }

  const completeOnboarding = async () => {
    const deviceLocation = await getDeviceLocation()
    const deviceStatus = await getDeviceStatus()
    if (!deviceStatus.deviceExists || !deviceStatus.device) {
      deviceStatus.device = await registerDevice(
        deviceLocation.coords.latitude,
        deviceLocation.coords.longitude,
      )
      deviceStatus.deviceExists = true
    }

    if (!userVdt) {
      await initUserVdt(deviceStatus.device)
    }

    if (!deviceStatus.vdtExists) {
      let intervalSeed: NodeJS.Timeout | undefined = undefined
      const onIVDTCreated = async () => {
        clearInterval(intervalSeed)
        await mintDevice(deviceStatus.device!)
        deviceStatus.vdtExists = true
        setWalletStatus(WalletStatus.Success)
      }

      const onIVDTTimeout = () => {
        handleShowError(t(i18nKeys.onboarding.errors.ivdtTimeout))
        clearInterval(intervalSeed)
      }

      intervalSeed = waitForUserVdtToBeCreated(onIVDTCreated, onIVDTTimeout)
    }
  }

  const mintDevice = async (device: Device) => {
    const deviceLocation = await getDeviceLocation()
    DeviceService.initDeviceVdt({
      deviceId: device.id,
      latitude: deviceLocation.coords.latitude ?? 0,
      longitude: deviceLocation.coords.longitude ?? 0,
      meanSeaLevel: 0,
      recipientAddress: wallet.address,
    })
  }
  const registerDevice = async (latitude: number, longitude: number) => {
    return DeviceService.registerDevice({
      externalId: uuid(),
      latitude,
      longitude,
      meanSeaLevel: 0,
      ownerUserId: profile?.userId || '',
      recipientAddress: wallet?.address,
      model: getDeviceModel(),
      name: getUniqueDeviceName(),
      operativeSystem: getDeviceOSName(),
    })
  }
  const getDeviceStatus = async () => {
    const devices = await DeviceService.searchDevices({
      isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
      name: getUniqueDeviceName(),
      userId: profile?.userId!,
      // TODO add orgId filter when available
    })

    const deviceExists = devices.totalCount > 0
    let deviceVdt: DeviceVdt | null = null

    if (deviceExists) {
      // Verify if it is minted
      const firstDevice = devices.items[0]
      deviceVdt = await Web3Service.getDeviceVdt(firstDevice.id)
    }

    return {
      deviceExists,
      vdtExists: deviceVdt !== null,
      device: deviceExists ? devices.items[0] : null,
    }
  }

  const handleChooseStorage = (option: string) => {
    setStorageOption(option)
    setRenderScreen('')
  }

  return (
    <div className="container mx-auto">
      <OnboardingStepper initialStep={4} />
      <ConfirmationModal
        handleClose={() => setIsShowConfirmationModal(false)}
        isOpen={isShowConfirmationModal}
        handleContinue={() => {
          setStorageOption(StorageOption.Locally)
          setRenderScreen('')
          setIsShowConfirmationModal(false)
        }}
      />
      <AuthGoogleDrive ref={googleAuthRef} onAccessToken={handleAccessToken} />

      <AuthOneDrive ref={microsoftAuthRef} onAccessToken={handleAccessToken} />

      {renderScreen === '' && walletStatus === '' && (
        <div
          className={`md:w-[432px] mx-auto px-5 p-5 rounded-md pb-5 shadow-md mb-5 bg-[${colors.onboardingBackGround}]`}
        >
          <h1
            className="text-[32px] font-semibold text-center mb-3"
            color={colors.primary[950]}
          >
            {t(i18nKeys.onboarding.myWallet.myWalletTitle)}
          </h1>
          <p
            className="text-base font-normal text-center"
            color={colors.primary[950]}
          >
            {t(i18nKeys.onboarding.myWallet.newWallet)}
          </p>

          <button
            className={`text-left w-full border-[1px] p-3 border-solid rounded-[12px] mt-8 flex items-center justify-between mb-2 cursor-pointer
             ${storageOption ? `bg-primary-50 border-primary` : 'border-primary-950'} text-primary-950`}
            onClick={() => setRenderScreen(Screen.Backup)}
          >
            <div className="flex items-center">
              <Icon
                icon={storageOption ? 'checkCircle' : 'upload'}
                color={colors.primary.DEFAULT}
                size={16}
              />
              <div className="ml-3">
                <h4 className="font-medium text-sm">
                  {t(i18nKeys.onboarding.myWallet.whereSecureIt)}
                </h4>
                <p className="text-xs text-left">
                  {storageOption
                    ? mapStorageOption()
                    : t(i18nKeys.onboarding.myWallet.secureContent)}
                </p>
              </div>
            </div>

            <Icon icon="arrowRight" color={colors.primary.DEFAULT} size={16} />
          </button>

          <button
            className={`text-left w-full border-[1px] p-3 border-solid rounded-[12px] mt-8 flex items-center justify-between mb-2 cursor-pointer text-primary-950 ${repeatUserPin ? `bg-primary-50 border-primary` : `border-primary-950`}
          `}
            onClick={() => setRenderScreen(Screen.Pin)}
          >
            <div className="flex items-center">
              <Icon
                icon={repeatUserPin ? 'checkCircle' : 'lock'}
                color={colors.primary.DEFAULT}
                size={16}
              />
              <div className="ml-3">
                <h4 className="font-medium text-sm">
                  {t(i18nKeys.onboarding.myWallet.howSecureIt)}
                </h4>
                <p className="text-xs text-left">
                  {repeatUserPin
                    ? 'Secret PIN ****'
                    : t(i18nKeys.onboarding.myWallet.secureCreatePin)}
                </p>
              </div>
            </div>

            <Icon icon="arrowRight" color={colors.primary.DEFAULT} size={16} />
          </button>

          <button
            className={`my-8 font-medium text-sm text-white rounded-2xl w-full py-3 
          ${!storageOption || !repeatUserPin ? 'bg-gray-300' : 'bg-primary'}
        `}
            onClick={() => handleClickContinue()}
            disabled={!storageOption || !repeatUserPin}
          >
            {t(i18nKeys.ui.continue)}
          </button>
        </div>
      )}

      {renderScreen === Screen.Backup && (
        <div
          className={`md:w-[432px] mx-auto px-8 rounded-md shadow-md mb-5 pb-3 bg-[${colors.onboardingBackGround}]`}
        >
          <button
            className="underline text-base font-semibold relative text-primary-950"
            onClick={() => setRenderScreen('')}
          >
            <Icon
              icon="arrowRight"
              color={colors.primary[950]}
              size={22}
              className="absolute top-5 rotate-180"
            />
          </button>
          <h1
            className="text-[32px] font-semibold text-center mb-3"
            color={colors.primary[950]}
          >
            {t(i18nKeys.onboarding.setBackup.setBackupTitle)}
          </h1>
          <p
            className="text-base font-normal text-center"
            color={colors.primary[950]}
          >
            {t(i18nKeys.onboarding.setBackup.setBackupText)}
          </p>

          <div
            className={`bg-info-50 text-info-600 rounded-xl py-4 px-5 flex mt-5`}
          >
            <Icon
              icon="infoCircle"
              color={colors.info[600]}
              size={22}
              className="mr-3"
            />
            <p className="text-base font-semibold">
              {t(i18nKeys.onboarding.setBackup.infoContent)}
            </p>
          </div>

          <div className="my-8">
            <h4 className="text-[20px] font-bold text-center">
              {t(i18nKeys.onboarding.setBackup.chooseStorage)}
            </h4>

            <div className="flex flex-wrap mt-7 justify-between items-center md:px-10">
              <button
                className={`cursor-pointer text-center w-[35%] h-[100px] border-[1px] border-solid py-5 rounded-md mb-10 
                ${storageOption === StorageOption.GoogleDrive ? `bg-primary-50 border-[${colors.primary.DEFAULT}]` : `border-primary-950`}
              `}
                onClick={() => handleChooseStorage(StorageOption.GoogleDrive)}
              >
                <img
                  className="m-auto"
                  src={googleDriveIcon}
                  alt="GoogleDrive"
                />
                <p className={`text-xs text-primary-950 mt-2`}>
                  {t(i18nKeys.onboarding.setBackup.googleDrive)}
                </p>
              </button>
              <button
                className={`cursor-pointer text-center w-[35%] h-[100px] border-[1px] border-solid py-5 rounded-md mb-10 
                     ${
                       storageOption === StorageOption.OneDrive
                         ? `bg-primary-50 border-[${colors.primary.DEFAULT}]`
                         : `border-primary-950`
                     }
                   `}
                onClick={() => handleChooseStorage(StorageOption.OneDrive)}
              >
                <img className="m-auto" src={oneDriveIcon} alt="One Drive" />
                <p className={`text-xs text-primary-950 mt-2`}>
                  {t(i18nKeys.onboarding.setBackup.oneDrive)}
                </p>
              </button>
            </div>
            {/* TODO: below are the other storage options*/}
            {/* <div
                className={`cursor-pointer text-center w-[35%] h-[100px] border-[1px] border-solid py-5 rounded-md mb-10 
                   ${storageOption === 'iCloud' ? 'bg-primary-50 border-primary' : 'border-primary-950'}
                 `}
                onClick={() => handleChooseStorage('iCloud')}
              >
                <img className="m-auto" src={iCloudIcon} alt="Icloud" />
                <p className="text-xs text-primary-950 mt-2">
                  {t(i18nKeys.onboarding.setBackup.iCloud)}
                </p>
              </div>
              <div
                className={`cursor-pointer text-center w-[35%] h-[100px] border-[1px] border-solid py-5 rounded-md mb-10 
             ${storageOption === 'dropbox' ? 'bg-primary-50 border-primary' : 'border-primary-950'}
           `}
                onClick={() => handleChooseStorage('dropbox')}
              >
                <img className="m-auto" src={dropBoxDriveIcon} alt="DropBox" />
                <p className="text-xs text-primary-950 mt-2">
                  {t(i18nKeys.onboarding.setBackup.dropbox)}
                </p>
              </div> */}
          </div>

          {/* <div className="text-center py-[40px] relative">
                <div
                  className="text-sm before:content-[''] before:h-[1px] before:absolute before:left-0 before:top-[50px] before:bg-gray-400 before:w-full"
                  color={colors.gray[400]}
                >
                  <span className="inline-block px-3 z-10 relative bg-white">
                    {' '}
                    Or{' '}
                  </span>
                </div>
              </div>

              <div className="text-center">
                <button
                  className={`underline text-base font-semibold 
                  ${storageOption === 'locally' ? 'text-primary-50 bg-primary' : 'text-primary-950'}
                `}
                  onClick={() => setIsShowConfirmationModal(true)}
                >
                  {t(i18nKeys.onboarding.setBackup.storeLocally)}
                </button>
              </div> */}
        </div>
      )}
      {renderScreen === Screen.Pin && (
        <div
          className={`lg:w-2/6 mx-auto bg-[${colors.onboardingBackGround}] px-5 rounded-md pb-3 shadow-md mb-5`}
        >
          <button
            className={`underline text-base text-primary-950] font-semibold relative`}
            onClick={() => {
              setRenderScreen('')
              setUserPin('')
              setRepeatUserPin('')
            }}
          >
            <Icon
              icon="arrowRight"
              color={colors.primary[950]}
              size={22}
              className="absolute top-5 rotate-180"
            />
          </button>
          <h1
            className="text-[32px] font-semibold text-center mb-3"
            color={colors.primary[950]}
          >
            {userPin.length === NCW.PIN_LENGTH
              ? t(i18nKeys.onboarding.myWallet.repeatPIN)
              : t(i18nKeys.onboarding.myWallet.createPIN)}
          </h1>

          <div className="flex mt-10 justify-center">
            <div className="input-grp">
              {userPin.length < NCW.PIN_LENGTH ? (
                <PinInput
                  key="user-pin"
                  handleChange={(value) => setUserPin(value)}
                  pin={userPin}
                />
              ) : (
                <PinInput
                  key="repeat-user-pin"
                  handleChange={(value) => setRepeatUserPin(value)}
                  pin={repeatUserPin}
                  error={
                    repeatUserPin.length === NCW.PIN_LENGTH &&
                    repeatUserPin !== userPin
                  }
                />
              )}
              {repeatUserPin.length === NCW.PIN_LENGTH &&
                repeatUserPin !== userPin && (
                  <p
                    className={`mt-4 text-center font-semibold text-[20px] text-red-900`}
                  >
                    {t(i18nKeys.onboarding.errors.pinNotMatched)}
                  </p>
                )}
            </div>
          </div>
          {!!repeatUserPin && (
            <div className="text-center my-5">
              <Button
                mode="contained"
                type="submit"
                color={colors.primary.DEFAULT}
                className="rounded-2xl w-[270px]"
                textColor={colors.surface}
                disabled={repeatUserPin !== userPin}
                onClick={() => setRenderScreen('')}
              >
                {t(i18nKeys.ui.save)}
              </Button>
            </div>
          )}
        </div>
      )}
      {walletStatus === WalletStatus.Creating && (
        <div
          className={`md:w-[350px] mx-auto text-center my-8 rounded-md py-5 bg-[${colors.onboardingBackGround}]`}
        >
          <h1 className="text-lg font-semibold">
            {t(i18nKeys.onboarding.creatingWallet.creatingWalletTitle)}
          </h1>
          <p className="mt-2 text-gray-600">
            {t(i18nKeys.onboarding.creatingWallet.creatingWalletText)}
          </p>
          <div className="text-center my-2">
            <img
              src={walletIcon}
              alt="creating wallet"
              className="mx-auto animate-heartbeat"
            />
          </div>
          <div className="flex items-center justify-center gap-2">
            <Spinner size={15} />
            <p className="font-medium text-gray-600">{loadingMessage}</p>
          </div>
        </div>
      )}
      {walletStatus === WalletStatus.Success && (
        <div
          className={`md:w-[432px] mx-auto text-center my-8 rounded-md px-3 py-6 bg-[${colors.onboardingBackGround}]`}
        >
          <PersonalInformationCompleted />
        </div>
      )}
    </div>
  )
}
