import type { PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { Order } from '@/common/types'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import { OrderService } from '@/core'

import { OrdersList } from '../components'

// TODO missing translations
export const OrderHistoryPage: React.FC = () => {
  const { error } = useNotify()

  const [orders, setOrders] = useState<PagedResponse<Order>>()

  const handleFetchHistory = async (page: number = 1) => {
    try {
      const orders = await OrderService.getOrders({ orderType: 'BUY', page })
      setOrders(orders)
    } catch (err) {
      Logger.error('Failed to fetch order history', undefined, err as Error)
      error('Failed to fetch order history')
    }
  }

  useEffect(() => {
    handleFetchHistory()
  }, [])

  return (
    <div className="bg-white">
      <div className="py-6 sm:py-8">
        <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {t(i18nKeys.profile.order.title)}
            </h1>
            <p className="mt-2 text-sm text-gray-500">
              {t(i18nKeys.profile.order.description)}
            </p>
          </div>
        </div>

        <div className="mt-16">
          <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
            <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {orders ? (
                <OrdersList
                  {...orders}
                  onSearch={async (page: number) =>
                    handleFetchHistory(page + 1)
                  }
                />
              ) : (
                <Spinner containerClassName="flex items-center justify-center py-12" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
