import { t } from 'i18next'
import React, { Fragment, useCallback } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import { classNames } from '@/core'

import { Icon } from '../../common/components'
import { profileRoutes } from '../../routes/profile-routes'

import styles from './index.module.css'

export const Profile: React.FC = () => {
  const location = useLocation()

  const isCurrentRoute = useCallback(
    (route: string) => `/profile/${route}` === location.pathname,
    [location.pathname],
  )

  return (
    <div
      className={classNames(
        'md:flex h-100 min-h-[720px]',
        styles.profileWraper,
      )}
    >
      <div className="flex md:w-72 flex-col lg:block border-r border-gray-200 ">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto pl-4 overflow-hidden">
          <nav className="flex flex-1 flex-col">
            <ul className="-mx-2 space-y-1">
              {profileRoutes
                .filter((i) => !i.hideOnMenu)
                .map((item, key) => (
                  <Fragment key={key}>
                    {item.showSeparator ? <hr /> : null}
                    <li>
                      <Link
                        to={item.externalPath ?? item.route.path!}
                        target={item.target}
                        className={classNames(
                          isCurrentRoute(item.route.path!)
                            ? styles.selectedTab
                            : 'hover:bg-gray-50',
                          'group flex gap-x-3  p-2 text-sm leading-6 font-semibold text-gray-700',
                        )}
                      >
                        <Icon
                          className="mt-auto mb-auto"
                          icon={item.icon!}
                          size={20}
                        />
                        {t(item.label!)}
                      </Link>
                    </li>
                  </Fragment>
                ))}
            </ul>
          </nav>
        </div>
      </div>
      <div className="container mx-auto p-5 max-w-screen-lg">
        <Outlet />
      </div>
    </div>
  )
}
