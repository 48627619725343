import { t } from 'i18next'
import React, { useMemo } from 'react'
import { FaYoutube } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

import ChainITLogo from '@assets/icons/chainIT_marketplace_logo.svg'
import FacebookLogo from '@assets/icons/facebook.svg?react'
import InstagramLogo from '@assets/icons/instagram.svg?react'
import LinkedInLogo from '@assets/icons/linkedIn.svg?react'
import XLogo from '@assets/icons/x-twitter.svg?react'

import { colors } from '../../theme'

const navigation = {
  help: [
    {
      name: 'footer.help.buy',
      href: 'https://support.chainit.com/how-do-i-buy-a-product',
    },
    {
      name: 'footer.help.sell',
      href: 'http://support.chainit.com/how-do-i-sell-a-product',
    },
    { name: 'footer.help.sellerCenter', href: '#' },
  ],
  support: [
    {
      name: 'footer.support.contact',
      href: 'https://support.chainit.com/kb-tickets/new',
    },
    {
      name: 'footer.support.returns',
      href: 'https://support.chainit.com/chainitx-returns',
    },
  ],
  learn: [
    {
      name: 'footer.learn.vdt',
      href: 'https://support.chainit.com/what-are-validated-data-tokens',
    },
    {
      name: 'footer.learn.ivdt',
      href: 'https://support.chainit.com/what-is-a-web3-digital-identity',
    },
    {
      name: 'footer.learn.ovdt',
      href: 'https://support.chainit.com/what-are-validated-data-tokens',
    },
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/_chainit_',
      icon: (props: any) => <InstagramLogo {...props} />,
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Chainit-113882568325638',
      icon: (props: any) => <FacebookLogo {...props} />,
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCkmqFjwiIcVu6nF2jIb0O5Q',
      icon: (props: any) => <FaYoutube {...props} />,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/chain-it-bit',
      icon: (props: any) => <LinkedInLogo {...props} />,
    },
    {
      name: 'X',
      href: 'https://twitter.com/_ChainIT_',
      icon: (props: any) => <XLogo {...props} />,
    },
  ],
}

export default function Footer() {
  const currentYear = useMemo(() => new Date().getFullYear().toString(), [])

  return (
    <footer
      className="bg-white border-t-2 border-[#E6EEFF]"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-2xl md:max-w-[1440px] px-6 pb-8 pt-12 lg:px-8">
        <div className="mb-8 xl:grid xl:grid-cols-3 xl:gap-8">
          <img className="h-9 sm:h-10" src={ChainITLogo} alt="ChainIT" />
          <div className="mt-16 sm:grid sm:grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="grid grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-xl font-bold leading-6 text-gray-900">
                  {t`footer.help.title`}
                </h3>
                <FooterLinks links={navigation.help} />
              </div>
              <div>
                <h3 className="text-xl font-bold leading-6 text-gray-900">
                  {t`footer.support.title`}
                </h3>
                <FooterLinks links={navigation.support} />
              </div>
            </div>
            <div className="md:grid md:gap-8">
              <div className="mt-12 sm:mt-0">
                <h3 className="text-xl font-bold leading-6 text-gray-900">
                  {t`footer.learn.title`}
                </h3>

                <FooterLinks links={navigation.learn} />
              </div>
            </div>
          </div>
        </div>

        <div className="h-px bg-gray-900/10 absolute start-0 end-0"></div>
        <div className="pt-8 md:flex md:items-center md:justify-between">
          <div className="flex justify-center sm:justify-start space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                target="_blank"
                rel="noreferrer noopener"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon
                  className="h-6 w-6"
                  aria-hidden="true"
                  fill={colors.details}
                />
              </Link>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0 text-center sm:text-start">
            {t`footer.copyright`.replace('{YEAR}', currentYear)}
          </p>
        </div>
      </div>
    </footer>
  )
}

/**
 * Represents a link
 */
type LinkItem = {
  name: string
  href: string
}

interface FooterLinksProps {
  links: LinkItem[]
}

/**
 * Represents a list of links intended to be displayed at the footer
 */
function FooterLinks(props: FooterLinksProps) {
  return (
    <ul className="mt-6 space-y-3">
      {props.links.map((item) => (
        <li key={t(item.name)}>
          <Link
            to={item.href}
            target="_blank"
            rel="noreferrer noopener"
            className={`text-base leading-6 hover:text-blue-800`}
            style={{ color: colors.cardContrast }}
          >
            {t(item.name)}
          </Link>
        </li>
      ))}
    </ul>
  )
}
