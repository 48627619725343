export class HttpError extends Error {
  data: any
  status: number

  private constructor(message: string, data: any, status: number) {
    super(message)
    this.data = data
    this.status = status

    // This line is necessary to make the instanceof operator work
    Object.setPrototypeOf(this, HttpError.prototype)
  }

  static async fromResponse(response: Response) {
    let message = response.statusText
    let data = null

    try {
      data = await response.json()
      message = data?.message
    } catch {}

    return new HttpError(message, data, response.status)
  }
}
