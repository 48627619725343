import type {
  StorefrontMenuItem,
  StorefrontSection,
} from '@/features/storefront'
import storefront_tag_group_1 from '@assets/images/storefront_tag_group_1.png'
import storefront_tag_group_2 from '@assets/images/storefront_tag_group_2.png'
import storefront_tag_group_3 from '@assets/images/storefront_tag_group_3.png'
import storefront_tag_group_4 from '@assets/images/storefront_tag_group_4.png'
import storefront_tag_group_5 from '@assets/images/storefront_tag_group_5.png'
import storefront_tag_group_6 from '@assets/images/storefront_tag_group_6.png'

import { mockedCustomerAssetWithPrice } from '.'

export const mockedTabs: StorefrontMenuItem[] = [
  { label: 'Home', tags: [], items: [], isRoot: true },
  {
    label: 'Trending',
    tags: ['trending', 'featured'],
    items: [],
    isRoot: true,
  },
  { label: 'Best Sellers', tags: ['best seller'], items: [], isRoot: true },
  {
    label: 'Mirrorless Camera',
    tags: [],
    isRoot: true,
    items: [
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [
          {
            label: 'Fujifilm X-T4',
            tags: [],
            items: [
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
            ],
          },
          {
            label: 'Fujifilm X-T4',
            tags: [],
            items: [
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
              {
                label: 'Fujifilm X-T4',
                tags: [],
                items: [],
              },
            ],
          },
          {
            label: 'Fujifilm X-T4',
            tags: [],
            items: [],
          },
        ],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
      {
        label: 'Fujifilm X-T4',
        tags: [],
        items: [],
      },
    ],
  },
  { label: 'Lenses', tags: ['lenses'], items: [], isRoot: true },
  {
    label: 'Compact Cameras',
    tags: ['compact camera'],
    items: [],
    isRoot: true,
  },
  { label: 'Binoculars', tags: ['binoculars'], items: [], isRoot: true },
  { label: 'Other', tags: ['other'], items: [], isRoot: true },
]

export const mockedSections: StorefrontSection[] = [
  {
    type: 'gallery',
    items: [
      {
        label: "What's New",
        imageSrc: storefront_tag_group_1,
        tags: ['new'],
      },
      {
        label: 'Mirrorless Cameras',
        imageSrc: storefront_tag_group_2,
        tags: ['mirrorless', 'camera'],
      },
      {
        label: 'Lenses',
        imageSrc: storefront_tag_group_3,
        tags: [],
      },
      {
        label: 'Compact Cameras',
        imageSrc: storefront_tag_group_4,
        tags: [],
      },
    ],
  },
  {
    type: 'product',
    items: [
      mockedCustomerAssetWithPrice,
      mockedCustomerAssetWithPrice,
      mockedCustomerAssetWithPrice,
      mockedCustomerAssetWithPrice,
    ],
  },
  {
    type: 'gallery',
    items: [
      {
        label: 'Binoculars',
        imageSrc: storefront_tag_group_5,
        tags: [],
      },
      {
        label: 'Sale Items',
        imageSrc: storefront_tag_group_6,
        tags: [],
      },
    ],
  },
]
