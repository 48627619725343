import envVariables from '@/common/envVariables'
export class EventService {
  static buildDocumentImageUrlById(docId?: string): string {
    if (!docId) {
      return ''
    }
    return `${envVariables.API_BASE_URL}/core-events/v1/chain-assets/attachments/${docId}/view`
  }

  static buildAssetQrCodeUrl(
    id: string,
    width: number,
    height: number,
  ): string {
    if (!id || !width || !height) {
      return ''
    }
    return `${envVariables.API_BASE_URL}/core-events/v1/qrcode?id=${id}&width=${width}&height=${height}`
  }
}
