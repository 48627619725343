import type { StepsCardStatus } from '.'

export const cardClassNames: Record<StepsCardStatus, string> = {
  completed: 'border-primary shadow shadow-primary',
  current:
    'border-yellow shadow hover:shadow-lg hover:shadow-yellow shadow-yellow transition-shadow transition-duration-300 ease-in-out cursor-pointer',
  upcoming: 'border-gray-300',
}

export const iconClassNames: Record<StepsCardStatus, string> = {
  completed: 'fill-primary',
  current: 'fill-yellow',
  upcoming: 'fill-gray-300',
}

export const stepClassNames: Record<StepsCardStatus, string> = {
  completed: 'text-primary font-medium',
  current: 'text-yellow font-medium',
  upcoming: 'text-gray-300',
}
