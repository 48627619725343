import type { CustomerAsset } from '@bit-ui-libs/common'
import { t } from 'i18next'
import { isUndefined, isNull, isEmpty } from 'lodash'
import React from 'react'

import { Button, Icon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { EventService } from '@/core/events'
import { colors } from '@/theme'
import WhatIcon from '@assets/images/what-icon.png'

import { CardLabel } from '../cardLabel'

interface WhatFullPropsInterface {
  product: CustomerAsset
  onClickback: () => void
}

export const WhatFull: React.FC<WhatFullPropsInterface> = (props) => {
  return (
    <>
      <Button
        onClick={() => props.onClickback()}
        mode="text"
        color={colors.linkGreen}
      >
        {t(i18nKeys.ui.back)}
      </Button>

      <div className="bg-white rounded mb-5">
        <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 lg:px-8 sm:grid sm:grid-row gap-4">
          <div className="border-b border-gray-100">
            <div>
              <label className="w-full text-xl font-semibold flex items-center gap-2">
                <img src={WhatIcon} className="size-5" alt="what-icon" />
                {t(i18nKeys.profile.myLibrary.what.title)}
              </label>
              <ConditionalRendering
                renderIf={props.product.isVerifiedByOfficial}
              >
                <h2 className="text-xl mt-3 mb-5 flex">
                  <Icon
                    className="my-auto me-3"
                    icon="checkCircle"
                    color={colors.primary[500]}
                    size={20}
                  />
                  {t(i18nKeys.profile.myLibrary.what.verifiedByOfficials)}
                </h2>
              </ConditionalRendering>
            </div>
            <ConditionalRendering
              renderIf={
                !isNull(props.product.qrCodeRef) &&
                !isUndefined(props.product.qrCodeRef) &&
                !isEmpty(props.product.qrCodeRef)
              }
            >
              <div className="sm:grid sm:grid-cols-2 gap-4 mb-5">
                <div>
                  <h2 className="text-xl mt-3 mb-5 font-bold">
                    {props.product.name}
                  </h2>
                  <CardLabel
                    title="Asset QR Code"
                    value={props.product.qrCodeRef!}
                  />
                </div>
                <img
                  src={EventService.buildAssetQrCodeUrl(
                    props.product.qrCodeRef!,
                    200,
                    200,
                  )}
                  // TODO add translation
                  alt="Asset QR Code"
                  className="m-auto"
                />
              </div>
            </ConditionalRendering>
          </div>
          <div className="border-b border-gray-100">
            <div>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="camera" size={20} />
                {t(i18nKeys.profile.myLibrary.what.images)}
              </h2>
            </div>
            <div className="flex flex-col sm:grid sm:grid-cols-5 gap-4 mb-5">
              {props.product.serializedImages?.map((image, key) => (
                <img
                  key={key}
                  src={EventService.buildDocumentImageUrlById(image.docId)}
                  alt={image.name ?? ''}
                  className="h-full w-full object-cover object-center sm:rounded-lg"
                />
              ))}
            </div>
          </div>
          <div>
            <div>
              <h2 className="text-2xl font-bold mt-3 mb-3 flex">
                <Icon className="my-auto me-3" icon="error" size={20} />
                {t(i18nKeys.profile.myLibrary.what.details)}
              </h2>
            </div>

            <div className="flex flex-col sm:grid sm:grid-cols-6 gap-4 mb-5">
              {props.product.serializedProps?.map((e, index) => (
                <CardLabel
                  key={`what-asset-prop-${index}`}
                  title={e.name}
                  value={e.value}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
