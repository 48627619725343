import React from 'react'

import Content from './Content'

export type EmptyLayoutProps = {}

const EmptyLayout: React.FC<React.PropsWithChildren<EmptyLayoutProps>> = ({
  children,
}) => {
  return (
    <div className="bg-slate-100 h-full">
      <div className="pt-5 h-full">
        <Content>{children}</Content>
      </div>
    </div>
  )
}

export default EmptyLayout
