import { t } from 'i18next'
import React, { useMemo, useState } from 'react'

import { Button, Icon, SlideOver } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { colors } from '@/theme'

export interface SelectAssetSlideOverProps<T> {
  open?: boolean
  title: string
  emptyMessage?: string
  items: T[]
  searchPlaceholder?: string
  emptySearch?: (search: string) => string
  onSearch: (search: string, item: T) => boolean
  onClose?: () => void | Promise<void>
  onCreate?: () => void
  RenderItem: (props: { index: number; item: T }) => React.ReactNode
  isLoading?: boolean
}

export const SelectAssetSlideOver = <T extends object>(
  props: SelectAssetSlideOverProps<T>,
) => {
  const [search, setSearch] = useState<string>('')

  const items = useMemo(() => {
    const _search = search.toLowerCase()
    return props.items.filter((i) => props.onSearch(_search, i))
  }, [props.items, props.onSearch, search])

  return (
    <SlideOver
      title={props.title}
      onClose={props.onClose}
      open={props.open}
      HeaderAppend={
        <div className="flex items-center mt-2 gap-4">
          <div className="relative rounded-md shadow-sm flex-1">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Icon icon="search" size={20} color={colors.gray[400]} />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              placeholder={props.searchPlaceholder}
            />
          </div>
          {props.onCreate ? (
            <Button className="rounded-md" onClick={props.onCreate}>
              {/* TODO add translation */}
              New
            </Button>
          ) : null}
        </div>
      }
    >
      {props.isLoading ? (
        <div className="w-full flex justify-center">
          <Spinner text={t(i18nKeys.ui.loading)} />
        </div>
      ) : (
        items.map((asset, key) => (
          <props.RenderItem key={key} index={key} item={asset} />
        ))
      )}
      {items.length === 0 && props.items.length > 0 && (
        <div className="text-center flex flex-col items-center">
          <Icon icon="search" size={48} color={colors.gray[300]} />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            {props.emptyMessage ?? t(i18nKeys.common.noItemsFound)}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {props.emptySearch
              ? props.emptySearch(search)
              : t(i18nKeys.common.emptySearchItems).replace('{SEARCH}', search)}
          </p>
        </div>
      )}
    </SlideOver>
  )
}
