import envVariables from '../../common/envVariables'
import { doGet, doPost, doPatch } from '../fetch'

export class AuthService {
  // TODO change the "any" type to what this endpoint is expecting
  async biometricsAuth(input: any) {
    return doPost(`users/v1/end-user/auth/accept-challenge`, input)
  }

  // TODO check parameter "userType" if it is needed. We should be only using (maybe) "end-user". If it is needed
  // changing the type to an enum or a string literal type, not a generic string
  async createAuthToken(
    input: {
      clientId: string
      code: string
      codeVerifier: string
      state: string | null
      sessionId: string
    },
    userType: string,
  ) {
    return doPost(`users/v1/${userType}/auth/exchange-code`, input)
  }

  async refreshToken(
    input: {
      clientId: string
      refreshToken: string
    },
    userType: string,
  ) {
    return doPost(`users/v1/${userType}/auth/refresh-token`, input)
  }

  // TODO this method should be in users service
  async getProfile(id: string) {
    return doGet(`/users/v1/end-user/users/${id}`)
  }

  // TODO this method should be in users service
  async getMainAddress() {
    return doGet(`/users/v1/end-user/addresses/main`)
  }

  async finalizeDocumentsAuth(props: { userId: string; sessionId: string }) {
    return doPatch(
      `/users/v1/end-user/users/${props.userId}/complete-onboarding`,
      props,
    )
  }

  static get signOutUrl() {
    const clientId = envVariables.AUTH0_CLIENT_ID
    const redirectUri = window.location.origin
    return `${envVariables.API_BASE_URL}/users/v1/end-user/auth/sign-out?clientId=${clientId}&redirectUri=${redirectUri}`
  }
}
