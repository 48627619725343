import type {
  ChainAssetEventData,
  CreateAttachmentsPayload,
  CreateChainAssetRequest,
  CreateQrAttachmentRequest,
  GenerateQrRequest,
  LinkQrRequest,
  QrPackageCodeResponse,
  UpdateAttachmentRequest,
  UpdateLocationPrivacyRequest,
} from '@bit-ui-libs/common'
import { ChainAssetService, ChainServiceService } from '@bit-ui-libs/common'

import { doPost, doPut } from '..'
import { serviceConfiguration } from '../service-configuration'

import type { ChainServiceRequest, ChainServiceResponse } from '.'

const chainAssetService = new ChainAssetService(serviceConfiguration)
const chainServiceService = new ChainServiceService(serviceConfiguration)

export interface ChainAssetRequest extends CreateChainAssetRequest {
  data: CreateChainAssetRequest['data'] & {
    asset?: ChainAssetEventData & {
      tags: string[]
    }
  }
}

export class CoreEventService {
  async chainAssets(params: ChainAssetRequest) {
    return doPost('/core-events/v1/chain-assets', { ...params })
  }

  async initAttachments(params: CreateAttachmentsPayload) {
    return chainAssetService.createAttachments(params)
  }

  async updateAttachments(eventId: string, params: UpdateAttachmentRequest) {
    return chainAssetService.updateAttachments(eventId, params)
  }

  async uploadQr(eventId: string, params: CreateQrAttachmentRequest) {
    return doPost(`/core-events/v1/chain-assets/${eventId}/qr`, {
      ...params,
    }) as Promise<void>
  }

  async linkQrCode(qrId: string, params: LinkQrRequest) {
    return doPut(`/core-events/v1/qrcode/${qrId}/link`, {
      ...params,
    }) as Promise<QrPackageCodeResponse>
  }

  async createQrCode(params: GenerateQrRequest) {
    return doPost(
      `/core-events/v1/qrcode/system-generated?width=300&height=300&special=false`,
      { ...params },
    ) as Promise<QrPackageCodeResponse>
  }

  async updatePrivacy(eventId: string, params: UpdateLocationPrivacyRequest) {
    return chainAssetService.updateLocationPrivacy(eventId, params)
  }

  async deleteAttachment(attachmentId: string) {
    return chainAssetService.deleteAttachments(attachmentId)
  }

  service = {
    chainService: async (params: ChainServiceRequest) => {
      return doPost(`/core-events/v1/chain-services`, {
        ...params,
      }) as Promise<ChainServiceResponse>
    },

    initAttachments: async (params: CreateAttachmentsPayload) => {
      return chainServiceService.createAttachments(params)
    },
    updateAttachments: async (
      eventId: string,
      params: UpdateAttachmentRequest,
    ) => {
      return chainServiceService.updateAttachments(eventId, params)
    },

    uploadQr: async (eventId: string, params: CreateQrAttachmentRequest) => {
      return doPost(`/core-events/v1/chain-services/${eventId}/qr`, {
        ...params,
      }) as Promise<void>
    },

    updatePrivacy: async (
      eventId: string,
      params: UpdateLocationPrivacyRequest,
    ) => {
      return chainServiceService.updateLocationPrivacy(eventId, params)
    },

    deleteAttachment: async (attachmentId: string) => {
      return chainServiceService.deleteAttachments(attachmentId)
    },
  }
}
