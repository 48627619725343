import { useMemo } from 'react'

import { useProfile } from '.'

export const ALLOWED_LEVELS = [1, 2, 4, 6]

export function useBeingIDCheck() {
  const { profile } = useProfile()

  const currentLevel = useMemo(() => {
    return profile?.beingId?.level ? Number(profile.beingId.level) : 10
  }, [profile?.beingId?.level])

  const allowed = useMemo(
    () => ALLOWED_LEVELS.includes(currentLevel),
    [currentLevel],
  )

  const required = useMemo(() => {
    const levels = ALLOWED_LEVELS.filter((i) => i <= currentLevel)
    return Math.max(...levels)
  }, [currentLevel])

  return {
    allowed,
    loading: !profile?.beingId,
    currentLevel,
    required,
  }
}
