import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { Logger } from '@/config'
import type { ShareableIVDTResponse } from '@/core'
import { UserService } from '@/core'

import i18nKeys from '../i18nKeys'

import { useNotify, useProfile } from '.'

// TODO add translations
export const useProfileShare = () => {
  const { error, info } = useNotify()
  const { profile } = useProfile()

  const [shareProfileIVDT, setShareProfileIVDT] =
    useState<ShareableIVDTResponse>()

  const handleFetchQR = async () => {
    try {
      const service = new UserService()
      const result = await service.shareProfileIVDT()
      setShareProfileIVDT(result)
    } catch (e) {
      Logger.error('Failed to fetch share profile IVDT', undefined, e as Error)
      error(t(i18nKeys.common.somethingWentWrong))
    }
  }

  const handleCopyIVDT = async () => {
    if (!shareProfileIVDT) return
    await navigator.clipboard.writeText(shareProfileIVDT?.token)
    info('Copied iVDT to clipboard!')
  }
  const handleCopyQR = async () => {
    if (!shareProfileIVDT) return
    await navigator.clipboard.writeText(shareProfileIVDT?.qrImgUrl)
    info('Copied QR URL to clipboard!')
  }

  useEffect(() => {
    if (!profile || shareProfileIVDT) return

    handleFetchQR()
  }, [profile])

  return {
    token: shareProfileIVDT?.token,
    qrCode: shareProfileIVDT?.qrImgUrl,
    copyIVDT: handleCopyIVDT,
    copyQR: handleCopyQR,
  }
}
